<script setup>
import { inject, computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { to } from 'await-to-js'
import {useEnvStore} from '@dutypay/store-modules'

const composables = inject('composables')
const { jurisdictionList, setJurisdiction, getJurisdictionByLocale } = composables.useJurisdictionSwitch()
const {locale, setLocaleMessage} = useI18n({useScope: 'global'});
const {t} = useI18n();
// eslint-disable-next-line
const tKAAliases = [
  t('hs.jurisdiction.listValue-')
]

async function handleJurisdictionSwitch(jurisdiction) {
  setJurisdiction(locale, jurisdiction);
  let translationValues, error;
  [error, translationValues] = await to(import('/src/locales/' + locale.value + '/translationValues.json'));
  if (error) {
    throw error;
  }
  let elementLocale;
  [error, elementLocale] = await to(import('@dutypay/components-library/src/locales/element-plus/' + locale.value + '.js'));
  if (error) {
    throw error;
  }
  setLocaleMessage(locale.value, Object.assign(elementLocale.default, translationValues.default));
}

const envStore = useEnvStore()
const assetsApiEndpoint = computed(() => {
  return `${envStore.apiUrls.dutypay}/api/v0/assets`;
});

onMounted(() => {
  function resizeViewportHeight() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  resizeViewportHeight();
  window.addEventListener('resize', () => {
    resizeViewportHeight();
  });
})
</script>
<script>
  export default {
    name: 'PageContainer'
  };
</script>

<template>
  <div class="page-container dp-flex column justify-content__space-between">
    <div class="header">
      <img :src="`${assetsApiEndpoint}/logo/dutypay/standard/light/svg`"
           alt="DutyPay" class="application-logo speak-never"/>
      <el-dropdown class="jurisdiction-select"
                   trigger="click"
                   :tabindex="0"
                   @command="handleJurisdictionSwitch">
        <div class="jurisdiction-select__trigger dp-flex row">
          <DpFlag :flagISOCode="getJurisdictionByLocale(locale).flagISOCode"/><DpIcon icon="chevron-down" :margin="false"/>
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item
              v-for="(jurisdiction, index) in jurisdictionList"
              :key="`select-jurisdiction-${index}`"
              :command="jurisdiction"
              >
              <span><DpFlag :flagISOCode="jurisdiction.flagISOCode"/></span>
              <span>{{ t(`hs.jurisdiction.listValue-${jurisdiction.translationKeyId}`) }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <div>
      <div class="content">
        <slot></slot>
      </div>
    </div>
    <div class="footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<i18n>{}</i18n>

<style lang="scss">
  .page-container {
    min-height: calc(var(--vh, 1vh) * 100); // Adjustment for iOS screen height
    color: #FFFFFF;

    h1, h2, h3, h4, h5, h6, p,
    a, a:hover, a:focus, a:visited, a:active {
      color: #FFFFFF;
    }

    h2 {
      margin-bottom: 1rem;
    }

    .application-logo {
      width: auto;
      height: 32px;
    }
  }

  // Light font on dark background
  .redirect-message {
    text-align: center;
    color: #FFFFFF;
  }

  .el-form-item__label {
    line-height: 2rem;
    color: #FFFFFF;
  }

  .el-form--label-top .el-form-item__label {
    padding: 0;
  }

  button.dp-button {
    width: 100%;
    font-size: 1rem;
  }

  .el-loading-mask {
    background-color: rgba(81, 81, 81, .9);
    border-radius: 8px;
  }
</style>

<style scoped lang="scss">
  @media screen and (max-width: 768px) {
    .page-container .footer a {
      display: block;
      margin: 1em 0;
    }
  }

  .page-container {
    margin: 0 auto;
    max-width: 480px;

    .header {
      text-align: center;
      margin: 1.5rem auto;
    }

    .content {
      margin: 1.5rem auto;
      padding: 0 1.5rem;
    }

    .footer {
      margin: 1.5rem 1em;
      text-align: center;

      a {
        margin-top: 1em;
      }
    }
  }

  .jurisdiction-select__trigger {
    position: absolute;
    margin-inline-start: .5rem;
    transform: translateY(1.2rem);
    padding: .33rem .5rem;
    color: #ffffff;
    cursor: pointer;

    i {
      opacity: 0;
    }

    &:hover {
      background: #ffffff;
      border-radius: 4px;
      color: #525252;

      i {
        opacity: 1;
      }
    }
  }
</style>
