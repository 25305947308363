<script setup>
import { defineEmits, defineProps, inject } from "vue";
import { useI18n } from "vue-i18n";
import { ref, reactive } from 'vue';
import { to } from "await-to-js";
import { useEnvStore } from "@dutypay/store-modules";
import { interceptor } from "@dutypay/utilities";

const { t } = useI18n();
const composables = inject("composables");
const emit = defineEmits(["update", "trigger-authenticate"]);
const props = defineProps({
  modelValue: { type: Object, required: true },
});
const loginData = composables.useTwoWayBinding(props, emit, "modelValue");
const mobileNumberAvailable = ref(false);
const resendDisabled = ref(true);
const notification = reactive({
  sendSMS: {
    success: false,
    asyncError: false
  },
  saveMobileNumber: {
    asyncError: false
  }
});
const mobileNumberForm = reactive({
  mobileNumber: ""
})
const isLoadingMobileNumberForm = ref(false);
const countdown = ref(0);
const envStore = useEnvStore();

async function setupSMSAuthentication() {
  loginData.value.firstTime = true;
  loginData.value.mode = "sms";
  emit("trigger-authenticate");
}

async function saveMobileNumber() {
  isLoadingMobileNumberForm.value = true;
  notification.saveMobileNumber.asyncError = false;
  let err;
  [err] = await to(interceptor.post(`${ envStore.apiUrls.dutypay }/api/v0/setup/auth-sms`, {
    mobileNumber: mobileNumberForm.mobileNumber,
    ...loginData.value,
  }));
  if (err) {
    isLoadingMobileNumberForm.value = false;
    notification.saveMobileNumber.asyncError = true;
    return;
  }
  notification.saveMobileNumber.asyncError = false;
  await sendAuthenticationSMS();
  isLoadingMobileNumberForm.value = false;
}

function countDownTimer() {
  if (countdown.value > 0) {
    setTimeout(() => {
      countdown.value -= 1;
      countDownTimer();
    }, 1000);
  } else {
    resendDisabled.value = false;
  }
}
async function sendAuthenticationSMS() {
  resendDisabled.value = true;
  notification.sendSMS.success = false;
  notification.sendSMS.asyncError = false;
  let err;
  [err] = await to(interceptor.post(`${ envStore.apiUrls.dutypay }/api/v0/send/auth-sms`, {
    ...loginData.value,
  }));
  if (err) {
    mobileNumberAvailable.value = false
    resendDisabled.value = false;
    notification.sendSMS.asyncError = true;
  } else {
    notification.sendSMS.success = true;
    notification.sendSMS.asyncError = false;
    mobileNumberAvailable.value = true;
    countdown.value = 20;
    countDownTimer();
  }
}
</script>
<script>

export default {
  name: "SMSSetupForm"
}
</script>

<template>
  <div>
    <h2>{{ t("SMSSetupForm.title") }}</h2>

    <p>{{ t("pleaseEnterMobileNumber.label") }}</p>

    <DpNotification
      :visibility="notification.sendSMS.success"
      :title="t('notification.sendSMS.success.title')"
      :message="t('notification.sendSMS.success.message')"
      type="success"
    ></DpNotification>

    <DpNotification
      :visibility="notification.sendSMS.asyncError"
      :title="t('notification.sendSMS.asyncError.title')"
      :message="t('notification.sendSMS.asyncError.message')"
      type="error"
    ></DpNotification>

    <DpNotification
      :visibility="notification.saveMobileNumber.asyncError"
      :title="t('notification.saveMobileNumber.asyncError.title')"
      :message="t('notification.saveMobileNumber.asyncError.message')"
      type="error"
    ></DpNotification>

    <DpExpand>
      <DpForm
        v-show="!mobileNumberAvailable"
        :action="saveMobileNumber"
        reference="save-mobile-number"
        v-model="mobileNumberForm"
        v-loading="isLoadingMobileNumberForm"
        disable-notification
        :primary-button-label="t('saveMobileNumber.buttonLabel')"
        :primary-button-parameter="{
          class: ['full-width'],
          size: 'large',
        }">
        <DpInputMobileNumber
          v-model="mobileNumberForm.mobileNumber"
          prop="mobileNumber"
          required
          size="large"/>
      </DpForm>
    </DpExpand>

    <DpExpand>
      <dp-button
        v-show="mobileNumberAvailable"
        purpose="secondaryAction"
        class="full-width"
        :disabled="resendDisabled"
        @click="sendAuthenticationSMS">
        <span v-if="resendDisabled">{{
            t("resendSMSInSeconds.buttonLabel", { seconds: countdown })
          }}</span>
        <span v-else>{{ t("resendSMS.buttonLabel") }}</span>
      </dp-button>
    </DpExpand>

    <DpForm
      v-model="loginData"
      hide-required-asterisk
      reference="setup-email-authentication"
      :action="setupSMSAuthentication"
      :primaryButtonLabel="t('confirmCode.buttonLabel')"
      :primaryButtonParameter="{
        class: ['full-width'],
        size: 'large',
        disabled: !mobileNumberAvailable,
      }"
      disable-notification>
      <DpInputMultiFactorAuthenticationCode
        v-model="loginData.SMSOTP"
        prop="SMSOTP"
        required
        :input-parameters="{
          disabled: !mobileNumberAvailable,
          size: 'large',
        }"/>
    </DpForm>
  </div>
</template>

<i18n>
{
  "ar-AE": {
    "SMSSetupForm": {
      "title": "رسالة قصيرة"
    },
    "pleaseEnterMobileNumber": {
      "label": "قم بتنشيط المصادقة ذات العاملين عن طريق إدخال رمز المصادقة وتأكيده. للقيام بذلك ، احفظ رقم هاتفك المحمول. يتم إرسال الرمز إلى رقم الهاتف المحمول المخزن عبر الرسائل القصيرة."
    },
    "confirmCode": {
      "buttonLabel": "تأكيد"
    },
    "saveMobileNumber": {
      "buttonLabel": "حفظ وإرسال"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "message": "تم إرسال رمز المصادقة عبر الرسائل القصيرة. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة لتسجيل الدخول."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "إعادة إرسال في {seconds} ثوان"
    },
    "resendSMS": {
      "buttonLabel": "أعد الإرسال"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "message": "تم إرسال رمز المصادقة عبر الرسائل القصيرة. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة لتسجيل الدخول.",
          "title": "عمل ناجح"
        },
        "asyncError": {
          "title": "خطأ",
          "message": "تعذر إرسال الرسالة. يرجى التحقق من دخولك."
        }
      },
      "saveMobileNumber": {
        "asyncError": {
          "title": "خطأ",
          "message": "لا يمكن حفظ رقم الهاتف المحمول. تحقق من الدخول وحفظ مرة أخرى."
        }
      }
    }
  },
  "ar-EG": {
    "SMSSetupForm": {
      "title": "رسالة قصيرة"
    },
    "pleaseEnterMobileNumber": {
      "label": "قم بتنشيط المصادقة ذات العاملين عن طريق إدخال رمز المصادقة وتأكيده. للقيام بذلك ، احفظ رقم هاتفك المحمول. يتم إرسال الرمز إلى رقم الهاتف المحمول المخزن عبر الرسائل القصيرة."
    },
    "confirmCode": {
      "buttonLabel": "تأكيد"
    },
    "saveMobileNumber": {
      "buttonLabel": "حفظ وإرسال"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "message": "تم إرسال رمز المصادقة عبر الرسائل القصيرة. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة لتسجيل الدخول."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "إعادة إرسال في {seconds} ثوان"
    },
    "resendSMS": {
      "buttonLabel": "أعد الإرسال"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "message": "تم إرسال رمز المصادقة عبر الرسائل القصيرة. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة لتسجيل الدخول.",
          "title": "عمل ناجح"
        },
        "asyncError": {
          "title": "خطأ",
          "message": "تعذر إرسال الرسالة. يرجى التحقق من دخولك."
        }
      },
      "saveMobileNumber": {
        "asyncError": {
          "title": "خطأ",
          "message": "لا يمكن حفظ رقم الهاتف المحمول. تحقق من الدخول وحفظ مرة أخرى."
        }
      }
    }
  },
  "ar-SA": {
    "SMSSetupForm": {
      "title": "رسالة قصيرة"
    },
    "pleaseEnterMobileNumber": {
      "label": "قم بتنشيط المصادقة ذات العاملين عن طريق إدخال رمز المصادقة وتأكيده. للقيام بذلك ، احفظ رقم هاتفك المحمول. يتم إرسال الرمز إلى رقم الهاتف المحمول المخزن عبر الرسائل القصيرة."
    },
    "confirmCode": {
      "buttonLabel": "تأكيد"
    },
    "saveMobileNumber": {
      "buttonLabel": "حفظ وإرسال"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "message": "تم إرسال رمز المصادقة عبر الرسائل القصيرة. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة لتسجيل الدخول."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "إعادة إرسال في {seconds} ثوان"
    },
    "resendSMS": {
      "buttonLabel": "أعد الإرسال"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "message": "تم إرسال رمز المصادقة عبر الرسائل القصيرة. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة لتسجيل الدخول.",
          "title": "عمل ناجح"
        },
        "asyncError": {
          "title": "خطأ",
          "message": "تعذر إرسال الرسالة. يرجى التحقق من دخولك."
        }
      },
      "saveMobileNumber": {
        "asyncError": {
          "title": "خطأ",
          "message": "لا يمكن حفظ رقم الهاتف المحمول. تحقق من الدخول وحفظ مرة أخرى."
        }
      }
    }
  },
  "el-GR": {
    "SMSSetupForm": {
      "title": "γραπτό μήνυμα"
    },
    "pleaseEnterMobileNumber": {
      "label": "Ενεργοποιήστε τον έλεγχο ταυτότητας δύο παραγόντων εισάγοντας και επιβεβαιώνοντας τον κωδικό ελέγχου ταυτότητας. Για να το κάνετε αυτό, αποθηκεύστε τον αριθμό του κινητού σας. Ο κωδικός αποστέλλεται στον αποθηκευμένο αριθμό κινητού μέσω SMS."
    },
    "confirmCode": {
      "buttonLabel": "Επιβεβαίωση"
    },
    "saveMobileNumber": {
      "buttonLabel": "Αποθήκευση και αποστολή"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "message": "Ο κωδικός ελέγχου ταυτότητας στάλθηκε μέσω SMS. Μπορεί να χρησιμοποιηθεί μόνο μία φορά και εντός περιορισμένου χρόνου για τη σύνδεση."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Επανάληψη αποστολής σε {seconds} δευτερόλεπτα"
    },
    "resendSMS": {
      "buttonLabel": "Στείλε ξανά"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "message": "Ο κωδικός ελέγχου ταυτότητας στάλθηκε μέσω SMS. Μπορεί να χρησιμοποιηθεί μόνο μία φορά και εντός περιορισμένου χρόνου για τη σύνδεση.",
          "title": "Επιτυχημένη ενέργεια"
        },
        "asyncError": {
          "title": "Σφάλμα",
          "message": "Δεν ήταν δυνατή η αποστολή του μηνύματος. Ελέγξτε την καταχώρισή σας."
        }
      },
      "saveMobileNumber": {
        "asyncError": {
          "title": "Σφάλμα",
          "message": "Δεν ήταν δυνατή η αποθήκευση του αριθμού κινητού τηλεφώνου. Ελέγξτε την καταχώριση και αποθηκεύστε ξανά."
        }
      }
    }
  },
  "el-CY": {
    "SMSSetupForm": {
      "title": "γραπτό μήνυμα"
    },
    "pleaseEnterMobileNumber": {
      "label": "Ενεργοποιήστε τον έλεγχο ταυτότητας δύο παραγόντων εισάγοντας και επιβεβαιώνοντας τον κωδικό ελέγχου ταυτότητας. Για να το κάνετε αυτό, αποθηκεύστε τον αριθμό του κινητού σας. Ο κωδικός αποστέλλεται στον αποθηκευμένο αριθμό κινητού μέσω SMS."
    },
    "confirmCode": {
      "buttonLabel": "Επιβεβαίωση"
    },
    "saveMobileNumber": {
      "buttonLabel": "Αποθήκευση και αποστολή"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "message": "Ο κωδικός ελέγχου ταυτότητας στάλθηκε μέσω SMS. Μπορεί να χρησιμοποιηθεί μόνο μία φορά και εντός περιορισμένου χρόνου για τη σύνδεση."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Επανάληψη αποστολής σε {seconds} δευτερόλεπτα"
    },
    "resendSMS": {
      "buttonLabel": "Στείλε ξανά"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "message": "Ο κωδικός ελέγχου ταυτότητας στάλθηκε μέσω SMS. Μπορεί να χρησιμοποιηθεί μόνο μία φορά και εντός περιορισμένου χρόνου για τη σύνδεση.",
          "title": "Επιτυχημένη ενέργεια"
        },
        "asyncError": {
          "title": "Σφάλμα",
          "message": "Δεν ήταν δυνατή η αποστολή του μηνύματος. Ελέγξτε την καταχώρισή σας."
        }
      },
      "saveMobileNumber": {
        "asyncError": {
          "title": "Σφάλμα",
          "message": "Δεν ήταν δυνατή η αποθήκευση του αριθμού κινητού τηλεφώνου. Ελέγξτε την καταχώριση και αποθηκεύστε ξανά."
        }
      }
    }
  },
  "de-DE": {
    "SMSSetupForm": {
      "title": "SMS"
    },
    "pleaseEnterMobileNumber": {
      "label": "Aktiviere die Zwei-Faktor-Authentifizierung durch Eingabe und Bestätigung des Authentifizierungscodes. Speichere dazu deine Mobilnummer. Der Code wird an die gespeicherte Mobilnummer per SMS gesendet."
    },
    "confirmCode": {
      "buttonLabel": "Bestätigen"
    },
    "saveMobileNumber": {
      "buttonLabel": "Speichern und senden"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "message": "Der Authentifizierungscode wurde per SMS gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Erneut senden in {seconds} Sekunden"
    },
    "resendSMS": {
      "buttonLabel": "Erneut senden"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "message": "Der Authentifizierungscode wurde per SMS gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden.",
          "title": "Erfolgreiche Aktion"
        },
        "asyncError": {
          "title": "Fehler",
          "message": "Die Nachricht konnte nicht gesendet werden. Bitte prüfe deine Eingabe."
        }
      },
      "saveMobileNumber": {
        "asyncError": {
          "title": "Fehler",
          "message": "Die Mobilnummer konnte nicht gespeichert. Eingabe überprüfen und erneut speichern."
        }
      }
    }
  },
  "de-AT": {
    "SMSSetupForm": {
      "title": "SMS"
    },
    "pleaseEnterMobileNumber": {
      "label": "Aktiviere die Zwei-Faktor-Authentifizierung durch Eingabe und Bestätigung des Authentifizierungscodes. Speichere dazu deine Mobilnummer. Der Code wird an die gespeicherte Mobilnummer per SMS gesendet."
    },
    "confirmCode": {
      "buttonLabel": "Bestätigen"
    },
    "saveMobileNumber": {
      "buttonLabel": "Speichern und senden"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "message": "Der Authentifizierungscode wurde per SMS gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Erneut senden in {seconds} Sekunden"
    },
    "resendSMS": {
      "buttonLabel": "Erneut senden"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "message": "Der Authentifizierungscode wurde per SMS gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden.",
          "title": "Erfolgreiche Aktion"
        },
        "asyncError": {
          "title": "Fehler",
          "message": "Die Nachricht konnte nicht gesendet werden. Bitte prüfe deine Eingabe."
        }
      },
      "saveMobileNumber": {
        "asyncError": {
          "title": "Fehler",
          "message": "Die Mobilnummer konnte nicht gespeichert. Eingabe überprüfen und erneut speichern."
        }
      }
    }
  },
  "de-CH": {
    "SMSSetupForm": {
      "title": "SMS"
    },
    "pleaseEnterMobileNumber": {
      "label": "Aktiviere die Zwei-Faktor-Authentifizierung durch Eingabe und Bestätigung des Authentifizierungscodes. Speichere dazu deine Mobilnummer. Der Code wird an die gespeicherte Mobilnummer per SMS gesendet."
    },
    "confirmCode": {
      "buttonLabel": "Bestätigen"
    },
    "saveMobileNumber": {
      "buttonLabel": "Speichern und senden"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "message": "Der Authentifizierungscode wurde per SMS gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Erneut senden in {seconds} Sekunden"
    },
    "resendSMS": {
      "buttonLabel": "Erneut senden"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "message": "Der Authentifizierungscode wurde per SMS gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden.",
          "title": "Erfolgreiche Aktion"
        },
        "asyncError": {
          "title": "Fehler",
          "message": "Die Nachricht konnte nicht gesendet werden. Bitte prüfe deine Eingabe."
        }
      },
      "saveMobileNumber": {
        "asyncError": {
          "title": "Fehler",
          "message": "Die Mobilnummer konnte nicht gespeichert. Eingabe überprüfen und erneut speichern."
        }
      }
    }
  },
  "en-US": {
    "SMSSetupForm": {
      "title": "SMS"
    },
    "pleaseEnterMobileNumber": {
      "label": "Activate two-factor authentication by entering and confirming the authentication code. To do this, save your mobile number. The code is sent to the saved mobile number via SMS."
    },
    "confirmCode": {
      "buttonLabel": "Confirm"
    },
    "saveMobileNumber": {
      "buttonLabel": "Save and send"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "message": "The authentication code was sent via SMS. It can only be used once and within a limited time for logging in.."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Resend in {seconds} seconds"
    },
    "resendSMS": {
      "buttonLabel": "Send again"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "message": "The authentication code was sent via SMS. It can only be used once and within a limited time for logging in..",
          "title": "Successful action"
        },
        "asyncError": {
          "title": "Error",
          "message": "The message could not be sent. Please check your entry."
        }
      },
      "saveMobileNumber": {
        "asyncError": {
          "title": "Error",
          "message": "The mobile number could not be saved. Check entry and save again."
        }
      }
    }
  },
  "en-GB": {
    "SMSSetupForm": {
      "title": "SMS"
    },
    "pleaseEnterMobileNumber": {
      "label": "Activate two-factor authentication by entering and confirming the authentication code. To do this, save your mobile number. The code is sent to the saved mobile number via SMS."
    },
    "confirmCode": {
      "buttonLabel": "Confirm"
    },
    "saveMobileNumber": {
      "buttonLabel": "Save and send"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "message": "The authentication code was sent via SMS. It can only be used once and within a limited time for logging in.."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Resend in {seconds} seconds"
    },
    "resendSMS": {
      "buttonLabel": "Send again"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "message": "The authentication code was sent via SMS. It can only be used once and within a limited time for logging in..",
          "title": "Successful action"
        },
        "asyncError": {
          "title": "Error",
          "message": "The message could not be sent. Please check your entry."
        }
      },
      "saveMobileNumber": {
        "asyncError": {
          "title": "Error",
          "message": "The mobile number could not be saved. Check entry and save again."
        }
      }
    }
  },
  "it-IT": {
    "SMSSetupForm": {
      "title": "SMS"
    },
    "pleaseEnterMobileNumber": {
      "label": "Per farlo, salva il tuo numero di telefono. Il codice viene inviato al numero di telefono salvato via SMS."
    },
    "confirmCode": {
      "buttonLabel": "Conferma"
    },
    "saveMobileNumber": {
      "buttonLabel": "Salva e invia"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "message": "Il codice di autenticazione è stato inviato tramite SMS. Può essere utilizzato solo una volta ed entro un tempo limitato per l'accesso."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Invia nuovamente tra {seconds} secondi"
    },
    "resendSMS": {
      "buttonLabel": "Invia di nuovo"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "message": "Il codice di autenticazione è stato inviato tramite SMS. Può essere utilizzato solo una volta ed entro un tempo limitato per l'accesso.",
          "title": "Azione riuscita"
        },
        "asyncError": {
          "title": "Errore",
          "message": "Impossibile inviare il messaggio. Si prega di controllare la tua voce."
        }
      },
      "saveMobileNumber": {
        "asyncError": {
          "title": "Errore",
          "message": "Non è stato possibile salvare il numero di cellulare. Controlla la voce e salva di nuovo."
        }
      }
    }
  },
  "fr-FR": {
    "SMSSetupForm": {
      "title": "SMS"
    },
    "pleaseEnterMobileNumber": {
      "label": "Activez l'authentification à deux facteurs en saisissant et en confirmant le code d'authentification. Pour ce faire, enregistrez votre numéro de téléphone mobile. Le code est envoyé par SMS au numéro de téléphone mobile enregistré."
    },
    "confirmCode": {
      "buttonLabel": "Confirmer"
    },
    "saveMobileNumber": {
      "buttonLabel": "Enregistrer et envoyer"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "message": "Le code d'authentification a été envoyé par SMS. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour se connecter."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Renvoyer dans {seconds} secondes"
    },
    "resendSMS": {
      "buttonLabel": "Envoyer à nouveau"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "message": "Le code d'authentification a été envoyé par SMS. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour se connecter.",
          "title": "Action réussie"
        },
        "asyncError": {
          "title": "Erreur",
          "message": "Le message n'a pas pu être envoyé. Veuillez vérifier votre saisie."
        }
      },
      "saveMobileNumber": {
        "asyncError": {
          "title": "Erreur",
          "message": "Le numéro de portable n'a pas pu être enregistré. Vérifiez l'entrée et enregistrez à nouveau."
        }
      }
    }
  },
  "es-ES": {
    "SMSSetupForm": {
      "title": "SMS"
    },
    "pleaseEnterMobileNumber": {
      "label": "Active la autenticación en dos pasos introduciendo y confirmando el código de autenticación. Para ello, guarde su número de teléfono. El código se envía por SMS al número de teléfono guardado."
    },
    "confirmCode": {
      "buttonLabel": "Confirmar"
    },
    "saveMobileNumber": {
      "buttonLabel": "Guardar y enviar"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "message": "El código de autenticación se envió por SMS. Solo se puede usar una vez y dentro de un tiempo limitado para iniciar sesión."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Enviar de nuevo en {seconds} segundos"
    },
    "resendSMS": {
      "buttonLabel": "Enviar de nuevo"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "message": "El código de autenticación se envió por SMS. Solo se puede usar una vez y dentro de un tiempo limitado para iniciar sesión.",
          "title": "Listo"
        },
        "asyncError": {
          "title": "Error",
          "message": "El mensaje no se pudo enviar. Por favor revise su entrada."
        }
      },
      "saveMobileNumber": {
        "asyncError": {
          "title": "Error",
          "message": "No se pudo guardar el número de móvil. Verifique la entrada y guarde nuevamente."
        }
      }
    }
  },
  "pl-PL": {
    "SMSSetupForm": {
      "title": "SMS"
    },
    "pleaseEnterMobileNumber": {
      "label": "Aktywuj uwierzytelnianie dwuskładnikowe, wprowadzając i potwierdzając kod uwierzytelniający. Aby to zrobić, zapisz swój numer telefonu komórkowego. Kod jest wysyłany na zapisany numer telefonu komórkowego SMS-em."
    },
    "confirmCode": {
      "buttonLabel": "Potwierdź"
    },
    "saveMobileNumber": {
      "buttonLabel": "Zapisz i wyślij"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "message": "Kod uwierzytelniający został wysłany SMS-em. Można go użyć tylko raz iw ograniczonym czasie do zalogowania."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Wyślij ponownie za {seconds} s"
    },
    "resendSMS": {
      "buttonLabel": "Wyślij ponownie"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "message": "Kod uwierzytelniający został wysłany SMS-em. Można go użyć tylko raz iw ograniczonym czasie do zalogowania.",
          "title": "Czynność zakończona powodzeniem"
        },
        "asyncError": {
          "title": "Błąd",
          "message": "Nie udało się wysłać wiadomości. Sprawdź swój wpis."
        }
      },
      "saveMobileNumber": {
        "asyncError": {
          "title": "Błąd",
          "message": "Nie można zapisać numeru telefonu komórkowego. Sprawdź wpis i zapisz ponownie."
        }
      }
    }
  },
  "nl-NL": {
    "SMSSetupForm": {
      "title": "SMS"
    },
    "pleaseEnterMobileNumber": {
      "label": "Activeer de twee-factor authenticatie door de authenticatiecode in te voeren en te bevestigen. Om dit te doen, slaat u uw mobiele nummer op. De code wordt via sms naar het opgeslagen mobiele nummer gestuurd."
    },
    "confirmCode": {
      "buttonLabel": "Bevestigen"
    },
    "saveMobileNumber": {
      "buttonLabel": "Opslaan en verzenden"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "message": "De authenticatiecode is per sms verzonden. Het kan slechts één keer en binnen een beperkte tijd worden gebruikt om in te loggen."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Verzenden over {seconds} seconden"
    },
    "resendSMS": {
      "buttonLabel": "Opnieuw verzenden"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "message": "De authenticatiecode is per sms verzonden. Het kan slechts één keer en binnen een beperkte tijd worden gebruikt om in te loggen.",
          "title": "Geslaagde actie"
        },
        "asyncError": {
          "title": "Fout",
          "message": "Het bericht kon niet worden verzonden. Controleer uw invoer."
        }
      },
      "saveMobileNumber": {
        "asyncError": {
          "title": "Fout",
          "message": "Het mobiele nummer kon niet worden opgeslagen. Controleer invoer en sla opnieuw op."
        }
      }
    }
  },
  "bg-BG": {
    "SMSSetupForm": {
      "title": "SMS"
    },
    "pleaseEnterMobileNumber": {
      "label": "Активирайте двуфакторно удостоверяване, като въведете и потвърдите кода за удостоверяване. За да направите това, запазете мобилния си номер. Кодът се изпраща на съхранения мобилен номер чрез SMS."
    },
    "confirmCode": {
      "buttonLabel": "Потвърждение"
    },
    "saveMobileNumber": {
      "buttonLabel": "Съхраняване и изпращане"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "message": "Кодът за удостоверяване беше изпратен чрез SMS. Може да се използва само веднъж и в рамките на ограничено време за влизане."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Изпращане след {seconds} секунди"
    },
    "resendSMS": {
      "buttonLabel": "Изпрати отново"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "message": "Кодът за удостоверяване беше изпратен чрез SMS. Може да се използва само веднъж и в рамките на ограничено време за влизане.",
          "title": "Успешно действие"
        },
        "asyncError": {
          "title": "грешка",
          "message": "Съобщението не можа да бъде изпратено. Моля, проверете вашето въвеждане."
        }
      },
      "saveMobileNumber": {
        "asyncError": {
          "title": "грешка",
          "message": "Мобилният номер не можа да бъде запазен. Проверете въведеното и запазете отново."
        }
      }
    }
  },
  "bs-BA": {
    "SMSSetupForm": {
      "title": "SMS"
    },
    "pleaseEnterMobileNumber": {
      "label": "Aktivirajte dvofaktorsku autentifikaciju unosom i potvrđivanjem autentifikacijskog koda. Da biste to učinili, spremite svoj broj mobitela. Kôd se putem SMS-a šalje na spremljeni broj mobitela."
    },
    "confirmCode": {
      "buttonLabel": "Potvrdite"
    },
    "saveMobileNumber": {
      "buttonLabel": "Spremite i pošaljite"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "message": "Kod za autentifikaciju je poslan putem SMS-a. Može se koristiti samo jednom iu ograničenom vremenu za prijavu."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Ponovo pošalji za {seconds} sekundi"
    },
    "resendSMS": {
      "buttonLabel": "Pošalji ponovo"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "message": "Kod za autentifikaciju je poslan putem SMS-a. Može se koristiti samo jednom iu ograničenom vremenu za prijavu.",
          "title": "Uspješno obavljeno"
        },
        "asyncError": {
          "title": "Greška",
          "message": "Poruka se nije mogla poslati. Molimo provjerite svoj unos."
        }
      },
      "saveMobileNumber": {
        "asyncError": {
          "title": "Greška",
          "message": "Broj mobilnog telefona nije mogao biti sačuvan. Provjerite unos i ponovo sačuvajte."
        }
      }
    }
  },
  "da-DK": {
    "SMSSetupForm": {
      "title": "SMS"
    },
    "pleaseEnterMobileNumber": {
      "label": "Aktiver tofaktorautentificering ved at indtaste og bekræfte godkendelseskoden. For at gøre dette skal du gemme dit mobilnummer. Koden sendes til det gemte mobilnummer via SMS."
    },
    "confirmCode": {
      "buttonLabel": "Bekræft"
    },
    "saveMobileNumber": {
      "buttonLabel": "Gem og send"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "message": "Godkendelseskoden blev sendt via SMS. Det kan kun bruges én gang og inden for en begrænset tid til at logge ind."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Send igen om {seconds} sekunder"
    },
    "resendSMS": {
      "buttonLabel": "Send igen"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "message": "Godkendelseskoden blev sendt via SMS. Det kan kun bruges én gang og inden for en begrænset tid til at logge ind.",
          "title": "Vellykket handling"
        },
        "asyncError": {
          "title": "Fejl",
          "message": "Meddelelsen kunne ikke sendes. Tjek venligst din post."
        }
      },
      "saveMobileNumber": {
        "asyncError": {
          "title": "Fejl",
          "message": "Mobilnummeret kunne ikke gemmes. Tjek indtastningen og gem igen."
        }
      }
    }
  },
  "et-EE": {
    "SMSSetupForm": {
      "title": "SMS"
    },
    "pleaseEnterMobileNumber": {
      "label": "Aktiveerige kaheastmeline autentimine, sisestades ja kinnitades autentimiskoodi. Selleks salvestage oma mobiilinumber. Kood saadetakse salvestatud mobiiltelefoninumbrile SMS-i teel."
    },
    "confirmCode": {
      "buttonLabel": "Kinnita"
    },
    "saveMobileNumber": {
      "buttonLabel": "Salvesta ja saada"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "message": "Autentimiskood saadeti SMS-iga. Seda saab kasutada ainult üks kord ja piiratud aja jooksul sisselogimiseks."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Saada uuesti {seconds} sekundi pärast"
    },
    "resendSMS": {
      "buttonLabel": "Saada uuesti"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "message": "Autentimiskood saadeti SMS-iga. Seda saab kasutada ainult üks kord ja piiratud aja jooksul sisselogimiseks.",
          "title": "Edukas tegevus"
        },
        "asyncError": {
          "title": "Viga",
          "message": "Sõnumit ei õnnestunud saata. Palun kontrollige oma sisestust."
        }
      },
      "saveMobileNumber": {
        "asyncError": {
          "title": "Viga",
          "message": "Mobiilinumbrit ei saanud salvestada. Kontrollige sisestust ja salvestage uuesti."
        }
      }
    }
  },
  "fi-FI": {
    "SMSSetupForm": {
      "title": "Tekstiviesti"
    },
    "pleaseEnterMobileNumber": {
      "label": "Aktivoi kaksivaiheinen todennus syöttämällä ja vahvistamalla todennuskoodi. Tehdäksesi tämän, tallenna matkapuhelinnumerosi. Koodi lähetetään tallennettuun matkapuhelinnumeroon tekstiviestillä."
    },
    "confirmCode": {
      "buttonLabel": "Vahvista"
    },
    "saveMobileNumber": {
      "buttonLabel": "Tallenna ja lähetä"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "message": "Todennuskoodi lähetettiin tekstiviestillä. Sitä voidaan käyttää vain kerran ja rajoitetun ajan sisällä kirjautumiseen."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Lähetä uudelleen {seconds} sekunnissa"
    },
    "resendSMS": {
      "buttonLabel": "Lähetä uudestaan"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "message": "Todennuskoodi lähetettiin tekstiviestillä. Sitä voidaan käyttää vain kerran ja rajoitetun ajan sisällä kirjautumiseen.",
          "title": "Onnistunut toiminta"
        },
        "asyncError": {
          "title": "Virhe",
          "message": "Viestiä ei voitu lähettää. Tarkista syöttösi."
        }
      },
      "saveMobileNumber": {
        "asyncError": {
          "title": "Virhe",
          "message": "Matkapuhelinnumeroa ei voitu tallentaa. Tarkista syöte ja tallenna uudelleen."
        }
      }
    }
  },
  "he-IL": {
    "SMSSetupForm": {
      "title": "סמס"
    },
    "pleaseEnterMobileNumber": {
      "label": "הפעל אימות דו-גורמי על ידי הזנת קוד האימות ואישורו. לשם כך, שמור את מספר הנייד שלך. הקוד נשלח למספר הנייד המאוחסן באמצעות SMS."
    },
    "confirmCode": {
      "buttonLabel": "לְאַשֵׁר"
    },
    "saveMobileNumber": {
      "buttonLabel": "שמור ושלח"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "message": "קוד האימות נשלח באמצעות SMS. ניתן להשתמש בו רק פעם אחת ובתוך זמן מוגבל לכניסה."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "שלח מחדש בתוך {seconds} שניות"
    },
    "resendSMS": {
      "buttonLabel": "שלח שוב"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "message": "קוד האימות נשלח באמצעות SMS. ניתן להשתמש בו רק פעם אחת ובתוך זמן מוגבל לכניסה.",
          "title": "פעולה מוצלחת"
        },
        "asyncError": {
          "title": "שְׁגִיאָה",
          "message": "לא ניתן היה לשלוח את ההודעה. אנא בדוק את הערך שלך."
        }
      },
      "saveMobileNumber": {
        "asyncError": {
          "title": "שְׁגִיאָה",
          "message": "לא ניתן היה לשמור את מספר הנייד. בדוק את הערך ושמור שוב."
        }
      }
    }
  },
  "ga-IE": {
    "SMSSetupForm": {
      "title": "SMS"
    },
    "pleaseEnterMobileNumber": {
      "label": "Gníomhachtaigh fíordheimhniú dhá fhachtóir tríd an gcód fíordheimhnithe a iontráil agus a dhearbhú. Chun é seo a dhéanamh, sábháil d’uimhir fóin póca. Seoltar an cód chuig an uimhir fóin póca stóráilte trí SMS."
    },
    "confirmCode": {
      "buttonLabel": "Deimhnigh"
    },
    "saveMobileNumber": {
      "buttonLabel": "Sábháil agus seol"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "message": "Seoladh an cód fíordheimhnithe trí SMS. Ní féidir é a úsáid ach uair amháin agus laistigh de thréimhse teoranta chun logáil isteach."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Athsheol i gceann {seconds} soicind"
    },
    "resendSMS": {
      "buttonLabel": "Seol arís"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "message": "Seoladh an cód fíordheimhnithe trí SMS. Ní féidir é a úsáid ach uair amháin agus laistigh de thréimhse teoranta chun logáil isteach.",
          "title": "Gníomh rathúil"
        },
        "asyncError": {
          "title": "Earráid",
          "message": "Ní fhéadfaí an teachtaireacht a sheoladh. Seiceáil d’iontráil le do thoil."
        }
      },
      "saveMobileNumber": {
        "asyncError": {
          "title": "Earráid",
          "message": "Níorbh fhéidir an uimhir soghluaiste a shábháil. Seiceáil iontráil agus sábháil arís."
        }
      }
    }
  },
  "ja-JP": {
    "SMSSetupForm": {
      "title": "SMS"
    },
    "pleaseEnterMobileNumber": {
      "label": "認証コードを入力して確認することで、二要素認証を有効にします。 これを行うには、携帯電話番号を保存します。 コードは SMS 経由で保存されている携帯電話番号に送信されます。"
    },
    "confirmCode": {
      "buttonLabel": "確認"
    },
    "saveMobileNumber": {
      "buttonLabel": "保存して送信"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "message": "認証コードは SMS 経由で送信されました。 ログイン時に1回限り、限られた時間内でのみ使用できます。"
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "{seconds}秒で再送信"
    },
    "resendSMS": {
      "buttonLabel": "もう一度送信"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "message": "認証コードは SMS 経由で送信されました。 ログイン時に1回限り、限られた時間内でのみ使用できます。",
          "title": "成功したアクション"
        },
        "asyncError": {
          "title": "エラー",
          "message": "メッセージを送信できませんでした。 入力内容をご確認ください。"
        }
      },
      "saveMobileNumber": {
        "asyncError": {
          "title": "エラー",
          "message": "携帯電話番号を保存できませんでした。 入力内容を確認し、再度保存してください。"
        }
      }
    }
  },
  "hr-HR": {
    "SMSSetupForm": {
      "title": "SMS"
    },
    "pleaseEnterMobileNumber": {
      "label": "Aktivirajte dvofaktorsku autentifikaciju unosom i potvrđivanjem autentifikacijskog koda. Da biste to učinili, spremite svoj broj mobitela. Kôd se putem SMS-a šalje na pohranjeni broj mobitela."
    },
    "confirmCode": {
      "buttonLabel": "Potvrda"
    },
    "saveMobileNumber": {
      "buttonLabel": "Spremi i pošalji"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "message": "Kod za autentifikaciju poslan je putem SMS-a. Može se koristiti samo jednom i unutar ograničenog vremena za prijavu."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Ponovno slanje u {seconds} sekundama"
    },
    "resendSMS": {
      "buttonLabel": "Pošalji opet"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "message": "Kod za autentifikaciju poslan je putem SMS-a. Može se koristiti samo jednom i unutar ograničenog vremena za prijavu.",
          "title": "Uspješna akcija"
        },
        "asyncError": {
          "title": "Pogreška",
          "message": "Poruka se nije mogla poslati. Molimo provjerite svoj unos."
        }
      },
      "saveMobileNumber": {
        "asyncError": {
          "title": "Pogreška",
          "message": "Broj mobitela nije bilo moguće spremiti. Provjerite unos i ponovno spremite."
        }
      }
    }
  },
  "lv-LV": {
    "SMSSetupForm": {
      "title": "Īsziņa"
    },
    "pleaseEnterMobileNumber": {
      "label": "Aktivizējiet divfaktoru autentifikāciju, ievadot un apstiprinot autentifikācijas kodu.Lai to izdarītu, saglabājiet savu mobilā tālruņa numuru. Lai to izdarītu, saglabājiet savu mobilā tālruņa numuru."
    },
    "confirmCode": {
      "buttonLabel": "Apstiprināt"
    },
    "saveMobileNumber": {
      "buttonLabel": "Saglabāt un nosūtīt"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "message": "Autentifikācijas kods tika nosūtīts ar SMS. To var izmantot tikai vienu reizi un ierobežotā laikā, lai pieteiktos."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Atkārtoti nosūtīt pēc {seconds} sek."
    },
    "resendSMS": {
      "buttonLabel": "Sūtīt vēlreiz"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "message": "Autentifikācijas kods tika nosūtīts ar SMS. To var izmantot tikai vienu reizi un ierobežotā laikā, lai pieteiktos.",
          "title": "Veiksmīga darbība"
        },
        "asyncError": {
          "title": "Kļūda",
          "message": "Ziņojumu nevarēja nosūtīt. Lūdzu, pārbaudiet savu ierakstu."
        }
      },
      "saveMobileNumber": {
        "asyncError": {
          "title": "Kļūda",
          "message": "Mobilā tālruņa numuru nevarēja saglabāt. Pārbaudiet ierakstu un saglabājiet vēlreiz."
        }
      }
    }
  },
  "lt-LT": {
    "SMSSetupForm": {
      "title": "Trumpoji žinutė"
    },
    "pleaseEnterMobileNumber": {
      "label": "Suaktyvinkite dviejų veiksnių autentifikavimą įvesdami ir patvirtindami autentifikavimo kodą. Norėdami tai padaryti, išsaugokite savo mobiliojo telefono numerį. Kodas išsiunčiamas išsaugotu mobiliojo telefono numeriu SMS žinute."
    },
    "confirmCode": {
      "buttonLabel": "Patvirtinti"
    },
    "saveMobileNumber": {
      "buttonLabel": "Išsaugoti ir siųsti"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "message": "Autentifikavimo kodas buvo išsiųstas SMS žinute. Jį galima naudoti tik vieną kartą ir per ribotą prisijungimo laiką."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Iš naujo išsiųsti po {seconds} sek."
    },
    "resendSMS": {
      "buttonLabel": "Siųsti dar kartą"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "message": "Autentifikavimo kodas buvo išsiųstas SMS žinute. Jį galima naudoti tik vieną kartą ir per ribotą prisijungimo laiką.",
          "title": "Sėkmingas veiksmas"
        },
        "asyncError": {
          "title": "Klaida",
          "message": "Nepavyko išsiųsti pranešimo. Patikrinkite savo įrašą."
        }
      },
      "saveMobileNumber": {
        "asyncError": {
          "title": "Klaida",
          "message": "Nepavyko išsaugoti mobiliojo telefono numerio. Patikrinkite įrašą ir išsaugokite dar kartą."
        }
      }
    }
  },
  "pt-PT": {
    "SMSSetupForm": {
      "title": "SMS"
    },
    "pleaseEnterMobileNumber": {
      "label": "Ative a autenticação de dois fatores inserindo e confirmando o código de autenticação. Para fazer isso, guarde o número do seu telemóvel O código é enviado por SMS para o número de telemóvel armazenado."
    },
    "confirmCode": {
      "buttonLabel": "Confirmar"
    },
    "saveMobileNumber": {
      "buttonLabel": "Guardar e enviar"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "message": "O código de autenticação foi enviado via SMS. Ele só pode ser usado uma vez e dentro de um tempo limitado para fazer login."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Reenviar em {seconds} segundos"
    },
    "resendSMS": {
      "buttonLabel": "Envie novamente"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "message": "O código de autenticação foi enviado via SMS. Ele só pode ser usado uma vez e dentro de um tempo limitado para fazer login.",
          "title": "Ação bem-sucedida"
        },
        "asyncError": {
          "title": "Erro",
          "message": "A mensagem não pôde ser enviada. Por favor, verifique sua entrada."
        }
      },
      "saveMobileNumber": {
        "asyncError": {
          "title": "Erro",
          "message": "O número do celular não pôde ser salvo. Verifique a entrada e salve novamente."
        }
      }
    }
  },
  "ro-RO": {
    "SMSSetupForm": {
      "title": "SMS"
    },
    "pleaseEnterMobileNumber": {
      "label": "Activați autentificarea cu doi factori introducând și confirmând codul de autentificare. În acest scop, trebuie să salvați numărul de telefon mobil. Codul va fi trimis prin SMS, la numărul de telefon mobil salvat."
    },
    "confirmCode": {
      "buttonLabel": "Confirmare"
    },
    "saveMobileNumber": {
      "buttonLabel": "Salvare și trimitere"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "message": "Codul de autentificare a fost trimis prin SMS. Poate fi folosit o singură dată și într-un timp limitat pentru autentificare."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Retrimitere în {seconds} secunde"
    },
    "resendSMS": {
      "buttonLabel": "Trimite din nou"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "message": "Codul de autentificare a fost trimis prin SMS. Poate fi folosit o singură dată și într-un timp limitat pentru autentificare.",
          "title": "Acțiune reușită"
        },
        "asyncError": {
          "title": "Eroare",
          "message": "Mesajul nu a putut fi trimis. Vă rugăm să verificați intrarea."
        }
      },
      "saveMobileNumber": {
        "asyncError": {
          "title": "Eroare",
          "message": "Numărul de mobil nu a putut fi salvat. Verificați intrarea și salvați din nou."
        }
      }
    }
  },
  "ru-RU": {
    "SMSSetupForm": {
      "title": "SMS"
    },
    "pleaseEnterMobileNumber": {
      "label": "Активируйте двухфакторную аутентификацию, введя и подтвердив код аутентификации. Для этого сохраните номер своего мобильного телефона. Код отправляется на сохраненный номер мобильного телефона по SMS."
    },
    "confirmCode": {
      "buttonLabel": "Подтвердить"
    },
    "saveMobileNumber": {
      "buttonLabel": "Сохранить и отправить"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "message": "Код аутентификации был отправлен в SMS. Его можно использовать только один раз и в течение ограниченного времени для входа в систему."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Отправить повторно через {seconds} секунд"
    },
    "resendSMS": {
      "buttonLabel": "Отправь еще раз"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "message": "Код аутентификации был отправлен в SMS. Его можно использовать только один раз и в течение ограниченного времени для входа в систему.",
          "title": "Успешное действие"
        },
        "asyncError": {
          "title": "Ошибка",
          "message": "Сообщение не может быть отправлено. Пожалуйста, проверьте вашу запись."
        }
      },
      "saveMobileNumber": {
        "asyncError": {
          "title": "Ошибка",
          "message": "Не удалось сохранить номер мобильного телефона. Проверьте запись и сохраните снова."
        }
      }
    }
  },
  "sv-SE": {
    "SMSSetupForm": {
      "title": "SMS"
    },
    "pleaseEnterMobileNumber": {
      "label": "Aktivera tvåfaktorautentisering genom att ange och bekräfta autentiseringskoden. Spara ditt mobilnummer för att göra detta. Koden skickas till det sparade mobilnumret via SMS."
    },
    "confirmCode": {
      "buttonLabel": "Bekräfta"
    },
    "saveMobileNumber": {
      "buttonLabel": "Spara och skicka"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "message": "Autentiseringskoden skickades via SMS. Den kan endast användas en gång och inom en begränsad tid för inloggning."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Skicka igen om {seconds} sekunder"
    },
    "resendSMS": {
      "buttonLabel": "Skicka igen"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "message": "Autentiseringskoden skickades via SMS. Den kan endast användas en gång och inom en begränsad tid för inloggning.",
          "title": "Åtgärd utförd"
        },
        "asyncError": {
          "title": "Fel",
          "message": "Meddelandet kunde inte skickas. Kontrollera ditt bidrag."
        }
      },
      "saveMobileNumber": {
        "asyncError": {
          "title": "Fel",
          "message": "Mobilnumret kunde inte sparas. Kontrollera inmatningen och spara igen."
        }
      }
    }
  },
  "sk-SK": {
    "SMSSetupForm": {
      "title": "SMS"
    },
    "pleaseEnterMobileNumber": {
      "label": "Aktivujte dvojfaktorové overenie zadaním a potvrdením overovacieho kódu. Uložte pre to vaše mobilné číslo. Kód sa odošle na uložené mobilné číslo prostredníctvom SMS."
    },
    "confirmCode": {
      "buttonLabel": "Potvrdiť"
    },
    "saveMobileNumber": {
      "buttonLabel": "Uložiť a odoslať"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "message": "Overovací kód bol odoslaný prostredníctvom SMS. Dá sa použiť len raz a v obmedzenom čase na prihlásenie."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Opäť odoslať o {seconds} sekúnd"
    },
    "resendSMS": {
      "buttonLabel": "Poslať znova"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "message": "Overovací kód bol odoslaný prostredníctvom SMS. Dá sa použiť len raz a v obmedzenom čase na prihlásenie.",
          "title": "Úspešná akcia"
        },
        "asyncError": {
          "title": "Chyba",
          "message": "Správu sa nepodarilo odoslať. Skontrolujte svoje zadanie."
        }
      },
      "saveMobileNumber": {
        "asyncError": {
          "title": "Chyba",
          "message": "Mobilné číslo sa nepodarilo uložiť. Skontrolujte zadanie a znova uložte."
        }
      }
    }
  },
  "cs-CZ": {
    "SMSSetupForm": {
      "title": "SMS"
    },
    "pleaseEnterMobileNumber": {
      "label": "Aktivujte dvoufaktorové ověřování zadáním a potvrzením ověřovacího kódu. Chcete-li to provést, uložte své mobilní číslo. Kód je odeslán na uložené číslo mobilního telefonu prostřednictvím SMS."
    },
    "confirmCode": {
      "buttonLabel": "Potvrdit"
    },
    "saveMobileNumber": {
      "buttonLabel": "Uložit a odeslat"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "message": "Ověřovací kód byl zaslán prostřednictvím SMS. Lze jej použít pouze jednou a po omezenou dobu pro přihlášení."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Odeslat znovu za {seconds} s."
    },
    "resendSMS": {
      "buttonLabel": "Poslat znovu"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "message": "Ověřovací kód byl zaslán prostřednictvím SMS. Lze jej použít pouze jednou a po omezenou dobu pro přihlášení.",
          "title": "Úspěšná akce"
        },
        "asyncError": {
          "title": "Chyba",
          "message": "Zprávu se nepodařilo odeslat. Zkontrolujte prosím svůj údaj."
        }
      },
      "saveMobileNumber": {
        "asyncError": {
          "title": "Chyba",
          "message": "Mobilní číslo se nepodařilo uložit. Zkontrolujte zadání a uložte znovu."
        }
      }
    }
  },
  "hu-HU": {
    "SMSSetupForm": {
      "title": "SMS"
    },
    "pleaseEnterMobileNumber": {
      "label": "Aktiválja a kétfaktoros hitelesítést a hitelesítő kód megadásával és megerősítésével. Ehhez mentse el mobilszámát. A kódot SMS-ben küldjük az elmentett mobilszámra."
    },
    "confirmCode": {
      "buttonLabel": "Megerősít"
    },
    "saveMobileNumber": {
      "buttonLabel": "Mentés és küldés"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "message": "A hitelesítési kódot SMS-ben küldték el. Csak egyszer és korlátozott ideig használható a bejelentkezéshez."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Újraküldés {seconds} másodperc múlva"
    },
    "resendSMS": {
      "buttonLabel": "Küldd újra"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "message": "A hitelesítési kódot SMS-ben küldték el. Csak egyszer és korlátozott ideig használható a bejelentkezéshez.",
          "title": "Sikeres művelet"
        },
        "asyncError": {
          "title": "Hiba",
          "message": "Az üzenetet nem sikerült elküldeni. Kérjük, ellenőrizze a bejegyzést."
        }
      },
      "saveMobileNumber": {
        "asyncError": {
          "title": "Hiba",
          "message": "A mobilszámot nem sikerült elmenteni. Ellenőrizze a bejegyzést, és mentse újra."
        }
      }
    }
  },
  "fr-BE": {
    "SMSSetupForm": {
      "title": "SMS"
    },
    "pleaseEnterMobileNumber": {
      "label": "Activez l'authentification à deux facteurs en saisissant et en confirmant le code d'authentification. Pour ce faire, enregistrez votre numéro de téléphone mobile. Le code est envoyé par SMS au numéro de téléphone mobile enregistré."
    },
    "confirmCode": {
      "buttonLabel": "Confirmer"
    },
    "saveMobileNumber": {
      "buttonLabel": "Enregistrer et envoyer"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "message": "Le code d'authentification a été envoyé par SMS. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour se connecter."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Renvoyer dans {seconds} secondes"
    },
    "resendSMS": {
      "buttonLabel": "Envoyer à nouveau"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "message": "Le code d'authentification a été envoyé par SMS. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour se connecter.",
          "title": "Action réussie"
        },
        "asyncError": {
          "title": "Erreur",
          "message": "Le message n'a pas pu être envoyé. Veuillez vérifier votre saisie."
        }
      },
      "saveMobileNumber": {
        "asyncError": {
          "title": "Erreur",
          "message": "Le numéro de portable n'a pas pu être enregistré. Vérifiez l'entrée et enregistrez à nouveau."
        }
      }
    }
  },
  "fr-LU": {
    "SMSSetupForm": {
      "title": "SMS"
    },
    "pleaseEnterMobileNumber": {
      "label": "Activez l'authentification à deux facteurs en saisissant et en confirmant le code d'authentification. Pour ce faire, enregistrez votre numéro de téléphone mobile. Le code est envoyé par SMS au numéro de téléphone mobile enregistré."
    },
    "confirmCode": {
      "buttonLabel": "Confirmer"
    },
    "saveMobileNumber": {
      "buttonLabel": "Enregistrer et envoyer"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "message": "Le code d'authentification a été envoyé par SMS. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour se connecter."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Renvoyer dans {seconds} secondes"
    },
    "resendSMS": {
      "buttonLabel": "Envoyer à nouveau"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "message": "Le code d'authentification a été envoyé par SMS. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour se connecter.",
          "title": "Action réussie"
        },
        "asyncError": {
          "title": "Erreur",
          "message": "Le message n'a pas pu être envoyé. Veuillez vérifier votre saisie."
        }
      },
      "saveMobileNumber": {
        "asyncError": {
          "title": "Erreur",
          "message": "Le numéro de portable n'a pas pu être enregistré. Vérifiez l'entrée et enregistrez à nouveau."
        }
      }
    }
  },
  "mt-MT": {
    "SMSSetupForm": {
      "title": "SMS"
    },
    "pleaseEnterMobileNumber": {
      "label": "Attiva awtentikazzjoni b'żewġ fatturi billi ddaħħal u tikkonferma l-kodiċi ta' awtentikazzjoni. Biex tagħmel dan, issejvja n-numru tal-mowbajl tiegħek. Il-kodiċi jintbagħat lil n-numru tal-mowbajl issejvjat permezz ta' SMS."
    },
    "confirmCode": {
      "buttonLabel": "Ikkonferma"
    },
    "saveMobileNumber": {
      "buttonLabel": "Ibgħat u ssejvja"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "message": "Il-kodiċi ta' awtentikazzjoni intbagħat permezz ta' SMS. Jista 'jintuża darba biss u fi żmien limitat għall-illoggjar."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Erġa' ibgħat fi {seconds} sekondi"
    },
    "resendSMS": {
      "buttonLabel": "Ibgħat mill-ġdid"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "message": "Il-kodiċi ta' awtentikazzjoni intbagħat permezz ta' SMS. Jista 'jintuża darba biss u fi żmien limitat għall-illoggjar.",
          "title": "Azzjoni ta' suċċess"
        },
        "asyncError": {
          "title": "Żball",
          "message": "Il-messaġġ ma setax jintbagħat. Jekk jogħġbok iċċekkja d-dħul tiegħek."
        }
      },
      "saveMobileNumber": {
        "asyncError": {
          "title": "Żball",
          "message": "In-numru tal-mowbajl ma setax jiġi salvat. Iċċekkja d-dħul u erġa ssejvja."
        }
      }
    }
  },
  "sl-SI": {
    "SMSSetupForm": {
      "title": "SMS"
    },
    "pleaseEnterMobileNumber": {
      "label": "Dvofaktorsko preverjanje pristnosti aktivirajte tako, da vnesete in potrdite kodo za preverjanje pristnosti. Če želite to narediti, shranite svojo številko mobilnega telefona. Koda se na shranjeno mobilno številko pošlje prek SMS-a."
    },
    "confirmCode": {
      "buttonLabel": "Potrdi"
    },
    "saveMobileNumber": {
      "buttonLabel": "Shrani in pošlji"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "message": "Koda za preverjanje pristnosti je bila poslana prek SMS-a. Uporabite ga lahko samo enkrat in v omejenem času za prijavo."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Ponovno pošlji v {seconds} sekundah"
    },
    "resendSMS": {
      "buttonLabel": "Pošlji ponovno"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "message": "Koda za preverjanje pristnosti je bila poslana prek SMS-a. Uporabite ga lahko samo enkrat in v omejenem času za prijavo.",
          "title": "Uspešno opravljeno"
        },
        "asyncError": {
          "title": "Napaka",
          "message": "Sporočila ni bilo mogoče poslati. Prosimo, preverite svoj vnos."
        }
      },
      "saveMobileNumber": {
        "asyncError": {
          "title": "Napaka",
          "message": "Številke mobilnega telefona ni bilo mogoče shraniti. Preverite vnos in znova shranite."
        }
      }
    }
  },
  "tr-TR": {
    "SMSSetupForm": {
      "title": "SMS"
    },
    "pleaseEnterMobileNumber": {
      "label": "Kimlik doğrulama kodunu girip onaylayarak iki faktörlü kimlik doğrulamayı etkinleştirin. Bunu yapmak için cep telefonu numaranızı kaydedin. Kod, kayıtlı cep telefonu numarasına SMS ile gönderilir."
    },
    "confirmCode": {
      "buttonLabel": "Onayla"
    },
    "saveMobileNumber": {
      "buttonLabel": "Kaydet ve gönder"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "message": "Kimlik doğrulama kodu SMS ile gönderildi. Giriş yapmak için yalnızca bir kez ve sınırlı bir süre içinde kullanılabilir."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "{seconds} saniye içinde yeniden gönder"
    },
    "resendSMS": {
      "buttonLabel": "Tekrar gönderin"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "message": "Kimlik doğrulama kodu SMS ile gönderildi. Giriş yapmak için yalnızca bir kez ve sınırlı bir süre içinde kullanılabilir.",
          "title": "Başarılı eylem"
        },
        "asyncError": {
          "title": "Hata",
          "message": "Mesaj gönderilemedi. Lütfen girişinizi kontrol edin."
        }
      },
      "saveMobileNumber": {
        "asyncError": {
          "title": "Hata",
          "message": "Cep telefonu numarası kaydedilemedi. Girişi kontrol edin ve tekrar kaydedin."
        }
      }
    }
  },
  "tr-CY": {
    "SMSSetupForm": {
      "title": "SMS"
    },
    "pleaseEnterMobileNumber": {
      "label": "Kimlik doğrulama kodunu girip onaylayarak iki faktörlü kimlik doğrulamayı etkinleştirin. Bunu yapmak için cep telefonu numaranızı kaydedin. Kod, kayıtlı cep telefonu numarasına SMS ile gönderilir."
    },
    "confirmCode": {
      "buttonLabel": "Onayla"
    },
    "saveMobileNumber": {
      "buttonLabel": "Kaydet ve gönder"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "message": "Kimlik doğrulama kodu SMS ile gönderildi. Giriş yapmak için yalnızca bir kez ve sınırlı bir süre içinde kullanılabilir."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "{seconds} saniye içinde yeniden gönder"
    },
    "resendSMS": {
      "buttonLabel": "Tekrar gönderin"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "message": "Kimlik doğrulama kodu SMS ile gönderildi. Giriş yapmak için yalnızca bir kez ve sınırlı bir süre içinde kullanılabilir.",
          "title": "Başarılı eylem"
        },
        "asyncError": {
          "title": "Hata",
          "message": "Mesaj gönderilemedi. Lütfen girişinizi kontrol edin."
        }
      },
      "saveMobileNumber": {
        "asyncError": {
          "title": "Hata",
          "message": "Cep telefonu numarası kaydedilemedi. Girişi kontrol edin ve tekrar kaydedin."
        }
      }
    }
  },
  "uk-UA": {
    "SMSSetupForm": {
      "title": "SMS"
    },
    "pleaseEnterMobileNumber": {
      "label": "Aktiviere die Zwei-Faktor-Authentifizierung durch Eingabe und Bestätigung des Authentifizierungscodes. Speichere dazu deine Mobilnummer. Der Code wird an die gespeicherte Mobilnummer per SMS gesendet."
    },
    "confirmCode": {
      "buttonLabel": "Підтвердити"
    },
    "saveMobileNumber": {
      "buttonLabel": "Зберегти та відправити"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "message": "Код автентифікації надіслано через SMS. Його можна використовувати лише один раз і протягом обмеженого часу для входу."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Надіслати повторно через {seconds} секунд"
    },
    "resendSMS": {
      "buttonLabel": "Надіслати знову"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "message": "Код автентифікації надіслано через SMS. Його можна використовувати лише один раз і протягом обмеженого часу для входу.",
          "title": "Успішна акція"
        },
        "asyncError": {
          "title": "Помилка",
          "message": "Die Nachricht konnte nicht gesendet werden. Bitte prüfe deine Eingabe."
        }
      },
      "saveMobileNumber": {
        "asyncError": {
          "title": "Помилка",
          "message": "Не вдалося зберегти номер мобільного телефону. Перевірте введення та збережіть ще раз."
        }
      }
    }
  },
  "zh-CN": {
    "SMSSetupForm": {
      "title": "短信"
    },
    "pleaseEnterMobileNumber": {
      "label": "输入并确认身份验证码以激活双重身份验证。为此，请保存手机号码。代码将通过短信发送到存储的手机号码。"
    },
    "confirmCode": {
      "buttonLabel": "确认"
    },
    "saveMobileNumber": {
      "buttonLabel": "保存并发送"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "message": "验证码是通过短信发送的。 仅限登录一次，限时使用。"
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "在{seconds}内重新发送"
    },
    "resendSMS": {
      "buttonLabel": "重新发送"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "message": "验证码是通过短信发送的。 仅限登录一次，限时使用。",
          "title": "操作成功"
        },
        "asyncError": {
          "title": "错误",
          "message": "无法发送消息。 请检查您的输入。"
        }
      },
      "saveMobileNumber": {
        "asyncError": {
          "title": "错误",
          "message": "无法保存手机号码。 检查条目并再次保存。"
        }
      }
    }
  }
}
</i18n>
