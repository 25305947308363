import {to} from 'await-to-js';
import {createI18n} from 'vue-i18n'
import {useJurisdictionSwitch} from "@dutypay/components-library/src/composables/jurisdictionSwitch";
const { loadJurisdiction } = useJurisdictionSwitch()
import '@dutypay/components-library/src/locales/numeralLocales'

const jurisdiction = await loadJurisdiction();
async function loadLocaleMessages() {
  let messages = {};
  let translationValues, error;
  [error, translationValues] = await to(import('/src/locales/' + jurisdiction.i18n + '/translationValues.json'));
  if (error) {
    throw error;
  }
  let elementLocale;
  [error, elementLocale] = await to(import('@dutypay/components-library/src/locales/element-plus/' + jurisdiction.i18n + '.js'));
  if (error) {
    throw error;
  }
  messages[jurisdiction.i18n] = Object.assign(elementLocale.default, translationValues.default);
  return messages;
}

export const i18n = createI18n({
  globalInjection: true,
  useScope: "global",
  legacy: false,
  locale: jurisdiction.i18n,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en-US',
  messages: await loadLocaleMessages(),
  fallbackWarn: false,
  silentTranslationWarn: true,
  silentFallbackWarn: true
});

