export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ar-AE": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل وتأكد من استلام رمز المصادقة."])}
        },
        "notification": {
          "emailSent": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمل ناجح"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال رمز المصادقة عبر البريد الإلكتروني. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة للتسجيل."])}
          },
          "emailOTPNotVerified": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن تأكيد رمز المصادقة."])}
          },
          "verifyEmailOTP": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن تأكيد رمز المصادقة."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])},
            "asyncError": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن تأكيد رمز المصادقة."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])}
            }
          },
          "sendEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال رمز المصادقة عبر البريد الإلكتروني. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة للتسجيل."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمل ناجح"])},
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال رمز المصادقة عبر البريد الإلكتروني. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة للتسجيل."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمل ناجح"])}
            }
          }
        },
        "emailOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز المصادقة عن طريق البريد الإلكتروني"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد"])}
        },
        "resendEmail": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعد الإرسال"])}
        },
        "resendEmailInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["إعادة إرسال في ", _interpolate(_named("seconds")), " ثوان"])}
        }
      },
      "ar-EG": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل وتأكد من استلام رمز المصادقة."])}
        },
        "notification": {
          "emailSent": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمل ناجح"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال رمز المصادقة عبر البريد الإلكتروني. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة للتسجيل."])}
          },
          "emailOTPNotVerified": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن تأكيد رمز المصادقة."])}
          },
          "verifyEmailOTP": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن تأكيد رمز المصادقة."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])},
            "asyncError": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن تأكيد رمز المصادقة."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])}
            }
          },
          "sendEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال رمز المصادقة عبر البريد الإلكتروني. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة للتسجيل."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمل ناجح"])},
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال رمز المصادقة عبر البريد الإلكتروني. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة للتسجيل."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمل ناجح"])}
            }
          }
        },
        "emailOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز المصادقة عن طريق البريد الإلكتروني"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد"])}
        },
        "resendEmail": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعد الإرسال"])}
        },
        "resendEmailInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["إعادة إرسال في ", _interpolate(_named("seconds")), " ثوان"])}
        }
      },
      "ar-SA": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل وتأكد من استلام رمز المصادقة."])}
        },
        "notification": {
          "emailSent": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمل ناجح"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال رمز المصادقة عبر البريد الإلكتروني. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة للتسجيل."])}
          },
          "emailOTPNotVerified": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن تأكيد رمز المصادقة."])}
          },
          "verifyEmailOTP": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن تأكيد رمز المصادقة."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])},
            "asyncError": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن تأكيد رمز المصادقة."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])}
            }
          },
          "sendEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال رمز المصادقة عبر البريد الإلكتروني. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة للتسجيل."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمل ناجح"])},
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال رمز المصادقة عبر البريد الإلكتروني. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة للتسجيل."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمل ناجح"])}
            }
          }
        },
        "emailOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز المصادقة عن طريق البريد الإلكتروني"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد"])}
        },
        "resendEmail": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعد الإرسال"])}
        },
        "resendEmailInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["إعادة إرسال في ", _interpolate(_named("seconds")), " ثوان"])}
        }
      },
      "el-GR": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε και επιβεβαιώστε τον κωδικό ελέγχου ταυτότητας που λάβατε."])}
        },
        "notification": {
          "emailSent": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιτυχημένη ενέργεια"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο κωδικός ελέγχου ταυτότητας στάλθηκε μέσω email. Μπορεί να χρησιμοποιηθεί μόνο μία φορά και εντός περιορισμένου χρόνου για εγγραφή."])}
          },
          "emailOTPNotVerified": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σφάλμα"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν ήταν δυνατή η επιβεβαίωση του κωδικού ελέγχου ταυτότητας."])}
          },
          "verifyEmailOTP": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν ήταν δυνατή η επιβεβαίωση του κωδικού ελέγχου ταυτότητας."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σφάλμα"])},
            "asyncError": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν ήταν δυνατή η επιβεβαίωση του κωδικού ελέγχου ταυτότητας."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σφάλμα"])}
            }
          },
          "sendEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο κωδικός ελέγχου ταυτότητας στάλθηκε μέσω email. Μπορεί να χρησιμοποιηθεί μόνο μία φορά και εντός περιορισμένου χρόνου για εγγραφή."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιτυχημένη ενέργεια"])},
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο κωδικός ελέγχου ταυτότητας στάλθηκε μέσω email. Μπορεί να χρησιμοποιηθεί μόνο μία φορά και εντός περιορισμένου χρόνου για εγγραφή."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιτυχημένη ενέργεια"])}
            }
          }
        },
        "emailOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κωδικός ελέγχου ταυτότητας μέσω email"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιβεβαίωση"])}
        },
        "resendEmail": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στείλε ξανά"])}
        },
        "resendEmailInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Επανάληψη αποστολής σε ", _interpolate(_named("seconds")), " δευτερόλεπτα"])}
        }
      },
      "el-CY": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε και επιβεβαιώστε τον κωδικό ελέγχου ταυτότητας που λάβατε."])}
        },
        "notification": {
          "emailSent": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιτυχημένη ενέργεια"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο κωδικός ελέγχου ταυτότητας στάλθηκε μέσω email. Μπορεί να χρησιμοποιηθεί μόνο μία φορά και εντός περιορισμένου χρόνου για εγγραφή."])}
          },
          "emailOTPNotVerified": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σφάλμα"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν ήταν δυνατή η επιβεβαίωση του κωδικού ελέγχου ταυτότητας."])}
          },
          "verifyEmailOTP": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν ήταν δυνατή η επιβεβαίωση του κωδικού ελέγχου ταυτότητας."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σφάλμα"])},
            "asyncError": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν ήταν δυνατή η επιβεβαίωση του κωδικού ελέγχου ταυτότητας."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σφάλμα"])}
            }
          },
          "sendEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο κωδικός ελέγχου ταυτότητας στάλθηκε μέσω email. Μπορεί να χρησιμοποιηθεί μόνο μία φορά και εντός περιορισμένου χρόνου για εγγραφή."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιτυχημένη ενέργεια"])},
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο κωδικός ελέγχου ταυτότητας στάλθηκε μέσω email. Μπορεί να χρησιμοποιηθεί μόνο μία φορά και εντός περιορισμένου χρόνου για εγγραφή."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιτυχημένη ενέργεια"])}
            }
          }
        },
        "emailOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κωδικός ελέγχου ταυτότητας μέσω email"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιβεβαίωση"])}
        },
        "resendEmail": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στείλε ξανά"])}
        },
        "resendEmailInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Επανάληψη αποστολής σε ", _interpolate(_named("seconds")), " δευτερόλεπτα"])}
        }
      },
      "de-DE": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfangenen Authentifizierungscode eingeben und bestätigen."])}
        },
        "notification": {
          "emailSent": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreiche Aktion"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Authentifizierungscode wurde per E-Mail gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden."])}
          },
          "emailOTPNotVerified": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Authentifizierungscode konnte nicht bestätigt werden."])}
          },
          "verifyEmailOTP": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Authentifizierungscode konnte nicht bestätigt werden."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
            "asyncError": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Authentifizierungscode konnte nicht bestätigt werden."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])}
            }
          },
          "sendEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Authentifizierungscode wurde per E-Mail gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreiche Aktion"])},
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Authentifizierungscode wurde per E-Mail gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreiche Aktion"])}
            }
          }
        },
        "emailOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifizierungscode per E-Mail"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])}
        },
        "resendEmail": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erneut senden"])}
        },
        "resendEmailInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Erneut senden in ", _interpolate(_named("seconds")), " Sekunden"])}
        }
      },
      "de-AT": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfangenen Authentifizierungscode eingeben und bestätigen."])}
        },
        "notification": {
          "emailSent": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreiche Aktion"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Authentifizierungscode wurde per E-Mail gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden."])}
          },
          "emailOTPNotVerified": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Authentifizierungscode konnte nicht bestätigt werden."])}
          },
          "verifyEmailOTP": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Authentifizierungscode konnte nicht bestätigt werden."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
            "asyncError": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Authentifizierungscode konnte nicht bestätigt werden."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])}
            }
          },
          "sendEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Authentifizierungscode wurde per E-Mail gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreiche Aktion"])},
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Authentifizierungscode wurde per E-Mail gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreiche Aktion"])}
            }
          }
        },
        "emailOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifizierungscode per E-Mail"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])}
        },
        "resendEmail": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erneut senden"])}
        },
        "resendEmailInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Erneut senden in ", _interpolate(_named("seconds")), " Sekunden"])}
        }
      },
      "de-CH": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfangenen Authentifizierungscode eingeben und bestätigen."])}
        },
        "notification": {
          "emailSent": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreiche Aktion"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Authentifizierungscode wurde per E-Mail gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden."])}
          },
          "emailOTPNotVerified": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Authentifizierungscode konnte nicht bestätigt werden."])}
          },
          "verifyEmailOTP": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Authentifizierungscode konnte nicht bestätigt werden."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
            "asyncError": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Authentifizierungscode konnte nicht bestätigt werden."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])}
            }
          },
          "sendEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Authentifizierungscode wurde per E-Mail gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreiche Aktion"])},
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Authentifizierungscode wurde per E-Mail gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreiche Aktion"])}
            }
          }
        },
        "emailOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifizierungscode per E-Mail"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])}
        },
        "resendEmail": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erneut senden"])}
        },
        "resendEmailInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Erneut senden in ", _interpolate(_named("seconds")), " Sekunden"])}
        }
      },
      "en-US": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter and confirm the authentication code received."])}
        },
        "notification": {
          "emailSent": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful action"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The authentication code was sent via e-mail. It can only be used once and within a limited time for logging in."])}
          },
          "emailOTPNotVerified": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The authentication code could not be confirmed."])}
          },
          "verifyEmailOTP": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The authentication code could not be confirmed."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
            "asyncError": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The authentication code could not be confirmed."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])}
            }
          },
          "sendEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The authentication code was sent via e-mail. It can only be used once and within a limited time for logging in."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful action"])},
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The authentication code was sent via e-mail. It can only be used once and within a limited time for logging in."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful action"])}
            }
          }
        },
        "emailOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication code by E-mail"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])}
        },
        "resendEmail": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send again"])}
        },
        "resendEmailInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Resend in ", _interpolate(_named("seconds")), " seconds"])}
        }
      },
      "en-GB": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter and confirm the authentication code received."])}
        },
        "notification": {
          "emailSent": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful action"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The authentication code was sent via email. It can only be used once and within a limited time for registration."])}
          },
          "emailOTPNotVerified": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The authentication code could not be confirmed."])}
          },
          "verifyEmailOTP": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The authentication code could not be confirmed."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
            "asyncError": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The authentication code could not be confirmed."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])}
            }
          },
          "sendEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The authentication code was sent via email. It can only be used once and within a limited time for registration."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful action"])},
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The authentication code was sent via email. It can only be used once and within a limited time for registration."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful action"])}
            }
          }
        },
        "emailOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication code by E-mail"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])}
        },
        "resendEmail": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send again"])}
        },
        "resendEmailInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Resend in ", _interpolate(_named("seconds")), " seconds"])}
        }
      },
      "it-IT": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci e conferma il codice di autenticazione ricevuto."])}
        },
        "notification": {
          "emailSent": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azione riuscita"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il codice di autenticazione è stato inviato via e-mail. Può essere utilizzato solo una volta ed entro un tempo limitato per la registrazione."])}
          },
          "emailOTPNotVerified": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è stato possibile confermare il codice di autenticazione."])}
          },
          "verifyEmailOTP": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è stato possibile confermare il codice di autenticazione."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore"])},
            "asyncError": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è stato possibile confermare il codice di autenticazione."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore"])}
            }
          },
          "sendEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il codice di autenticazione è stato inviato via e-mail. Può essere utilizzato solo una volta ed entro un tempo limitato per la registrazione."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azione riuscita"])},
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il codice di autenticazione è stato inviato via e-mail. Può essere utilizzato solo una volta ed entro un tempo limitato per la registrazione."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azione riuscita"])}
            }
          }
        },
        "emailOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice di autenticazione via e-mail"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma"])}
        },
        "resendEmail": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia di nuovo"])}
        },
        "resendEmailInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Invia nuovamente tra ", _interpolate(_named("seconds")), " secondi"])}
        }
      },
      "fr-FR": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez et confirmez le code d'authentification reçu."])}
        },
        "notification": {
          "emailSent": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action réussie"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code d'authentification a été envoyé par e-mail. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour l'enregistrement."])}
          },
          "emailOTPNotVerified": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code d'authentification n'a pas pu être confirmé."])}
          },
          "verifyEmailOTP": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code d'authentification n'a pas pu être confirmé."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
            "asyncError": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code d'authentification n'a pas pu être confirmé."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])}
            }
          },
          "sendEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code d'authentification a été envoyé par e-mail. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour l'enregistrement."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action réussie"])},
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code d'authentification a été envoyé par e-mail. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour l'enregistrement."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action réussie"])}
            }
          }
        },
        "emailOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code d'authentification par email"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])}
        },
        "resendEmail": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer à nouveau"])}
        },
        "resendEmailInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Renvoyer dans ", _interpolate(_named("seconds")), " secondes"])}
        }
      },
      "es-ES": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca y confirme el código de autenticación recibido."])}
        },
        "notification": {
          "emailSent": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listo"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El código de autenticación se envió por correo electrónico. Solo se puede usar una vez y dentro de un tiempo limitado para el registro."])}
          },
          "emailOTPNotVerified": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo confirmar el código de autenticación."])}
          },
          "verifyEmailOTP": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo confirmar el código de autenticación."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
            "asyncError": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo confirmar el código de autenticación."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])}
            }
          },
          "sendEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El código de autenticación se envió por correo electrónico. Solo se puede usar una vez y dentro de un tiempo limitado para el registro."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listo"])},
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El código de autenticación se envió por correo electrónico. Solo se puede usar una vez y dentro de un tiempo limitado para el registro."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listo"])}
            }
          }
        },
        "emailOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de autenticación por correo electrónico"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])}
        },
        "resendEmail": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar de nuevo"])}
        },
        "resendEmailInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Enviar de nuevo en ", _interpolate(_named("seconds")), " segundos"])}
        }
      },
      "pl-PL": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź i potwierdź otrzymany kod uwierzytelniający."])}
        },
        "notification": {
          "emailSent": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czynność zakończona powodzeniem"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod uwierzytelniający został wysłany e-mailem. Można go użyć tylko raz iw ograniczonym czasie do rejestracji."])}
          },
          "emailOTPNotVerified": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błąd"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie udało się potwierdzić kodu uwierzytelniającego."])}
          },
          "verifyEmailOTP": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie udało się potwierdzić kodu uwierzytelniającego."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błąd"])},
            "asyncError": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie udało się potwierdzić kodu uwierzytelniającego."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błąd"])}
            }
          },
          "sendEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod uwierzytelniający został wysłany e-mailem. Można go użyć tylko raz iw ograniczonym czasie do rejestracji."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czynność zakończona powodzeniem"])},
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod uwierzytelniający został wysłany e-mailem. Można go użyć tylko raz iw ograniczonym czasie do rejestracji."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czynność zakończona powodzeniem"])}
            }
          }
        },
        "emailOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod uwierzytelniający przez e-mail"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdź"])}
        },
        "resendEmail": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij ponownie"])}
        },
        "resendEmailInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wyślij ponownie za ", _interpolate(_named("seconds")), " s"])}
        }
      },
      "nl-NL": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer de ontvangen authenticatiecode in en bevestig deze."])}
        },
        "notification": {
          "emailSent": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geslaagde actie"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De authenticatiecode is per e-mail verzonden. Het kan slechts één keer en binnen een beperkte tijd worden gebruikt voor registratie."])}
          },
          "emailOTPNotVerified": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De authencitatiecode kon niet worden bevestigd."])}
          },
          "verifyEmailOTP": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De authencitatiecode kon niet worden bevestigd."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout"])},
            "asyncError": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De authencitatiecode kon niet worden bevestigd."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout"])}
            }
          },
          "sendEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De authenticatiecode is per e-mail verzonden. Het kan slechts één keer en binnen een beperkte tijd worden gebruikt voor registratie."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geslaagde actie"])},
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De authenticatiecode is per e-mail verzonden. Het kan slechts één keer en binnen een beperkte tijd worden gebruikt voor registratie."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geslaagde actie"])}
            }
          }
        },
        "emailOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticatiecode per e-mail"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestigen"])}
        },
        "resendEmail": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opnieuw verzenden"])}
        },
        "resendEmailInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Verzenden over ", _interpolate(_named("seconds")), " seconden"])}
        }
      },
      "bg-BG": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въведете и потвърдете получения код за удостоверяване."])}
        },
        "notification": {
          "emailSent": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно действие"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кодът за удостоверяване беше изпратен по имейл. Може да се използва само веднъж и в рамките на ограничено време за регистрация."])}
          },
          "emailOTPNotVerified": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["грешка"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кодът за удостоверяване не може да бъде потвърден."])}
          },
          "verifyEmailOTP": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кодът за удостоверяване не може да бъде потвърден."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["грешка"])},
            "asyncError": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кодът за удостоверяване не може да бъде потвърден."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["грешка"])}
            }
          },
          "sendEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кодът за удостоверяване беше изпратен по имейл. Може да се използва само веднъж и в рамките на ограничено време за регистрация."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно действие"])},
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кодът за удостоверяване беше изпратен по имейл. Може да се използва само веднъж и в рамките на ограничено време за регистрация."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно действие"])}
            }
          }
        },
        "emailOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код за удостоверяване по имейл"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потвърждение"])}
        },
        "resendEmail": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изпрати отново"])}
        },
        "resendEmailInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Изпращане след ", _interpolate(_named("seconds")), " секунди"])}
        }
      },
      "bs-BA": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite i potvrdite primljeni kod za autentifikaciju."])}
        },
        "notification": {
          "emailSent": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno obavljeno"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod za autentifikaciju je poslan putem e-pošte. Može se koristiti samo jednom iu ograničenom vremenu za registraciju."])}
          },
          "emailOTPNotVerified": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greška"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nije moguće potvrditi autentifikacijski kôd."])}
          },
          "verifyEmailOTP": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nije moguće potvrditi autentifikacijski kôd."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greška"])},
            "asyncError": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nije moguće potvrditi autentifikacijski kôd."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greška"])}
            }
          },
          "sendEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod za autentifikaciju je poslan putem e-pošte. Može se koristiti samo jednom iu ograničenom vremenu za registraciju."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno obavljeno"])},
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod za autentifikaciju je poslan putem e-pošte. Može se koristiti samo jednom iu ograničenom vremenu za registraciju."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno obavljeno"])}
            }
          }
        },
        "emailOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentifikacijski kod e-poštom"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdite"])}
        },
        "resendEmail": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pošalji ponovo"])}
        },
        "resendEmailInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ponovo pošalji za ", _interpolate(_named("seconds")), " sekundi"])}
        }
      },
      "da-DK": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast og bekræft den modtagne godkendelseskode."])}
        },
        "notification": {
          "emailSent": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vellykket handling"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkendelseskoden blev sendt via e-mail. Den kan kun bruges én gang og inden for en begrænset tid til registrering."])}
          },
          "emailOTPNotVerified": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fejl"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkendelseskoden kunne ikke bekræftes."])}
          },
          "verifyEmailOTP": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkendelseskoden kunne ikke bekræftes."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fejl"])},
            "asyncError": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkendelseskoden kunne ikke bekræftes."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fejl"])}
            }
          },
          "sendEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkendelseskoden blev sendt via e-mail. Den kan kun bruges én gang og inden for en begrænset tid til registrering."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vellykket handling"])},
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkendelseskoden blev sendt via e-mail. Den kan kun bruges én gang og inden for en begrænset tid til registrering."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vellykket handling"])}
            }
          }
        },
        "emailOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkendelseskode via e-mail"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekræft"])}
        },
        "resendEmail": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send igen"])}
        },
        "resendEmailInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Send igen om ", _interpolate(_named("seconds")), " sekunder"])}
        }
      },
      "et-EE": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisestage ja kinnitage saadud autentimiskood."])}
        },
        "notification": {
          "emailSent": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edukas tegevus"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentimiskood saadeti meili teel. Seda saab registreerimiseks kasutada ainult üks kord ja piiratud aja jooksul."])}
          },
          "emailOTPNotVerified": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viga"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentimiskoodi ei õnnestunud kinnitada."])}
          },
          "verifyEmailOTP": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentimiskoodi ei õnnestunud kinnitada."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viga"])},
            "asyncError": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentimiskoodi ei õnnestunud kinnitada."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viga"])}
            }
          },
          "sendEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentimiskood saadeti meili teel. Seda saab registreerimiseks kasutada ainult üks kord ja piiratud aja jooksul."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edukas tegevus"])},
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentimiskood saadeti meili teel. Seda saab registreerimiseks kasutada ainult üks kord ja piiratud aja jooksul."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edukas tegevus"])}
            }
          }
        },
        "emailOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentimiskood e-posti teel"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kinnita"])}
        },
        "resendEmail": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saada uuesti"])}
        },
        "resendEmailInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Saada uuesti ", _interpolate(_named("seconds")), " sekundi pärast"])}
        }
      },
      "fi-FI": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syötä ja vahvista vastaanotettu todennuskoodi."])}
        },
        "notification": {
          "emailSent": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onnistunut toiminta"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todennuskoodi lähetettiin sähköpostitse. Sitä voidaan käyttää vain kerran ja rajoitetun ajan rekisteröintiä varten."])}
          },
          "emailOTPNotVerified": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virhe"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todennuskoodia ei voitu vahvistaa."])}
          },
          "verifyEmailOTP": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todennuskoodia ei voitu vahvistaa."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virhe"])},
            "asyncError": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todennuskoodia ei voitu vahvistaa."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virhe"])}
            }
          },
          "sendEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todennuskoodi lähetettiin sähköpostitse. Sitä voidaan käyttää vain kerran ja rajoitetun ajan rekisteröintiä varten."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onnistunut toiminta"])},
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todennuskoodi lähetettiin sähköpostitse. Sitä voidaan käyttää vain kerran ja rajoitetun ajan rekisteröintiä varten."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onnistunut toiminta"])}
            }
          }
        },
        "emailOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todennuskoodi sähköpostitse"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vahvista"])}
        },
        "resendEmail": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetä uudestaan"])}
        },
        "resendEmailInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lähetä uudelleen ", _interpolate(_named("seconds")), " sekunnissa"])}
        }
      },
      "he-IL": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הזן ואשר את קוד האימות שהתקבל."])}
        },
        "notification": {
          "emailSent": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["פעולה מוצלחת"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["קוד האימות נשלח בדוא＂ל. ניתן להשתמש בו רק פעם אחת ובזמן מוגבל לרישום."])}
          },
          "emailOTPNotVerified": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שְׁגִיאָה"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["לא ניתן היה לאשר את קוד האימות."])}
          },
          "verifyEmailOTP": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["לא ניתן היה לאשר את קוד האימות."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שְׁגִיאָה"])},
            "asyncError": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["לא ניתן היה לאשר את קוד האימות."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שְׁגִיאָה"])}
            }
          },
          "sendEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["קוד האימות נשלח בדוא＂ל. ניתן להשתמש בו רק פעם אחת ובזמן מוגבל לרישום."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["פעולה מוצלחת"])},
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["קוד האימות נשלח בדוא＂ל. ניתן להשתמש בו רק פעם אחת ובזמן מוגבל לרישום."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["פעולה מוצלחת"])}
            }
          }
        },
        "emailOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["קוד אימות במייל"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["לְאַשֵׁר"])}
        },
        "resendEmail": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שלח שוב"])}
        },
        "resendEmailInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["שלח מחדש בתוך ", _interpolate(_named("seconds")), " שניות"])}
        }
      },
      "ga-IE": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuir isteach agus deimhnigh an cód fíordheimhnithe a fuarthas."])}
        },
        "notification": {
          "emailSent": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gníomh rathúil"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seoladh an cód fíordheimhnithe trí ríomhphost. Ní féidir é a úsáid ach uair amháin agus laistigh de thréimhse teoranta ama le haghaidh clárúcháin."])}
          },
          "emailOTPNotVerified": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earráid"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Níorbh fhéidir an cód fíordheimhnithe a dhearbhú."])}
          },
          "verifyEmailOTP": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Níorbh fhéidir an cód fíordheimhnithe a dhearbhú."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earráid"])},
            "asyncError": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Níorbh fhéidir an cód fíordheimhnithe a dhearbhú."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earráid"])}
            }
          },
          "sendEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seoladh an cód fíordheimhnithe trí ríomhphost. Ní féidir é a úsáid ach uair amháin agus laistigh de thréimhse teoranta ama le haghaidh clárúcháin."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gníomh rathúil"])},
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seoladh an cód fíordheimhnithe trí ríomhphost. Ní féidir é a úsáid ach uair amháin agus laistigh de thréimhse teoranta ama le haghaidh clárúcháin."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gníomh rathúil"])}
            }
          }
        },
        "emailOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cód fíordheimhnithe trí ríomhphost"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deimhnigh"])}
        },
        "resendEmail": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seol arís"])}
        },
        "resendEmailInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Athsheol i gceann ", _interpolate(_named("seconds")), " soicind"])}
        }
      },
      "ja-JP": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受信した認証コードを入力して確認します。"])}
        },
        "notification": {
          "emailSent": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功したアクション"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証コードはメールで送信されました。 1回のみ利用可能で、登録の制限時間内に利用できます。"])}
          },
          "emailOTPNotVerified": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エラー"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証コードが確認できませんでした。"])}
          },
          "verifyEmailOTP": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証コードが確認できませんでした。"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エラー"])},
            "asyncError": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証コードが確認できませんでした。"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エラー"])}
            }
          },
          "sendEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証コードはメールで送信されました。 1回のみ利用可能で、登録の制限時間内に利用できます。"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功したアクション"])},
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証コードはメールで送信されました。 1回のみ利用可能で、登録の制限時間内に利用できます。"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功したアクション"])}
            }
          }
        },
        "emailOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールによる認証コード"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認"])}
        },
        "resendEmail": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["もう一度送信"])}
        },
        "resendEmailInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("seconds")), "秒で再送信"])}
        }
      },
      "hr-HR": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite i potvrdite primljeni autentifikacijski kod."])}
        },
        "notification": {
          "emailSent": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješna akcija"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod za provjeru autentičnosti poslan je e-poštom. Može se koristiti samo jednom i unutar ograničenog vremena za registraciju."])}
          },
          "emailOTPNotVerified": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pogreška"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nije moguće potvrditi autentifikacijski kod."])}
          },
          "verifyEmailOTP": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nije moguće potvrditi autentifikacijski kod."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pogreška"])},
            "asyncError": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nije moguće potvrditi autentifikacijski kod."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pogreška"])}
            }
          },
          "sendEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod za provjeru autentičnosti poslan je e-poštom. Može se koristiti samo jednom i unutar ograničenog vremena za registraciju."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješna akcija"])},
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod za provjeru autentičnosti poslan je e-poštom. Može se koristiti samo jednom i unutar ograničenog vremena za registraciju."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješna akcija"])}
            }
          }
        },
        "emailOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentifikacijski kod e-poštom"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrda"])}
        },
        "resendEmail": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pošalji opet"])}
        },
        "resendEmailInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ponovno slanje u ", _interpolate(_named("seconds")), " sekundama"])}
        }
      },
      "lv-LV": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ievadiet un apstipriniet saņemto autentifikācijas kodu."])}
        },
        "notification": {
          "emailSent": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veiksmīga darbība"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentifikācijas kods tika nosūtīts pa e-pastu. To var izmantot tikai vienu reizi un ierobežotā reģistrācijas laikā."])}
          },
          "emailOTPNotVerified": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kļūda"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nevarēja apstiprināt autentifikācijas kodu."])}
          },
          "verifyEmailOTP": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nevarēja apstiprināt autentifikācijas kodu."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kļūda"])},
            "asyncError": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nevarēja apstiprināt autentifikācijas kodu."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kļūda"])}
            }
          },
          "sendEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentifikācijas kods tika nosūtīts pa e-pastu. To var izmantot tikai vienu reizi un ierobežotā reģistrācijas laikā."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veiksmīga darbība"])},
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentifikācijas kods tika nosūtīts pa e-pastu. To var izmantot tikai vienu reizi un ierobežotā reģistrācijas laikā."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veiksmīga darbība"])}
            }
          }
        },
        "emailOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentifikācijas kods pa e-pastu"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstiprināt"])}
        },
        "resendEmail": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sūtīt vēlreiz"])}
        },
        "resendEmailInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Atkārtoti nosūtīt pēc ", _interpolate(_named("seconds")), " sek."])}
        }
      },
      "lt-LT": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įveskite ir patvirtinkite gautą autentifikavimo kodą."])}
        },
        "notification": {
          "emailSent": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sėkmingas veiksmas"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentifikavimo kodas buvo išsiųstas el. paštu. Jį galima naudoti tik vieną kartą ir per ribotą registracijos laiką."])}
          },
          "emailOTPNotVerified": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klaida"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepavyko patvirtinti autentifikavimo kodo."])}
          },
          "verifyEmailOTP": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepavyko patvirtinti autentifikavimo kodo."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klaida"])},
            "asyncError": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepavyko patvirtinti autentifikavimo kodo."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klaida"])}
            }
          },
          "sendEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentifikavimo kodas buvo išsiųstas el. paštu. Jį galima naudoti tik vieną kartą ir per ribotą registracijos laiką."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sėkmingas veiksmas"])},
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentifikavimo kodas buvo išsiųstas el. paštu. Jį galima naudoti tik vieną kartą ir per ribotą registracijos laiką."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sėkmingas veiksmas"])}
            }
          }
        },
        "emailOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentifikavimo kodas el. Paštu"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patvirtinti"])}
        },
        "resendEmail": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siųsti dar kartą"])}
        },
        "resendEmailInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Iš naujo išsiųsti po ", _interpolate(_named("seconds")), " sek."])}
        }
      },
      "pt-PT": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digite e confirme o código de autenticação recebido."])}
        },
        "notification": {
          "emailSent": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ação bem-sucedida"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O código de autenticação foi enviado por e-mail. Ele só pode ser usado uma vez e dentro de um tempo limitado para registro."])}
          },
          "emailOTPNotVerified": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não foi possível confirmar o código de autenticação."])}
          },
          "verifyEmailOTP": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não foi possível confirmar o código de autenticação."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro"])},
            "asyncError": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não foi possível confirmar o código de autenticação."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro"])}
            }
          },
          "sendEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O código de autenticação foi enviado por e-mail. Ele só pode ser usado uma vez e dentro de um tempo limitado para registro."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ação bem-sucedida"])},
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O código de autenticação foi enviado por e-mail. Ele só pode ser usado uma vez e dentro de um tempo limitado para registro."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ação bem-sucedida"])}
            }
          }
        },
        "emailOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de autenticação por email"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])}
        },
        "resendEmail": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envie novamente"])}
        },
        "resendEmailInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Reenviar em ", _interpolate(_named("seconds")), " segundos"])}
        }
      },
      "ro-RO": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduceți și confirmați codul de autentificare primit."])}
        },
        "notification": {
          "emailSent": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acțiune reușită"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codul de autentificare a fost trimis prin e-mail. Poate fi folosit o singură dată și într-un timp limitat pentru înregistrare."])}
          },
          "emailOTPNotVerified": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eroare"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codul de autentificare nu a putut fi confirmat."])}
          },
          "verifyEmailOTP": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codul de autentificare nu a putut fi confirmat."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eroare"])},
            "asyncError": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codul de autentificare nu a putut fi confirmat."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eroare"])}
            }
          },
          "sendEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codul de autentificare a fost trimis prin e-mail. Poate fi folosit o singură dată și într-un timp limitat pentru înregistrare."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acțiune reușită"])},
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codul de autentificare a fost trimis prin e-mail. Poate fi folosit o singură dată și într-un timp limitat pentru înregistrare."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acțiune reușită"])}
            }
          }
        },
        "emailOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cod de autentificare prin e-mail"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmare"])}
        },
        "resendEmail": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimite din nou"])}
        },
        "resendEmailInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Retrimitere în ", _interpolate(_named("seconds")), " secunde"])}
        }
      },
      "ru-RU": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите и подтвердите полученный код аутентификации."])}
        },
        "notification": {
          "emailSent": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешное действие"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код аутентификации был отправлен по электронной почте. Его можно использовать только один раз и в течение ограниченного времени регистрации."])}
          },
          "emailOTPNotVerified": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код аутентификации не подтвержден."])}
          },
          "verifyEmailOTP": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код аутентификации не подтвержден."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])},
            "asyncError": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код аутентификации не подтвержден."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])}
            }
          },
          "sendEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код аутентификации был отправлен по электронной почте. Его можно использовать только один раз и в течение ограниченного времени регистрации."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешное действие"])},
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код аутентификации был отправлен по электронной почте. Его можно использовать только один раз и в течение ограниченного времени регистрации."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешное действие"])}
            }
          }
        },
        "emailOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код аутентификации по электронной почте"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить"])}
        },
        "resendEmail": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправь еще раз"])}
        },
        "resendEmailInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Отправить повторно через ", _interpolate(_named("seconds")), " секунд"])}
        }
      },
      "sv-SE": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange och bekräfta den mottagna autentiseringskoden."])}
        },
        "notification": {
          "emailSent": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åtgärd utförd"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentiseringskoden skickades via e-post. Den kan endast användas en gång och inom en begränsad tid för registrering."])}
          },
          "emailOTPNotVerified": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fel"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentiseringskoden kunde inte bekräftas."])}
          },
          "verifyEmailOTP": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentiseringskoden kunde inte bekräftas."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fel"])},
            "asyncError": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentiseringskoden kunde inte bekräftas."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fel"])}
            }
          },
          "sendEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentiseringskoden skickades via e-post. Den kan endast användas en gång och inom en begränsad tid för registrering."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åtgärd utförd"])},
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentiseringskoden skickades via e-post. Den kan endast användas en gång och inom en begränsad tid för registrering."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åtgärd utförd"])}
            }
          }
        },
        "emailOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentiseringskod via e-post"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekräfta"])}
        },
        "resendEmail": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skicka igen"])}
        },
        "resendEmailInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Skicka igen om ", _interpolate(_named("seconds")), " sekunder"])}
        }
      },
      "sk-SK": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte a potvrďte prijatý overovací kód."])}
        },
        "notification": {
          "emailSent": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspešná akcia"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overovací kód bol odoslaný e-mailom. Môže byť použitý iba raz a v obmedzenom čase na registráciu."])}
          },
          "emailOTPNotVerified": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overovací kód nebolo možné potvrdiť."])}
          },
          "verifyEmailOTP": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overovací kód nebolo možné potvrdiť."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba"])},
            "asyncError": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overovací kód nebolo možné potvrdiť."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba"])}
            }
          },
          "sendEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overovací kód bol odoslaný e-mailom. Môže byť použitý iba raz a v obmedzenom čase na registráciu."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspešná akcia"])},
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overovací kód bol odoslaný e-mailom. Môže byť použitý iba raz a v obmedzenom čase na registráciu."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspešná akcia"])}
            }
          }
        },
        "emailOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentifikačný kód e-mailom"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdiť"])}
        },
        "resendEmail": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poslať znova"])}
        },
        "resendEmailInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Opäť odoslať o ", _interpolate(_named("seconds")), " sekúnd"])}
        }
      },
      "cs-CZ": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte a potvrďte přijatý ověřovací kód."])}
        },
        "notification": {
          "emailSent": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspěšná akce"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověřovací kód byl zaslán e-mailem. Lze jej použít pouze jednou a během omezené doby pro registraci."])}
          },
          "emailOTPNotVerified": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nelze potvrdit ověřovací kód."])}
          },
          "verifyEmailOTP": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nelze potvrdit ověřovací kód."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba"])},
            "asyncError": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nelze potvrdit ověřovací kód."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba"])}
            }
          },
          "sendEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověřovací kód byl zaslán e-mailem. Lze jej použít pouze jednou a během omezené doby pro registraci."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspěšná akce"])},
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověřovací kód byl zaslán e-mailem. Lze jej použít pouze jednou a během omezené doby pro registraci."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspěšná akce"])}
            }
          }
        },
        "emailOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověřovací kód e-mailem"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdit"])}
        },
        "resendEmail": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poslat znovu"])}
        },
        "resendEmailInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Odeslat znovu za ", _interpolate(_named("seconds")), " s."])}
        }
      },
      "hu-HU": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Írja be és erősítse meg a kapott hitelesítési kódot."])}
        },
        "notification": {
          "emailSent": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikeres művelet"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hitelesítési kódot e-mailben küldték el. Csak egyszer és korlátozott ideig használható fel regisztrációhoz."])}
          },
          "emailOTPNotVerified": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiba"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hitelesítő kódot nem sikerült megerősíteni."])}
          },
          "verifyEmailOTP": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hitelesítő kódot nem sikerült megerősíteni."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiba"])},
            "asyncError": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hitelesítő kódot nem sikerült megerősíteni."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiba"])}
            }
          },
          "sendEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hitelesítési kódot e-mailben küldték el. Csak egyszer és korlátozott ideig használható fel regisztrációhoz."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikeres művelet"])},
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hitelesítési kódot e-mailben küldték el. Csak egyszer és korlátozott ideig használható fel regisztrációhoz."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikeres művelet"])}
            }
          }
        },
        "emailOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hitelesítési kód e-mailben"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megerősít"])}
        },
        "resendEmail": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küldd újra"])}
        },
        "resendEmailInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Újraküldés ", _interpolate(_named("seconds")), " másodperc múlva"])}
        }
      },
      "fr-BE": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez et confirmez le code d'authentification reçu."])}
        },
        "notification": {
          "emailSent": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action réussie"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code d'authentification a été envoyé par e-mail. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour l'enregistrement."])}
          },
          "emailOTPNotVerified": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code d'authentification n'a pas pu être confirmé."])}
          },
          "verifyEmailOTP": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code d'authentification n'a pas pu être confirmé."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
            "asyncError": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code d'authentification n'a pas pu être confirmé."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])}
            }
          },
          "sendEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code d'authentification a été envoyé par e-mail. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour l'enregistrement."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action réussie"])},
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code d'authentification a été envoyé par e-mail. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour l'enregistrement."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action réussie"])}
            }
          }
        },
        "emailOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code d'authentification par email"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])}
        },
        "resendEmail": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer à nouveau"])}
        },
        "resendEmailInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Renvoyer dans ", _interpolate(_named("seconds")), " secondes"])}
        }
      },
      "fr-LU": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez et confirmez le code d'authentification reçu."])}
        },
        "notification": {
          "emailSent": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action réussie"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code d'authentification a été envoyé par e-mail. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour l'enregistrement."])}
          },
          "emailOTPNotVerified": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code d'authentification n'a pas pu être confirmé."])}
          },
          "verifyEmailOTP": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code d'authentification n'a pas pu être confirmé."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
            "asyncError": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code d'authentification n'a pas pu être confirmé."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])}
            }
          },
          "sendEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code d'authentification a été envoyé par e-mail. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour l'enregistrement."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action réussie"])},
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code d'authentification a été envoyé par e-mail. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour l'enregistrement."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action réussie"])}
            }
          }
        },
        "emailOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code d'authentification par email"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])}
        },
        "resendEmail": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer à nouveau"])}
        },
        "resendEmailInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Renvoyer dans ", _interpolate(_named("seconds")), " secondes"])}
        }
      },
      "mt-MT": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daħħal u kkonferma l-kodiċi ta 'awtentikazzjoni riċevut."])}
        },
        "notification": {
          "emailSent": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azzjoni ta' suċċess"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il-kodiċi ta' awtentikazzjoni ntbagħat permezz ta' email. Jista' jintuża darba biss u fi żmien limitat għar-reġistrazzjoni."])}
          },
          "emailOTPNotVerified": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Żball"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il-kodiċi ta' awtentikazzjoni ma setax jiġi kkonfermat."])}
          },
          "verifyEmailOTP": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il-kodiċi ta' awtentikazzjoni ma setax jiġi kkonfermat."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Żball"])},
            "asyncError": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il-kodiċi ta' awtentikazzjoni ma setax jiġi kkonfermat."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Żball"])}
            }
          },
          "sendEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il-kodiċi ta' awtentikazzjoni ntbagħat permezz ta' email. Jista' jintuża darba biss u fi żmien limitat għar-reġistrazzjoni."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azzjoni ta' suċċess"])},
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il-kodiċi ta' awtentikazzjoni ntbagħat permezz ta' email. Jista' jintuża darba biss u fi żmien limitat għar-reġistrazzjoni."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azzjoni ta' suċċess"])}
            }
          }
        },
        "emailOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kodiċi ta 'awtentikazzjoni bl-email"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikkonferma"])}
        },
        "resendEmail": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ibgħat mill-ġdid"])}
        },
        "resendEmailInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Erġa' ibgħat fi ", _interpolate(_named("seconds")), " sekondi"])}
        }
      },
      "sl-SI": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vnesite in potrdite prejeto kodo za preverjanje pristnosti."])}
        },
        "notification": {
          "emailSent": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspešno opravljeno"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koda za preverjanje pristnosti je bila poslana po e-pošti. Uporablja se lahko samo enkrat in v omejenem času za registracijo."])}
          },
          "emailOTPNotVerified": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napaka"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode za preverjanje pristnosti ni bilo mogoče potrditi."])}
          },
          "verifyEmailOTP": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode za preverjanje pristnosti ni bilo mogoče potrditi."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napaka"])},
            "asyncError": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode za preverjanje pristnosti ni bilo mogoče potrditi."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napaka"])}
            }
          },
          "sendEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koda za preverjanje pristnosti je bila poslana po e-pošti. Uporablja se lahko samo enkrat in v omejenem času za registracijo."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspešno opravljeno"])},
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koda za preverjanje pristnosti je bila poslana po e-pošti. Uporablja se lahko samo enkrat in v omejenem času za registracijo."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspešno opravljeno"])}
            }
          }
        },
        "emailOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koda za preverjanje pristnosti po e-pošti"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potrdi"])}
        },
        "resendEmail": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pošlji ponovno"])}
        },
        "resendEmailInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ponovno pošlji v ", _interpolate(_named("seconds")), " sekundah"])}
        }
      },
      "tr-TR": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alınan doğrulama kodunu girin ve onaylayın."])}
        },
        "notification": {
          "emailSent": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarılı eylem"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik doğrulama kodu e-posta yoluyla gönderildi. Kayıt için yalnızca bir kez ve sınırlı bir süre içinde kullanılabilir."])}
          },
          "emailOTPNotVerified": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik doğrulama kodu onaylanamadı."])}
          },
          "verifyEmailOTP": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik doğrulama kodu onaylanamadı."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata"])},
            "asyncError": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik doğrulama kodu onaylanamadı."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata"])}
            }
          },
          "sendEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik doğrulama kodu e-posta yoluyla gönderildi. Kayıt için yalnızca bir kez ve sınırlı bir süre içinde kullanılabilir."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarılı eylem"])},
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik doğrulama kodu e-posta yoluyla gönderildi. Kayıt için yalnızca bir kez ve sınırlı bir süre içinde kullanılabilir."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarılı eylem"])}
            }
          }
        },
        "emailOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta ile kimlik doğrulama kodu"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onayla"])}
        },
        "resendEmail": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekrar gönderin"])}
        },
        "resendEmailInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("seconds")), " saniye içinde yeniden gönder"])}
        }
      },
      "tr-CY": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alınan doğrulama kodunu girin ve onaylayın."])}
        },
        "notification": {
          "emailSent": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarılı eylem"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik doğrulama kodu e-posta yoluyla gönderildi. Kayıt için yalnızca bir kez ve sınırlı bir süre içinde kullanılabilir."])}
          },
          "emailOTPNotVerified": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik doğrulama kodu onaylanamadı."])}
          },
          "verifyEmailOTP": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik doğrulama kodu onaylanamadı."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata"])},
            "asyncError": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik doğrulama kodu onaylanamadı."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata"])}
            }
          },
          "sendEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik doğrulama kodu e-posta yoluyla gönderildi. Kayıt için yalnızca bir kez ve sınırlı bir süre içinde kullanılabilir."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarılı eylem"])},
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik doğrulama kodu e-posta yoluyla gönderildi. Kayıt için yalnızca bir kez ve sınırlı bir süre içinde kullanılabilir."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarılı eylem"])}
            }
          }
        },
        "emailOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta ile kimlik doğrulama kodu"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onayla"])}
        },
        "resendEmail": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekrar gönderin"])}
        },
        "resendEmailInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("seconds")), " saniye içinde yeniden gönder"])}
        }
      },
      "uk-UA": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть і підтвердьте отриманий код автентифікації."])}
        },
        "notification": {
          "emailSent": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успішна акція"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код автентифікації надіслано електронною поштою. Його можна використовувати лише один раз і протягом обмеженого часу для реєстрації."])}
          },
          "emailOTPNotVerified": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося підтвердити код автентифікації."])}
          },
          "verifyEmailOTP": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося підтвердити код автентифікації."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка"])},
            "asyncError": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося підтвердити код автентифікації."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка"])}
            }
          },
          "sendEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код автентифікації надіслано електронною поштою. Його можна використовувати лише один раз і протягом обмеженого часу для реєстрації."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успішна акція"])},
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код автентифікації надіслано електронною поштою. Його можна використовувати лише один раз і протягом обмеженого часу для реєстрації."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успішна акція"])}
            }
          }
        },
        "emailOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код автентифікації електронною поштою"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтвердити"])}
        },
        "resendEmail": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Надіслати знову"])}
        },
        "resendEmailInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Надіслати повторно через ", _interpolate(_named("seconds")), " секунд"])}
        }
      },
      "zh-CN": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入并确认收到的验证码。"])}
        },
        "notification": {
          "emailSent": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作成功"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验证码是通过电子邮件发送的。 注册后限时使用一次。"])}
          },
          "emailOTPNotVerified": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["错误"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法确认验证码。"])}
          },
          "verifyEmailOTP": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法确认验证码。"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["错误"])},
            "asyncError": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法确认验证码。"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["错误"])}
            }
          },
          "sendEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验证码是通过电子邮件发送的。 注册后限时使用一次。"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作成功"])},
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验证码是通过电子邮件发送的。 注册后限时使用一次。"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作成功"])}
            }
          }
        },
        "emailOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过电子邮件验证代码"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认"])}
        },
        "resendEmail": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新发送"])}
        },
        "resendEmailInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["在", _interpolate(_named("seconds")), "内重新发送"])}
        }
      }
    }
  })
}
