export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ar-AE": {
        "SMSSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسالة قصيرة"])}
        },
        "pleaseEnterMobileNumber": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بتنشيط المصادقة ذات العاملين عن طريق إدخال رمز المصادقة وتأكيده. للقيام بذلك ، احفظ رقم هاتفك المحمول. يتم إرسال الرمز إلى رقم الهاتف المحمول المخزن عبر الرسائل القصيرة."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد"])}
        },
        "saveMobileNumber": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ وإرسال"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال رمز المصادقة عبر الرسائل القصيرة. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة لتسجيل الدخول."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["إعادة إرسال في ", _interpolate(_named("seconds")), " ثوان"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعد الإرسال"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال رمز المصادقة عبر الرسائل القصيرة. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة لتسجيل الدخول."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمل ناجح"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعذر إرسال الرسالة. يرجى التحقق من دخولك."])}
            }
          },
          "saveMobileNumber": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن حفظ رقم الهاتف المحمول. تحقق من الدخول وحفظ مرة أخرى."])}
            }
          }
        }
      },
      "ar-EG": {
        "SMSSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسالة قصيرة"])}
        },
        "pleaseEnterMobileNumber": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بتنشيط المصادقة ذات العاملين عن طريق إدخال رمز المصادقة وتأكيده. للقيام بذلك ، احفظ رقم هاتفك المحمول. يتم إرسال الرمز إلى رقم الهاتف المحمول المخزن عبر الرسائل القصيرة."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد"])}
        },
        "saveMobileNumber": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ وإرسال"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال رمز المصادقة عبر الرسائل القصيرة. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة لتسجيل الدخول."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["إعادة إرسال في ", _interpolate(_named("seconds")), " ثوان"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعد الإرسال"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال رمز المصادقة عبر الرسائل القصيرة. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة لتسجيل الدخول."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمل ناجح"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعذر إرسال الرسالة. يرجى التحقق من دخولك."])}
            }
          },
          "saveMobileNumber": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن حفظ رقم الهاتف المحمول. تحقق من الدخول وحفظ مرة أخرى."])}
            }
          }
        }
      },
      "ar-SA": {
        "SMSSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسالة قصيرة"])}
        },
        "pleaseEnterMobileNumber": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بتنشيط المصادقة ذات العاملين عن طريق إدخال رمز المصادقة وتأكيده. للقيام بذلك ، احفظ رقم هاتفك المحمول. يتم إرسال الرمز إلى رقم الهاتف المحمول المخزن عبر الرسائل القصيرة."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد"])}
        },
        "saveMobileNumber": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ وإرسال"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال رمز المصادقة عبر الرسائل القصيرة. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة لتسجيل الدخول."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["إعادة إرسال في ", _interpolate(_named("seconds")), " ثوان"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعد الإرسال"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال رمز المصادقة عبر الرسائل القصيرة. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة لتسجيل الدخول."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمل ناجح"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعذر إرسال الرسالة. يرجى التحقق من دخولك."])}
            }
          },
          "saveMobileNumber": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن حفظ رقم الهاتف المحمول. تحقق من الدخول وحفظ مرة أخرى."])}
            }
          }
        }
      },
      "el-GR": {
        "SMSSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["γραπτό μήνυμα"])}
        },
        "pleaseEnterMobileNumber": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενεργοποιήστε τον έλεγχο ταυτότητας δύο παραγόντων εισάγοντας και επιβεβαιώνοντας τον κωδικό ελέγχου ταυτότητας. Για να το κάνετε αυτό, αποθηκεύστε τον αριθμό του κινητού σας. Ο κωδικός αποστέλλεται στον αποθηκευμένο αριθμό κινητού μέσω SMS."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιβεβαίωση"])}
        },
        "saveMobileNumber": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποθήκευση και αποστολή"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο κωδικός ελέγχου ταυτότητας στάλθηκε μέσω SMS. Μπορεί να χρησιμοποιηθεί μόνο μία φορά και εντός περιορισμένου χρόνου για τη σύνδεση."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Επανάληψη αποστολής σε ", _interpolate(_named("seconds")), " δευτερόλεπτα"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στείλε ξανά"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο κωδικός ελέγχου ταυτότητας στάλθηκε μέσω SMS. Μπορεί να χρησιμοποιηθεί μόνο μία φορά και εντός περιορισμένου χρόνου για τη σύνδεση."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιτυχημένη ενέργεια"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σφάλμα"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν ήταν δυνατή η αποστολή του μηνύματος. Ελέγξτε την καταχώρισή σας."])}
            }
          },
          "saveMobileNumber": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σφάλμα"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν ήταν δυνατή η αποθήκευση του αριθμού κινητού τηλεφώνου. Ελέγξτε την καταχώριση και αποθηκεύστε ξανά."])}
            }
          }
        }
      },
      "el-CY": {
        "SMSSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["γραπτό μήνυμα"])}
        },
        "pleaseEnterMobileNumber": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενεργοποιήστε τον έλεγχο ταυτότητας δύο παραγόντων εισάγοντας και επιβεβαιώνοντας τον κωδικό ελέγχου ταυτότητας. Για να το κάνετε αυτό, αποθηκεύστε τον αριθμό του κινητού σας. Ο κωδικός αποστέλλεται στον αποθηκευμένο αριθμό κινητού μέσω SMS."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιβεβαίωση"])}
        },
        "saveMobileNumber": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποθήκευση και αποστολή"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο κωδικός ελέγχου ταυτότητας στάλθηκε μέσω SMS. Μπορεί να χρησιμοποιηθεί μόνο μία φορά και εντός περιορισμένου χρόνου για τη σύνδεση."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Επανάληψη αποστολής σε ", _interpolate(_named("seconds")), " δευτερόλεπτα"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στείλε ξανά"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο κωδικός ελέγχου ταυτότητας στάλθηκε μέσω SMS. Μπορεί να χρησιμοποιηθεί μόνο μία φορά και εντός περιορισμένου χρόνου για τη σύνδεση."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιτυχημένη ενέργεια"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σφάλμα"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν ήταν δυνατή η αποστολή του μηνύματος. Ελέγξτε την καταχώρισή σας."])}
            }
          },
          "saveMobileNumber": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σφάλμα"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν ήταν δυνατή η αποθήκευση του αριθμού κινητού τηλεφώνου. Ελέγξτε την καταχώριση και αποθηκεύστε ξανά."])}
            }
          }
        }
      },
      "de-DE": {
        "SMSSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])}
        },
        "pleaseEnterMobileNumber": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviere die Zwei-Faktor-Authentifizierung durch Eingabe und Bestätigung des Authentifizierungscodes. Speichere dazu deine Mobilnummer. Der Code wird an die gespeicherte Mobilnummer per SMS gesendet."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])}
        },
        "saveMobileNumber": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern und senden"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Authentifizierungscode wurde per SMS gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Erneut senden in ", _interpolate(_named("seconds")), " Sekunden"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erneut senden"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Authentifizierungscode wurde per SMS gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreiche Aktion"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Nachricht konnte nicht gesendet werden. Bitte prüfe deine Eingabe."])}
            }
          },
          "saveMobileNumber": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Mobilnummer konnte nicht gespeichert. Eingabe überprüfen und erneut speichern."])}
            }
          }
        }
      },
      "de-AT": {
        "SMSSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])}
        },
        "pleaseEnterMobileNumber": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviere die Zwei-Faktor-Authentifizierung durch Eingabe und Bestätigung des Authentifizierungscodes. Speichere dazu deine Mobilnummer. Der Code wird an die gespeicherte Mobilnummer per SMS gesendet."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])}
        },
        "saveMobileNumber": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern und senden"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Authentifizierungscode wurde per SMS gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Erneut senden in ", _interpolate(_named("seconds")), " Sekunden"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erneut senden"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Authentifizierungscode wurde per SMS gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreiche Aktion"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Nachricht konnte nicht gesendet werden. Bitte prüfe deine Eingabe."])}
            }
          },
          "saveMobileNumber": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Mobilnummer konnte nicht gespeichert. Eingabe überprüfen und erneut speichern."])}
            }
          }
        }
      },
      "de-CH": {
        "SMSSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])}
        },
        "pleaseEnterMobileNumber": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviere die Zwei-Faktor-Authentifizierung durch Eingabe und Bestätigung des Authentifizierungscodes. Speichere dazu deine Mobilnummer. Der Code wird an die gespeicherte Mobilnummer per SMS gesendet."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])}
        },
        "saveMobileNumber": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern und senden"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Authentifizierungscode wurde per SMS gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Erneut senden in ", _interpolate(_named("seconds")), " Sekunden"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erneut senden"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Authentifizierungscode wurde per SMS gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreiche Aktion"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Nachricht konnte nicht gesendet werden. Bitte prüfe deine Eingabe."])}
            }
          },
          "saveMobileNumber": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Mobilnummer konnte nicht gespeichert. Eingabe überprüfen und erneut speichern."])}
            }
          }
        }
      },
      "en-US": {
        "SMSSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])}
        },
        "pleaseEnterMobileNumber": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate two-factor authentication by entering and confirming the authentication code. To do this, save your mobile number. The code is sent to the saved mobile number via SMS."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])}
        },
        "saveMobileNumber": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save and send"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The authentication code was sent via SMS. It can only be used once and within a limited time for logging in.."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Resend in ", _interpolate(_named("seconds")), " seconds"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send again"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The authentication code was sent via SMS. It can only be used once and within a limited time for logging in.."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful action"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The message could not be sent. Please check your entry."])}
            }
          },
          "saveMobileNumber": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The mobile number could not be saved. Check entry and save again."])}
            }
          }
        }
      },
      "en-GB": {
        "SMSSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])}
        },
        "pleaseEnterMobileNumber": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate two-factor authentication by entering and confirming the authentication code. To do this, save your mobile number. The code is sent to the saved mobile number via SMS."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])}
        },
        "saveMobileNumber": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save and send"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The authentication code was sent via SMS. It can only be used once and within a limited time for logging in.."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Resend in ", _interpolate(_named("seconds")), " seconds"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send again"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The authentication code was sent via SMS. It can only be used once and within a limited time for logging in.."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful action"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The message could not be sent. Please check your entry."])}
            }
          },
          "saveMobileNumber": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The mobile number could not be saved. Check entry and save again."])}
            }
          }
        }
      },
      "it-IT": {
        "SMSSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])}
        },
        "pleaseEnterMobileNumber": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per farlo, salva il tuo numero di telefono. Il codice viene inviato al numero di telefono salvato via SMS."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma"])}
        },
        "saveMobileNumber": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva e invia"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il codice di autenticazione è stato inviato tramite SMS. Può essere utilizzato solo una volta ed entro un tempo limitato per l'accesso."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Invia nuovamente tra ", _interpolate(_named("seconds")), " secondi"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia di nuovo"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il codice di autenticazione è stato inviato tramite SMS. Può essere utilizzato solo una volta ed entro un tempo limitato per l'accesso."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azione riuscita"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile inviare il messaggio. Si prega di controllare la tua voce."])}
            }
          },
          "saveMobileNumber": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è stato possibile salvare il numero di cellulare. Controlla la voce e salva di nuovo."])}
            }
          }
        }
      },
      "fr-FR": {
        "SMSSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])}
        },
        "pleaseEnterMobileNumber": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activez l'authentification à deux facteurs en saisissant et en confirmant le code d'authentification. Pour ce faire, enregistrez votre numéro de téléphone mobile. Le code est envoyé par SMS au numéro de téléphone mobile enregistré."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])}
        },
        "saveMobileNumber": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer et envoyer"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code d'authentification a été envoyé par SMS. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour se connecter."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Renvoyer dans ", _interpolate(_named("seconds")), " secondes"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer à nouveau"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code d'authentification a été envoyé par SMS. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour se connecter."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action réussie"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le message n'a pas pu être envoyé. Veuillez vérifier votre saisie."])}
            }
          },
          "saveMobileNumber": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le numéro de portable n'a pas pu être enregistré. Vérifiez l'entrée et enregistrez à nouveau."])}
            }
          }
        }
      },
      "es-ES": {
        "SMSSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])}
        },
        "pleaseEnterMobileNumber": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active la autenticación en dos pasos introduciendo y confirmando el código de autenticación. Para ello, guarde su número de teléfono. El código se envía por SMS al número de teléfono guardado."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])}
        },
        "saveMobileNumber": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar y enviar"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El código de autenticación se envió por SMS. Solo se puede usar una vez y dentro de un tiempo limitado para iniciar sesión."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Enviar de nuevo en ", _interpolate(_named("seconds")), " segundos"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar de nuevo"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El código de autenticación se envió por SMS. Solo se puede usar una vez y dentro de un tiempo limitado para iniciar sesión."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listo"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El mensaje no se pudo enviar. Por favor revise su entrada."])}
            }
          },
          "saveMobileNumber": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo guardar el número de móvil. Verifique la entrada y guarde nuevamente."])}
            }
          }
        }
      },
      "pl-PL": {
        "SMSSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])}
        },
        "pleaseEnterMobileNumber": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywuj uwierzytelnianie dwuskładnikowe, wprowadzając i potwierdzając kod uwierzytelniający. Aby to zrobić, zapisz swój numer telefonu komórkowego. Kod jest wysyłany na zapisany numer telefonu komórkowego SMS-em."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdź"])}
        },
        "saveMobileNumber": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz i wyślij"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod uwierzytelniający został wysłany SMS-em. Można go użyć tylko raz iw ograniczonym czasie do zalogowania."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wyślij ponownie za ", _interpolate(_named("seconds")), " s"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij ponownie"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod uwierzytelniający został wysłany SMS-em. Można go użyć tylko raz iw ograniczonym czasie do zalogowania."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czynność zakończona powodzeniem"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błąd"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie udało się wysłać wiadomości. Sprawdź swój wpis."])}
            }
          },
          "saveMobileNumber": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błąd"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie można zapisać numeru telefonu komórkowego. Sprawdź wpis i zapisz ponownie."])}
            }
          }
        }
      },
      "nl-NL": {
        "SMSSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])}
        },
        "pleaseEnterMobileNumber": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activeer de twee-factor authenticatie door de authenticatiecode in te voeren en te bevestigen. Om dit te doen, slaat u uw mobiele nummer op. De code wordt via sms naar het opgeslagen mobiele nummer gestuurd."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestigen"])}
        },
        "saveMobileNumber": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslaan en verzenden"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De authenticatiecode is per sms verzonden. Het kan slechts één keer en binnen een beperkte tijd worden gebruikt om in te loggen."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Verzenden over ", _interpolate(_named("seconds")), " seconden"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opnieuw verzenden"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De authenticatiecode is per sms verzonden. Het kan slechts één keer en binnen een beperkte tijd worden gebruikt om in te loggen."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geslaagde actie"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het bericht kon niet worden verzonden. Controleer uw invoer."])}
            }
          },
          "saveMobileNumber": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het mobiele nummer kon niet worden opgeslagen. Controleer invoer en sla opnieuw op."])}
            }
          }
        }
      },
      "bg-BG": {
        "SMSSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])}
        },
        "pleaseEnterMobileNumber": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активирайте двуфакторно удостоверяване, като въведете и потвърдите кода за удостоверяване. За да направите това, запазете мобилния си номер. Кодът се изпраща на съхранения мобилен номер чрез SMS."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потвърждение"])}
        },
        "saveMobileNumber": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Съхраняване и изпращане"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кодът за удостоверяване беше изпратен чрез SMS. Може да се използва само веднъж и в рамките на ограничено време за влизане."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Изпращане след ", _interpolate(_named("seconds")), " секунди"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изпрати отново"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кодът за удостоверяване беше изпратен чрез SMS. Може да се използва само веднъж и в рамките на ограничено време за влизане."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно действие"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["грешка"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Съобщението не можа да бъде изпратено. Моля, проверете вашето въвеждане."])}
            }
          },
          "saveMobileNumber": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["грешка"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мобилният номер не можа да бъде запазен. Проверете въведеното и запазете отново."])}
            }
          }
        }
      },
      "bs-BA": {
        "SMSSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])}
        },
        "pleaseEnterMobileNumber": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivirajte dvofaktorsku autentifikaciju unosom i potvrđivanjem autentifikacijskog koda. Da biste to učinili, spremite svoj broj mobitela. Kôd se putem SMS-a šalje na spremljeni broj mobitela."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdite"])}
        },
        "saveMobileNumber": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spremite i pošaljite"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod za autentifikaciju je poslan putem SMS-a. Može se koristiti samo jednom iu ograničenom vremenu za prijavu."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ponovo pošalji za ", _interpolate(_named("seconds")), " sekundi"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pošalji ponovo"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod za autentifikaciju je poslan putem SMS-a. Može se koristiti samo jednom iu ograničenom vremenu za prijavu."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno obavljeno"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greška"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poruka se nije mogla poslati. Molimo provjerite svoj unos."])}
            }
          },
          "saveMobileNumber": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greška"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broj mobilnog telefona nije mogao biti sačuvan. Provjerite unos i ponovo sačuvajte."])}
            }
          }
        }
      },
      "da-DK": {
        "SMSSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])}
        },
        "pleaseEnterMobileNumber": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiver tofaktorautentificering ved at indtaste og bekræfte godkendelseskoden. For at gøre dette skal du gemme dit mobilnummer. Koden sendes til det gemte mobilnummer via SMS."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekræft"])}
        },
        "saveMobileNumber": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem og send"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkendelseskoden blev sendt via SMS. Det kan kun bruges én gang og inden for en begrænset tid til at logge ind."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Send igen om ", _interpolate(_named("seconds")), " sekunder"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send igen"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkendelseskoden blev sendt via SMS. Det kan kun bruges én gang og inden for en begrænset tid til at logge ind."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vellykket handling"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fejl"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meddelelsen kunne ikke sendes. Tjek venligst din post."])}
            }
          },
          "saveMobileNumber": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fejl"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobilnummeret kunne ikke gemmes. Tjek indtastningen og gem igen."])}
            }
          }
        }
      },
      "et-EE": {
        "SMSSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])}
        },
        "pleaseEnterMobileNumber": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiveerige kaheastmeline autentimine, sisestades ja kinnitades autentimiskoodi. Selleks salvestage oma mobiilinumber. Kood saadetakse salvestatud mobiiltelefoninumbrile SMS-i teel."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kinnita"])}
        },
        "saveMobileNumber": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvesta ja saada"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentimiskood saadeti SMS-iga. Seda saab kasutada ainult üks kord ja piiratud aja jooksul sisselogimiseks."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Saada uuesti ", _interpolate(_named("seconds")), " sekundi pärast"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saada uuesti"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentimiskood saadeti SMS-iga. Seda saab kasutada ainult üks kord ja piiratud aja jooksul sisselogimiseks."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edukas tegevus"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viga"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sõnumit ei õnnestunud saata. Palun kontrollige oma sisestust."])}
            }
          },
          "saveMobileNumber": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viga"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobiilinumbrit ei saanud salvestada. Kontrollige sisestust ja salvestage uuesti."])}
            }
          }
        }
      },
      "fi-FI": {
        "SMSSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekstiviesti"])}
        },
        "pleaseEnterMobileNumber": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivoi kaksivaiheinen todennus syöttämällä ja vahvistamalla todennuskoodi. Tehdäksesi tämän, tallenna matkapuhelinnumerosi. Koodi lähetetään tallennettuun matkapuhelinnumeroon tekstiviestillä."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vahvista"])}
        },
        "saveMobileNumber": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tallenna ja lähetä"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todennuskoodi lähetettiin tekstiviestillä. Sitä voidaan käyttää vain kerran ja rajoitetun ajan sisällä kirjautumiseen."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lähetä uudelleen ", _interpolate(_named("seconds")), " sekunnissa"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetä uudestaan"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todennuskoodi lähetettiin tekstiviestillä. Sitä voidaan käyttää vain kerran ja rajoitetun ajan sisällä kirjautumiseen."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onnistunut toiminta"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virhe"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viestiä ei voitu lähettää. Tarkista syöttösi."])}
            }
          },
          "saveMobileNumber": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virhe"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matkapuhelinnumeroa ei voitu tallentaa. Tarkista syöte ja tallenna uudelleen."])}
            }
          }
        }
      },
      "he-IL": {
        "SMSSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סמס"])}
        },
        "pleaseEnterMobileNumber": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הפעל אימות דו-גורמי על ידי הזנת קוד האימות ואישורו. לשם כך, שמור את מספר הנייד שלך. הקוד נשלח למספר הנייד המאוחסן באמצעות SMS."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["לְאַשֵׁר"])}
        },
        "saveMobileNumber": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שמור ושלח"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["קוד האימות נשלח באמצעות SMS. ניתן להשתמש בו רק פעם אחת ובתוך זמן מוגבל לכניסה."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["שלח מחדש בתוך ", _interpolate(_named("seconds")), " שניות"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שלח שוב"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["קוד האימות נשלח באמצעות SMS. ניתן להשתמש בו רק פעם אחת ובתוך זמן מוגבל לכניסה."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["פעולה מוצלחת"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שְׁגִיאָה"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["לא ניתן היה לשלוח את ההודעה. אנא בדוק את הערך שלך."])}
            }
          },
          "saveMobileNumber": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שְׁגִיאָה"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["לא ניתן היה לשמור את מספר הנייד. בדוק את הערך ושמור שוב."])}
            }
          }
        }
      },
      "ga-IE": {
        "SMSSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])}
        },
        "pleaseEnterMobileNumber": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gníomhachtaigh fíordheimhniú dhá fhachtóir tríd an gcód fíordheimhnithe a iontráil agus a dhearbhú. Chun é seo a dhéanamh, sábháil d’uimhir fóin póca. Seoltar an cód chuig an uimhir fóin póca stóráilte trí SMS."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deimhnigh"])}
        },
        "saveMobileNumber": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sábháil agus seol"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seoladh an cód fíordheimhnithe trí SMS. Ní féidir é a úsáid ach uair amháin agus laistigh de thréimhse teoranta chun logáil isteach."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Athsheol i gceann ", _interpolate(_named("seconds")), " soicind"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seol arís"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seoladh an cód fíordheimhnithe trí SMS. Ní féidir é a úsáid ach uair amháin agus laistigh de thréimhse teoranta chun logáil isteach."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gníomh rathúil"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earráid"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ní fhéadfaí an teachtaireacht a sheoladh. Seiceáil d’iontráil le do thoil."])}
            }
          },
          "saveMobileNumber": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earráid"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Níorbh fhéidir an uimhir soghluaiste a shábháil. Seiceáil iontráil agus sábháil arís."])}
            }
          }
        }
      },
      "ja-JP": {
        "SMSSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])}
        },
        "pleaseEnterMobileNumber": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証コードを入力して確認することで、二要素認証を有効にします。 これを行うには、携帯電話番号を保存します。 コードは SMS 経由で保存されている携帯電話番号に送信されます。"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認"])}
        },
        "saveMobileNumber": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存して送信"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証コードは SMS 経由で送信されました。 ログイン時に1回限り、限られた時間内でのみ使用できます。"])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("seconds")), "秒で再送信"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["もう一度送信"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証コードは SMS 経由で送信されました。 ログイン時に1回限り、限られた時間内でのみ使用できます。"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功したアクション"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エラー"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メッセージを送信できませんでした。 入力内容をご確認ください。"])}
            }
          },
          "saveMobileNumber": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エラー"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["携帯電話番号を保存できませんでした。 入力内容を確認し、再度保存してください。"])}
            }
          }
        }
      },
      "hr-HR": {
        "SMSSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])}
        },
        "pleaseEnterMobileNumber": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivirajte dvofaktorsku autentifikaciju unosom i potvrđivanjem autentifikacijskog koda. Da biste to učinili, spremite svoj broj mobitela. Kôd se putem SMS-a šalje na pohranjeni broj mobitela."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrda"])}
        },
        "saveMobileNumber": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spremi i pošalji"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod za autentifikaciju poslan je putem SMS-a. Može se koristiti samo jednom i unutar ograničenog vremena za prijavu."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ponovno slanje u ", _interpolate(_named("seconds")), " sekundama"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pošalji opet"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod za autentifikaciju poslan je putem SMS-a. Može se koristiti samo jednom i unutar ograničenog vremena za prijavu."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješna akcija"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pogreška"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poruka se nije mogla poslati. Molimo provjerite svoj unos."])}
            }
          },
          "saveMobileNumber": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pogreška"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broj mobitela nije bilo moguće spremiti. Provjerite unos i ponovno spremite."])}
            }
          }
        }
      },
      "lv-LV": {
        "SMSSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Īsziņa"])}
        },
        "pleaseEnterMobileNumber": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivizējiet divfaktoru autentifikāciju, ievadot un apstiprinot autentifikācijas kodu.Lai to izdarītu, saglabājiet savu mobilā tālruņa numuru. Lai to izdarītu, saglabājiet savu mobilā tālruņa numuru."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstiprināt"])}
        },
        "saveMobileNumber": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saglabāt un nosūtīt"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentifikācijas kods tika nosūtīts ar SMS. To var izmantot tikai vienu reizi un ierobežotā laikā, lai pieteiktos."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Atkārtoti nosūtīt pēc ", _interpolate(_named("seconds")), " sek."])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sūtīt vēlreiz"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentifikācijas kods tika nosūtīts ar SMS. To var izmantot tikai vienu reizi un ierobežotā laikā, lai pieteiktos."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veiksmīga darbība"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kļūda"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziņojumu nevarēja nosūtīt. Lūdzu, pārbaudiet savu ierakstu."])}
            }
          },
          "saveMobileNumber": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kļūda"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobilā tālruņa numuru nevarēja saglabāt. Pārbaudiet ierakstu un saglabājiet vēlreiz."])}
            }
          }
        }
      },
      "lt-LT": {
        "SMSSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trumpoji žinutė"])}
        },
        "pleaseEnterMobileNumber": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suaktyvinkite dviejų veiksnių autentifikavimą įvesdami ir patvirtindami autentifikavimo kodą. Norėdami tai padaryti, išsaugokite savo mobiliojo telefono numerį. Kodas išsiunčiamas išsaugotu mobiliojo telefono numeriu SMS žinute."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patvirtinti"])}
        },
        "saveMobileNumber": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Išsaugoti ir siųsti"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentifikavimo kodas buvo išsiųstas SMS žinute. Jį galima naudoti tik vieną kartą ir per ribotą prisijungimo laiką."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Iš naujo išsiųsti po ", _interpolate(_named("seconds")), " sek."])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siųsti dar kartą"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentifikavimo kodas buvo išsiųstas SMS žinute. Jį galima naudoti tik vieną kartą ir per ribotą prisijungimo laiką."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sėkmingas veiksmas"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klaida"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepavyko išsiųsti pranešimo. Patikrinkite savo įrašą."])}
            }
          },
          "saveMobileNumber": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klaida"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepavyko išsaugoti mobiliojo telefono numerio. Patikrinkite įrašą ir išsaugokite dar kartą."])}
            }
          }
        }
      },
      "pt-PT": {
        "SMSSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])}
        },
        "pleaseEnterMobileNumber": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ative a autenticação de dois fatores inserindo e confirmando o código de autenticação. Para fazer isso, guarde o número do seu telemóvel O código é enviado por SMS para o número de telemóvel armazenado."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])}
        },
        "saveMobileNumber": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar e enviar"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O código de autenticação foi enviado via SMS. Ele só pode ser usado uma vez e dentro de um tempo limitado para fazer login."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Reenviar em ", _interpolate(_named("seconds")), " segundos"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envie novamente"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O código de autenticação foi enviado via SMS. Ele só pode ser usado uma vez e dentro de um tempo limitado para fazer login."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ação bem-sucedida"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A mensagem não pôde ser enviada. Por favor, verifique sua entrada."])}
            }
          },
          "saveMobileNumber": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O número do celular não pôde ser salvo. Verifique a entrada e salve novamente."])}
            }
          }
        }
      },
      "ro-RO": {
        "SMSSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])}
        },
        "pleaseEnterMobileNumber": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activați autentificarea cu doi factori introducând și confirmând codul de autentificare. În acest scop, trebuie să salvați numărul de telefon mobil. Codul va fi trimis prin SMS, la numărul de telefon mobil salvat."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmare"])}
        },
        "saveMobileNumber": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvare și trimitere"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codul de autentificare a fost trimis prin SMS. Poate fi folosit o singură dată și într-un timp limitat pentru autentificare."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Retrimitere în ", _interpolate(_named("seconds")), " secunde"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimite din nou"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codul de autentificare a fost trimis prin SMS. Poate fi folosit o singură dată și într-un timp limitat pentru autentificare."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acțiune reușită"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eroare"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesajul nu a putut fi trimis. Vă rugăm să verificați intrarea."])}
            }
          },
          "saveMobileNumber": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eroare"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numărul de mobil nu a putut fi salvat. Verificați intrarea și salvați din nou."])}
            }
          }
        }
      },
      "ru-RU": {
        "SMSSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])}
        },
        "pleaseEnterMobileNumber": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активируйте двухфакторную аутентификацию, введя и подтвердив код аутентификации. Для этого сохраните номер своего мобильного телефона. Код отправляется на сохраненный номер мобильного телефона по SMS."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить"])}
        },
        "saveMobileNumber": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить и отправить"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код аутентификации был отправлен в SMS. Его можно использовать только один раз и в течение ограниченного времени для входа в систему."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Отправить повторно через ", _interpolate(_named("seconds")), " секунд"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправь еще раз"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код аутентификации был отправлен в SMS. Его можно использовать только один раз и в течение ограниченного времени для входа в систему."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешное действие"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщение не может быть отправлено. Пожалуйста, проверьте вашу запись."])}
            }
          },
          "saveMobileNumber": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось сохранить номер мобильного телефона. Проверьте запись и сохраните снова."])}
            }
          }
        }
      },
      "sv-SE": {
        "SMSSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])}
        },
        "pleaseEnterMobileNumber": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivera tvåfaktorautentisering genom att ange och bekräfta autentiseringskoden. Spara ditt mobilnummer för att göra detta. Koden skickas till det sparade mobilnumret via SMS."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekräfta"])}
        },
        "saveMobileNumber": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spara och skicka"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentiseringskoden skickades via SMS. Den kan endast användas en gång och inom en begränsad tid för inloggning."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Skicka igen om ", _interpolate(_named("seconds")), " sekunder"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skicka igen"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentiseringskoden skickades via SMS. Den kan endast användas en gång och inom en begränsad tid för inloggning."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åtgärd utförd"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fel"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meddelandet kunde inte skickas. Kontrollera ditt bidrag."])}
            }
          },
          "saveMobileNumber": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fel"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobilnumret kunde inte sparas. Kontrollera inmatningen och spara igen."])}
            }
          }
        }
      },
      "sk-SK": {
        "SMSSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])}
        },
        "pleaseEnterMobileNumber": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivujte dvojfaktorové overenie zadaním a potvrdením overovacieho kódu. Uložte pre to vaše mobilné číslo. Kód sa odošle na uložené mobilné číslo prostredníctvom SMS."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdiť"])}
        },
        "saveMobileNumber": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložiť a odoslať"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overovací kód bol odoslaný prostredníctvom SMS. Dá sa použiť len raz a v obmedzenom čase na prihlásenie."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Opäť odoslať o ", _interpolate(_named("seconds")), " sekúnd"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poslať znova"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overovací kód bol odoslaný prostredníctvom SMS. Dá sa použiť len raz a v obmedzenom čase na prihlásenie."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspešná akcia"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Správu sa nepodarilo odoslať. Skontrolujte svoje zadanie."])}
            }
          },
          "saveMobileNumber": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobilné číslo sa nepodarilo uložiť. Skontrolujte zadanie a znova uložte."])}
            }
          }
        }
      },
      "cs-CZ": {
        "SMSSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])}
        },
        "pleaseEnterMobileNumber": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivujte dvoufaktorové ověřování zadáním a potvrzením ověřovacího kódu. Chcete-li to provést, uložte své mobilní číslo. Kód je odeslán na uložené číslo mobilního telefonu prostřednictvím SMS."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdit"])}
        },
        "saveMobileNumber": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit a odeslat"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověřovací kód byl zaslán prostřednictvím SMS. Lze jej použít pouze jednou a po omezenou dobu pro přihlášení."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Odeslat znovu za ", _interpolate(_named("seconds")), " s."])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poslat znovu"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověřovací kód byl zaslán prostřednictvím SMS. Lze jej použít pouze jednou a po omezenou dobu pro přihlášení."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspěšná akce"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zprávu se nepodařilo odeslat. Zkontrolujte prosím svůj údaj."])}
            }
          },
          "saveMobileNumber": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobilní číslo se nepodařilo uložit. Zkontrolujte zadání a uložte znovu."])}
            }
          }
        }
      },
      "hu-HU": {
        "SMSSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])}
        },
        "pleaseEnterMobileNumber": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiválja a kétfaktoros hitelesítést a hitelesítő kód megadásával és megerősítésével. Ehhez mentse el mobilszámát. A kódot SMS-ben küldjük az elmentett mobilszámra."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megerősít"])}
        },
        "saveMobileNumber": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentés és küldés"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hitelesítési kódot SMS-ben küldték el. Csak egyszer és korlátozott ideig használható a bejelentkezéshez."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Újraküldés ", _interpolate(_named("seconds")), " másodperc múlva"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küldd újra"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hitelesítési kódot SMS-ben küldték el. Csak egyszer és korlátozott ideig használható a bejelentkezéshez."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikeres művelet"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiba"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az üzenetet nem sikerült elküldeni. Kérjük, ellenőrizze a bejegyzést."])}
            }
          },
          "saveMobileNumber": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiba"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A mobilszámot nem sikerült elmenteni. Ellenőrizze a bejegyzést, és mentse újra."])}
            }
          }
        }
      },
      "fr-BE": {
        "SMSSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])}
        },
        "pleaseEnterMobileNumber": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activez l'authentification à deux facteurs en saisissant et en confirmant le code d'authentification. Pour ce faire, enregistrez votre numéro de téléphone mobile. Le code est envoyé par SMS au numéro de téléphone mobile enregistré."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])}
        },
        "saveMobileNumber": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer et envoyer"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code d'authentification a été envoyé par SMS. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour se connecter."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Renvoyer dans ", _interpolate(_named("seconds")), " secondes"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer à nouveau"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code d'authentification a été envoyé par SMS. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour se connecter."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action réussie"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le message n'a pas pu être envoyé. Veuillez vérifier votre saisie."])}
            }
          },
          "saveMobileNumber": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le numéro de portable n'a pas pu être enregistré. Vérifiez l'entrée et enregistrez à nouveau."])}
            }
          }
        }
      },
      "fr-LU": {
        "SMSSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])}
        },
        "pleaseEnterMobileNumber": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activez l'authentification à deux facteurs en saisissant et en confirmant le code d'authentification. Pour ce faire, enregistrez votre numéro de téléphone mobile. Le code est envoyé par SMS au numéro de téléphone mobile enregistré."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])}
        },
        "saveMobileNumber": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer et envoyer"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code d'authentification a été envoyé par SMS. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour se connecter."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Renvoyer dans ", _interpolate(_named("seconds")), " secondes"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer à nouveau"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code d'authentification a été envoyé par SMS. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour se connecter."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action réussie"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le message n'a pas pu être envoyé. Veuillez vérifier votre saisie."])}
            }
          },
          "saveMobileNumber": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le numéro de portable n'a pas pu être enregistré. Vérifiez l'entrée et enregistrez à nouveau."])}
            }
          }
        }
      },
      "mt-MT": {
        "SMSSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])}
        },
        "pleaseEnterMobileNumber": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attiva awtentikazzjoni b'żewġ fatturi billi ddaħħal u tikkonferma l-kodiċi ta' awtentikazzjoni. Biex tagħmel dan, issejvja n-numru tal-mowbajl tiegħek. Il-kodiċi jintbagħat lil n-numru tal-mowbajl issejvjat permezz ta' SMS."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikkonferma"])}
        },
        "saveMobileNumber": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ibgħat u ssejvja"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il-kodiċi ta' awtentikazzjoni intbagħat permezz ta' SMS. Jista 'jintuża darba biss u fi żmien limitat għall-illoggjar."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Erġa' ibgħat fi ", _interpolate(_named("seconds")), " sekondi"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ibgħat mill-ġdid"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il-kodiċi ta' awtentikazzjoni intbagħat permezz ta' SMS. Jista 'jintuża darba biss u fi żmien limitat għall-illoggjar."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azzjoni ta' suċċess"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Żball"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il-messaġġ ma setax jintbagħat. Jekk jogħġbok iċċekkja d-dħul tiegħek."])}
            }
          },
          "saveMobileNumber": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Żball"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In-numru tal-mowbajl ma setax jiġi salvat. Iċċekkja d-dħul u erġa ssejvja."])}
            }
          }
        }
      },
      "sl-SI": {
        "SMSSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])}
        },
        "pleaseEnterMobileNumber": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvofaktorsko preverjanje pristnosti aktivirajte tako, da vnesete in potrdite kodo za preverjanje pristnosti. Če želite to narediti, shranite svojo številko mobilnega telefona. Koda se na shranjeno mobilno številko pošlje prek SMS-a."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potrdi"])}
        },
        "saveMobileNumber": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shrani in pošlji"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koda za preverjanje pristnosti je bila poslana prek SMS-a. Uporabite ga lahko samo enkrat in v omejenem času za prijavo."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ponovno pošlji v ", _interpolate(_named("seconds")), " sekundah"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pošlji ponovno"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koda za preverjanje pristnosti je bila poslana prek SMS-a. Uporabite ga lahko samo enkrat in v omejenem času za prijavo."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspešno opravljeno"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napaka"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sporočila ni bilo mogoče poslati. Prosimo, preverite svoj vnos."])}
            }
          },
          "saveMobileNumber": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napaka"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Številke mobilnega telefona ni bilo mogoče shraniti. Preverite vnos in znova shranite."])}
            }
          }
        }
      },
      "tr-TR": {
        "SMSSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])}
        },
        "pleaseEnterMobileNumber": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik doğrulama kodunu girip onaylayarak iki faktörlü kimlik doğrulamayı etkinleştirin. Bunu yapmak için cep telefonu numaranızı kaydedin. Kod, kayıtlı cep telefonu numarasına SMS ile gönderilir."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onayla"])}
        },
        "saveMobileNumber": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydet ve gönder"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik doğrulama kodu SMS ile gönderildi. Giriş yapmak için yalnızca bir kez ve sınırlı bir süre içinde kullanılabilir."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("seconds")), " saniye içinde yeniden gönder"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekrar gönderin"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik doğrulama kodu SMS ile gönderildi. Giriş yapmak için yalnızca bir kez ve sınırlı bir süre içinde kullanılabilir."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarılı eylem"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj gönderilemedi. Lütfen girişinizi kontrol edin."])}
            }
          },
          "saveMobileNumber": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cep telefonu numarası kaydedilemedi. Girişi kontrol edin ve tekrar kaydedin."])}
            }
          }
        }
      },
      "tr-CY": {
        "SMSSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])}
        },
        "pleaseEnterMobileNumber": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik doğrulama kodunu girip onaylayarak iki faktörlü kimlik doğrulamayı etkinleştirin. Bunu yapmak için cep telefonu numaranızı kaydedin. Kod, kayıtlı cep telefonu numarasına SMS ile gönderilir."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onayla"])}
        },
        "saveMobileNumber": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydet ve gönder"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik doğrulama kodu SMS ile gönderildi. Giriş yapmak için yalnızca bir kez ve sınırlı bir süre içinde kullanılabilir."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("seconds")), " saniye içinde yeniden gönder"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekrar gönderin"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik doğrulama kodu SMS ile gönderildi. Giriş yapmak için yalnızca bir kez ve sınırlı bir süre içinde kullanılabilir."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarılı eylem"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj gönderilemedi. Lütfen girişinizi kontrol edin."])}
            }
          },
          "saveMobileNumber": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cep telefonu numarası kaydedilemedi. Girişi kontrol edin ve tekrar kaydedin."])}
            }
          }
        }
      },
      "uk-UA": {
        "SMSSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])}
        },
        "pleaseEnterMobileNumber": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviere die Zwei-Faktor-Authentifizierung durch Eingabe und Bestätigung des Authentifizierungscodes. Speichere dazu deine Mobilnummer. Der Code wird an die gespeicherte Mobilnummer per SMS gesendet."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтвердити"])}
        },
        "saveMobileNumber": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти та відправити"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код автентифікації надіслано через SMS. Його можна використовувати лише один раз і протягом обмеженого часу для входу."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Надіслати повторно через ", _interpolate(_named("seconds")), " секунд"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Надіслати знову"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код автентифікації надіслано через SMS. Його можна використовувати лише один раз і протягом обмеженого часу для входу."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успішна акція"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Nachricht konnte nicht gesendet werden. Bitte prüfe deine Eingabe."])}
            }
          },
          "saveMobileNumber": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося зберегти номер мобільного телефону. Перевірте введення та збережіть ще раз."])}
            }
          }
        }
      },
      "zh-CN": {
        "SMSSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["短信"])}
        },
        "pleaseEnterMobileNumber": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入并确认身份验证码以激活双重身份验证。为此，请保存手机号码。代码将通过短信发送到存储的手机号码。"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认"])}
        },
        "saveMobileNumber": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存并发送"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验证码是通过短信发送的。 仅限登录一次，限时使用。"])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["在", _interpolate(_named("seconds")), "内重新发送"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新发送"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验证码是通过短信发送的。 仅限登录一次，限时使用。"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作成功"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["错误"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法发送消息。 请检查您的输入。"])}
            }
          },
          "saveMobileNumber": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["错误"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法保存手机号码。 检查条目并再次保存。"])}
            }
          }
        }
      }
    }
  })
}
