export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ar-AE": {
        "authenticatorMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تطبيق المصادقة"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصل على رمز المصادقة لتسجيل الدخول من تطبيق المصادقة الخاص بك."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم التنشيط"])}
            }
          }
        },
        "SMSMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسالة قصيرة"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصل على رمز المصادقة للمصادقة متعددة الخطوات عبر الرسائل القصيرة."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم التنشيط"])}
            }
          }
        },
        "explanationMFAChoice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بزيادة الأمان باستخدام المصادقة متعددة العوامل عند تسجيل الدخول."])}
        },
        "MFAChoice": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مصادقة متعددة العوامل"])}
        },
        "feedforward": {
          "authenticatorMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم التنشيط"])}
            }
          },
          "SMSMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم التنشيط"])}
            }
          }
        }
      },
      "ar-EG": {
        "authenticatorMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تطبيق المصادقة"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصل على رمز المصادقة لتسجيل الدخول من تطبيق المصادقة الخاص بك."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم التنشيط"])}
            }
          }
        },
        "SMSMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسالة قصيرة"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استلام رمز المصادقة لتسجيل الدخول عبر الرسائل القصيرة."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم التنشيط"])}
            }
          }
        },
        "explanationMFAChoice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بزيادة الأمان باستخدام المصادقة متعددة العوامل عند تسجيل الدخول."])}
        },
        "MFAChoice": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مصادقة متعددة العوامل"])}
        },
        "feedforward": {
          "authenticatorMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم التنشيط"])}
            }
          },
          "SMSMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم التنشيط"])}
            }
          }
        }
      },
      "ar-SA": {
        "authenticatorMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تطبيق المصادقة"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصل على رمز المصادقة لتسجيل الدخول من تطبيق المصادقة الخاص بك."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم التنشيط"])}
            }
          }
        },
        "SMSMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسالة قصيرة"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استلام رمز المصادقة لتسجيل الدخول عبر الرسائل القصيرة."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم التنشيط"])}
            }
          }
        },
        "explanationMFAChoice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بزيادة الأمان باستخدام المصادقة متعددة العوامل عند تسجيل الدخول."])}
        },
        "MFAChoice": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مصادقة متعددة العوامل"])}
        },
        "feedforward": {
          "authenticatorMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم التنشيط"])}
            }
          },
          "SMSMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم التنشيط"])}
            }
          }
        }
      },
      "el-GR": {
        "authenticatorMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εφαρμογή ελέγχου ταυτότητας"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λάβετε τον κωδικό ελέγχου ταυτότητας για σύνδεση από την εφαρμογή ελέγχου ταυτότητας."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενεργοποιήθηκε"])}
            }
          }
        },
        "SMSMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["γραπτό μήνυμα"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λάβετε κωδικό ελέγχου ταυτότητας για έλεγχο ταυτότητας πολλαπλών βημάτων μέσω SMS."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενεργοποιήθηκε"])}
            }
          }
        },
        "explanationMFAChoice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυξήστε την ασφάλεια με έλεγχο ταυτότητας πολλών παραγόντων κατά τη σύνδεση."])}
        },
        "MFAChoice": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχος ταυτότητας πολλών παραγόντων"])}
        },
        "feedforward": {
          "authenticatorMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενεργοποιήθηκε"])}
            }
          },
          "SMSMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενεργοποιήθηκε"])}
            }
          }
        }
      },
      "el-CY": {
        "authenticatorMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εφαρμογή ελέγχου ταυτότητας"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λάβετε τον κωδικό ελέγχου ταυτότητας για σύνδεση από την εφαρμογή ελέγχου ταυτότητας."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενεργοποιήθηκε"])}
            }
          }
        },
        "SMSMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["γραπτό μήνυμα"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λάβετε κωδικό ελέγχου ταυτότητας για έλεγχο ταυτότητας πολλαπλών βημάτων μέσω SMS."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενεργοποιήθηκε"])}
            }
          }
        },
        "explanationMFAChoice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυξήστε την ασφάλεια με έλεγχο ταυτότητας πολλών παραγόντων κατά τη σύνδεση."])}
        },
        "MFAChoice": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχος ταυτότητας πολλών παραγόντων"])}
        },
        "feedforward": {
          "authenticatorMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενεργοποιήθηκε"])}
            }
          },
          "SMSMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενεργοποιήθηκε"])}
            }
          }
        }
      },
      "de-DE": {
        "authenticatorMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifizierungs-App"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalte den Authentifizierungscode für den Login per Authentifizierungs-App."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviert"])}
            }
          }
        },
        "SMSMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifizierungscode für die Multi-Faktor-Authentifizierung per SMS erhalten."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviert"])}
            }
          }
        },
        "explanationMFAChoice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhöhe die Sicherheit durch Multi-Faktor-Authentifizierung beim Login."])}
        },
        "MFAChoice": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi-Faktor-Authentifizierung"])}
        },
        "feedforward": {
          "authenticatorMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviert"])}
            }
          },
          "SMSMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviert"])}
            }
          }
        }
      },
      "de-AT": {
        "authenticatorMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifizierungs-App"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalte den Authentifizierungscode für den Login per Authentifizierungs-App."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviert"])}
            }
          }
        },
        "SMSMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifizierungscode für die Multi-Faktor-Authentifizierung per SMS erhalten."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviert"])}
            }
          }
        },
        "explanationMFAChoice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhöhe die Sicherheit durch Multi-Faktor-Authentifizierung beim Login."])}
        },
        "MFAChoice": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi-Faktor-Authentifizierung"])}
        },
        "feedforward": {
          "authenticatorMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviert"])}
            }
          },
          "SMSMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviert"])}
            }
          }
        }
      },
      "de-CH": {
        "authenticatorMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifizierungs-App"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalte den Authentifizierungscode für den Login per Authentifizierungs-App."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviert"])}
            }
          }
        },
        "SMSMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifizierungscode für die Multi-Faktor-Authentifizierung per SMS erhalten."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviert"])}
            }
          }
        },
        "explanationMFAChoice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhöhe die Sicherheit durch Multi-Faktor-Authentifizierung beim Login."])}
        },
        "MFAChoice": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi-Faktor-Authentifizierung"])}
        },
        "feedforward": {
          "authenticatorMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviert"])}
            }
          },
          "SMSMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviert"])}
            }
          }
        }
      },
      "en-US": {
        "authenticatorMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticator-App"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get your authentication code for login by authentication app."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activated"])}
            }
          }
        },
        "SMSMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive authentication code for multi-factor authentication via SMS."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activated"])}
            }
          }
        },
        "explanationMFAChoice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Increase security with multi-factor authentication for login."])}
        },
        "MFAChoice": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi-Factor Authentication"])}
        },
        "feedforward": {
          "authenticatorMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activated"])}
            }
          },
          "SMSMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activated"])}
            }
          }
        }
      },
      "en-GB": {
        "authenticatorMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticator-App"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get your authentication code for login by authentication app."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activated"])}
            }
          }
        },
        "SMSMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive authentication code for multi-factor authentication via SMS."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activated"])}
            }
          }
        },
        "explanationMFAChoice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Increase security with multi-factor authentication for login."])}
        },
        "MFAChoice": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi-Factor Authentication"])}
        },
        "feedforward": {
          "authenticatorMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activated"])}
            }
          },
          "SMSMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activated"])}
            }
          }
        }
      },
      "it-IT": {
        "authenticatorMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticator-App"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevi il codice di autenticazione per il login mediante l'app di autenticazione."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivato"])}
            }
          }
        },
        "SMSMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevi codice di autenticazione per l'autenticazione a più fattori via SMS"])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivato"])}
            }
          }
        },
        "explanationMFAChoice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aumenta la sicurezza con l'autenticazione a più fattori durante l'accesso."])}
        },
        "MFAChoice": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticazione a più fattori"])}
        },
        "feedforward": {
          "authenticatorMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivato"])}
            }
          },
          "SMSMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivato"])}
            }
          }
        }
      },
      "fr-FR": {
        "authenticatorMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentificateur-App"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtenez votre code d'authentification pour la connexion par l'application d'authentification."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activé"])}
            }
          }
        },
        "SMSMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recevez le code d'authentification pour l'authentification multifacteur par SMS."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activé"])}
            }
          }
        },
        "explanationMFAChoice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renforcez la sécurité grâce à l'authentification multifactorielle pour la connexion."])}
        },
        "MFAChoice": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentification multi-facteurs"])}
        },
        "feedforward": {
          "authenticatorMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activé"])}
            }
          },
          "SMSMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activé"])}
            }
          }
        }
      },
      "es-ES": {
        "authenticatorMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicación de autenticación"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reciba el código de autenticación para iniciar sesión en la aplicación de autenticación."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activada"])}
            }
          }
        },
        "SMSMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reciba el código de autenticación para la autenticación de varios pasos por SMS."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activada"])}
            }
          }
        },
        "explanationMFAChoice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aumente la seguridad de su cuenta con la autenticación multifactor para iniciar sesión."])}
        },
        "MFAChoice": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticación multifactor"])}
        },
        "feedforward": {
          "authenticatorMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activada"])}
            }
          },
          "SMSMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activada"])}
            }
          }
        }
      },
      "pl-PL": {
        "authenticatorMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikacja Authenticator"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odbierz kod uwierzytelniający do logowania z aplikacji uwierzytelniającej."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywowano"])}
            }
          }
        },
        "SMSMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odbierz kod uwierzytelniający do wieloetapowej autoryzacji przez SMS."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywowano"])}
            }
          }
        },
        "explanationMFAChoice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwiększ bezpieczeństwo dzięki uwierzytelnianiu wieloskładnikowemu podczas logowania."])}
        },
        "MFAChoice": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uwierzytelnianie wieloskładnikowe"])}
        },
        "feedforward": {
          "authenticatorMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywowano"])}
            }
          },
          "SMSMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywowano"])}
            }
          }
        }
      },
      "nl-NL": {
        "authenticatorMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticator-App"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvang uw authenticatie code bij het inloggen via de authenticatie app."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geactiveerd"])}
            }
          }
        },
        "SMSMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvang authenticatiecode voor meerstapsauthenticatie via sms."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geactiveerd"])}
            }
          }
        },
        "explanationMFAChoice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verhoog de veiligheid met multifactorauthenticatie bij het aanmelden."])}
        },
        "MFAChoice": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi-Factor Authenticatie"])}
        },
        "feedforward": {
          "authenticatorMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geactiveerd"])}
            }
          },
          "SMSMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geactiveerd"])}
            }
          }
        }
      },
      "bg-BG": {
        "authenticatorMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приложение за удостоверяване"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получете кода за удостоверяване за влизане от приложението за удостоверяване."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активирано"])}
            }
          }
        },
        "SMSMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получаване на код за удостоверяване за многоетапно удостоверяване чрез SMS."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активирано"])}
            }
          }
        },
        "explanationMFAChoice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увеличете защитата с многофакторно удостоверяване при влизане."])}
        },
        "MFAChoice": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Многофакторно удостоверяване"])}
        },
        "feedforward": {
          "authenticatorMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активирано"])}
            }
          },
          "SMSMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активирано"])}
            }
          }
        }
      },
      "bs-BA": {
        "authenticatorMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikacija za autentifikaciju"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primite autentifikacijski kôd za prijavu iz svoje aplikacije za autentifikaciju."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivirano"])}
            }
          }
        },
        "SMSMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primite kod za autentifikaciju za višestepenu autentifikaciju putem SMS-a."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivirano"])}
            }
          }
        },
        "explanationMFAChoice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povećajte sigurnost pomoću višefaktorske autentifikacije prilikom prijavljivanja."])}
        },
        "MFAChoice": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Višefaktorska autentifikacija"])}
        },
        "feedforward": {
          "authenticatorMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivirano"])}
            }
          },
          "SMSMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivirano"])}
            }
          }
        }
      },
      "da-DK": {
        "authenticatorMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticator-App"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modtag godkendelseskoden til login fra din godkendelsesapp."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiveret"])}
            }
          }
        },
        "SMSMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modtag autentificeringskode til multifaktorgodkendelse via SMS."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiveret"])}
            }
          }
        },
        "explanationMFAChoice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forøg sikkerheden med multifaktorautentificering, når du logger ind."])}
        },
        "MFAChoice": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multifaktorautentificering"])}
        },
        "feedforward": {
          "authenticatorMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiveret"])}
            }
          },
          "SMSMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiveret"])}
            }
          }
        }
      },
      "et-EE": {
        "authenticatorMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticator-App"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saate autentimiskoodi sisselogimiseks oma autentimisrakendusest."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiveeritud"])}
            }
          }
        },
        "SMSMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saate SMS-i kaudu autentimiskoodi mitmeastmeliseks autentimiseks."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiveeritud"])}
            }
          }
        },
        "explanationMFAChoice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suurendage sisselogimisel turvalisust mitme teguri autentimisega."])}
        },
        "MFAChoice": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitmeteguriline autentimine"])}
        },
        "feedforward": {
          "authenticatorMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiveeritud"])}
            }
          },
          "SMSMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiveeritud"])}
            }
          }
        }
      },
      "fi-FI": {
        "authenticatorMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticator-Sovellus"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastaanota todennuskoodi kirjautumista varten todennussovelluksestasi."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivoitu"])}
            }
          }
        },
        "SMSMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekstiviesti"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastaanota todennuskoodi monivaiheista todennusta varten tekstiviestillä."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivoitu"])}
            }
          }
        },
        "explanationMFAChoice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paranna tietoturvaa monivaiheisella todennuksella sisäänkirjautumisen yhteydessä."])}
        },
        "MFAChoice": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monivaiheinen todennus"])}
        },
        "feedforward": {
          "authenticatorMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivoitu"])}
            }
          },
          "SMSMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivoitu"])}
            }
          }
        }
      },
      "he-IL": {
        "authenticatorMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מאמת-אפליקציה"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["קבל את קוד האימות להתחברות מאפליקציית האימות שלך."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מופעל"])}
            }
          }
        },
        "SMSMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סמס"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["קבל קוד אימות עבור אימות רב-שלבי באמצעות SMS."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מופעל"])}
            }
          }
        },
        "explanationMFAChoice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הגדל את האבטחה באמצעות אימות רב-גורמי בעת כניסה."])}
        },
        "MFAChoice": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אימות רב גורמים"])}
        },
        "feedforward": {
          "authenticatorMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מופעל"])}
            }
          },
          "SMSMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מופעל"])}
            }
          }
        }
      },
      "ga-IE": {
        "authenticatorMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aip fíordheimhnitheora"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faigh an cód fíordheimhnithe atá uait chun logáil isteach trí aip fíordheimhnithe."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gníomhachtaithe"])}
            }
          }
        },
        "SMSMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faigh cód fíordheimhnithe le haghaidh fíordheimhnithe ilfhachtóiriúil trí SMS."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gníomhachtaithe"])}
            }
          }
        },
        "explanationMFAChoice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slándáil a mhéadú le fíordheimhniú ilfhachtóirí agus tú ag logáil isteach."])}
        },
        "MFAChoice": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fíordheimhniú ilfhachtóirí"])}
        },
        "feedforward": {
          "authenticatorMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gníomhachtaithe"])}
            }
          },
          "SMSMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gníomhachtaithe"])}
            }
          }
        }
      },
      "ja-JP": {
        "authenticatorMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証アプリ"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証アプリからログイン用の認証コードを受け取ります。"])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティブ化"])}
            }
          }
        },
        "SMSMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS経由で多段階認証の認証コードを受け取ります。"])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティブ化"])}
            }
          }
        },
        "explanationMFAChoice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン時の多要素認証でセキュリティを強化。"])}
        },
        "MFAChoice": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["多要素認証"])}
        },
        "feedforward": {
          "authenticatorMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティブ化"])}
            }
          },
          "SMSMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティブ化"])}
            }
          }
        }
      },
      "hr-HR": {
        "authenticatorMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentifikator-App"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primite kôd za provjeru autentičnosti za prijavu iz svoje aplikacije za provjeru autentičnosti."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviran"])}
            }
          }
        },
        "SMSMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primite autentifikacijski kod za autentifikaciju u više koraka putem SMS-a."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviran"])}
            }
          }
        },
        "explanationMFAChoice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povećajte sigurnost pomoću višefaktorske provjere autentičnosti prilikom prijave."])}
        },
        "MFAChoice": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Višefaktorska autentifikacija"])}
        },
        "feedforward": {
          "authenticatorMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviran"])}
            }
          },
          "SMSMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviran"])}
            }
          }
        }
      },
      "lv-LV": {
        "authenticatorMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentifikators-lietotne"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saņemiet autentifikācijas kodu, lai pieteiktos no savas autentifikācijas lietotnes."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivizēts"])}
            }
          }
        },
        "SMSMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Īsziņa"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saņemiet autentifikācijas kodu daudzpakāpju autentifikācijai, izmantojot SMS."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivizēts"])}
            }
          }
        },
        "explanationMFAChoice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palieliniet drošību, piesakoties, izmantojot daudzfaktoru autentifikāciju."])}
        },
        "MFAChoice": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daudzfaktoru autentifikācija"])}
        },
        "feedforward": {
          "authenticatorMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivizēts"])}
            }
          },
          "SMSMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivizēts"])}
            }
          }
        }
      },
      "lt-LT": {
        "authenticatorMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["„Authenticator“ programa"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gaukite prisijungimo autentifikavimo kodą iš autentifikavimo programos."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suaktyvinta"])}
            }
          }
        },
        "SMSMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trumpoji žinutė"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gaukite autentifikavimo kodą daugiapakopiam autentifikavimui SMS žinute."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suaktyvinta"])}
            }
          }
        },
        "explanationMFAChoice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Padidinkite saugumą naudodami dviejų veiksnių autentifikavimą, kai prisijungiate."])}
        },
        "MFAChoice": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dviejų veiksnių autentifikavimas"])}
        },
        "feedforward": {
          "authenticatorMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suaktyvinta"])}
            }
          },
          "SMSMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suaktyvinta"])}
            }
          }
        }
      },
      "pt-PT": {
        "authenticatorMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticator-App"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receba o código de autenticação para iniciar sessão pela aplicação de autenticação."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativado"])}
            }
          }
        },
        "SMSMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receba o código de autenticação para autenticação em várias etapas via SMS."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativado"])}
            }
          }
        },
        "explanationMFAChoice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aumente a segurança com a autenticação multifator ao efetuar o início de sessão."])}
        },
        "MFAChoice": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticação multifator"])}
        },
        "feedforward": {
          "authenticatorMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativado"])}
            }
          },
          "SMSMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativado"])}
            }
          }
        }
      },
      "ro-RO": {
        "authenticatorMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicație de autentificare"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primiți codul de autentificare pentru conectare dintr-o aplicație de autentificare."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activat"])}
            }
          }
        },
        "SMSMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primiți codul de autentificare pentru autentificarea multifactorială prin SMS"])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activat"])}
            }
          }
        },
        "explanationMFAChoice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Îmbunătățiti securitatea prin autentificarea cu mai mulți factori la conectare."])}
        },
        "MFAChoice": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentificare cu mai mulți factori"])}
        },
        "feedforward": {
          "authenticatorMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activat"])}
            }
          },
          "SMSMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activat"])}
            }
          }
        }
      },
      "ru-RU": {
        "authenticatorMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приложение для аутентификации"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получите код аутентификации для входа в систему через приложение для аутентификации."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активировано"])}
            }
          }
        },
        "SMSMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получите код аутентификации для многофакторной аутентификации через SMS."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активировано"])}
            }
          }
        },
        "explanationMFAChoice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повысьте безопасность с помощью многофакторной аутентификации при входе в систему."])}
        },
        "MFAChoice": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Многофакторная аутентификация"])}
        },
        "feedforward": {
          "authenticatorMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активировано"])}
            }
          },
          "SMSMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активировано"])}
            }
          }
        }
      },
      "sv-SE": {
        "authenticatorMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentiseringsapp"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta emot autentiseringskoden för inloggning från din autentiseringsapp."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiverad"])}
            }
          }
        },
        "SMSMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta emot autentiseringskod för multifaktorautentisering via SMS"])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiverad"])}
            }
          }
        },
        "explanationMFAChoice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öka säkerheten med autentisering med flera faktorer när du loggar in."])}
        },
        "MFAChoice": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multifaktorautentisering"])}
        },
        "feedforward": {
          "authenticatorMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiverad"])}
            }
          },
          "SMSMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiverad"])}
            }
          }
        }
      },
      "sk-SK": {
        "authenticatorMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikácia Authenticator"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Získajte overovací kód na prihlásenie pomocou overovacej aplikácie."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivované"])}
            }
          }
        },
        "SMSMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijmite autentifikačný kód pre viackrokovú autentifikáciu prostredníctvom SMS."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivované"])}
            }
          }
        },
        "explanationMFAChoice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zvýšte zabezpečenie pomocou viacfaktorového overenia pri prihlasovaní."])}
        },
        "MFAChoice": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viacfaktorové overenie"])}
        },
        "feedforward": {
          "authenticatorMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivované"])}
            }
          },
          "SMSMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivované"])}
            }
          }
        }
      },
      "cs-CZ": {
        "authenticatorMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikace Authenticator"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijměte ověřovací kód pro přihlášení z vaší ověřovací aplikace."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivováno"])}
            }
          }
        },
        "SMSMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obdržíte ověřovací kód pro vícefázové ověření prostřednictvím SMS."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivováno"])}
            }
          }
        },
        "explanationMFAChoice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zvyšte zabezpečení pomocí vícefaktorového ověřování při přihlášení."])}
        },
        "MFAChoice": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vícefaktorové ověřování"])}
        },
        "feedforward": {
          "authenticatorMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivováno"])}
            }
          },
          "SMSMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivováno"])}
            }
          }
        }
      },
      "hu-HU": {
        "authenticatorMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hitelesítő app"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapja meg a bejelentkezéshez szükséges hitelesítési kódot a hitelesítési alkalmazással."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivált"])}
            }
          }
        },
        "SMSMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hitelesítési kód fogadása a többlépcsős hitelesítéshez SMS-ben."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivált"])}
            }
          }
        },
        "explanationMFAChoice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Növelje a biztonságot többfaktoros hitelesítéssel a bejelentkezéskor."])}
        },
        "MFAChoice": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Többfaktoros hitelesítés"])}
        },
        "feedforward": {
          "authenticatorMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivált"])}
            }
          },
          "SMSMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivált"])}
            }
          }
        }
      },
      "fr-BE": {
        "authenticatorMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentificateur-App"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtenez votre code d'authentification pour la connexion par l'application d'authentification."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activé"])}
            }
          }
        },
        "SMSMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recevez le code d'authentification pour l'authentification multifacteur par SMS."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activé"])}
            }
          }
        },
        "explanationMFAChoice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renforcez la sécurité grâce à l'authentification multifactorielle pour la connexion."])}
        },
        "MFAChoice": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentification multi-facteurs"])}
        },
        "feedforward": {
          "authenticatorMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activé"])}
            }
          },
          "SMSMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activé"])}
            }
          }
        }
      },
      "fr-LU": {
        "authenticatorMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentificateur-App"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtenez votre code d'authentification pour la connexion par l'application d'authentification."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activé"])}
            }
          }
        },
        "SMSMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recevez le code d'authentification pour l'authentification multifacteur par SMS."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activé"])}
            }
          }
        },
        "explanationMFAChoice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renforcez la sécurité grâce à l'authentification multifactorielle pour la connexion."])}
        },
        "MFAChoice": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentification multi-facteurs"])}
        },
        "feedforward": {
          "authenticatorMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activé"])}
            }
          },
          "SMSMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activé"])}
            }
          }
        }
      },
      "mt-MT": {
        "authenticatorMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App tal-Awtentikatur"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irċievi l-kodiċi tal-awtentikazzjoni tiegħek għal-login mill-app tal-awtentikazzjoni."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivat"])}
            }
          }
        },
        "SMSMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irċievi kodiċi ta 'awtentikazzjoni għal awtentikazzjoni multi-fattur permezz ta' SMS"])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivat"])}
            }
          }
        },
        "explanationMFAChoice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Żid is-sigurtà b'awtentikazzjoni b'ħafna fatturi meta tidħol."])}
        },
        "MFAChoice": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Awtentikazzjoni b'Ħafna Fatturi"])}
        },
        "feedforward": {
          "authenticatorMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivat"])}
            }
          },
          "SMSMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivat"])}
            }
          }
        }
      },
      "sl-SI": {
        "authenticatorMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikacija za preverjanje pristnosti"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kodo za prijavo s preverjanjem pristnosti pridobite iz aplikacije za preverjanje pristnosti."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivirano"])}
            }
          }
        },
        "SMSMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prejmite avtentikacijsko kodo za večfaktorsko avtentikacijo prek SMS-a."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivirano"])}
            }
          }
        },
        "explanationMFAChoice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izboljšajte varnost z večkratnim preverjanjem pristnosti ob prijavi."])}
        },
        "MFAChoice": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Večkratno preverjanje pristnosti"])}
        },
        "feedforward": {
          "authenticatorMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivirano"])}
            }
          },
          "SMSMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivirano"])}
            }
          }
        }
      },
      "tr-TR": {
        "authenticatorMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik Doğrulayıcı-Uygulama"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik doğrulama uygulamasında oturum açmak için kimlik doğrulama kodunuzu alın."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkinleştirildi"])}
            }
          }
        },
        "SMSMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS yoluyla çok adımlı kimlik doğrulama için kimlik doğrulama kodu alın."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkinleştirildi"])}
            }
          }
        },
        "explanationMFAChoice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum açarken çok faktörlü kimlik doğrulama ile güvenliği artırın."])}
        },
        "MFAChoice": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çok faktörlü kimlik doğrulama"])}
        },
        "feedforward": {
          "authenticatorMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkinleştirildi"])}
            }
          },
          "SMSMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkinleştirildi"])}
            }
          }
        }
      },
      "tr-CY": {
        "authenticatorMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik Doğrulayıcı-Uygulama"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik doğrulama uygulamasında oturum açmak için kimlik doğrulama kodunuzu alın."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkinleştirildi"])}
            }
          }
        },
        "SMSMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS yoluyla çok adımlı kimlik doğrulama için kimlik doğrulama kodu alın."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkinleştirildi"])}
            }
          }
        },
        "explanationMFAChoice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum açarken çok faktörlü kimlik doğrulama ile güvenliği artırın."])}
        },
        "MFAChoice": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çok faktörlü kimlik doğrulama"])}
        },
        "feedforward": {
          "authenticatorMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkinleştirildi"])}
            }
          },
          "SMSMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkinleştirildi"])}
            }
          }
        }
      },
      "uk-UA": {
        "authenticatorMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifizierungs-App"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalte den Authentifizierungscode für den Login per Authentifizierungs-App."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активовано"])}
            }
          }
        },
        "SMSMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримайте код автентифікації для багатоетапної автентифікації через SMS."])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активовано"])}
            }
          }
        },
        "explanationMFAChoice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhöhe die Sicherheit durch Multi-Faktor-Authentifizierung beim Login."])}
        },
        "MFAChoice": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Багатофакторна автентифікація"])}
        },
        "feedforward": {
          "authenticatorMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активовано"])}
            }
          },
          "SMSMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активовано"])}
            }
          }
        }
      },
      "zh-CN": {
        "authenticatorMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身份验证器应用"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过身份验证应用程序接收登录验证码。"])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已激活"])}
            }
          }
        },
        "SMSMFA": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["短信"])},
          "description": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过 SMS 接收用于多重身份验证的身份验证代码。"])}
          },
          "feedforward": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已激活"])}
            }
          }
        },
        "explanationMFAChoice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录时通过多重身份验证提高安全性。"])}
        },
        "MFAChoice": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["多重身份验证"])}
        },
        "feedforward": {
          "authenticatorMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已激活"])}
            }
          },
          "SMSMFA": {
            "success": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已激活"])}
            }
          }
        }
      }
    }
  })
}
