import {createRouter, createWebHistory} from 'vue-router'

import LoginStages from './pages/LoginStages.vue';
import concat from 'lodash/concat';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { left: 0, top: 0 }
    }
  },
  routes: concat([
    {
      // Having only one route for all login stages is intentional
      // Individual stages should not be accessible by modifying URL
      path: '/',
      name: 'login',
      component: LoginStages,
      props: true
    },
    {
      path: '',
      name: 'login',
      component: LoginStages,
      props: true
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'login',
      component: LoginStages,
      props: true
    }
  ])
});

export default router;
