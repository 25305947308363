<script setup>
import { defineEmits, defineProps, inject, ref, reactive, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { to } from "await-to-js";
import { useEnvStore } from '@dutypay/store-modules';
import { interceptor } from "@dutypay/utilities";
import { useRoute, useRouter } from 'vue-router';

const { t } = useI18n();
const composables = inject("composables");
const emit = defineEmits(["update", "go-to"]);
const props = defineProps({
  modelValue: { type: Object, required: true },
});
const loginData = composables.useTwoWayBinding(props, emit, "modelValue");
const notification = reactive({
  checkUser: {
    asyncError: false
  }
});
const isLoading = ref(false);

async function checkUser() {
  const envStore = useEnvStore();
  isLoading.value = true;
  notification.checkUser.asyncError = false;
  const { email } = loginData.value;
  let err, userExists;
  [err, userExists] = await to(interceptor.post(`${ envStore.apiUrls.dutypay }/api/v0/check-user`, { email }));
  if (err) {
    isLoading.value = false;
    notification.checkUser.asyncError = true;
    loginData.value.emailIsVerified = false;
    return;
  }
  notification.checkUser.asyncError = false;
  loginData.value.emailIsVerified = !!userExists.data.result.isVerified;
  emit("go-to", "EmailOTPForm");
  isLoading.value = false;
}

onMounted(() => {
  const route = useRoute();
  const router = useRouter();
  // Clear route query params
  const query = Object.assign({}, route.query);
  delete query.emailAuthCode;
  delete query.email;
  router.replace({ query });
})
</script>
<script>
export default {
  name: "EmailForm"
};
</script>

<template>
  <div>
    <h2>{{ t("emailForm.title") }}</h2>

    <DpNotification
      type="error"
      :visibility="notification.checkUser.asyncError"
      :title="t('notification.checkUser.asyncError.title')"
      :message="t('notification.checkUser.asyncError.message')"
    ></DpNotification>

    <DpForm
      :action="checkUser"
      v-loading="isLoading"
      reference="emailForm"
      class="dp-login-form"
      v-model="loginData"
      hide-required-asterisk
      :primaryButtonLabel="t('emailForm.buttonLabel')"
      :primaryButtonParameter="{ size: 'large' }"
      disable-notification>
      <DpInputEmailAddress
        v-model="loginData.email"
        prop="email"
        :input-parameters="{ size: 'large' }"
        required
      ></DpInputEmailAddress>
    </DpForm>
  </div>
</template>

<style lang="scss">
.dp-login-form {
  button.dp-button.dp-button__primary,
  button.dp-button.dp-button__primary:visited {
    &:not(.is-disabled) {
      -webkit-animation: color-change ease-in-out 57s infinite;
      -moz-animation: color-change ease-in-out 57s infinite;
      -o-animation: color-change ease-in-out 57s infinite;
      -ms-animation: color-change ease-in-out 57s infinite;
      animation: color-change ease-in-out 57s infinite;

      $color-zero: #409eff;
      $color-one: #e83158;
      $color-two: #db9800;
      $color-three: #9dcc4a;
      $color-four: #2c9fa8;
      $color-five: #099192;
      $color-six: #65adc1;
      $color-seven: #8b85db;
      $color-eight: #e09dea;
      $color-nine: #5399d3;

      @-webkit-keyframes color-change {
        0% {
          background-color: $color-zero;
        }
        10% {
          background-color: $color-one;
        }
        20% {
          background-color: $color-two;
        }
        30% {
          background-color: $color-three;
        }
        40% {
          background-color: $color-four;
        }
        50% {
          background-color: $color-five;
        }
        60% {
          background-color: $color-six;
        }
        70% {
          background-color: $color-seven;
        }
        80% {
          background-color: $color-eight;
        }
        90% {
          background-color: $color-nine;
        }
        100% {
          background-color: $color-zero;
        }
      }
      @-moz-keyframes color-change {
        0% {
          background-color: $color-zero;
        }
        10% {
          background-color: $color-one;
        }
        20% {
          background-color: $color-two;
        }
        30% {
          background-color: $color-three;
        }
        40% {
          background-color: $color-four;
        }
        50% {
          background-color: $color-five;
        }
        60% {
          background-color: $color-six;
        }
        70% {
          background-color: $color-seven;
        }
        80% {
          background-color: $color-eight;
        }
        90% {
          background-color: $color-nine;
        }
        100% {
          background-color: $color-zero;
        }
      }
      @-ms-keyframes color-change {
        0% {
          background-color: $color-zero;
        }
        10% {
          background-color: $color-one;
        }
        20% {
          background-color: $color-two;
        }
        30% {
          background-color: $color-three;
        }
        40% {
          background-color: $color-four;
        }
        50% {
          background-color: $color-five;
        }
        60% {
          background-color: $color-six;
        }
        70% {
          background-color: $color-seven;
        }
        80% {
          background-color: $color-eight;
        }
        90% {
          background-color: $color-nine;
        }
        100% {
          background-color: $color-zero;
        }
      }
      @-o-keyframes color-change {
        0% {
          background-color: $color-zero;
        }
        10% {
          background-color: $color-one;
        }
        20% {
          background-color: $color-two;
        }
        30% {
          background-color: $color-three;
        }
        40% {
          background-color: $color-four;
        }
        50% {
          background-color: $color-five;
        }
        60% {
          background-color: $color-six;
        }
        70% {
          background-color: $color-seven;
        }
        80% {
          background-color: $color-eight;
        }
        90% {
          background-color: $color-nine;
        }
        100% {
          background-color: $color-zero;
        }
      }
      @keyframes color-change {
        0% {
          background-color: $color-zero;
        }
        10% {
          background-color: $color-one;
        }
        20% {
          background-color: $color-two;
        }
        30% {
          background-color: $color-three;
        }
        40% {
          background-color: $color-four;
        }
        50% {
          background-color: $color-five;
        }
        60% {
          background-color: $color-six;
        }
        70% {
          background-color: $color-seven;
        }
        80% {
          background-color: $color-eight;
        }
        90% {
          background-color: $color-nine;
        }
        100% {
          background-color: $color-zero;
        }
      }
    }
  }
}
</style>

<i18n>
{
  "ar-AE": {
    "emailForm": {
      "title": "تسجيل الدخول",
      "buttonLabel": "تسجيل الدخول"
    },
    "notification": {
      "userDoesNotExist": {
        "title": "خطأ",
        "message": "لا يوجد حساب DutyPay في HubSystem لعنوان البريد الإلكتروني هذا."
      },
      "hasUser": {
        "asyncError": {
          "title": "خطأ"
        }
      },
      "checkUser": {
        "asyncError": {
          "title": "خطأ",
          "message": "لا يوجد حساب DutyPay في HubSystem لعنوان البريد الإلكتروني هذا."
        }
      }
    }
  },
  "ar-EG": {
    "emailForm": {
      "title": "تسجيل الدخول",
      "buttonLabel": "تسجيل الدخول"
    },
    "notification": {
      "userDoesNotExist": {
        "title": "خطأ",
        "message": "لا يوجد حساب DutyPay في HubSystem لعنوان البريد الإلكتروني هذا."
      },
      "hasUser": {
        "asyncError": {
          "title": "خطأ"
        }
      },
      "checkUser": {
        "asyncError": {
          "title": "خطأ",
          "message": "لا يوجد حساب DutyPay في HubSystem لعنوان البريد الإلكتروني هذا."
        }
      }
    }
  },
  "ar-SA": {
    "emailForm": {
      "title": "تسجيل الدخول",
      "buttonLabel": "تسجيل الدخول"
    },
    "notification": {
      "userDoesNotExist": {
        "title": "خطأ",
        "message": "لا يوجد حساب DutyPay في HubSystem لعنوان البريد الإلكتروني هذا."
      },
      "hasUser": {
        "asyncError": {
          "title": "خطأ"
        }
      },
      "checkUser": {
        "asyncError": {
          "title": "خطأ",
          "message": "لا يوجد حساب DutyPay في HubSystem لعنوان البريد الإلكتروني هذا."
        }
      }
    }
  },
  "el-GR": {
    "emailForm": {
      "title": "Σύνδεση",
      "buttonLabel": "Σύνδεση"
    },
    "notification": {
      "userDoesNotExist": {
        "title": "Σφάλμα",
        "message": "Δεν υπάρχει λογαριασμός DutyPay στο HubSystem για αυτήν τη διεύθυνση ηλεκτρονικού ταχυδρομείου."
      },
      "hasUser": {
        "asyncError": {
          "title": "Σφάλμα"
        }
      },
      "checkUser": {
        "asyncError": {
          "title": "Σφάλμα",
          "message": "Δεν υπάρχει λογαριασμός DutyPay στο HubSystem για αυτήν τη διεύθυνση ηλεκτρονικού ταχυδρομείου."
        }
      }
    }
  },
  "el-CY": {
    "emailForm": {
      "title": "Σύνδεση",
      "buttonLabel": "Σύνδεση"
    },
    "notification": {
      "userDoesNotExist": {
        "title": "Σφάλμα",
        "message": "Δεν υπάρχει λογαριασμός DutyPay στο HubSystem για αυτήν τη διεύθυνση ηλεκτρονικού ταχυδρομείου."
      },
      "hasUser": {
        "asyncError": {
          "title": "Σφάλμα"
        }
      },
      "checkUser": {
        "asyncError": {
          "title": "Σφάλμα",
          "message": "Δεν υπάρχει λογαριασμός DutyPay στο HubSystem για αυτήν τη διεύθυνση ηλεκτρονικού ταχυδρομείου."
        }
      }
    }
  },
  "de-DE": {
    "emailForm": {
      "title": "Anmelden",
      "buttonLabel": "Anmelden"
    },
    "notification": {
      "userDoesNotExist": {
        "title": "Fehler",
        "message": "Zu dieser E-Mail-Adresse existiert kein DutyPay-Konto im HubSystem."
      },
      "hasUser": {
        "asyncError": {
          "title": "Fehler"
        }
      },
      "checkUser": {
        "asyncError": {
          "title": "Fehler",
          "message": "Zu dieser E-Mail-Adresse existiert kein DutyPay-Konto im HubSystem."
        }
      }
    }
  },
  "de-AT": {
    "emailForm": {
      "title": "Anmelden",
      "buttonLabel": "Anmelden"
    },
    "notification": {
      "userDoesNotExist": {
        "title": "Fehler",
        "message": "Zu dieser E-Mail-Adresse existiert kein DutyPay-Konto im HubSystem."
      },
      "hasUser": {
        "asyncError": {
          "title": "Fehler"
        }
      },
      "checkUser": {
        "asyncError": {
          "title": "Fehler",
          "message": "Zu dieser E-Mail-Adresse existiert kein DutyPay-Konto im HubSystem."
        }
      }
    }
  },
  "de-CH": {
    "emailForm": {
      "title": "Anmelden",
      "buttonLabel": "Anmelden"
    },
    "notification": {
      "userDoesNotExist": {
        "title": "Fehler",
        "message": "Zu dieser E-Mail-Adresse existiert kein DutyPay-Konto im HubSystem."
      },
      "hasUser": {
        "asyncError": {
          "title": "Fehler"
        }
      },
      "checkUser": {
        "asyncError": {
          "title": "Fehler",
          "message": "Zu dieser E-Mail-Adresse existiert kein DutyPay-Konto im HubSystem."
        }
      }
    }
  },
  "en-US": {
    "emailForm": {
      "title": "Login",
      "buttonLabel": "Login"
    },
    "notification": {
      "userDoesNotExist": {
        "title": "Error",
        "message": "There is no DutyPay account in the HubSystem for this e-mail address."
      },
      "hasUser": {
        "asyncError": {
          "title": "Error"
        }
      },
      "checkUser": {
        "asyncError": {
          "title": "Error",
          "message": "There is no DutyPay account in the HubSystem for this e-mail address."
        }
      }
    }
  },
  "en-GB": {
    "emailForm": {
      "title": "Login",
      "buttonLabel": "Login"
    },
    "notification": {
      "userDoesNotExist": {
        "title": "Error",
        "message": "There is no DutyPay account in the HubSystem for this e-mail address."
      },
      "hasUser": {
        "asyncError": {
          "title": "Error"
        }
      },
      "checkUser": {
        "asyncError": {
          "title": "Error",
          "message": "There is no DutyPay account in the HubSystem for this e-mail address."
        }
      }
    }
  },
  "it-IT": {
    "emailForm": {
      "title": "Accedi",
      "buttonLabel": "Accedi"
    },
    "notification": {
      "userDoesNotExist": {
        "title": "Errore",
        "message": "Non esiste un account DutyPay in HubSystem per questo indirizzo e-mail."
      },
      "hasUser": {
        "asyncError": {
          "title": "Errore"
        }
      },
      "checkUser": {
        "asyncError": {
          "title": "Errore",
          "message": "Non esiste un account DutyPay in HubSystem per questo indirizzo e-mail."
        }
      }
    }
  },
  "fr-FR": {
    "emailForm": {
      "title": "Connexion",
      "buttonLabel": "Connexion"
    },
    "notification": {
      "userDoesNotExist": {
        "title": "Erreur",
        "message": "Il n'y a pas de compte DutyPay dans le HubSystem pour cette adresse e-mail."
      },
      "hasUser": {
        "asyncError": {
          "title": "Erreur"
        }
      },
      "checkUser": {
        "asyncError": {
          "title": "Erreur",
          "message": "Il n'y a pas de compte DutyPay dans le HubSystem pour cette adresse e-mail."
        }
      }
    }
  },
  "es-ES": {
    "emailForm": {
      "title": "Iniciar sesión",
      "buttonLabel": "Iniciar sesión"
    },
    "notification": {
      "userDoesNotExist": {
        "title": "Error",
        "message": "No hay una cuenta de DutyPay en HubSystem para esta dirección de correo electrónico."
      },
      "hasUser": {
        "asyncError": {
          "title": "Error"
        }
      },
      "checkUser": {
        "asyncError": {
          "title": "Error",
          "message": "No hay una cuenta de DutyPay en HubSystem para esta dirección de correo electrónico."
        }
      }
    }
  },
  "pl-PL": {
    "emailForm": {
      "title": "Zaloguj się",
      "buttonLabel": "Zaloguj się"
    },
    "notification": {
      "userDoesNotExist": {
        "title": "Błąd",
        "message": "Dla tego adresu e-mail nie ma konta DutyPay w HubSystem."
      },
      "hasUser": {
        "asyncError": {
          "title": "Błąd"
        }
      },
      "checkUser": {
        "asyncError": {
          "title": "Błąd",
          "message": "Dla tego adresu e-mail nie ma konta DutyPay w HubSystem."
        }
      }
    }
  },
  "nl-NL": {
    "emailForm": {
      "title": "Inloggen",
      "buttonLabel": "Inloggen"
    },
    "notification": {
      "userDoesNotExist": {
        "title": "Fout",
        "message": "Er is geen DutyPay-account in het HubSystem voor dit e-mailadres."
      },
      "hasUser": {
        "asyncError": {
          "title": "Fout"
        }
      },
      "checkUser": {
        "asyncError": {
          "title": "Fout",
          "message": "Er is geen DutyPay-account in het HubSystem voor dit e-mailadres."
        }
      }
    }
  },
  "bg-BG": {
    "emailForm": {
      "title": "вход",
      "buttonLabel": "вход"
    },
    "notification": {
      "userDoesNotExist": {
        "title": "грешка",
        "message": "Няма акаунт в DutyPay в HubSystem за този имейл адрес."
      },
      "hasUser": {
        "asyncError": {
          "title": "грешка"
        }
      },
      "checkUser": {
        "asyncError": {
          "title": "грешка",
          "message": "Няма акаунт в DutyPay в HubSystem за този имейл адрес."
        }
      }
    }
  },
  "bs-BA": {
    "emailForm": {
      "title": "Prijavi se",
      "buttonLabel": "Prijavi se"
    },
    "notification": {
      "userDoesNotExist": {
        "title": "Greška",
        "message": "Ne postoji DutyPay nalog u HubSystemu za ovu adresu e-pošte."
      },
      "hasUser": {
        "asyncError": {
          "title": "Greška"
        }
      },
      "checkUser": {
        "asyncError": {
          "title": "Greška",
          "message": "Ne postoji DutyPay nalog u HubSystemu za ovu adresu e-pošte."
        }
      }
    }
  },
  "da-DK": {
    "emailForm": {
      "title": "Log på",
      "buttonLabel": "Log på"
    },
    "notification": {
      "userDoesNotExist": {
        "title": "Fejl",
        "message": "Der er ingen DutyPay-konto i HubSystemet for denne e-mailadresse."
      },
      "hasUser": {
        "asyncError": {
          "title": "Fejl"
        }
      },
      "checkUser": {
        "asyncError": {
          "title": "Fejl",
          "message": "Der er ingen DutyPay-konto i HubSystemet for denne e-mailadresse."
        }
      }
    }
  },
  "et-EE": {
    "emailForm": {
      "title": "Logi sisse",
      "buttonLabel": "Logi sisse"
    },
    "notification": {
      "userDoesNotExist": {
        "title": "Viga",
        "message": "Selle e-posti aadressi jaoks pole HubSystemis DutyPay kontot."
      },
      "hasUser": {
        "asyncError": {
          "title": "Viga"
        }
      },
      "checkUser": {
        "asyncError": {
          "title": "Viga",
          "message": "Selle e-posti aadressi jaoks pole HubSystemis DutyPay kontot."
        }
      }
    }
  },
  "fi-FI": {
    "emailForm": {
      "title": "Sisäänkirjautuminen",
      "buttonLabel": "Sisäänkirjautuminen"
    },
    "notification": {
      "userDoesNotExist": {
        "title": "Virhe",
        "message": "HubSystemissä ei ole DutyPay-tiliä tälle sähköpostiosoitteelle."
      },
      "hasUser": {
        "asyncError": {
          "title": "Virhe"
        }
      },
      "checkUser": {
        "asyncError": {
          "title": "Virhe",
          "message": "HubSystemissä ei ole DutyPay-tiliä tälle sähköpostiosoitteelle."
        }
      }
    }
  },
  "he-IL": {
    "emailForm": {
      "title": "התחברות",
      "buttonLabel": "התחברות"
    },
    "notification": {
      "userDoesNotExist": {
        "title": "שְׁגִיאָה",
        "message": "אין חשבון DutyPay ב-HubSystem עבור כתובת דוא＂ל זו."
      },
      "hasUser": {
        "asyncError": {
          "title": "שְׁגִיאָה"
        }
      },
      "checkUser": {
        "asyncError": {
          "title": "שְׁגִיאָה",
          "message": "אין חשבון DutyPay ב-HubSystem עבור כתובת דוא＂ל זו."
        }
      }
    }
  },
  "ga-IE": {
    "emailForm": {
      "title": "Logáil isteach",
      "buttonLabel": "Logáil isteach"
    },
    "notification": {
      "userDoesNotExist": {
        "title": "Earráid",
        "message": "Níl aon chuntas DutyPay sa HubSystem don seoladh ríomhphoist seo."
      },
      "hasUser": {
        "asyncError": {
          "title": "Earráid"
        }
      },
      "checkUser": {
        "asyncError": {
          "title": "Earráid",
          "message": "Níl aon chuntas DutyPay sa HubSystem don seoladh ríomhphoist seo."
        }
      }
    }
  },
  "ja-JP": {
    "emailForm": {
      "title": "ログインする",
      "buttonLabel": "ログインする"
    },
    "notification": {
      "userDoesNotExist": {
        "title": "エラー",
        "message": "HubSystem には、このメール アドレスの DutyPay アカウントがありません。"
      },
      "hasUser": {
        "asyncError": {
          "title": "エラー"
        }
      },
      "checkUser": {
        "asyncError": {
          "title": "エラー",
          "message": "HubSystem には、このメール アドレスの DutyPay アカウントがありません。"
        }
      }
    }
  },
  "hr-HR": {
    "emailForm": {
      "title": "Prijavi se",
      "buttonLabel": "Prijavi se"
    },
    "notification": {
      "userDoesNotExist": {
        "title": "Pogreška",
        "message": "Ne postoji DutyPay račun u HubSystem za ovu adresu e-pošte."
      },
      "hasUser": {
        "asyncError": {
          "title": "Pogreška"
        }
      },
      "checkUser": {
        "asyncError": {
          "title": "Pogreška",
          "message": "Ne postoji DutyPay račun u HubSystem za ovu adresu e-pošte."
        }
      }
    }
  },
  "lv-LV": {
    "emailForm": {
      "title": "Pieslēgties",
      "buttonLabel": "Pieslēgties"
    },
    "notification": {
      "userDoesNotExist": {
        "title": "Kļūda",
        "message": "Šai e-pasta adresei HubSystem nav DutyPay konta."
      },
      "hasUser": {
        "asyncError": {
          "title": "Kļūda"
        }
      },
      "checkUser": {
        "asyncError": {
          "title": "Kļūda",
          "message": "Šai e-pasta adresei HubSystem nav DutyPay konta."
        }
      }
    }
  },
  "lt-LT": {
    "emailForm": {
      "title": "Prisijungti",
      "buttonLabel": "Prisijungti"
    },
    "notification": {
      "userDoesNotExist": {
        "title": "Klaida",
        "message": "Šiam el. pašto adresui „HubSystem“ nėra „DutyPay“ paskyros."
      },
      "hasUser": {
        "asyncError": {
          "title": "Klaida"
        }
      },
      "checkUser": {
        "asyncError": {
          "title": "Klaida",
          "message": "Šiam el. pašto adresui „HubSystem“ nėra „DutyPay“ paskyros."
        }
      }
    }
  },
  "pt-PT": {
    "emailForm": {
      "title": "Iniciar sessão",
      "buttonLabel": "Iniciar sessão"
    },
    "notification": {
      "userDoesNotExist": {
        "title": "Erro",
        "message": "Não há conta DutyPay no HubSystem para este endereço de e-mail."
      },
      "hasUser": {
        "asyncError": {
          "title": "Erro"
        }
      },
      "checkUser": {
        "asyncError": {
          "title": "Erro",
          "message": "Não há conta DutyPay no HubSystem para este endereço de e-mail."
        }
      }
    }
  },
  "ro-RO": {
    "emailForm": {
      "title": "Autentificare",
      "buttonLabel": "Autentificare"
    },
    "notification": {
      "userDoesNotExist": {
        "title": "Eroare",
        "message": "Nu există niciun cont DutyPay în HubSystem pentru această adresă de e-mail."
      },
      "hasUser": {
        "asyncError": {
          "title": "Eroare"
        }
      },
      "checkUser": {
        "asyncError": {
          "title": "Eroare",
          "message": "Nu există niciun cont DutyPay în HubSystem pentru această adresă de e-mail."
        }
      }
    }
  },
  "ru-RU": {
    "emailForm": {
      "title": "Вход",
      "buttonLabel": "Вход"
    },
    "notification": {
      "userDoesNotExist": {
        "title": "Ошибка",
        "message": "Для этого адреса электронной почты в системе HubSystem нет учетной записи DutyPay."
      },
      "hasUser": {
        "asyncError": {
          "title": "Ошибка"
        }
      },
      "checkUser": {
        "asyncError": {
          "title": "Ошибка",
          "message": "Для этого адреса электронной почты в системе HubSystem нет учетной записи DutyPay."
        }
      }
    }
  },
  "sv-SE": {
    "emailForm": {
      "title": "Logga in",
      "buttonLabel": "Logga in"
    },
    "notification": {
      "userDoesNotExist": {
        "title": "Fel",
        "message": "Det finns inget DutyPay-konto i HubSystem för den här e-postadressen."
      },
      "hasUser": {
        "asyncError": {
          "title": "Fel"
        }
      },
      "checkUser": {
        "asyncError": {
          "title": "Fel",
          "message": "Det finns inget DutyPay-konto i HubSystem för den här e-postadressen."
        }
      }
    }
  },
  "sk-SK": {
    "emailForm": {
      "title": "Prihlásenie",
      "buttonLabel": "Prihlásenie"
    },
    "notification": {
      "userDoesNotExist": {
        "title": "Chyba",
        "message": "Pre túto e-mailovú adresu nie je v HubSystem žiadny účet DutyPay."
      },
      "hasUser": {
        "asyncError": {
          "title": "Chyba"
        }
      },
      "checkUser": {
        "asyncError": {
          "title": "Chyba",
          "message": "Pre túto e-mailovú adresu nie je v HubSystem žiadny účet DutyPay."
        }
      }
    }
  },
  "cs-CZ": {
    "emailForm": {
      "title": "Přihlášení",
      "buttonLabel": "Přihlášení"
    },
    "notification": {
      "userDoesNotExist": {
        "title": "Chyba",
        "message": "Pro tuto e-mailovou adresu není v HubSystemu žádný účet DutyPay."
      },
      "hasUser": {
        "asyncError": {
          "title": "Chyba"
        }
      },
      "checkUser": {
        "asyncError": {
          "title": "Chyba",
          "message": "Pro tuto e-mailovou adresu není v HubSystemu žádný účet DutyPay."
        }
      }
    }
  },
  "hu-HU": {
    "emailForm": {
      "title": "Belépés",
      "buttonLabel": "Belépés"
    },
    "notification": {
      "userDoesNotExist": {
        "title": "Hiba",
        "message": "Ehhez az e-mail címhez nincs DutyPay-fiók a HubSystemben."
      },
      "hasUser": {
        "asyncError": {
          "title": "Hiba"
        }
      },
      "checkUser": {
        "asyncError": {
          "title": "Hiba",
          "message": "Ehhez az e-mail címhez nincs DutyPay-fiók a HubSystemben."
        }
      }
    }
  },
  "fr-BE": {
    "emailForm": {
      "title": "Connexion",
      "buttonLabel": "Connexion"
    },
    "notification": {
      "userDoesNotExist": {
        "title": "Erreur",
        "message": "Il n'y a pas de compte DutyPay dans le HubSystem pour cette adresse e-mail."
      },
      "hasUser": {
        "asyncError": {
          "title": "Erreur"
        }
      },
      "checkUser": {
        "asyncError": {
          "title": "Erreur",
          "message": "Il n'y a pas de compte DutyPay dans le HubSystem pour cette adresse e-mail."
        }
      }
    }
  },
  "fr-LU": {
    "emailForm": {
      "title": "Connexion",
      "buttonLabel": "Connexion"
    },
    "notification": {
      "userDoesNotExist": {
        "title": "Erreur",
        "message": "Il n'y a pas de compte DutyPay dans le HubSystem pour cette adresse e-mail."
      },
      "hasUser": {
        "asyncError": {
          "title": "Erreur"
        }
      },
      "checkUser": {
        "asyncError": {
          "title": "Erreur",
          "message": "Il n'y a pas de compte DutyPay dans le HubSystem pour cette adresse e-mail."
        }
      }
    }
  },
  "mt-MT": {
    "emailForm": {
      "title": "Idħol",
      "buttonLabel": "Idħol"
    },
    "notification": {
      "userDoesNotExist": {
        "title": "Żball",
        "message": "M'hemm l-ebda kont DutyPay fis-HubSystem għal dan l-indirizz elettroniku."
      },
      "hasUser": {
        "asyncError": {
          "title": "Żball"
        }
      },
      "checkUser": {
        "asyncError": {
          "title": "Żball",
          "message": "M'hemm l-ebda kont DutyPay fis-HubSystem għal dan l-indirizz elettroniku."
        }
      }
    }
  },
  "sl-SI": {
    "emailForm": {
      "title": "Prijava",
      "buttonLabel": "Prijava"
    },
    "notification": {
      "userDoesNotExist": {
        "title": "Napaka",
        "message": "Za ta e-poštni naslov v sistemu HubSystem ni računa DutyPay."
      },
      "hasUser": {
        "asyncError": {
          "title": "Napaka"
        }
      },
      "checkUser": {
        "asyncError": {
          "title": "Napaka",
          "message": "Za ta e-poštni naslov v sistemu HubSystem ni računa DutyPay."
        }
      }
    }
  },
  "tr-TR": {
    "emailForm": {
      "title": "Oturum aç",
      "buttonLabel": "Oturum aç"
    },
    "notification": {
      "userDoesNotExist": {
        "title": "Hata",
        "message": "HubSystem'de bu e-posta adresi için bir DutyPay hesabı yok."
      },
      "hasUser": {
        "asyncError": {
          "title": "Hata"
        }
      },
      "checkUser": {
        "asyncError": {
          "title": "Hata",
          "message": "HubSystem'de bu e-posta adresi için bir DutyPay hesabı yok."
        }
      }
    }
  },
  "tr-CY": {
    "emailForm": {
      "title": "Oturum aç",
      "buttonLabel": "Oturum aç"
    },
    "notification": {
      "userDoesNotExist": {
        "title": "Hata",
        "message": "HubSystem'de bu e-posta adresi için bir DutyPay hesabı yok."
      },
      "hasUser": {
        "asyncError": {
          "title": "Hata"
        }
      },
      "checkUser": {
        "asyncError": {
          "title": "Hata",
          "message": "HubSystem'de bu e-posta adresi için bir DutyPay hesabı yok."
        }
      }
    }
  },
  "uk-UA": {
    "emailForm": {
      "title": "Anmelden",
      "buttonLabel": "Анмельден"
    },
    "notification": {
      "userDoesNotExist": {
        "title": "Помилка",
        "message": "У системі HubSystem немає облікового запису DutyPay для цієї електронної адреси."
      },
      "hasUser": {
        "asyncError": {
          "title": "Помилка"
        }
      },
      "checkUser": {
        "asyncError": {
          "title": "Помилка",
          "message": "У системі HubSystem немає облікового запису DutyPay для цієї електронної адреси."
        }
      }
    }
  },
  "zh-CN": {
    "emailForm": {
      "title": "登录",
      "buttonLabel": "登录"
    },
    "notification": {
      "userDoesNotExist": {
        "title": "错误",
        "message": "该电子邮件地址在 HubSystem 中没有 DutyPay 帐户。"
      },
      "hasUser": {
        "asyncError": {
          "title": "错误"
        }
      },
      "checkUser": {
        "asyncError": {
          "title": "错误",
          "message": "该电子邮件地址在 HubSystem 中没有 DutyPay 帐户。"
        }
      }
    }
  }
}
</i18n>
