export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ar-AE": {
        "emailForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])}
        },
        "notification": {
          "userDoesNotExist": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد حساب DutyPay في HubSystem لعنوان البريد الإلكتروني هذا."])}
          },
          "hasUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])}
            }
          },
          "checkUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد حساب DutyPay في HubSystem لعنوان البريد الإلكتروني هذا."])}
            }
          }
        }
      },
      "ar-EG": {
        "emailForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])}
        },
        "notification": {
          "userDoesNotExist": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد حساب DutyPay في HubSystem لعنوان البريد الإلكتروني هذا."])}
          },
          "hasUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])}
            }
          },
          "checkUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد حساب DutyPay في HubSystem لعنوان البريد الإلكتروني هذا."])}
            }
          }
        }
      },
      "ar-SA": {
        "emailForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])}
        },
        "notification": {
          "userDoesNotExist": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد حساب DutyPay في HubSystem لعنوان البريد الإلكتروني هذا."])}
          },
          "hasUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])}
            }
          },
          "checkUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد حساب DutyPay في HubSystem لعنوان البريد الإلكتروني هذا."])}
            }
          }
        }
      },
      "el-GR": {
        "emailForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύνδεση"])},
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύνδεση"])}
        },
        "notification": {
          "userDoesNotExist": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σφάλμα"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχει λογαριασμός DutyPay στο HubSystem για αυτήν τη διεύθυνση ηλεκτρονικού ταχυδρομείου."])}
          },
          "hasUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σφάλμα"])}
            }
          },
          "checkUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σφάλμα"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχει λογαριασμός DutyPay στο HubSystem για αυτήν τη διεύθυνση ηλεκτρονικού ταχυδρομείου."])}
            }
          }
        }
      },
      "el-CY": {
        "emailForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύνδεση"])},
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύνδεση"])}
        },
        "notification": {
          "userDoesNotExist": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σφάλμα"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχει λογαριασμός DutyPay στο HubSystem για αυτήν τη διεύθυνση ηλεκτρονικού ταχυδρομείου."])}
          },
          "hasUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σφάλμα"])}
            }
          },
          "checkUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σφάλμα"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχει λογαριασμός DutyPay στο HubSystem για αυτήν τη διεύθυνση ηλεκτρονικού ταχυδρομείου."])}
            }
          }
        }
      },
      "de-DE": {
        "emailForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])}
        },
        "notification": {
          "userDoesNotExist": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu dieser E-Mail-Adresse existiert kein DutyPay-Konto im HubSystem."])}
          },
          "hasUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])}
            }
          },
          "checkUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu dieser E-Mail-Adresse existiert kein DutyPay-Konto im HubSystem."])}
            }
          }
        }
      },
      "de-AT": {
        "emailForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])}
        },
        "notification": {
          "userDoesNotExist": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu dieser E-Mail-Adresse existiert kein DutyPay-Konto im HubSystem."])}
          },
          "hasUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])}
            }
          },
          "checkUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu dieser E-Mail-Adresse existiert kein DutyPay-Konto im HubSystem."])}
            }
          }
        }
      },
      "de-CH": {
        "emailForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])}
        },
        "notification": {
          "userDoesNotExist": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu dieser E-Mail-Adresse existiert kein DutyPay-Konto im HubSystem."])}
          },
          "hasUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])}
            }
          },
          "checkUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu dieser E-Mail-Adresse existiert kein DutyPay-Konto im HubSystem."])}
            }
          }
        }
      },
      "en-US": {
        "emailForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])}
        },
        "notification": {
          "userDoesNotExist": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no DutyPay account in the HubSystem for this e-mail address."])}
          },
          "hasUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])}
            }
          },
          "checkUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no DutyPay account in the HubSystem for this e-mail address."])}
            }
          }
        }
      },
      "en-GB": {
        "emailForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])}
        },
        "notification": {
          "userDoesNotExist": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no DutyPay account in the HubSystem for this e-mail address."])}
          },
          "hasUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])}
            }
          },
          "checkUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no DutyPay account in the HubSystem for this e-mail address."])}
            }
          }
        }
      },
      "it-IT": {
        "emailForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi"])},
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi"])}
        },
        "notification": {
          "userDoesNotExist": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non esiste un account DutyPay in HubSystem per questo indirizzo e-mail."])}
          },
          "hasUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore"])}
            }
          },
          "checkUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non esiste un account DutyPay in HubSystem per questo indirizzo e-mail."])}
            }
          }
        }
      },
      "fr-FR": {
        "emailForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])}
        },
        "notification": {
          "userDoesNotExist": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y a pas de compte DutyPay dans le HubSystem pour cette adresse e-mail."])}
          },
          "hasUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])}
            }
          },
          "checkUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y a pas de compte DutyPay dans le HubSystem pour cette adresse e-mail."])}
            }
          }
        }
      },
      "es-ES": {
        "emailForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])}
        },
        "notification": {
          "userDoesNotExist": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay una cuenta de DutyPay en HubSystem para esta dirección de correo electrónico."])}
          },
          "hasUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])}
            }
          },
          "checkUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay una cuenta de DutyPay en HubSystem para esta dirección de correo electrónico."])}
            }
          }
        }
      },
      "pl-PL": {
        "emailForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaloguj się"])},
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaloguj się"])}
        },
        "notification": {
          "userDoesNotExist": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błąd"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dla tego adresu e-mail nie ma konta DutyPay w HubSystem."])}
          },
          "hasUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błąd"])}
            }
          },
          "checkUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błąd"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dla tego adresu e-mail nie ma konta DutyPay w HubSystem."])}
            }
          }
        }
      },
      "nl-NL": {
        "emailForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen"])},
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen"])}
        },
        "notification": {
          "userDoesNotExist": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is geen DutyPay-account in het HubSystem voor dit e-mailadres."])}
          },
          "hasUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout"])}
            }
          },
          "checkUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is geen DutyPay-account in het HubSystem voor dit e-mailadres."])}
            }
          }
        }
      },
      "bg-BG": {
        "emailForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вход"])},
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вход"])}
        },
        "notification": {
          "userDoesNotExist": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["грешка"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма акаунт в DutyPay в HubSystem за този имейл адрес."])}
          },
          "hasUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["грешка"])}
            }
          },
          "checkUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["грешка"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма акаунт в DutyPay в HubSystem за този имейл адрес."])}
            }
          }
        }
      },
      "bs-BA": {
        "emailForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijavi se"])},
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijavi se"])}
        },
        "notification": {
          "userDoesNotExist": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greška"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne postoji DutyPay nalog u HubSystemu za ovu adresu e-pošte."])}
          },
          "hasUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greška"])}
            }
          },
          "checkUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greška"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne postoji DutyPay nalog u HubSystemu za ovu adresu e-pošte."])}
            }
          }
        }
      },
      "da-DK": {
        "emailForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log på"])},
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log på"])}
        },
        "notification": {
          "userDoesNotExist": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fejl"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er ingen DutyPay-konto i HubSystemet for denne e-mailadresse."])}
          },
          "hasUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fejl"])}
            }
          },
          "checkUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fejl"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er ingen DutyPay-konto i HubSystemet for denne e-mailadresse."])}
            }
          }
        }
      },
      "et-EE": {
        "emailForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logi sisse"])},
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logi sisse"])}
        },
        "notification": {
          "userDoesNotExist": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viga"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selle e-posti aadressi jaoks pole HubSystemis DutyPay kontot."])}
          },
          "hasUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viga"])}
            }
          },
          "checkUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viga"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selle e-posti aadressi jaoks pole HubSystemis DutyPay kontot."])}
            }
          }
        }
      },
      "fi-FI": {
        "emailForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisäänkirjautuminen"])},
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisäänkirjautuminen"])}
        },
        "notification": {
          "userDoesNotExist": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virhe"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HubSystemissä ei ole DutyPay-tiliä tälle sähköpostiosoitteelle."])}
          },
          "hasUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virhe"])}
            }
          },
          "checkUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virhe"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HubSystemissä ei ole DutyPay-tiliä tälle sähköpostiosoitteelle."])}
            }
          }
        }
      },
      "he-IL": {
        "emailForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["התחברות"])},
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["התחברות"])}
        },
        "notification": {
          "userDoesNotExist": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שְׁגִיאָה"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אין חשבון DutyPay ב-HubSystem עבור כתובת דוא＂ל זו."])}
          },
          "hasUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שְׁגִיאָה"])}
            }
          },
          "checkUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שְׁגִיאָה"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אין חשבון DutyPay ב-HubSystem עבור כתובת דוא＂ל זו."])}
            }
          }
        }
      },
      "ga-IE": {
        "emailForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logáil isteach"])},
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logáil isteach"])}
        },
        "notification": {
          "userDoesNotExist": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earráid"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Níl aon chuntas DutyPay sa HubSystem don seoladh ríomhphoist seo."])}
          },
          "hasUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earráid"])}
            }
          },
          "checkUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earráid"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Níl aon chuntas DutyPay sa HubSystem don seoladh ríomhphoist seo."])}
            }
          }
        }
      },
      "ja-JP": {
        "emailForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログインする"])},
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログインする"])}
        },
        "notification": {
          "userDoesNotExist": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エラー"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HubSystem には、このメール アドレスの DutyPay アカウントがありません。"])}
          },
          "hasUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エラー"])}
            }
          },
          "checkUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エラー"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HubSystem には、このメール アドレスの DutyPay アカウントがありません。"])}
            }
          }
        }
      },
      "hr-HR": {
        "emailForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijavi se"])},
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijavi se"])}
        },
        "notification": {
          "userDoesNotExist": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pogreška"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne postoji DutyPay račun u HubSystem za ovu adresu e-pošte."])}
          },
          "hasUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pogreška"])}
            }
          },
          "checkUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pogreška"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne postoji DutyPay račun u HubSystem za ovu adresu e-pošte."])}
            }
          }
        }
      },
      "lv-LV": {
        "emailForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pieslēgties"])},
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pieslēgties"])}
        },
        "notification": {
          "userDoesNotExist": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kļūda"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šai e-pasta adresei HubSystem nav DutyPay konta."])}
          },
          "hasUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kļūda"])}
            }
          },
          "checkUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kļūda"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šai e-pasta adresei HubSystem nav DutyPay konta."])}
            }
          }
        }
      },
      "lt-LT": {
        "emailForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prisijungti"])},
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prisijungti"])}
        },
        "notification": {
          "userDoesNotExist": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klaida"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šiam el. pašto adresui „HubSystem“ nėra „DutyPay“ paskyros."])}
          },
          "hasUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klaida"])}
            }
          },
          "checkUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klaida"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šiam el. pašto adresui „HubSystem“ nėra „DutyPay“ paskyros."])}
            }
          }
        }
      },
      "pt-PT": {
        "emailForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sessão"])},
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sessão"])}
        },
        "notification": {
          "userDoesNotExist": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não há conta DutyPay no HubSystem para este endereço de e-mail."])}
          },
          "hasUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro"])}
            }
          },
          "checkUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não há conta DutyPay no HubSystem para este endereço de e-mail."])}
            }
          }
        }
      },
      "ro-RO": {
        "emailForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentificare"])},
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentificare"])}
        },
        "notification": {
          "userDoesNotExist": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eroare"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există niciun cont DutyPay în HubSystem pentru această adresă de e-mail."])}
          },
          "hasUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eroare"])}
            }
          },
          "checkUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eroare"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există niciun cont DutyPay în HubSystem pentru această adresă de e-mail."])}
            }
          }
        }
      },
      "ru-RU": {
        "emailForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вход"])},
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вход"])}
        },
        "notification": {
          "userDoesNotExist": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для этого адреса электронной почты в системе HubSystem нет учетной записи DutyPay."])}
          },
          "hasUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])}
            }
          },
          "checkUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для этого адреса электронной почты в системе HubSystem нет учетной записи DutyPay."])}
            }
          }
        }
      },
      "sv-SE": {
        "emailForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logga in"])},
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logga in"])}
        },
        "notification": {
          "userDoesNotExist": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fel"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det finns inget DutyPay-konto i HubSystem för den här e-postadressen."])}
          },
          "hasUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fel"])}
            }
          },
          "checkUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fel"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det finns inget DutyPay-konto i HubSystem för den här e-postadressen."])}
            }
          }
        }
      },
      "sk-SK": {
        "emailForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prihlásenie"])},
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prihlásenie"])}
        },
        "notification": {
          "userDoesNotExist": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre túto e-mailovú adresu nie je v HubSystem žiadny účet DutyPay."])}
          },
          "hasUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba"])}
            }
          },
          "checkUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre túto e-mailovú adresu nie je v HubSystem žiadny účet DutyPay."])}
            }
          }
        }
      },
      "cs-CZ": {
        "emailForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlášení"])},
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlášení"])}
        },
        "notification": {
          "userDoesNotExist": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro tuto e-mailovou adresu není v HubSystemu žádný účet DutyPay."])}
          },
          "hasUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba"])}
            }
          },
          "checkUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro tuto e-mailovou adresu není v HubSystemu žádný účet DutyPay."])}
            }
          }
        }
      },
      "hu-HU": {
        "emailForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belépés"])},
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belépés"])}
        },
        "notification": {
          "userDoesNotExist": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiba"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehhez az e-mail címhez nincs DutyPay-fiók a HubSystemben."])}
          },
          "hasUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiba"])}
            }
          },
          "checkUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiba"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehhez az e-mail címhez nincs DutyPay-fiók a HubSystemben."])}
            }
          }
        }
      },
      "fr-BE": {
        "emailForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])}
        },
        "notification": {
          "userDoesNotExist": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y a pas de compte DutyPay dans le HubSystem pour cette adresse e-mail."])}
          },
          "hasUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])}
            }
          },
          "checkUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y a pas de compte DutyPay dans le HubSystem pour cette adresse e-mail."])}
            }
          }
        }
      },
      "fr-LU": {
        "emailForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])}
        },
        "notification": {
          "userDoesNotExist": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y a pas de compte DutyPay dans le HubSystem pour cette adresse e-mail."])}
          },
          "hasUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])}
            }
          },
          "checkUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y a pas de compte DutyPay dans le HubSystem pour cette adresse e-mail."])}
            }
          }
        }
      },
      "mt-MT": {
        "emailForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idħol"])},
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idħol"])}
        },
        "notification": {
          "userDoesNotExist": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Żball"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["M'hemm l-ebda kont DutyPay fis-HubSystem għal dan l-indirizz elettroniku."])}
          },
          "hasUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Żball"])}
            }
          },
          "checkUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Żball"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["M'hemm l-ebda kont DutyPay fis-HubSystem għal dan l-indirizz elettroniku."])}
            }
          }
        }
      },
      "sl-SI": {
        "emailForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijava"])},
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijava"])}
        },
        "notification": {
          "userDoesNotExist": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napaka"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Za ta e-poštni naslov v sistemu HubSystem ni računa DutyPay."])}
          },
          "hasUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napaka"])}
            }
          },
          "checkUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napaka"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Za ta e-poštni naslov v sistemu HubSystem ni računa DutyPay."])}
            }
          }
        }
      },
      "tr-TR": {
        "emailForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum aç"])},
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum aç"])}
        },
        "notification": {
          "userDoesNotExist": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HubSystem'de bu e-posta adresi için bir DutyPay hesabı yok."])}
          },
          "hasUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata"])}
            }
          },
          "checkUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HubSystem'de bu e-posta adresi için bir DutyPay hesabı yok."])}
            }
          }
        }
      },
      "tr-CY": {
        "emailForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum aç"])},
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum aç"])}
        },
        "notification": {
          "userDoesNotExist": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HubSystem'de bu e-posta adresi için bir DutyPay hesabı yok."])}
          },
          "hasUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata"])}
            }
          },
          "checkUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HubSystem'de bu e-posta adresi için bir DutyPay hesabı yok."])}
            }
          }
        }
      },
      "uk-UA": {
        "emailForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анмельден"])}
        },
        "notification": {
          "userDoesNotExist": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У системі HubSystem немає облікового запису DutyPay для цієї електронної адреси."])}
          },
          "hasUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка"])}
            }
          },
          "checkUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У системі HubSystem немає облікового запису DutyPay для цієї електронної адреси."])}
            }
          }
        }
      },
      "zh-CN": {
        "emailForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])}
        },
        "notification": {
          "userDoesNotExist": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["错误"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["该电子邮件地址在 HubSystem 中没有 DutyPay 帐户。"])}
          },
          "hasUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["错误"])}
            }
          },
          "checkUser": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["错误"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["该电子邮件地址在 HubSystem 中没有 DutyPay 帐户。"])}
            }
          }
        }
      }
    }
  })
}
