export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ar-AE": {
        "notification": {
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمل ناجح"])}
            }
          }
        }
      },
      "ar-EG": {
        "notification": {
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمل ناجح"])}
            }
          }
        }
      },
      "ar-SA": {
        "notification": {
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمل ناجح"])}
            }
          }
        }
      },
      "el-GR": {
        "notification": {
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιτυχημένη ενέργεια"])}
            }
          }
        }
      },
      "el-CY": {
        "notification": {
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιτυχημένη ενέργεια"])}
            }
          }
        }
      },
      "de-DE": {
        "notification": {
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreiche Aktion"])}
            }
          }
        }
      },
      "de-AT": {
        "notification": {
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreiche Aktion"])}
            }
          }
        }
      },
      "de-CH": {
        "notification": {
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreiche Aktion"])}
            }
          }
        }
      },
      "en-US": {
        "notification": {
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful action"])}
            }
          }
        }
      },
      "en-GB": {
        "notification": {
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful action"])}
            }
          }
        }
      },
      "it-IT": {
        "notification": {
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azione riuscita"])}
            }
          }
        }
      },
      "fr-FR": {
        "notification": {
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action réussie"])}
            }
          }
        }
      },
      "es-ES": {
        "notification": {
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listo"])}
            }
          }
        }
      },
      "pl-PL": {
        "notification": {
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czynność zakończona powodzeniem"])}
            }
          }
        }
      },
      "nl-NL": {
        "notification": {
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geslaagde actie"])}
            }
          }
        }
      },
      "bg-BG": {
        "notification": {
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно действие"])}
            }
          }
        }
      },
      "bs-BA": {
        "notification": {
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno obavljeno"])}
            }
          }
        }
      },
      "da-DK": {
        "notification": {
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vellykket handling"])}
            }
          }
        }
      },
      "et-EE": {
        "notification": {
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edukas tegevus"])}
            }
          }
        }
      },
      "fi-FI": {
        "notification": {
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onnistunut toiminta"])}
            }
          }
        }
      },
      "he-IL": {
        "notification": {
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["פעולה מוצלחת"])}
            }
          }
        }
      },
      "ga-IE": {
        "notification": {
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gníomh rathúil"])}
            }
          }
        }
      },
      "ja-JP": {
        "notification": {
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功したアクション"])}
            }
          }
        }
      },
      "hr-HR": {
        "notification": {
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješna akcija"])}
            }
          }
        }
      },
      "lv-LV": {
        "notification": {
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veiksmīga darbība"])}
            }
          }
        }
      },
      "lt-LT": {
        "notification": {
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sėkmingas veiksmas"])}
            }
          }
        }
      },
      "pt-PT": {
        "notification": {
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ação bem-sucedida"])}
            }
          }
        }
      },
      "ro-RO": {
        "notification": {
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acțiune reușită"])}
            }
          }
        }
      },
      "ru-RU": {
        "notification": {
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешное действие"])}
            }
          }
        }
      },
      "sv-SE": {
        "notification": {
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åtgärd utförd"])}
            }
          }
        }
      },
      "sk-SK": {
        "notification": {
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspešná akcia"])}
            }
          }
        }
      },
      "cs-CZ": {
        "notification": {
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspěšná akce"])}
            }
          }
        }
      },
      "hu-HU": {
        "notification": {
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikeres művelet"])}
            }
          }
        }
      },
      "fr-BE": {
        "notification": {
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action réussie"])}
            }
          }
        }
      },
      "fr-LU": {
        "notification": {
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action réussie"])}
            }
          }
        }
      },
      "mt-MT": {
        "notification": {
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azzjoni ta' suċċess"])}
            }
          }
        }
      },
      "sl-SI": {
        "notification": {
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspešno opravljeno"])}
            }
          }
        }
      },
      "tr-TR": {
        "notification": {
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarılı eylem"])}
            }
          }
        }
      },
      "tr-CY": {
        "notification": {
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarılı eylem"])}
            }
          }
        }
      },
      "uk-UA": {
        "notification": {
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успішна акція"])}
            }
          }
        }
      },
      "zh-CN": {
        "notification": {
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作成功"])}
            }
          }
        }
      }
    }
  })
}
