export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ar-AE": {
        "scanCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["امسح رمز الاستجابة السريعة هذا ضوئيًا أو استخدم الرمز أدناه لتوصيل تطبيق المصادقة الخاص بك."])}
        },
        "afterYouScannedCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بتنشيط المصادقة ذات العاملين عن طريق إدخال رمز المصادقة وتأكيده. يتم إنشاء الرمز بواسطة تطبيق المصادقة."])}
        },
        "authenticatorSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تطبيق المصادقة"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد"])}
        },
        "notification": {
          "fetchSecret": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن تأكيد رمز المصادقة."])}
            }
          }
        }
      },
      "ar-EG": {
        "scanCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["امسح رمز الاستجابة السريعة هذا ضوئيًا أو استخدم الرمز أدناه لتوصيل تطبيق المصادقة الخاص بك."])}
        },
        "afterYouScannedCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بتنشيط المصادقة ذات العاملين عن طريق إدخال رمز المصادقة وتأكيده. يتم إنشاء الرمز بواسطة تطبيق المصادقة."])}
        },
        "authenticatorSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تطبيق المصادقة"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد"])}
        },
        "notification": {
          "fetchSecret": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن تأكيد رمز المصادقة."])}
            }
          }
        }
      },
      "ar-SA": {
        "scanCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["امسح رمز الاستجابة السريعة هذا ضوئيًا أو استخدم الرمز أدناه لتوصيل تطبيق المصادقة الخاص بك."])}
        },
        "afterYouScannedCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بتنشيط المصادقة ذات العاملين عن طريق إدخال رمز المصادقة وتأكيده. يتم إنشاء الرمز بواسطة تطبيق المصادقة."])}
        },
        "authenticatorSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تطبيق المصادقة"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد"])}
        },
        "notification": {
          "fetchSecret": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن تأكيد رمز المصادقة."])}
            }
          }
        }
      },
      "el-GR": {
        "scanCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σαρώστε αυτόν τον κωδικό QR ή χρησιμοποιήστε τον παρακάτω κωδικό για να συνδέσετε την εφαρμογή ελέγχου ταυτότητας."])}
        },
        "afterYouScannedCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενεργοποιήστε τον έλεγχο ταυτότητας δύο παραγόντων εισάγοντας και επιβεβαιώνοντας τον κωδικό ελέγχου ταυτότητας. Ο κώδικας δημιουργείται από την εφαρμογή ελέγχου ταυτότητας."])}
        },
        "authenticatorSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εφαρμογή ελέγχου ταυτότητας"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιβεβαίωση"])}
        },
        "notification": {
          "fetchSecret": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σφάλμα"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν ήταν δυνατή η επιβεβαίωση του κωδικού ελέγχου ταυτότητας."])}
            }
          }
        }
      },
      "el-CY": {
        "scanCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σαρώστε αυτόν τον κωδικό QR ή χρησιμοποιήστε τον παρακάτω κωδικό για να συνδέσετε την εφαρμογή ελέγχου ταυτότητας."])}
        },
        "afterYouScannedCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενεργοποιήστε τον έλεγχο ταυτότητας δύο παραγόντων εισάγοντας και επιβεβαιώνοντας τον κωδικό ελέγχου ταυτότητας. Ο κώδικας δημιουργείται από την εφαρμογή ελέγχου ταυτότητας."])}
        },
        "authenticatorSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εφαρμογή ελέγχου ταυτότητας"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιβεβαίωση"])}
        },
        "notification": {
          "fetchSecret": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σφάλμα"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν ήταν δυνατή η επιβεβαίωση του κωδικού ελέγχου ταυτότητας."])}
            }
          }
        }
      },
      "de-DE": {
        "scanCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanne diesen QR-Code oder verwende den darunter stehenden Code, um deine Authentifizierungs-App zu verbinden."])}
        },
        "afterYouScannedCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviere die Zwei-Faktor-Authentifizierung durch Eingabe und Bestätigung des Authentifizierungscodes. Der Code wird von der Authentifizierungs-App generiert."])}
        },
        "authenticatorSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifizierungs-App"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])}
        },
        "notification": {
          "fetchSecret": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Authentifizierungscode konnte nicht bestätigt werden."])}
            }
          }
        }
      },
      "de-AT": {
        "scanCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanne diesen QR-Code oder verwende den darunter stehenden Code, um deine Authentifizierungs-App zu verbinden."])}
        },
        "afterYouScannedCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviere die Zwei-Faktor-Authentifizierung durch Eingabe und Bestätigung des Authentifizierungscodes. Der Code wird von der Authentifizierungs-App generiert."])}
        },
        "authenticatorSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifizierungs-App"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])}
        },
        "notification": {
          "fetchSecret": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Authentifizierungscode konnte nicht bestätigt werden."])}
            }
          }
        }
      },
      "de-CH": {
        "scanCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanne diesen QR-Code oder verwende den darunter stehenden Code, um deine Authentifizierungs-App zu verbinden."])}
        },
        "afterYouScannedCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviere die Zwei-Faktor-Authentifizierung durch Eingabe und Bestätigung des Authentifizierungscodes. Der Code wird von der Authentifizierungs-App generiert."])}
        },
        "authenticatorSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifizierungs-App"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])}
        },
        "notification": {
          "fetchSecret": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Authentifizierungscode konnte nicht bestätigt werden."])}
            }
          }
        }
      },
      "en-US": {
        "scanCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan this QR code or use the code below to connect your authenticator app."])}
        },
        "afterYouScannedCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate two-factor authentication by entering and confirming the authentication code. The code is generated by the authentication app."])}
        },
        "authenticatorSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticator-App"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])}
        },
        "notification": {
          "fetchSecret": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The authentication code could not be confirmed."])}
            }
          }
        }
      },
      "en-GB": {
        "scanCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan this QR code or use the code below to connect your authenticator app."])}
        },
        "afterYouScannedCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate two-factor authentication by entering and confirming the authentication code. The code is generated by the authentication app."])}
        },
        "authenticatorSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticator-App"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])}
        },
        "notification": {
          "fetchSecret": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The authentication code could not be confirmed."])}
            }
          }
        }
      },
      "it-IT": {
        "scanCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effettua la scansione di questo codice QR o usa il codice di seguito per connetterti alla tua app di autenticazione."])}
        },
        "afterYouScannedCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attiva l'autentificazione a due fattori inserendo e confermando il codice di autenticazione."])}
        },
        "authenticatorSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticator-App"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma"])}
        },
        "notification": {
          "fetchSecret": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è stato possibile confermare il codice di autenticazione."])}
            }
          }
        }
      },
      "fr-FR": {
        "scanCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scannez ce code QR ou utilisez le code ci-dessous pour connecter votre application d'authentification."])}
        },
        "afterYouScannedCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activez l'authentification à deux facteurs en saisissant et en confirmant le code d'authentification. Ce code est généré par l'application d'authentification."])}
        },
        "authenticatorSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentificateur-App"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])}
        },
        "notification": {
          "fetchSecret": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code d'authentification n'a pas pu être confirmé."])}
            }
          }
        }
      },
      "es-ES": {
        "scanCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escanee este código QR o use el código a continuación para conectar su aplicación de autenticación."])}
        },
        "afterYouScannedCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active la autenticación en dos pasos introduciendo y confirmando el código de autenticación. El código lo genera automáticamente la aplicación."])}
        },
        "authenticatorSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicación de autenticación"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])}
        },
        "notification": {
          "fetchSecret": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo confirmar el código de autenticación."])}
            }
          }
        }
      },
      "pl-PL": {
        "scanCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeskanuj ten kod QR lub użyj poniższego kodu, aby połączyć aplikację uwierzytelniającą."])}
        },
        "afterYouScannedCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywuj uwierzytelnianie dwuskładnikowe, wprowadzając i potwierdzając kod uwierzytelniający. Kod jest generowany przez aplikację uwierzytelniającą."])}
        },
        "authenticatorSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikacja Authenticator"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdź"])}
        },
        "notification": {
          "fetchSecret": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błąd"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie udało się potwierdzić kodu uwierzytelniającego."])}
            }
          }
        }
      },
      "nl-NL": {
        "scanCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan deze QR code of gebruik de onderstaande code om uw authenticatie app te verbinden."])}
        },
        "afterYouScannedCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activeer de twee-factor authenticatie door de authenticatiecode in te voeren en te bevestigen. De code wordt gegenereerd door de verificatie-app."])}
        },
        "authenticatorSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticator-App"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestigen"])}
        },
        "notification": {
          "fetchSecret": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De authencitatiecode kon niet worden bevestigd."])}
            }
          }
        }
      },
      "bg-BG": {
        "scanCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сканирайте този QR код или използвайте кода по-долу, за да свържете приложението си за удостоверяване."])}
        },
        "afterYouScannedCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активирайте двуфакторно удостоверяване, като въведете и потвърдите кода за удостоверяване. Кодът се генерира от приложението за удостоверяване."])}
        },
        "authenticatorSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приложение за удостоверяване"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потвърждение"])}
        },
        "notification": {
          "fetchSecret": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["грешка"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кодът за удостоверяване не може да бъде потвърден."])}
            }
          }
        }
      },
      "bs-BA": {
        "scanCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skenirajte ovaj QR kôd ili upotrijebite donji kôd za povezivanje aplikacije za autentifikaciju."])}
        },
        "afterYouScannedCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivirajte dvofaktorsku autentifikaciju unosom i potvrđivanjem autentifikacijskog koda. Kôd generira aplikacija za autentifikaciju."])}
        },
        "authenticatorSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikacija za autentifikaciju"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdite"])}
        },
        "notification": {
          "fetchSecret": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greška"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nije moguće potvrditi autentifikacijski kôd."])}
            }
          }
        }
      },
      "da-DK": {
        "scanCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan denne QR-kode, eller brug koden nedenfor til at forbinde din godkendelsesapp."])}
        },
        "afterYouScannedCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiver tofaktorautentificering ved at indtaste og bekræfte godkendelseskoden. Koden genereres af godkendelsesappen."])}
        },
        "authenticatorSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticator-App"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekræft"])}
        },
        "notification": {
          "fetchSecret": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fejl"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkendelseskoden kunne ikke bekræftes."])}
            }
          }
        }
      },
      "et-EE": {
        "scanCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skannige see QR-kood või kasutage oma autentimisrakenduse ühendamiseks allolevat koodi."])}
        },
        "afterYouScannedCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiveerige kaheastmeline autentimine, sisestades ja kinnitades autentimiskoodi. Koodi genereerib autentimisrakendus."])}
        },
        "authenticatorSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticator-App"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kinnita"])}
        },
        "notification": {
          "fetchSecret": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viga"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentimiskoodi ei õnnestunud kinnitada."])}
            }
          }
        }
      },
      "fi-FI": {
        "scanCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skannaa tämä QR-koodi tai käytä alla olevaa koodia yhdistääksesi todennussovelluksesi."])}
        },
        "afterYouScannedCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivoi kaksivaiheinen todennus syöttämällä ja vahvistamalla todennuskoodi. Koodi on luotu todennussovelluksella."])}
        },
        "authenticatorSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticator-Sovellus"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vahvista"])}
        },
        "notification": {
          "fetchSecret": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virhe"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todennuskoodia ei voitu vahvistaa."])}
            }
          }
        }
      },
      "he-IL": {
        "scanCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סרוק קוד QR זה או השתמש בקוד שלמטה כדי לחבר את אפליקציית האימות שלך."])}
        },
        "afterYouScannedCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הפעל אימות דו-גורמי על ידי הזנת קוד האימות ואישורו. הקוד נוצר על ידי אפליקציית האימות."])}
        },
        "authenticatorSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מאמת-אפליקציה"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["לְאַשֵׁר"])}
        },
        "notification": {
          "fetchSecret": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שְׁגִיאָה"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["לא ניתן היה לאשר את קוד האימות."])}
            }
          }
        }
      },
      "ga-IE": {
        "scanCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan an cód QR seo nó bain úsáid as an gcód thíos chun d’aip fíordheimhnithe a nascadh."])}
        },
        "afterYouScannedCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fíordheimhniú dhá fhachtóir a ghníomhachtú tríd an gcód fíordheimhnithe a iontráil agus a dhearbhú. Gineann an aip fíordheimhnithe an cód."])}
        },
        "authenticatorSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aip fíordheimhnitheora"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deimhnigh"])}
        },
        "notification": {
          "fetchSecret": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earráid"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Níorbh fhéidir an cód fíordheimhnithe a dhearbhú."])}
            }
          }
        }
      },
      "ja-JP": {
        "scanCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この QR コードをスキャンするか、以下のコードを使用して認証アプリに接続します。"])}
        },
        "afterYouScannedCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証コードを入力して確認することで、二要素認証を有効にします。 コードは認証アプリによって生成されます。"])}
        },
        "authenticatorSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証アプリ"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認"])}
        },
        "notification": {
          "fetchSecret": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エラー"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証コードが確認できませんでした。"])}
            }
          }
        }
      },
      "hr-HR": {
        "scanCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skenirajte ovaj QR kôd ili upotrijebite donji kod za povezivanje aplikacije za provjeru autentičnosti."])}
        },
        "afterYouScannedCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivirajte dvofaktorsku autentifikaciju unosom i potvrđivanjem autentifikacijskog koda. Kôd generira aplikacija za provjeru autentičnosti."])}
        },
        "authenticatorSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentifikator-App"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrda"])}
        },
        "notification": {
          "fetchSecret": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pogreška"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nije moguće potvrditi autentifikacijski kod."])}
            }
          }
        }
      },
      "lv-LV": {
        "scanCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skenējiet šo QR kodu vai izmantojiet zemāk esošo kodu, lai izveidotu savienojumu ar savu autentifikācijas lietotni."])}
        },
        "afterYouScannedCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivizējiet divfaktoru autentifikāciju, ievadot un apstiprinot autentifikācijas kodu. Kodu ģenerē autentifikācijas lietotne."])}
        },
        "authenticatorSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentifikators-lietotne"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstiprināt"])}
        },
        "notification": {
          "fetchSecret": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kļūda"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nevarēja apstiprināt autentifikācijas kodu."])}
            }
          }
        }
      },
      "lt-LT": {
        "scanCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuskaitykite šį QR kodą arba naudokite toliau pateiktą kodą, kad susietumėte savo autentifikavimo programą."])}
        },
        "afterYouScannedCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suaktyvinkite dviejų veiksnių autentifikavimą įvesdami ir patvirtindami autentifikavimo kodą. Kodą sugeneruoja autentifikavimo programa."])}
        },
        "authenticatorSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["„Authenticator“ programa"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patvirtinti"])}
        },
        "notification": {
          "fetchSecret": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klaida"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepavyko patvirtinti autentifikavimo kodo."])}
            }
          }
        }
      },
      "pt-PT": {
        "scanCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leia este código QR ou use o código abaixo para ligar a aplicação de autenticação."])}
        },
        "afterYouScannedCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ative a autenticação de dois fatores inserindo e confirmando o código de autenticação. O código é gerado pela aplicação de autenticação."])}
        },
        "authenticatorSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticator-App"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])}
        },
        "notification": {
          "fetchSecret": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não foi possível confirmar o código de autenticação."])}
            }
          }
        }
      },
      "ro-RO": {
        "scanCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanați acest cod QR sau utilizați codul de mai jos pentru a vă conecta aplicația de autentificare."])}
        },
        "afterYouScannedCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activați autentificarea cu doi factori introducând și confirmând codul de autentificare. Codul este generat de aplicația de autentificare."])}
        },
        "authenticatorSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicație de autentificare"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmare"])}
        },
        "notification": {
          "fetchSecret": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eroare"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codul de autentificare nu a putut fi confirmat."])}
            }
          }
        }
      },
      "ru-RU": {
        "scanCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отсканируйте этот QR-код или используйте приведенный ниже код для подключения приложения для аутентификации."])}
        },
        "afterYouScannedCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активируйте двухфакторную аутентификацию, введя и подтвердив код аутентификации. Код генерируется приложением для аутентификации."])}
        },
        "authenticatorSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приложение для аутентификации"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить"])}
        },
        "notification": {
          "fetchSecret": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код аутентификации не подтвержден."])}
            }
          }
        }
      },
      "sv-SE": {
        "scanCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skanna den här QR-koden eller använd koden nedan för att ansluta din autentiseringsapp."])}
        },
        "afterYouScannedCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivera tvåfaktorautentisering genom att ange och bekräfta autentiseringskoden. Koden genereras av autentiseringsappen."])}
        },
        "authenticatorSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentiseringsapp"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekräfta"])}
        },
        "notification": {
          "fetchSecret": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fel"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentiseringskoden kunde inte bekräftas."])}
            }
          }
        }
      },
      "sk-SK": {
        "scanCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naskenujte tento QR kód alebo pomocou kódu nižšie pripojte vašu overovaciu aplikáciu."])}
        },
        "afterYouScannedCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivujte dvojfaktorové overenie zadaním a potvrdením overovacieho kódu. Kód je vygenerovaný overovacou aplikáciou."])}
        },
        "authenticatorSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikácia Authenticator"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdiť"])}
        },
        "notification": {
          "fetchSecret": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overovací kód nebolo možné potvrdiť."])}
            }
          }
        }
      },
      "cs-CZ": {
        "scanCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naskenujte tento QR kód nebo pomocí níže uvedeného kódu připojte svoji ověřovací aplikaci."])}
        },
        "afterYouScannedCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivujte dvoufaktorové ověřování zadáním a potvrzením ověřovacího kódu. Kód je generován ověřovací aplikací."])}
        },
        "authenticatorSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikace Authenticator"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdit"])}
        },
        "notification": {
          "fetchSecret": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nelze potvrdit ověřovací kód."])}
            }
          }
        }
      },
      "hu-HU": {
        "scanCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szkennelje be a QR-kódot, vagy használja az alábbi kódot a hitelesítési alkalmazás csatlakoztatásához."])}
        },
        "afterYouScannedCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiválja a kétfaktoros hitelesítést a hitelesítő kód megadásával és megerősítésével."])}
        },
        "authenticatorSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hitelesítő app"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megerősít"])}
        },
        "notification": {
          "fetchSecret": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiba"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hitelesítő kódot nem sikerült megerősíteni."])}
            }
          }
        }
      },
      "fr-BE": {
        "scanCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scannez ce code QR ou utilisez le code ci-dessous pour connecter votre application d'authentification."])}
        },
        "afterYouScannedCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activez l'authentification à deux facteurs en saisissant et en confirmant le code d'authentification. Ce code est généré par l'application d'authentification."])}
        },
        "authenticatorSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentificateur-App"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])}
        },
        "notification": {
          "fetchSecret": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code d'authentification n'a pas pu être confirmé."])}
            }
          }
        }
      },
      "fr-LU": {
        "scanCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scannez ce code QR ou utilisez le code ci-dessous pour connecter votre application d'authentification."])}
        },
        "afterYouScannedCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activez l'authentification à deux facteurs en saisissant et en confirmant le code d'authentification. Ce code est généré par l'application d'authentification."])}
        },
        "authenticatorSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentificateur-App"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])}
        },
        "notification": {
          "fetchSecret": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code d'authentification n'a pas pu être confirmé."])}
            }
          }
        }
      },
      "mt-MT": {
        "scanCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skennja dan il-kodiċi QR jew uża l-kodiċi hawn taħt biex tikkonnettja l-app ta' awtentikazzjoni tiegħek."])}
        },
        "afterYouScannedCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attiva awtentikazzjoni b'żewġ fatturi billi ddaħħal u tikkonferma l-kodiċi ta' awtentikazzjoni."])}
        },
        "authenticatorSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App tal-Awtentikatur"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikkonferma"])}
        },
        "notification": {
          "fetchSecret": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Żball"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il-kodiċi ta' awtentikazzjoni ma setax jiġi kkonfermat."])}
            }
          }
        }
      },
      "sl-SI": {
        "scanCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optično preberite to kodo QR ali uporabite spodnjo kodo za povezavo aplikacije za preverjanje pristnosti."])}
        },
        "afterYouScannedCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvofaktorsko preverjanje pristnosti aktivirajte tako, da vnesete in potrdite kodo za preverjanje pristnosti. Kodo generira aplikacija za preverjanje pristnosti."])}
        },
        "authenticatorSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikacija za preverjanje pristnosti"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potrdi"])}
        },
        "notification": {
          "fetchSecret": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napaka"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode za preverjanje pristnosti ni bilo mogoče potrditi."])}
            }
          }
        }
      },
      "tr-TR": {
        "scanCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik doğrulama uygulamanıza bağlanmak için bu QR kodunu taratın veya aşağıdaki kodu kullanın."])}
        },
        "afterYouScannedCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik doğrulama kodunu girip onaylayarak iki faktörlü kimlik doğrulamayı etkinleştirin. Kod, kimlik doğrulama uygulaması tarafından oluşturulur."])}
        },
        "authenticatorSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik Doğrulayıcı-Uygulama"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onayla"])}
        },
        "notification": {
          "fetchSecret": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik doğrulama kodu onaylanamadı."])}
            }
          }
        }
      },
      "tr-CY": {
        "scanCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik doğrulama uygulamanıza bağlanmak için bu QR kodunu taratın veya aşağıdaki kodu kullanın."])}
        },
        "afterYouScannedCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik doğrulama kodunu girip onaylayarak iki faktörlü kimlik doğrulamayı etkinleştirin. Kod, kimlik doğrulama uygulaması tarafından oluşturulur."])}
        },
        "authenticatorSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik Doğrulayıcı-Uygulama"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onayla"])}
        },
        "notification": {
          "fetchSecret": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik doğrulama kodu onaylanamadı."])}
            }
          }
        }
      },
      "uk-UA": {
        "scanCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanne diesen QR-Code oder verwende den darunter stehenden Code, um deine Authentifizierungs-App zu verbinden."])}
        },
        "afterYouScannedCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviere die Zwei-Faktor-Authentifizierung durch Eingabe und Bestätigung des Authentifizierungscodes. Der Code wird von der Authentifizierungs-App generiert."])}
        },
        "authenticatorSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifizierungs-App"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтвердити"])}
        },
        "notification": {
          "fetchSecret": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося підтвердити код автентифікації."])}
            }
          }
        }
      },
      "zh-CN": {
        "scanCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["扫描此二维码或使用以下代码连接您的身份验证应用程序。"])}
        },
        "afterYouScannedCode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入并确认身份验证码以激活双重身份验证。该代码由身份验证应用程序生成。"])}
        },
        "authenticatorSetupForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身份验证器应用"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认"])}
        },
        "notification": {
          "fetchSecret": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["错误"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法确认验证码。"])}
            }
          }
        }
      }
    }
  })
}
