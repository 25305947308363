<script setup>
import { defineEmits, defineProps, inject } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const composables = inject("composables");
const emit = defineEmits(["update", "trigger-authenticate"]);
const props = defineProps({
  modelValue: { type: Object, required: true },
});
const loginData = composables.useTwoWayBinding(props, emit, "modelValue");

async function verifyAuthenticator() {
  loginData.value.mode = "authenticator";
  emit("trigger-authenticate");
}
</script>
<script>
export default {
  name: "AuthenticatorOTPForm",
};
</script>

<template>
  <div>
    <h2>{{ t("authenticatorOTPForm.title") }}</h2>

    <DpForm
      v-model="loginData"
      hide-required-asterisk
      reference="verify-authenticator"
      :action="verifyAuthenticator"
      :primaryButtonLabel="t('confirmCode.buttonLabel')"
      :primaryButtonParameter="{
        class: ['full-width'],
        size: 'large',
      }"
      disable-notification>

      <p>{{ t("unlockWith.label") }}</p>

      <DpInputMultiFactorAuthenticationCode
        v-model="loginData.authenticatorOTP"
        prop="authenticatorOTP"
        :input-parameters="{ size: 'large' }"
        required/>
    </DpForm>
  </div>
</template>

<i18n>
{
  "ar-AE": {
    "unlockWith": {
      "label": "أدخل وتأكد من استلام رمز المصادقة."
    },
    "confirmCode": {
      "buttonLabel": "تأكيد"
    },
    "authenticatorOTPForm": {
      "title": "رمز المصادقة عبر تطبيق المصادقة"
    }
  },
  "ar-EG": {
    "unlockWith": {
      "label": "أدخل وتأكد من استلام رمز المصادقة."
    },
    "confirmCode": {
      "buttonLabel": "تأكيد"
    },
    "authenticatorOTPForm": {
      "title": "رمز المصادقة عبر تطبيق المصادقة"
    }
  },
  "ar-SA": {
    "unlockWith": {
      "label": "أدخل وتأكد من استلام رمز المصادقة."
    },
    "confirmCode": {
      "buttonLabel": "تأكيد"
    },
    "authenticatorOTPForm": {
      "title": "رمز المصادقة عبر تطبيق المصادقة"
    }
  },
  "el-GR": {
    "unlockWith": {
      "label": "Εισαγάγετε και επιβεβαιώστε τον κωδικό ελέγχου ταυτότητας που λάβατε."
    },
    "confirmCode": {
      "buttonLabel": "Επιβεβαίωση"
    },
    "authenticatorOTPForm": {
      "title": "Κωδικός ελέγχου ταυτότητας μέσω εφαρμογής ελέγχου ταυτότητας"
    }
  },
  "el-CY": {
    "unlockWith": {
      "label": "Εισαγάγετε και επιβεβαιώστε τον κωδικό ελέγχου ταυτότητας που λάβατε."
    },
    "confirmCode": {
      "buttonLabel": "Επιβεβαίωση"
    },
    "authenticatorOTPForm": {
      "title": "Κωδικός ελέγχου ταυτότητας μέσω εφαρμογής ελέγχου ταυτότητας"
    }
  },
  "de-DE": {
    "unlockWith": {
      "label": "Empfangenen Authentifizierungscode eingeben und bestätigen."
    },
    "confirmCode": {
      "buttonLabel": "Bestätigen"
    },
    "authenticatorOTPForm": {
      "title": "Authentifizierungscode per Authentifizierungs-App"
    }
  },
  "de-AT": {
    "unlockWith": {
      "label": "Empfangenen Authentifizierungscode eingeben und bestätigen."
    },
    "confirmCode": {
      "buttonLabel": "Bestätigen"
    },
    "authenticatorOTPForm": {
      "title": "Authentifizierungscode per Authentifizierungs-App"
    }
  },
  "de-CH": {
    "unlockWith": {
      "label": "Empfangenen Authentifizierungscode eingeben und bestätigen."
    },
    "confirmCode": {
      "buttonLabel": "Bestätigen"
    },
    "authenticatorOTPForm": {
      "title": "Authentifizierungscode per Authentifizierungs-App"
    }
  },
  "en-US": {
    "unlockWith": {
      "label": "Enter and confirm the authentication code received."
    },
    "confirmCode": {
      "buttonLabel": "Confirm"
    },
    "authenticatorOTPForm": {
      "title": "Authentication code by authentication app"
    }
  },
  "en-GB": {
    "unlockWith": {
      "label": "Enter and confirm the authentication code received."
    },
    "confirmCode": {
      "buttonLabel": "Confirm"
    },
    "authenticatorOTPForm": {
      "title": "Authentication code by authentication app"
    }
  },
  "it-IT": {
    "unlockWith": {
      "label": "Inserisci e conferma il codice di autenticazione ricevuto."
    },
    "confirmCode": {
      "buttonLabel": "Conferma"
    },
    "authenticatorOTPForm": {
      "title": "Codice di autenticazione tramite app di autenticazione"
    }
  },
  "fr-FR": {
    "unlockWith": {
      "label": "Entrez et confirmez le code d'authentification reçu."
    },
    "confirmCode": {
      "buttonLabel": "Confirmer"
    },
    "authenticatorOTPForm": {
      "title": "Code d'authentification via l'application d'authentification"
    }
  },
  "es-ES": {
    "unlockWith": {
      "label": "Introduzca y confirme el código de autenticación recibido."
    },
    "confirmCode": {
      "buttonLabel": "Confirmar"
    },
    "authenticatorOTPForm": {
      "title": "Código de autenticación a través de la aplicación de autenticación"
    }
  },
  "pl-PL": {
    "unlockWith": {
      "label": "Wprowadź i potwierdź otrzymany kod uwierzytelniający."
    },
    "confirmCode": {
      "buttonLabel": "Potwierdź"
    },
    "authenticatorOTPForm": {
      "title": "Kod uwierzytelniający za pośrednictwem aplikacji uwierzytelniającej"
    }
  },
  "nl-NL": {
    "unlockWith": {
      "label": "Voer de ontvangen authenticatiecode in en bevestig deze."
    },
    "confirmCode": {
      "buttonLabel": "Bevestigen"
    },
    "authenticatorOTPForm": {
      "title": "Authenticatiecode via authenticatie-app"
    }
  },
  "bg-BG": {
    "unlockWith": {
      "label": "Въведете и потвърдете получения код за удостоверяване."
    },
    "confirmCode": {
      "buttonLabel": "Потвърждение"
    },
    "authenticatorOTPForm": {
      "title": "Код за удостоверяване чрез приложение за удостоверяване"
    }
  },
  "bs-BA": {
    "unlockWith": {
      "label": "Unesite i potvrdite primljeni kod za autentifikaciju."
    },
    "confirmCode": {
      "buttonLabel": "Potvrdite"
    },
    "authenticatorOTPForm": {
      "title": "Kôd za provjeru autentičnosti putem aplikacije za provjeru autentičnosti"
    }
  },
  "da-DK": {
    "unlockWith": {
      "label": "Indtast og bekræft den modtagne godkendelseskode."
    },
    "confirmCode": {
      "buttonLabel": "Bekræft"
    },
    "authenticatorOTPForm": {
      "title": "Godkendelseskode via godkendelsesapp"
    }
  },
  "et-EE": {
    "unlockWith": {
      "label": "Sisestage ja kinnitage saadud autentimiskood."
    },
    "confirmCode": {
      "buttonLabel": "Kinnita"
    },
    "authenticatorOTPForm": {
      "title": "Autentimiskood autentimisrakenduse kaudu"
    }
  },
  "fi-FI": {
    "unlockWith": {
      "label": "Syötä ja vahvista vastaanotettu todennuskoodi."
    },
    "confirmCode": {
      "buttonLabel": "Vahvista"
    },
    "authenticatorOTPForm": {
      "title": "Todennuskoodi todennussovelluksen kautta"
    }
  },
  "he-IL": {
    "unlockWith": {
      "label": "הזן ואשר את קוד האימות שהתקבל."
    },
    "confirmCode": {
      "buttonLabel": "לְאַשֵׁר"
    },
    "authenticatorOTPForm": {
      "title": "קוד אימות באמצעות אפליקציית אימות"
    }
  },
  "ga-IE": {
    "unlockWith": {
      "label": "Cuir isteach agus deimhnigh an cód fíordheimhnithe a fuarthas."
    },
    "confirmCode": {
      "buttonLabel": "Deimhnigh"
    },
    "authenticatorOTPForm": {
      "title": "Cód fíordheimhnithe trí aip fíordheimhnithe"
    }
  },
  "ja-JP": {
    "unlockWith": {
      "label": "受信した認証コードを入力して確認します。"
    },
    "confirmCode": {
      "buttonLabel": "確認"
    },
    "authenticatorOTPForm": {
      "title": "認証アプリによる認証コード"
    }
  },
  "hr-HR": {
    "unlockWith": {
      "label": "Unesite i potvrdite primljeni autentifikacijski kod."
    },
    "confirmCode": {
      "buttonLabel": "Potvrda"
    },
    "authenticatorOTPForm": {
      "title": "Kôd za provjeru autentičnosti putem aplikacije za provjeru autentičnosti"
    }
  },
  "lv-LV": {
    "unlockWith": {
      "label": "Ievadiet un apstipriniet saņemto autentifikācijas kodu."
    },
    "confirmCode": {
      "buttonLabel": "Apstiprināt"
    },
    "authenticatorOTPForm": {
      "title": "Autentifikācijas kods, izmantojot autentifikācijas lietotni"
    }
  },
  "lt-LT": {
    "unlockWith": {
      "label": "Įveskite ir patvirtinkite gautą autentifikavimo kodą."
    },
    "confirmCode": {
      "buttonLabel": "Patvirtinti"
    },
    "authenticatorOTPForm": {
      "title": "Autentifikavimo kodas per autentifikavimo programą"
    }
  },
  "pt-PT": {
    "unlockWith": {
      "label": "Digite e confirme o código de autenticação recebido."
    },
    "confirmCode": {
      "buttonLabel": "Confirmar"
    },
    "authenticatorOTPForm": {
      "title": "Código de autenticação via aplicativo de autenticação"
    }
  },
  "ro-RO": {
    "unlockWith": {
      "label": "Introduceți și confirmați codul de autentificare primit."
    },
    "confirmCode": {
      "buttonLabel": "Confirmare"
    },
    "authenticatorOTPForm": {
      "title": "Cod de autentificare prin intermediul aplicației de autentificare"
    }
  },
  "ru-RU": {
    "unlockWith": {
      "label": "Введите и подтвердите полученный код аутентификации."
    },
    "confirmCode": {
      "buttonLabel": "Подтвердить"
    },
    "authenticatorOTPForm": {
      "title": "Код аутентификации через приложение аутентификации"
    }
  },
  "sv-SE": {
    "unlockWith": {
      "label": "Ange och bekräfta den mottagna autentiseringskoden."
    },
    "confirmCode": {
      "buttonLabel": "Bekräfta"
    },
    "authenticatorOTPForm": {
      "title": "Autentiseringskod via autentiseringsapp"
    }
  },
  "sk-SK": {
    "unlockWith": {
      "label": "Zadajte a potvrďte prijatý overovací kód."
    },
    "confirmCode": {
      "buttonLabel": "Potvrdiť"
    },
    "authenticatorOTPForm": {
      "title": "Autentifikačný kód prostredníctvom autentifikačnej aplikácie"
    }
  },
  "cs-CZ": {
    "unlockWith": {
      "label": "Zadejte a potvrďte přijatý ověřovací kód."
    },
    "confirmCode": {
      "buttonLabel": "Potvrdit"
    },
    "authenticatorOTPForm": {
      "title": "Ověřovací kód prostřednictvím ověřovací aplikace"
    }
  },
  "hu-HU": {
    "unlockWith": {
      "label": "Írja be és erősítse meg a kapott hitelesítési kódot."
    },
    "confirmCode": {
      "buttonLabel": "Megerősít"
    },
    "authenticatorOTPForm": {
      "title": "Hitelesítési kód a hitelesítési alkalmazáson keresztül"
    }
  },
  "fr-BE": {
    "unlockWith": {
      "label": "Entrez et confirmez le code d'authentification reçu."
    },
    "confirmCode": {
      "buttonLabel": "Confirmer"
    },
    "authenticatorOTPForm": {
      "title": "Code d'authentification via l'application d'authentification"
    }
  },
  "fr-LU": {
    "unlockWith": {
      "label": "Entrez et confirmez le code d'authentification reçu."
    },
    "confirmCode": {
      "buttonLabel": "Confirmer"
    },
    "authenticatorOTPForm": {
      "title": "Code d'authentification via l'application d'authentification"
    }
  },
  "mt-MT": {
    "unlockWith": {
      "label": "Daħħal u kkonferma l-kodiċi ta 'awtentikazzjoni riċevut."
    },
    "confirmCode": {
      "buttonLabel": "Ikkonferma"
    },
    "authenticatorOTPForm": {
      "title": "Kodiċi ta 'awtentikazzjoni permezz ta' app ta 'awtentikazzjoni"
    }
  },
  "sl-SI": {
    "unlockWith": {
      "label": "Vnesite in potrdite prejeto kodo za preverjanje pristnosti."
    },
    "confirmCode": {
      "buttonLabel": "Potrdi"
    },
    "authenticatorOTPForm": {
      "title": "Koda za preverjanje pristnosti prek aplikacije za preverjanje pristnosti"
    }
  },
  "tr-TR": {
    "unlockWith": {
      "label": "Alınan doğrulama kodunu girin ve onaylayın."
    },
    "confirmCode": {
      "buttonLabel": "Onayla"
    },
    "authenticatorOTPForm": {
      "title": "Kimlik doğrulama uygulaması aracılığıyla kimlik doğrulama kodu"
    }
  },
  "tr-CY": {
    "unlockWith": {
      "label": "Alınan doğrulama kodunu girin ve onaylayın."
    },
    "confirmCode": {
      "buttonLabel": "Onayla"
    },
    "authenticatorOTPForm": {
      "title": "Kimlik doğrulama uygulaması aracılığıyla kimlik doğrulama kodu"
    }
  },
  "uk-UA": {
    "unlockWith": {
      "label": "Введіть і підтвердьте отриманий код автентифікації."
    },
    "confirmCode": {
      "buttonLabel": "Підтвердити"
    },
    "authenticatorOTPForm": {
      "title": "Код автентифікації через додаток автентифікатора"
    }
  },
  "zh-CN": {
    "unlockWith": {
      "label": "输入并确认收到的验证码。"
    },
    "confirmCode": {
      "buttonLabel": "确认"
    },
    "authenticatorOTPForm": {
      "title": "通过身份验证应用程序的身份验证代码"
    }
  }
}
</i18n>
