<script setup>
import { defineEmits, defineProps, inject, computed } from "vue";
import { useI18n } from "vue-i18n";
import { get } from "lodash";

const composables = inject("composables");
const { isMobile } = composables.useViewportSize();
const { t } = useI18n();
const emit = defineEmits(['update', 'go-to']);
const props = defineProps({
  modelValue: { type: Object, required: true },
});

const hasAuthenticatorSetup = computed(() => {
  return get(props.modelValue, "mfaModes.authenticator", false);
});
const hasSMSSetup = computed(() => {
  return get(props.modelValue, "mfaModes.sms", false);
});
const hasNoMFA = computed(() => {
  return !hasAuthenticatorSetup.value && !hasSMSSetup.value;
});

function goTo(component) {
  emit("go-to", component);
}
</script>
<script>
export default {
  name: "MFAChoice"
};
</script>

<template>
  <div>
    <h2>{{ t("MFAChoice.title") }}</h2>
    <p>{{ t("explanationMFAChoice.label") }}</p>

    <div
      v-if="hasNoMFA || hasAuthenticatorSetup"
      class="button-area dp-flex row align-items__center"
      tabindex="0"
      @click="hasAuthenticatorSetup ? goTo('AuthenticatorOTPForm') : goTo('AuthenticatorSetupForm')"
      @keypress="
        hasAuthenticatorSetup ? goTo('AuthenticatorOTPForm') : goTo('AuthenticatorSetupForm')
      "
    >
      <div v-if="!isMobile" class="icon">
        <DpIcon icon="mobile" :margin="false" />
      </div>
      <div class="flex-basis-1 button-content">
        <div class="headline dp-flex row">
          <DpIcon v-if="isMobile" icon="mobile" />{{ t("authenticatorMFA.label") }}
        </div>
        <div v-if="hasAuthenticatorSetup" class="dp-color__font__success dp-flex row">
          <span><DpIcon icon="check" /></span>
          <span>{{ t("feedforward.authenticatorMFA.success.message") }}</span>
        </div>
        <div>{{ t("authenticatorMFA.description.label") }}</div>
      </div>
    </div>

    <div
      v-if="hasNoMFA || hasSMSSetup"
      class="button-area dp-flex row align-items__center"
      tabindex="0"
      @click="hasSMSSetup ? goTo('SMSOTPForm') : goTo('SMSSetupForm')"
      @keypress="hasSMSSetup ? goTo('SMSOTPForm') : goTo('SMSSetupForm')"
    >
      <div v-if="!isMobile" class="icon">
        <DpIcon icon="mobile" :margin="false" />
      </div>
      <div class="flex-basis-1 button-content">
        <div class="headline dp-flex row">
          <DpIcon v-if="isMobile" icon="mobile" />{{ t("SMSMFA.label") }}
        </div>
        <div v-if="hasSMSSetup" class="dp-color__font__success dp-flex row">
          <span><DpIcon icon="check" /></span>
          <span>{{ t("feedforward.SMSMFA.success.message") }}</span>
        </div>
        <div>{{ t("SMSMFA.description.label") }}</div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.button-area {
  border-radius: 6px;
  padding: 0.4em 0.8em;
  margin: 2rem 0;

  &:hover,
  &:focus {
    background-color: #515151;
    cursor: pointer;
  }

  .button-content {
    padding: 0.4em 1.2em 0.8em 1.2em;

    .headline {
      font-size: 1.8em;
    }

    div {
      padding: 0.5rem 0;
    }
  }

  .icon {
    margin: 0.4em 0.2em;
    font-size: 3em;
  }
}

.skip {
  text-align: center;
  margin: 2rem 0;
}

@media only screen and (max-width: 767px) {
  //xs-only
  .button-area {
    padding: 0.2em 1.2em;
    background-color: #515151;

    .button-content {
      padding: 0.4em 0 0.8em 0;
    }
  }
}
</style>

<i18n>
{
  "ar-AE": {
    "authenticatorMFA": {
      "label": "تطبيق المصادقة",
      "description": {
        "label": "احصل على رمز المصادقة لتسجيل الدخول من تطبيق المصادقة الخاص بك."
      },
      "feedforward": {
        "success": {
          "message": "تم التنشيط"
        }
      }
    },
    "SMSMFA": {
      "label": "رسالة قصيرة",
      "description": {
        "label": "احصل على رمز المصادقة للمصادقة متعددة الخطوات عبر الرسائل القصيرة."
      },
      "feedforward": {
        "success": {
          "message": "تم التنشيط"
        }
      }
    },
    "explanationMFAChoice": {
      "label": "قم بزيادة الأمان باستخدام المصادقة متعددة العوامل عند تسجيل الدخول."
    },
    "MFAChoice": {
      "title": "مصادقة متعددة العوامل"
    },
    "feedforward": {
      "authenticatorMFA": {
        "success": {
          "message": "تم التنشيط"
        }
      },
      "SMSMFA": {
        "success": {
          "message": "تم التنشيط"
        }
      }
    }
  },
  "ar-EG": {
    "authenticatorMFA": {
      "label": "تطبيق المصادقة",
      "description": {
        "label": "احصل على رمز المصادقة لتسجيل الدخول من تطبيق المصادقة الخاص بك."
      },
      "feedforward": {
        "success": {
          "message": "تم التنشيط"
        }
      }
    },
    "SMSMFA": {
      "label": "رسالة قصيرة",
      "description": {
        "label": "استلام رمز المصادقة لتسجيل الدخول عبر الرسائل القصيرة."
      },
      "feedforward": {
        "success": {
          "message": "تم التنشيط"
        }
      }
    },
    "explanationMFAChoice": {
      "label": "قم بزيادة الأمان باستخدام المصادقة متعددة العوامل عند تسجيل الدخول."
    },
    "MFAChoice": {
      "title": "مصادقة متعددة العوامل"
    },
    "feedforward": {
      "authenticatorMFA": {
        "success": {
          "message": "تم التنشيط"
        }
      },
      "SMSMFA": {
        "success": {
          "message": "تم التنشيط"
        }
      }
    }
  },
  "ar-SA": {
    "authenticatorMFA": {
      "label": "تطبيق المصادقة",
      "description": {
        "label": "احصل على رمز المصادقة لتسجيل الدخول من تطبيق المصادقة الخاص بك."
      },
      "feedforward": {
        "success": {
          "message": "تم التنشيط"
        }
      }
    },
    "SMSMFA": {
      "label": "رسالة قصيرة",
      "description": {
        "label": "استلام رمز المصادقة لتسجيل الدخول عبر الرسائل القصيرة."
      },
      "feedforward": {
        "success": {
          "message": "تم التنشيط"
        }
      }
    },
    "explanationMFAChoice": {
      "label": "قم بزيادة الأمان باستخدام المصادقة متعددة العوامل عند تسجيل الدخول."
    },
    "MFAChoice": {
      "title": "مصادقة متعددة العوامل"
    },
    "feedforward": {
      "authenticatorMFA": {
        "success": {
          "message": "تم التنشيط"
        }
      },
      "SMSMFA": {
        "success": {
          "message": "تم التنشيط"
        }
      }
    }
  },
  "el-GR": {
    "authenticatorMFA": {
      "label": "Εφαρμογή ελέγχου ταυτότητας",
      "description": {
        "label": "Λάβετε τον κωδικό ελέγχου ταυτότητας για σύνδεση από την εφαρμογή ελέγχου ταυτότητας."
      },
      "feedforward": {
        "success": {
          "message": "Ενεργοποιήθηκε"
        }
      }
    },
    "SMSMFA": {
      "label": "γραπτό μήνυμα",
      "description": {
        "label": "Λάβετε κωδικό ελέγχου ταυτότητας για έλεγχο ταυτότητας πολλαπλών βημάτων μέσω SMS."
      },
      "feedforward": {
        "success": {
          "message": "Ενεργοποιήθηκε"
        }
      }
    },
    "explanationMFAChoice": {
      "label": "Αυξήστε την ασφάλεια με έλεγχο ταυτότητας πολλών παραγόντων κατά τη σύνδεση."
    },
    "MFAChoice": {
      "title": "Έλεγχος ταυτότητας πολλών παραγόντων"
    },
    "feedforward": {
      "authenticatorMFA": {
        "success": {
          "message": "Ενεργοποιήθηκε"
        }
      },
      "SMSMFA": {
        "success": {
          "message": "Ενεργοποιήθηκε"
        }
      }
    }
  },
  "el-CY": {
    "authenticatorMFA": {
      "label": "Εφαρμογή ελέγχου ταυτότητας",
      "description": {
        "label": "Λάβετε τον κωδικό ελέγχου ταυτότητας για σύνδεση από την εφαρμογή ελέγχου ταυτότητας."
      },
      "feedforward": {
        "success": {
          "message": "Ενεργοποιήθηκε"
        }
      }
    },
    "SMSMFA": {
      "label": "γραπτό μήνυμα",
      "description": {
        "label": "Λάβετε κωδικό ελέγχου ταυτότητας για έλεγχο ταυτότητας πολλαπλών βημάτων μέσω SMS."
      },
      "feedforward": {
        "success": {
          "message": "Ενεργοποιήθηκε"
        }
      }
    },
    "explanationMFAChoice": {
      "label": "Αυξήστε την ασφάλεια με έλεγχο ταυτότητας πολλών παραγόντων κατά τη σύνδεση."
    },
    "MFAChoice": {
      "title": "Έλεγχος ταυτότητας πολλών παραγόντων"
    },
    "feedforward": {
      "authenticatorMFA": {
        "success": {
          "message": "Ενεργοποιήθηκε"
        }
      },
      "SMSMFA": {
        "success": {
          "message": "Ενεργοποιήθηκε"
        }
      }
    }
  },
  "de-DE": {
    "authenticatorMFA": {
      "label": "Authentifizierungs-App",
      "description": {
        "label": "Erhalte den Authentifizierungscode für den Login per Authentifizierungs-App."
      },
      "feedforward": {
        "success": {
          "message": "Aktiviert"
        }
      }
    },
    "SMSMFA": {
      "label": "SMS",
      "description": {
        "label": "Authentifizierungscode für die Multi-Faktor-Authentifizierung per SMS erhalten."
      },
      "feedforward": {
        "success": {
          "message": "Aktiviert"
        }
      }
    },
    "explanationMFAChoice": {
      "label": "Erhöhe die Sicherheit durch Multi-Faktor-Authentifizierung beim Login."
    },
    "MFAChoice": {
      "title": "Multi-Faktor-Authentifizierung"
    },
    "feedforward": {
      "authenticatorMFA": {
        "success": {
          "message": "Aktiviert"
        }
      },
      "SMSMFA": {
        "success": {
          "message": "Aktiviert"
        }
      }
    }
  },
  "de-AT": {
    "authenticatorMFA": {
      "label": "Authentifizierungs-App",
      "description": {
        "label": "Erhalte den Authentifizierungscode für den Login per Authentifizierungs-App."
      },
      "feedforward": {
        "success": {
          "message": "Aktiviert"
        }
      }
    },
    "SMSMFA": {
      "label": "SMS",
      "description": {
        "label": "Authentifizierungscode für die Multi-Faktor-Authentifizierung per SMS erhalten."
      },
      "feedforward": {
        "success": {
          "message": "Aktiviert"
        }
      }
    },
    "explanationMFAChoice": {
      "label": "Erhöhe die Sicherheit durch Multi-Faktor-Authentifizierung beim Login."
    },
    "MFAChoice": {
      "title": "Multi-Faktor-Authentifizierung"
    },
    "feedforward": {
      "authenticatorMFA": {
        "success": {
          "message": "Aktiviert"
        }
      },
      "SMSMFA": {
        "success": {
          "message": "Aktiviert"
        }
      }
    }
  },
  "de-CH": {
    "authenticatorMFA": {
      "label": "Authentifizierungs-App",
      "description": {
        "label": "Erhalte den Authentifizierungscode für den Login per Authentifizierungs-App."
      },
      "feedforward": {
        "success": {
          "message": "Aktiviert"
        }
      }
    },
    "SMSMFA": {
      "label": "SMS",
      "description": {
        "label": "Authentifizierungscode für die Multi-Faktor-Authentifizierung per SMS erhalten."
      },
      "feedforward": {
        "success": {
          "message": "Aktiviert"
        }
      }
    },
    "explanationMFAChoice": {
      "label": "Erhöhe die Sicherheit durch Multi-Faktor-Authentifizierung beim Login."
    },
    "MFAChoice": {
      "title": "Multi-Faktor-Authentifizierung"
    },
    "feedforward": {
      "authenticatorMFA": {
        "success": {
          "message": "Aktiviert"
        }
      },
      "SMSMFA": {
        "success": {
          "message": "Aktiviert"
        }
      }
    }
  },
  "en-US": {
    "authenticatorMFA": {
      "label": "Authenticator-App",
      "description": {
        "label": "Get your authentication code for login by authentication app."
      },
      "feedforward": {
        "success": {
          "message": "Activated"
        }
      }
    },
    "SMSMFA": {
      "label": "SMS",
      "description": {
        "label": "Receive authentication code for multi-factor authentication via SMS."
      },
      "feedforward": {
        "success": {
          "message": "Activated"
        }
      }
    },
    "explanationMFAChoice": {
      "label": "Increase security with multi-factor authentication for login."
    },
    "MFAChoice": {
      "title": "Multi-Factor Authentication"
    },
    "feedforward": {
      "authenticatorMFA": {
        "success": {
          "message": "Activated"
        }
      },
      "SMSMFA": {
        "success": {
          "message": "Activated"
        }
      }
    }
  },
  "en-GB": {
    "authenticatorMFA": {
      "label": "Authenticator-App",
      "description": {
        "label": "Get your authentication code for login by authentication app."
      },
      "feedforward": {
        "success": {
          "message": "Activated"
        }
      }
    },
    "SMSMFA": {
      "label": "SMS",
      "description": {
        "label": "Receive authentication code for multi-factor authentication via SMS."
      },
      "feedforward": {
        "success": {
          "message": "Activated"
        }
      }
    },
    "explanationMFAChoice": {
      "label": "Increase security with multi-factor authentication for login."
    },
    "MFAChoice": {
      "title": "Multi-Factor Authentication"
    },
    "feedforward": {
      "authenticatorMFA": {
        "success": {
          "message": "Activated"
        }
      },
      "SMSMFA": {
        "success": {
          "message": "Activated"
        }
      }
    }
  },
  "it-IT": {
    "authenticatorMFA": {
      "label": "Authenticator-App",
      "description": {
        "label": "Ricevi il codice di autenticazione per il login mediante l'app di autenticazione."
      },
      "feedforward": {
        "success": {
          "message": "Attivato"
        }
      }
    },
    "SMSMFA": {
      "label": "SMS",
      "description": {
        "label": "Ricevi codice di autenticazione per l'autenticazione a più fattori via SMS"
      },
      "feedforward": {
        "success": {
          "message": "Attivato"
        }
      }
    },
    "explanationMFAChoice": {
      "label": "Aumenta la sicurezza con l'autenticazione a più fattori durante l'accesso."
    },
    "MFAChoice": {
      "title": "Autenticazione a più fattori"
    },
    "feedforward": {
      "authenticatorMFA": {
        "success": {
          "message": "Attivato"
        }
      },
      "SMSMFA": {
        "success": {
          "message": "Attivato"
        }
      }
    }
  },
  "fr-FR": {
    "authenticatorMFA": {
      "label": "Authentificateur-App",
      "description": {
        "label": "Obtenez votre code d'authentification pour la connexion par l'application d'authentification."
      },
      "feedforward": {
        "success": {
          "message": "Activé"
        }
      }
    },
    "SMSMFA": {
      "label": "SMS",
      "description": {
        "label": "Recevez le code d'authentification pour l'authentification multifacteur par SMS."
      },
      "feedforward": {
        "success": {
          "message": "Activé"
        }
      }
    },
    "explanationMFAChoice": {
      "label": "Renforcez la sécurité grâce à l'authentification multifactorielle pour la connexion."
    },
    "MFAChoice": {
      "title": "Authentification multi-facteurs"
    },
    "feedforward": {
      "authenticatorMFA": {
        "success": {
          "message": "Activé"
        }
      },
      "SMSMFA": {
        "success": {
          "message": "Activé"
        }
      }
    }
  },
  "es-ES": {
    "authenticatorMFA": {
      "label": "Aplicación de autenticación",
      "description": {
        "label": "Reciba el código de autenticación para iniciar sesión en la aplicación de autenticación."
      },
      "feedforward": {
        "success": {
          "message": "Activada"
        }
      }
    },
    "SMSMFA": {
      "label": "SMS",
      "description": {
        "label": "Reciba el código de autenticación para la autenticación de varios pasos por SMS."
      },
      "feedforward": {
        "success": {
          "message": "Activada"
        }
      }
    },
    "explanationMFAChoice": {
      "label": "Aumente la seguridad de su cuenta con la autenticación multifactor para iniciar sesión."
    },
    "MFAChoice": {
      "title": "Autenticación multifactor"
    },
    "feedforward": {
      "authenticatorMFA": {
        "success": {
          "message": "Activada"
        }
      },
      "SMSMFA": {
        "success": {
          "message": "Activada"
        }
      }
    }
  },
  "pl-PL": {
    "authenticatorMFA": {
      "label": "Aplikacja Authenticator",
      "description": {
        "label": "Odbierz kod uwierzytelniający do logowania z aplikacji uwierzytelniającej."
      },
      "feedforward": {
        "success": {
          "message": "Aktywowano"
        }
      }
    },
    "SMSMFA": {
      "label": "SMS",
      "description": {
        "label": "Odbierz kod uwierzytelniający do wieloetapowej autoryzacji przez SMS."
      },
      "feedforward": {
        "success": {
          "message": "Aktywowano"
        }
      }
    },
    "explanationMFAChoice": {
      "label": "Zwiększ bezpieczeństwo dzięki uwierzytelnianiu wieloskładnikowemu podczas logowania."
    },
    "MFAChoice": {
      "title": "Uwierzytelnianie wieloskładnikowe"
    },
    "feedforward": {
      "authenticatorMFA": {
        "success": {
          "message": "Aktywowano"
        }
      },
      "SMSMFA": {
        "success": {
          "message": "Aktywowano"
        }
      }
    }
  },
  "nl-NL": {
    "authenticatorMFA": {
      "label": "Authenticator-App",
      "description": {
        "label": "Ontvang uw authenticatie code bij het inloggen via de authenticatie app."
      },
      "feedforward": {
        "success": {
          "message": "Geactiveerd"
        }
      }
    },
    "SMSMFA": {
      "label": "SMS",
      "description": {
        "label": "Ontvang authenticatiecode voor meerstapsauthenticatie via sms."
      },
      "feedforward": {
        "success": {
          "message": "Geactiveerd"
        }
      }
    },
    "explanationMFAChoice": {
      "label": "Verhoog de veiligheid met multifactorauthenticatie bij het aanmelden."
    },
    "MFAChoice": {
      "title": "Multi-Factor Authenticatie"
    },
    "feedforward": {
      "authenticatorMFA": {
        "success": {
          "message": "Geactiveerd"
        }
      },
      "SMSMFA": {
        "success": {
          "message": "Geactiveerd"
        }
      }
    }
  },
  "bg-BG": {
    "authenticatorMFA": {
      "label": "Приложение за удостоверяване",
      "description": {
        "label": "Получете кода за удостоверяване за влизане от приложението за удостоверяване."
      },
      "feedforward": {
        "success": {
          "message": "Активирано"
        }
      }
    },
    "SMSMFA": {
      "label": "SMS",
      "description": {
        "label": "Получаване на код за удостоверяване за многоетапно удостоверяване чрез SMS."
      },
      "feedforward": {
        "success": {
          "message": "Активирано"
        }
      }
    },
    "explanationMFAChoice": {
      "label": "Увеличете защитата с многофакторно удостоверяване при влизане."
    },
    "MFAChoice": {
      "title": "Многофакторно удостоверяване"
    },
    "feedforward": {
      "authenticatorMFA": {
        "success": {
          "message": "Активирано"
        }
      },
      "SMSMFA": {
        "success": {
          "message": "Активирано"
        }
      }
    }
  },
  "bs-BA": {
    "authenticatorMFA": {
      "label": "Aplikacija za autentifikaciju",
      "description": {
        "label": "Primite autentifikacijski kôd za prijavu iz svoje aplikacije za autentifikaciju."
      },
      "feedforward": {
        "success": {
          "message": "Aktivirano"
        }
      }
    },
    "SMSMFA": {
      "label": "SMS",
      "description": {
        "label": "Primite kod za autentifikaciju za višestepenu autentifikaciju putem SMS-a."
      },
      "feedforward": {
        "success": {
          "message": "Aktivirano"
        }
      }
    },
    "explanationMFAChoice": {
      "label": "Povećajte sigurnost pomoću višefaktorske autentifikacije prilikom prijavljivanja."
    },
    "MFAChoice": {
      "title": "Višefaktorska autentifikacija"
    },
    "feedforward": {
      "authenticatorMFA": {
        "success": {
          "message": "Aktivirano"
        }
      },
      "SMSMFA": {
        "success": {
          "message": "Aktivirano"
        }
      }
    }
  },
  "da-DK": {
    "authenticatorMFA": {
      "label": "Authenticator-App",
      "description": {
        "label": "Modtag godkendelseskoden til login fra din godkendelsesapp."
      },
      "feedforward": {
        "success": {
          "message": "Aktiveret"
        }
      }
    },
    "SMSMFA": {
      "label": "SMS",
      "description": {
        "label": "Modtag autentificeringskode til multifaktorgodkendelse via SMS."
      },
      "feedforward": {
        "success": {
          "message": "Aktiveret"
        }
      }
    },
    "explanationMFAChoice": {
      "label": "Forøg sikkerheden med multifaktorautentificering, når du logger ind."
    },
    "MFAChoice": {
      "title": "Multifaktorautentificering"
    },
    "feedforward": {
      "authenticatorMFA": {
        "success": {
          "message": "Aktiveret"
        }
      },
      "SMSMFA": {
        "success": {
          "message": "Aktiveret"
        }
      }
    }
  },
  "et-EE": {
    "authenticatorMFA": {
      "label": "Authenticator-App",
      "description": {
        "label": "Saate autentimiskoodi sisselogimiseks oma autentimisrakendusest."
      },
      "feedforward": {
        "success": {
          "message": "Aktiveeritud"
        }
      }
    },
    "SMSMFA": {
      "label": "SMS",
      "description": {
        "label": "Saate SMS-i kaudu autentimiskoodi mitmeastmeliseks autentimiseks."
      },
      "feedforward": {
        "success": {
          "message": "Aktiveeritud"
        }
      }
    },
    "explanationMFAChoice": {
      "label": "Suurendage sisselogimisel turvalisust mitme teguri autentimisega."
    },
    "MFAChoice": {
      "title": "Mitmeteguriline autentimine"
    },
    "feedforward": {
      "authenticatorMFA": {
        "success": {
          "message": "Aktiveeritud"
        }
      },
      "SMSMFA": {
        "success": {
          "message": "Aktiveeritud"
        }
      }
    }
  },
  "fi-FI": {
    "authenticatorMFA": {
      "label": "Authenticator-Sovellus",
      "description": {
        "label": "Vastaanota todennuskoodi kirjautumista varten todennussovelluksestasi."
      },
      "feedforward": {
        "success": {
          "message": "Aktivoitu"
        }
      }
    },
    "SMSMFA": {
      "label": "Tekstiviesti",
      "description": {
        "label": "Vastaanota todennuskoodi monivaiheista todennusta varten tekstiviestillä."
      },
      "feedforward": {
        "success": {
          "message": "Aktivoitu"
        }
      }
    },
    "explanationMFAChoice": {
      "label": "Paranna tietoturvaa monivaiheisella todennuksella sisäänkirjautumisen yhteydessä."
    },
    "MFAChoice": {
      "title": "Monivaiheinen todennus"
    },
    "feedforward": {
      "authenticatorMFA": {
        "success": {
          "message": "Aktivoitu"
        }
      },
      "SMSMFA": {
        "success": {
          "message": "Aktivoitu"
        }
      }
    }
  },
  "he-IL": {
    "authenticatorMFA": {
      "label": "מאמת-אפליקציה",
      "description": {
        "label": "קבל את קוד האימות להתחברות מאפליקציית האימות שלך."
      },
      "feedforward": {
        "success": {
          "message": "מופעל"
        }
      }
    },
    "SMSMFA": {
      "label": "סמס",
      "description": {
        "label": "קבל קוד אימות עבור אימות רב-שלבי באמצעות SMS."
      },
      "feedforward": {
        "success": {
          "message": "מופעל"
        }
      }
    },
    "explanationMFAChoice": {
      "label": "הגדל את האבטחה באמצעות אימות רב-גורמי בעת כניסה."
    },
    "MFAChoice": {
      "title": "אימות רב גורמים"
    },
    "feedforward": {
      "authenticatorMFA": {
        "success": {
          "message": "מופעל"
        }
      },
      "SMSMFA": {
        "success": {
          "message": "מופעל"
        }
      }
    }
  },
  "ga-IE": {
    "authenticatorMFA": {
      "label": "Aip fíordheimhnitheora",
      "description": {
        "label": "Faigh an cód fíordheimhnithe atá uait chun logáil isteach trí aip fíordheimhnithe."
      },
      "feedforward": {
        "success": {
          "message": "Gníomhachtaithe"
        }
      }
    },
    "SMSMFA": {
      "label": "SMS",
      "description": {
        "label": "Faigh cód fíordheimhnithe le haghaidh fíordheimhnithe ilfhachtóiriúil trí SMS."
      },
      "feedforward": {
        "success": {
          "message": "Gníomhachtaithe"
        }
      }
    },
    "explanationMFAChoice": {
      "label": "Slándáil a mhéadú le fíordheimhniú ilfhachtóirí agus tú ag logáil isteach."
    },
    "MFAChoice": {
      "title": "Fíordheimhniú ilfhachtóirí"
    },
    "feedforward": {
      "authenticatorMFA": {
        "success": {
          "message": "Gníomhachtaithe"
        }
      },
      "SMSMFA": {
        "success": {
          "message": "Gníomhachtaithe"
        }
      }
    }
  },
  "ja-JP": {
    "authenticatorMFA": {
      "label": "認証アプリ",
      "description": {
        "label": "認証アプリからログイン用の認証コードを受け取ります。"
      },
      "feedforward": {
        "success": {
          "message": "アクティブ化"
        }
      }
    },
    "SMSMFA": {
      "label": "SMS",
      "description": {
        "label": "SMS経由で多段階認証の認証コードを受け取ります。"
      },
      "feedforward": {
        "success": {
          "message": "アクティブ化"
        }
      }
    },
    "explanationMFAChoice": {
      "label": "ログイン時の多要素認証でセキュリティを強化。"
    },
    "MFAChoice": {
      "title": "多要素認証"
    },
    "feedforward": {
      "authenticatorMFA": {
        "success": {
          "message": "アクティブ化"
        }
      },
      "SMSMFA": {
        "success": {
          "message": "アクティブ化"
        }
      }
    }
  },
  "hr-HR": {
    "authenticatorMFA": {
      "label": "Autentifikator-App",
      "description": {
        "label": "Primite kôd za provjeru autentičnosti za prijavu iz svoje aplikacije za provjeru autentičnosti."
      },
      "feedforward": {
        "success": {
          "message": "Aktiviran"
        }
      }
    },
    "SMSMFA": {
      "label": "SMS",
      "description": {
        "label": "Primite autentifikacijski kod za autentifikaciju u više koraka putem SMS-a."
      },
      "feedforward": {
        "success": {
          "message": "Aktiviran"
        }
      }
    },
    "explanationMFAChoice": {
      "label": "Povećajte sigurnost pomoću višefaktorske provjere autentičnosti prilikom prijave."
    },
    "MFAChoice": {
      "title": "Višefaktorska autentifikacija"
    },
    "feedforward": {
      "authenticatorMFA": {
        "success": {
          "message": "Aktiviran"
        }
      },
      "SMSMFA": {
        "success": {
          "message": "Aktiviran"
        }
      }
    }
  },
  "lv-LV": {
    "authenticatorMFA": {
      "label": "Autentifikators-lietotne",
      "description": {
        "label": "Saņemiet autentifikācijas kodu, lai pieteiktos no savas autentifikācijas lietotnes."
      },
      "feedforward": {
        "success": {
          "message": "Aktivizēts"
        }
      }
    },
    "SMSMFA": {
      "label": "Īsziņa",
      "description": {
        "label": "Saņemiet autentifikācijas kodu daudzpakāpju autentifikācijai, izmantojot SMS."
      },
      "feedforward": {
        "success": {
          "message": "Aktivizēts"
        }
      }
    },
    "explanationMFAChoice": {
      "label": "Palieliniet drošību, piesakoties, izmantojot daudzfaktoru autentifikāciju."
    },
    "MFAChoice": {
      "title": "Daudzfaktoru autentifikācija"
    },
    "feedforward": {
      "authenticatorMFA": {
        "success": {
          "message": "Aktivizēts"
        }
      },
      "SMSMFA": {
        "success": {
          "message": "Aktivizēts"
        }
      }
    }
  },
  "lt-LT": {
    "authenticatorMFA": {
      "label": "„Authenticator“ programa",
      "description": {
        "label": "Gaukite prisijungimo autentifikavimo kodą iš autentifikavimo programos."
      },
      "feedforward": {
        "success": {
          "message": "Suaktyvinta"
        }
      }
    },
    "SMSMFA": {
      "label": "Trumpoji žinutė",
      "description": {
        "label": "Gaukite autentifikavimo kodą daugiapakopiam autentifikavimui SMS žinute."
      },
      "feedforward": {
        "success": {
          "message": "Suaktyvinta"
        }
      }
    },
    "explanationMFAChoice": {
      "label": "Padidinkite saugumą naudodami dviejų veiksnių autentifikavimą, kai prisijungiate."
    },
    "MFAChoice": {
      "title": "Dviejų veiksnių autentifikavimas"
    },
    "feedforward": {
      "authenticatorMFA": {
        "success": {
          "message": "Suaktyvinta"
        }
      },
      "SMSMFA": {
        "success": {
          "message": "Suaktyvinta"
        }
      }
    }
  },
  "pt-PT": {
    "authenticatorMFA": {
      "label": "Authenticator-App",
      "description": {
        "label": "Receba o código de autenticação para iniciar sessão pela aplicação de autenticação."
      },
      "feedforward": {
        "success": {
          "message": "Ativado"
        }
      }
    },
    "SMSMFA": {
      "label": "SMS",
      "description": {
        "label": "Receba o código de autenticação para autenticação em várias etapas via SMS."
      },
      "feedforward": {
        "success": {
          "message": "Ativado"
        }
      }
    },
    "explanationMFAChoice": {
      "label": "Aumente a segurança com a autenticação multifator ao efetuar o início de sessão."
    },
    "MFAChoice": {
      "title": "Autenticação multifator"
    },
    "feedforward": {
      "authenticatorMFA": {
        "success": {
          "message": "Ativado"
        }
      },
      "SMSMFA": {
        "success": {
          "message": "Ativado"
        }
      }
    }
  },
  "ro-RO": {
    "authenticatorMFA": {
      "label": "Aplicație de autentificare",
      "description": {
        "label": "Primiți codul de autentificare pentru conectare dintr-o aplicație de autentificare."
      },
      "feedforward": {
        "success": {
          "message": "Activat"
        }
      }
    },
    "SMSMFA": {
      "label": "SMS",
      "description": {
        "label": "Primiți codul de autentificare pentru autentificarea multifactorială prin SMS"
      },
      "feedforward": {
        "success": {
          "message": "Activat"
        }
      }
    },
    "explanationMFAChoice": {
      "label": "Îmbunătățiti securitatea prin autentificarea cu mai mulți factori la conectare."
    },
    "MFAChoice": {
      "title": "Autentificare cu mai mulți factori"
    },
    "feedforward": {
      "authenticatorMFA": {
        "success": {
          "message": "Activat"
        }
      },
      "SMSMFA": {
        "success": {
          "message": "Activat"
        }
      }
    }
  },
  "ru-RU": {
    "authenticatorMFA": {
      "label": "Приложение для аутентификации",
      "description": {
        "label": "Получите код аутентификации для входа в систему через приложение для аутентификации."
      },
      "feedforward": {
        "success": {
          "message": "Активировано"
        }
      }
    },
    "SMSMFA": {
      "label": "SMS",
      "description": {
        "label": "Получите код аутентификации для многофакторной аутентификации через SMS."
      },
      "feedforward": {
        "success": {
          "message": "Активировано"
        }
      }
    },
    "explanationMFAChoice": {
      "label": "Повысьте безопасность с помощью многофакторной аутентификации при входе в систему."
    },
    "MFAChoice": {
      "title": "Многофакторная аутентификация"
    },
    "feedforward": {
      "authenticatorMFA": {
        "success": {
          "message": "Активировано"
        }
      },
      "SMSMFA": {
        "success": {
          "message": "Активировано"
        }
      }
    }
  },
  "sv-SE": {
    "authenticatorMFA": {
      "label": "Autentiseringsapp",
      "description": {
        "label": "Ta emot autentiseringskoden för inloggning från din autentiseringsapp."
      },
      "feedforward": {
        "success": {
          "message": "Aktiverad"
        }
      }
    },
    "SMSMFA": {
      "label": "SMS",
      "description": {
        "label": "Ta emot autentiseringskod för multifaktorautentisering via SMS"
      },
      "feedforward": {
        "success": {
          "message": "Aktiverad"
        }
      }
    },
    "explanationMFAChoice": {
      "label": "Öka säkerheten med autentisering med flera faktorer när du loggar in."
    },
    "MFAChoice": {
      "title": "Multifaktorautentisering"
    },
    "feedforward": {
      "authenticatorMFA": {
        "success": {
          "message": "Aktiverad"
        }
      },
      "SMSMFA": {
        "success": {
          "message": "Aktiverad"
        }
      }
    }
  },
  "sk-SK": {
    "authenticatorMFA": {
      "label": "Aplikácia Authenticator",
      "description": {
        "label": "Získajte overovací kód na prihlásenie pomocou overovacej aplikácie."
      },
      "feedforward": {
        "success": {
          "message": "Aktivované"
        }
      }
    },
    "SMSMFA": {
      "label": "SMS",
      "description": {
        "label": "Prijmite autentifikačný kód pre viackrokovú autentifikáciu prostredníctvom SMS."
      },
      "feedforward": {
        "success": {
          "message": "Aktivované"
        }
      }
    },
    "explanationMFAChoice": {
      "label": "Zvýšte zabezpečenie pomocou viacfaktorového overenia pri prihlasovaní."
    },
    "MFAChoice": {
      "title": "Viacfaktorové overenie"
    },
    "feedforward": {
      "authenticatorMFA": {
        "success": {
          "message": "Aktivované"
        }
      },
      "SMSMFA": {
        "success": {
          "message": "Aktivované"
        }
      }
    }
  },
  "cs-CZ": {
    "authenticatorMFA": {
      "label": "Aplikace Authenticator",
      "description": {
        "label": "Přijměte ověřovací kód pro přihlášení z vaší ověřovací aplikace."
      },
      "feedforward": {
        "success": {
          "message": "Aktivováno"
        }
      }
    },
    "SMSMFA": {
      "label": "SMS",
      "description": {
        "label": "Obdržíte ověřovací kód pro vícefázové ověření prostřednictvím SMS."
      },
      "feedforward": {
        "success": {
          "message": "Aktivováno"
        }
      }
    },
    "explanationMFAChoice": {
      "label": "Zvyšte zabezpečení pomocí vícefaktorového ověřování při přihlášení."
    },
    "MFAChoice": {
      "title": "Vícefaktorové ověřování"
    },
    "feedforward": {
      "authenticatorMFA": {
        "success": {
          "message": "Aktivováno"
        }
      },
      "SMSMFA": {
        "success": {
          "message": "Aktivováno"
        }
      }
    }
  },
  "hu-HU": {
    "authenticatorMFA": {
      "label": "Hitelesítő app",
      "description": {
        "label": "Kapja meg a bejelentkezéshez szükséges hitelesítési kódot a hitelesítési alkalmazással."
      },
      "feedforward": {
        "success": {
          "message": "Aktivált"
        }
      }
    },
    "SMSMFA": {
      "label": "SMS",
      "description": {
        "label": "Hitelesítési kód fogadása a többlépcsős hitelesítéshez SMS-ben."
      },
      "feedforward": {
        "success": {
          "message": "Aktivált"
        }
      }
    },
    "explanationMFAChoice": {
      "label": "Növelje a biztonságot többfaktoros hitelesítéssel a bejelentkezéskor."
    },
    "MFAChoice": {
      "title": "Többfaktoros hitelesítés"
    },
    "feedforward": {
      "authenticatorMFA": {
        "success": {
          "message": "Aktivált"
        }
      },
      "SMSMFA": {
        "success": {
          "message": "Aktivált"
        }
      }
    }
  },
  "fr-BE": {
    "authenticatorMFA": {
      "label": "Authentificateur-App",
      "description": {
        "label": "Obtenez votre code d'authentification pour la connexion par l'application d'authentification."
      },
      "feedforward": {
        "success": {
          "message": "Activé"
        }
      }
    },
    "SMSMFA": {
      "label": "SMS",
      "description": {
        "label": "Recevez le code d'authentification pour l'authentification multifacteur par SMS."
      },
      "feedforward": {
        "success": {
          "message": "Activé"
        }
      }
    },
    "explanationMFAChoice": {
      "label": "Renforcez la sécurité grâce à l'authentification multifactorielle pour la connexion."
    },
    "MFAChoice": {
      "title": "Authentification multi-facteurs"
    },
    "feedforward": {
      "authenticatorMFA": {
        "success": {
          "message": "Activé"
        }
      },
      "SMSMFA": {
        "success": {
          "message": "Activé"
        }
      }
    }
  },
  "fr-LU": {
    "authenticatorMFA": {
      "label": "Authentificateur-App",
      "description": {
        "label": "Obtenez votre code d'authentification pour la connexion par l'application d'authentification."
      },
      "feedforward": {
        "success": {
          "message": "Activé"
        }
      }
    },
    "SMSMFA": {
      "label": "SMS",
      "description": {
        "label": "Recevez le code d'authentification pour l'authentification multifacteur par SMS."
      },
      "feedforward": {
        "success": {
          "message": "Activé"
        }
      }
    },
    "explanationMFAChoice": {
      "label": "Renforcez la sécurité grâce à l'authentification multifactorielle pour la connexion."
    },
    "MFAChoice": {
      "title": "Authentification multi-facteurs"
    },
    "feedforward": {
      "authenticatorMFA": {
        "success": {
          "message": "Activé"
        }
      },
      "SMSMFA": {
        "success": {
          "message": "Activé"
        }
      }
    }
  },
  "mt-MT": {
    "authenticatorMFA": {
      "label": "App tal-Awtentikatur",
      "description": {
        "label": "Irċievi l-kodiċi tal-awtentikazzjoni tiegħek għal-login mill-app tal-awtentikazzjoni."
      },
      "feedforward": {
        "success": {
          "message": "Attivat"
        }
      }
    },
    "SMSMFA": {
      "label": "SMS",
      "description": {
        "label": "Irċievi kodiċi ta 'awtentikazzjoni għal awtentikazzjoni multi-fattur permezz ta' SMS"
      },
      "feedforward": {
        "success": {
          "message": "Attivat"
        }
      }
    },
    "explanationMFAChoice": {
      "label": "Żid is-sigurtà b'awtentikazzjoni b'ħafna fatturi meta tidħol."
    },
    "MFAChoice": {
      "title": "Awtentikazzjoni b'Ħafna Fatturi"
    },
    "feedforward": {
      "authenticatorMFA": {
        "success": {
          "message": "Attivat"
        }
      },
      "SMSMFA": {
        "success": {
          "message": "Attivat"
        }
      }
    }
  },
  "sl-SI": {
    "authenticatorMFA": {
      "label": "Aplikacija za preverjanje pristnosti",
      "description": {
        "label": "Kodo za prijavo s preverjanjem pristnosti pridobite iz aplikacije za preverjanje pristnosti."
      },
      "feedforward": {
        "success": {
          "message": "Aktivirano"
        }
      }
    },
    "SMSMFA": {
      "label": "SMS",
      "description": {
        "label": "Prejmite avtentikacijsko kodo za večfaktorsko avtentikacijo prek SMS-a."
      },
      "feedforward": {
        "success": {
          "message": "Aktivirano"
        }
      }
    },
    "explanationMFAChoice": {
      "label": "Izboljšajte varnost z večkratnim preverjanjem pristnosti ob prijavi."
    },
    "MFAChoice": {
      "title": "Večkratno preverjanje pristnosti"
    },
    "feedforward": {
      "authenticatorMFA": {
        "success": {
          "message": "Aktivirano"
        }
      },
      "SMSMFA": {
        "success": {
          "message": "Aktivirano"
        }
      }
    }
  },
  "tr-TR": {
    "authenticatorMFA": {
      "label": "Kimlik Doğrulayıcı-Uygulama",
      "description": {
        "label": "Kimlik doğrulama uygulamasında oturum açmak için kimlik doğrulama kodunuzu alın."
      },
      "feedforward": {
        "success": {
          "message": "Etkinleştirildi"
        }
      }
    },
    "SMSMFA": {
      "label": "SMS",
      "description": {
        "label": "SMS yoluyla çok adımlı kimlik doğrulama için kimlik doğrulama kodu alın."
      },
      "feedforward": {
        "success": {
          "message": "Etkinleştirildi"
        }
      }
    },
    "explanationMFAChoice": {
      "label": "Oturum açarken çok faktörlü kimlik doğrulama ile güvenliği artırın."
    },
    "MFAChoice": {
      "title": "Çok faktörlü kimlik doğrulama"
    },
    "feedforward": {
      "authenticatorMFA": {
        "success": {
          "message": "Etkinleştirildi"
        }
      },
      "SMSMFA": {
        "success": {
          "message": "Etkinleştirildi"
        }
      }
    }
  },
  "tr-CY": {
    "authenticatorMFA": {
      "label": "Kimlik Doğrulayıcı-Uygulama",
      "description": {
        "label": "Kimlik doğrulama uygulamasında oturum açmak için kimlik doğrulama kodunuzu alın."
      },
      "feedforward": {
        "success": {
          "message": "Etkinleştirildi"
        }
      }
    },
    "SMSMFA": {
      "label": "SMS",
      "description": {
        "label": "SMS yoluyla çok adımlı kimlik doğrulama için kimlik doğrulama kodu alın."
      },
      "feedforward": {
        "success": {
          "message": "Etkinleştirildi"
        }
      }
    },
    "explanationMFAChoice": {
      "label": "Oturum açarken çok faktörlü kimlik doğrulama ile güvenliği artırın."
    },
    "MFAChoice": {
      "title": "Çok faktörlü kimlik doğrulama"
    },
    "feedforward": {
      "authenticatorMFA": {
        "success": {
          "message": "Etkinleştirildi"
        }
      },
      "SMSMFA": {
        "success": {
          "message": "Etkinleştirildi"
        }
      }
    }
  },
  "uk-UA": {
    "authenticatorMFA": {
      "label": "Authentifizierungs-App",
      "description": {
        "label": "Erhalte den Authentifizierungscode für den Login per Authentifizierungs-App."
      },
      "feedforward": {
        "success": {
          "message": "Активовано"
        }
      }
    },
    "SMSMFA": {
      "label": "SMS",
      "description": {
        "label": "Отримайте код автентифікації для багатоетапної автентифікації через SMS."
      },
      "feedforward": {
        "success": {
          "message": "Активовано"
        }
      }
    },
    "explanationMFAChoice": {
      "label": "Erhöhe die Sicherheit durch Multi-Faktor-Authentifizierung beim Login."
    },
    "MFAChoice": {
      "title": "Багатофакторна автентифікація"
    },
    "feedforward": {
      "authenticatorMFA": {
        "success": {
          "message": "Активовано"
        }
      },
      "SMSMFA": {
        "success": {
          "message": "Активовано"
        }
      }
    }
  },
  "zh-CN": {
    "authenticatorMFA": {
      "label": "身份验证器应用",
      "description": {
        "label": "通过身份验证应用程序接收登录验证码。"
      },
      "feedforward": {
        "success": {
          "message": "已激活"
        }
      }
    },
    "SMSMFA": {
      "label": "短信",
      "description": {
        "label": "通过 SMS 接收用于多重身份验证的身份验证代码。"
      },
      "feedforward": {
        "success": {
          "message": "已激活"
        }
      }
    },
    "explanationMFAChoice": {
      "label": "登录时通过多重身份验证提高安全性。"
    },
    "MFAChoice": {
      "title": "多重身份验证"
    },
    "feedforward": {
      "authenticatorMFA": {
        "success": {
          "message": "已激活"
        }
      },
      "SMSMFA": {
        "success": {
          "message": "已激活"
        }
      }
    }
  }
}
</i18n>
