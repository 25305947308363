<template>
  <el-config-provider :zIndex="3000">
    <div id="app" v-loading.fullscreen.lock="envStore.globalLoading">
      <router-view />
    </div>
  </el-config-provider>
</template>

<script setup>
import { onBeforeMount, onMounted, ref, provide } from "vue";
import { useEnvStore, useAuthStore } from "@dutypay/store-modules";
import { interceptor } from "@dutypay/utilities";
import { get } from 'lodash';
import qs from "qs";
import { to } from 'await-to-js';

const envStore = useEnvStore()
const authStore = useAuthStore()
const hasLogoutNotification = ref(false)
function hideLogoutNotification() {
  hasLogoutNotification.value = false;
}
provide('logoutNotification', {
  hideLogoutNotification,
  hasLogoutNotification
})

async function initSSO() {
  envStore.setGlobalLoading();

  let urlParams = qs.parse(window.location.search.replace('?', ''));
  if (Object.prototype.hasOwnProperty.call(urlParams,'logout')) {
    let err, logout;
    [err, logout] = await to(authStore.logout());
    if(err && envStore.debug){
      console.log(err)
    }
    envStore.releaseGlobalLoading();
    if (get(logout, '1.status') === 200) {
      hasLogoutNotification.value = true;
    }
  }

    let err, ottConfig;
  if (urlParams && urlParams.session) {
    [err, ottConfig] = await to(interceptor({
      url: `${ process.env.VUE_APP_API_DUTYPAY }/api/v0/authenticate/legacy`,
      data: { "session": urlParams.session },
      method: "POST"
    }));
    if (err) {
      envStore.releaseGlobalLoading()
      let redirect = "";
      if (urlParams.redirect) {
        redirect += '/?redirect=' + urlParams.redirect
      }
      window.history.pushState({}, document.title, redirect);
      return;
    }
  } else {
    [err, ottConfig] = await to(interceptor({
      url: `${ process.env.VUE_APP_API_DUTYPAY }/api/v0/authenticate/sso`,
      headers: { "Authorization": "Bearer " + localStorage.getItem('accessToken') },
      method: "POST"
    }));
    if (err) {
      await to(authStore.logout());
      envStore.releaseGlobalLoading()
      //Sanitization of URL Params only allow [redirect, emailAuthCode, email]
      let queryString = "";
      if (urlParams.redirect || urlParams.emailAuthCode || urlParams.email) {
        queryString = '/?' + qs.stringify(urlParams, { encode: false });
      }
      window.history.pushState({}, document.title, queryString);
      return false;
    }
  }
  let selectedJurisdiction = JSON.parse(localStorage.getItem('jurisdiction'));
  let i18n = get(selectedJurisdiction, 'i18n', process.env.VUE_APP_I18N_LOCALE);
  let url = qs.parse(window.location.search.substring(1));
  if (url && url.redirect) {
    window.location.href = `${ url.redirect }/#i18n=${ i18n }&token=${ ottConfig.data.result.ott }`;
    return;
  }
  window.location.href = `${ process.env.VUE_APP_HOST_TAXHUB }/#i18n=${ i18n }&token=${ ottConfig.data.result.ott }`;
  return true;
}

onBeforeMount(async () => {
  await initSSO()
});

onMounted(async () => {
  await envStore.fetchHosts();
})
</script>

<style lang="scss">
@use "~element-plus/theme-chalk/src/index.scss" as *;

@use "~@dutypay/design-system/src/scss/dp" as sso-app;

@include sso-app.use-application-color('dutypay');
@include sso-app.use-theme('light');

$--color-primary: #515151;
@import '~@dutypay/design-system/src/scss_legacy/index.scss';

body {
  background-color: #2d2d2d !important; /* Old browsers */
  background: -moz-linear-gradient(130deg, #2d2d2d 1%, #404040 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(130deg, #2d2d2d 1%, #404040 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(130deg, #2d2d2d 1%, #404040 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2d2d2d', endColorstr='#404040', GradientType=1); /* IE6-9 fallback on horizontal gradient */
}

#app {
  color: white;
  background-color: unset !important;

  .el-form-item .el-form-item__label {
    color: white;
  }
}
</style>

<i18n>
{
  "ar-AE": {
    "notification": {
      "logout": {
        "success": {
          "title": "عمل ناجح"
        }
      }
    }
  },
  "ar-EG": {
    "notification": {
      "logout": {
        "success": {
          "title": "عمل ناجح"
        }
      }
    }
  },
  "ar-SA": {
    "notification": {
      "logout": {
        "success": {
          "title": "عمل ناجح"
        }
      }
    }
  },
  "el-GR": {
    "notification": {
      "logout": {
        "success": {
          "title": "Επιτυχημένη ενέργεια"
        }
      }
    }
  },
  "el-CY": {
    "notification": {
      "logout": {
        "success": {
          "title": "Επιτυχημένη ενέργεια"
        }
      }
    }
  },
  "de-DE": {
    "notification": {
      "logout": {
        "success": {
          "title": "Erfolgreiche Aktion"
        }
      }
    }
  },
  "de-AT": {
    "notification": {
      "logout": {
        "success": {
          "title": "Erfolgreiche Aktion"
        }
      }
    }
  },
  "de-CH": {
    "notification": {
      "logout": {
        "success": {
          "title": "Erfolgreiche Aktion"
        }
      }
    }
  },
  "en-US": {
    "notification": {
      "logout": {
        "success": {
          "title": "Successful action"
        }
      }
    }
  },
  "en-GB": {
    "notification": {
      "logout": {
        "success": {
          "title": "Successful action"
        }
      }
    }
  },
  "it-IT": {
    "notification": {
      "logout": {
        "success": {
          "title": "Azione riuscita"
        }
      }
    }
  },
  "fr-FR": {
    "notification": {
      "logout": {
        "success": {
          "title": "Action réussie"
        }
      }
    }
  },
  "es-ES": {
    "notification": {
      "logout": {
        "success": {
          "title": "Listo"
        }
      }
    }
  },
  "pl-PL": {
    "notification": {
      "logout": {
        "success": {
          "title": "Czynność zakończona powodzeniem"
        }
      }
    }
  },
  "nl-NL": {
    "notification": {
      "logout": {
        "success": {
          "title": "Geslaagde actie"
        }
      }
    }
  },
  "bg-BG": {
    "notification": {
      "logout": {
        "success": {
          "title": "Успешно действие"
        }
      }
    }
  },
  "bs-BA": {
    "notification": {
      "logout": {
        "success": {
          "title": "Uspješno obavljeno"
        }
      }
    }
  },
  "da-DK": {
    "notification": {
      "logout": {
        "success": {
          "title": "Vellykket handling"
        }
      }
    }
  },
  "et-EE": {
    "notification": {
      "logout": {
        "success": {
          "title": "Edukas tegevus"
        }
      }
    }
  },
  "fi-FI": {
    "notification": {
      "logout": {
        "success": {
          "title": "Onnistunut toiminta"
        }
      }
    }
  },
  "he-IL": {
    "notification": {
      "logout": {
        "success": {
          "title": "פעולה מוצלחת"
        }
      }
    }
  },
  "ga-IE": {
    "notification": {
      "logout": {
        "success": {
          "title": "Gníomh rathúil"
        }
      }
    }
  },
  "ja-JP": {
    "notification": {
      "logout": {
        "success": {
          "title": "成功したアクション"
        }
      }
    }
  },
  "hr-HR": {
    "notification": {
      "logout": {
        "success": {
          "title": "Uspješna akcija"
        }
      }
    }
  },
  "lv-LV": {
    "notification": {
      "logout": {
        "success": {
          "title": "Veiksmīga darbība"
        }
      }
    }
  },
  "lt-LT": {
    "notification": {
      "logout": {
        "success": {
          "title": "Sėkmingas veiksmas"
        }
      }
    }
  },
  "pt-PT": {
    "notification": {
      "logout": {
        "success": {
          "title": "Ação bem-sucedida"
        }
      }
    }
  },
  "ro-RO": {
    "notification": {
      "logout": {
        "success": {
          "title": "Acțiune reușită"
        }
      }
    }
  },
  "ru-RU": {
    "notification": {
      "logout": {
        "success": {
          "title": "Успешное действие"
        }
      }
    }
  },
  "sv-SE": {
    "notification": {
      "logout": {
        "success": {
          "title": "Åtgärd utförd"
        }
      }
    }
  },
  "sk-SK": {
    "notification": {
      "logout": {
        "success": {
          "title": "Úspešná akcia"
        }
      }
    }
  },
  "cs-CZ": {
    "notification": {
      "logout": {
        "success": {
          "title": "Úspěšná akce"
        }
      }
    }
  },
  "hu-HU": {
    "notification": {
      "logout": {
        "success": {
          "title": "Sikeres művelet"
        }
      }
    }
  },
  "fr-BE": {
    "notification": {
      "logout": {
        "success": {
          "title": "Action réussie"
        }
      }
    }
  },
  "fr-LU": {
    "notification": {
      "logout": {
        "success": {
          "title": "Action réussie"
        }
      }
    }
  },
  "mt-MT": {
    "notification": {
      "logout": {
        "success": {
          "title": "Azzjoni ta' suċċess"
        }
      }
    }
  },
  "sl-SI": {
    "notification": {
      "logout": {
        "success": {
          "title": "Uspešno opravljeno"
        }
      }
    }
  },
  "tr-TR": {
    "notification": {
      "logout": {
        "success": {
          "title": "Başarılı eylem"
        }
      }
    }
  },
  "tr-CY": {
    "notification": {
      "logout": {
        "success": {
          "title": "Başarılı eylem"
        }
      }
    }
  },
  "uk-UA": {
    "notification": {
      "logout": {
        "success": {
          "title": "Успішна акція"
        }
      }
    }
  },
  "zh-CN": {
    "notification": {
      "logout": {
        "success": {
          "title": "操作成功"
        }
      }
    }
  }
}
</i18n>
