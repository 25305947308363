import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import {createPinia} from 'pinia';
import {i18n} from './i18n';
import ElementPlus from "element-plus";
import PopupManager from 'element-plus';
import DesignSystem from '@dutypay/design-system';
import ComponentsLibrary from '@dutypay/components-library';
import { name, version } from '../package.json';

const pinia = createPinia()
const app = createApp(App);
app.use(router)
  .use(pinia)
  .use(i18n)
  .use(ElementPlus)
  .use(PopupManager, {
    zIndex:3050 // Notifications z-index
  })
  .use(DesignSystem)
  .use(ComponentsLibrary)
  .mount("#app");

console.log( name + " version: ", version)



