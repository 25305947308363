export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ar-AE": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل وتأكد من استلام رمز المصادقة."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمل ناجح"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال رمز المصادقة عبر الرسائل القصيرة. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة لتسجيل الدخول."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["إعادة إرسال في ", _interpolate(_named("seconds")), " ثوان"])}
        },
        "SMSOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز المصادقة عبر الرسائل القصيرة"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعد الإرسال"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمل ناجح"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال رمز المصادقة عبر الرسائل القصيرة. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة لتسجيل الدخول."])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعذر إرسال الرسالة. يرجى التحقق من دخولك."])}
            }
          }
        }
      },
      "ar-EG": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل وتأكد من استلام رمز المصادقة."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمل ناجح"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال رمز المصادقة عبر الرسائل القصيرة. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة لتسجيل الدخول."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["إعادة إرسال في ", _interpolate(_named("seconds")), " ثوان"])}
        },
        "SMSOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز المصادقة عبر الرسائل القصيرة"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعد الإرسال"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمل ناجح"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال رمز المصادقة عبر الرسائل القصيرة. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة لتسجيل الدخول."])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعذر إرسال الرسالة. يرجى التحقق من دخولك."])}
            }
          }
        }
      },
      "ar-SA": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل وتأكد من استلام رمز المصادقة."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمل ناجح"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال رمز المصادقة عبر الرسائل القصيرة. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة لتسجيل الدخول."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["إعادة إرسال في ", _interpolate(_named("seconds")), " ثوان"])}
        },
        "SMSOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز المصادقة عبر الرسائل القصيرة"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعد الإرسال"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمل ناجح"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال رمز المصادقة عبر الرسائل القصيرة. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة لتسجيل الدخول."])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعذر إرسال الرسالة. يرجى التحقق من دخولك."])}
            }
          }
        }
      },
      "el-GR": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε και επιβεβαιώστε τον κωδικό ελέγχου ταυτότητας που λάβατε."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιβεβαίωση"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιτυχημένη ενέργεια"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο κωδικός ελέγχου ταυτότητας στάλθηκε μέσω SMS. Μπορεί να χρησιμοποιηθεί μόνο μία φορά και εντός περιορισμένου χρόνου για τη σύνδεση."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Επανάληψη αποστολής σε ", _interpolate(_named("seconds")), " δευτερόλεπτα"])}
        },
        "SMSOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κωδικός ελέγχου ταυτότητας μέσω SMS"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στείλε ξανά"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιτυχημένη ενέργεια"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο κωδικός ελέγχου ταυτότητας στάλθηκε μέσω SMS. Μπορεί να χρησιμοποιηθεί μόνο μία φορά και εντός περιορισμένου χρόνου για τη σύνδεση."])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σφάλμα"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν ήταν δυνατή η αποστολή του μηνύματος. Ελέγξτε την καταχώρισή σας."])}
            }
          }
        }
      },
      "el-CY": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε και επιβεβαιώστε τον κωδικό ελέγχου ταυτότητας που λάβατε."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιβεβαίωση"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιτυχημένη ενέργεια"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο κωδικός ελέγχου ταυτότητας στάλθηκε μέσω SMS. Μπορεί να χρησιμοποιηθεί μόνο μία φορά και εντός περιορισμένου χρόνου για τη σύνδεση."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Επανάληψη αποστολής σε ", _interpolate(_named("seconds")), " δευτερόλεπτα"])}
        },
        "SMSOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κωδικός ελέγχου ταυτότητας μέσω SMS"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στείλε ξανά"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιτυχημένη ενέργεια"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο κωδικός ελέγχου ταυτότητας στάλθηκε μέσω SMS. Μπορεί να χρησιμοποιηθεί μόνο μία φορά και εντός περιορισμένου χρόνου για τη σύνδεση."])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σφάλμα"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν ήταν δυνατή η αποστολή του μηνύματος. Ελέγξτε την καταχώρισή σας."])}
            }
          }
        }
      },
      "de-DE": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfangenen Authentifizierungscode eingeben und bestätigen."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreiche Aktion"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Authentifizierungscode wurde per SMS gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Erneut senden in ", _interpolate(_named("seconds")), " Sekunden"])}
        },
        "SMSOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifizierungscode per SMS"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erneut senden"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreiche Aktion"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Authentifizierungscode wurde per SMS gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden."])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Nachricht konnte nicht gesendet werden. Bitte prüfe deine Eingabe."])}
            }
          }
        }
      },
      "de-AT": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfangenen Authentifizierungscode eingeben und bestätigen."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreiche Aktion"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Authentifizierungscode wurde per SMS gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Erneut senden in ", _interpolate(_named("seconds")), " Sekunden"])}
        },
        "SMSOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifizierungscode per SMS"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erneut senden"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreiche Aktion"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Authentifizierungscode wurde per SMS gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden."])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Nachricht konnte nicht gesendet werden. Bitte prüfe deine Eingabe."])}
            }
          }
        }
      },
      "de-CH": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfangenen Authentifizierungscode eingeben und bestätigen."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreiche Aktion"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Authentifizierungscode wurde per SMS gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Erneut senden in ", _interpolate(_named("seconds")), " Sekunden"])}
        },
        "SMSOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifizierungscode per SMS"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erneut senden"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreiche Aktion"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Authentifizierungscode wurde per SMS gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden."])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Nachricht konnte nicht gesendet werden. Bitte prüfe deine Eingabe."])}
            }
          }
        }
      },
      "en-US": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter and confirm the authentication code received."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful action"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The authentication code was sent via SMS. It can only be used once and within a limited time for logging in.."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Resend in ", _interpolate(_named("seconds")), " seconds"])}
        },
        "SMSOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication code by SMS"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send again"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful action"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The authentication code was sent via SMS. It can only be used once and within a limited time for logging in.."])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The message could not be sent. Please check your entry."])}
            }
          }
        }
      },
      "en-GB": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter and confirm the authentication code received."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful action"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The authentication code was sent via SMS. It can only be used once and within a limited time for logging in.."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Resend in ", _interpolate(_named("seconds")), " seconds"])}
        },
        "SMSOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication code by SMS"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send again"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful action"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The authentication code was sent via SMS. It can only be used once and within a limited time for logging in.."])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The message could not be sent. Please check your entry."])}
            }
          }
        }
      },
      "it-IT": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci e conferma il codice di autenticazione ricevuto."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azione riuscita"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il codice di autenticazione è stato inviato tramite SMS. Può essere utilizzato solo una volta ed entro un tempo limitato per l'accesso."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Invia nuovamente tra ", _interpolate(_named("seconds")), " secondi"])}
        },
        "SMSOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice di autenticazione via SMS"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia di nuovo"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azione riuscita"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il codice di autenticazione è stato inviato tramite SMS. Può essere utilizzato solo una volta ed entro un tempo limitato per l'accesso."])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile inviare il messaggio. Si prega di controllare la tua voce."])}
            }
          }
        }
      },
      "fr-FR": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez et confirmez le code d'authentification reçu."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action réussie"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code d'authentification a été envoyé par SMS. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour se connecter."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Renvoyer dans ", _interpolate(_named("seconds")), " secondes"])}
        },
        "SMSOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code d'authentification par SMS"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer à nouveau"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action réussie"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code d'authentification a été envoyé par SMS. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour se connecter."])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le message n'a pas pu être envoyé. Veuillez vérifier votre saisie."])}
            }
          }
        }
      },
      "es-ES": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca y confirme el código de autenticación recibido."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listo"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El código de autenticación se envió por SMS. Solo se puede usar una vez y dentro de un tiempo limitado para iniciar sesión."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Enviar de nuevo en ", _interpolate(_named("seconds")), " segundos"])}
        },
        "SMSOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de autenticación por SMS"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar de nuevo"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listo"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El código de autenticación se envió por SMS. Solo se puede usar una vez y dentro de un tiempo limitado para iniciar sesión."])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El mensaje no se pudo enviar. Por favor revise su entrada."])}
            }
          }
        }
      },
      "pl-PL": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź i potwierdź otrzymany kod uwierzytelniający."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdź"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czynność zakończona powodzeniem"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod uwierzytelniający został wysłany SMS-em. Można go użyć tylko raz iw ograniczonym czasie do zalogowania."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wyślij ponownie za ", _interpolate(_named("seconds")), " s"])}
        },
        "SMSOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod uwierzytelniający przez SMS"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij ponownie"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czynność zakończona powodzeniem"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod uwierzytelniający został wysłany SMS-em. Można go użyć tylko raz iw ograniczonym czasie do zalogowania."])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błąd"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie udało się wysłać wiadomości. Sprawdź swój wpis."])}
            }
          }
        }
      },
      "nl-NL": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer de ontvangen authenticatiecode in en bevestig deze."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestigen"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geslaagde actie"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De authenticatiecode is per sms verzonden. Het kan slechts één keer en binnen een beperkte tijd worden gebruikt om in te loggen."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Verzenden over ", _interpolate(_named("seconds")), " seconden"])}
        },
        "SMSOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticatiecode via sms"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opnieuw verzenden"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geslaagde actie"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De authenticatiecode is per sms verzonden. Het kan slechts één keer en binnen een beperkte tijd worden gebruikt om in te loggen."])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het bericht kon niet worden verzonden. Controleer uw invoer."])}
            }
          }
        }
      },
      "bg-BG": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въведете и потвърдете получения код за удостоверяване."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потвърждение"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно действие"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кодът за удостоверяване беше изпратен чрез SMS. Може да се използва само веднъж и в рамките на ограничено време за влизане."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Изпращане след ", _interpolate(_named("seconds")), " секунди"])}
        },
        "SMSOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код за удостоверяване чрез SMS"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изпрати отново"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно действие"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кодът за удостоверяване беше изпратен чрез SMS. Може да се използва само веднъж и в рамките на ограничено време за влизане."])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["грешка"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Съобщението не можа да бъде изпратено. Моля, проверете вашето въвеждане."])}
            }
          }
        }
      },
      "bs-BA": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite i potvrdite primljeni kod za autentifikaciju."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdite"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno obavljeno"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod za autentifikaciju je poslan putem SMS-a. Može se koristiti samo jednom iu ograničenom vremenu za prijavu."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ponovo pošalji za ", _interpolate(_named("seconds")), " sekundi"])}
        },
        "SMSOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentifikacijski kod putem SMS-a"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pošalji ponovo"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno obavljeno"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod za autentifikaciju je poslan putem SMS-a. Može se koristiti samo jednom iu ograničenom vremenu za prijavu."])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greška"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poruka se nije mogla poslati. Molimo provjerite svoj unos."])}
            }
          }
        }
      },
      "da-DK": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast og bekræft den modtagne godkendelseskode."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekræft"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vellykket handling"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkendelseskoden blev sendt via SMS. Det kan kun bruges én gang og inden for en begrænset tid til at logge ind."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Send igen om ", _interpolate(_named("seconds")), " sekunder"])}
        },
        "SMSOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkendelseskode via SMS"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send igen"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vellykket handling"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkendelseskoden blev sendt via SMS. Det kan kun bruges én gang og inden for en begrænset tid til at logge ind."])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fejl"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meddelelsen kunne ikke sendes. Tjek venligst din post."])}
            }
          }
        }
      },
      "et-EE": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisestage ja kinnitage saadud autentimiskood."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kinnita"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edukas tegevus"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentimiskood saadeti SMS-iga. Seda saab kasutada ainult üks kord ja piiratud aja jooksul sisselogimiseks."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Saada uuesti ", _interpolate(_named("seconds")), " sekundi pärast"])}
        },
        "SMSOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentimiskood SMS-i teel"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saada uuesti"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edukas tegevus"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentimiskood saadeti SMS-iga. Seda saab kasutada ainult üks kord ja piiratud aja jooksul sisselogimiseks."])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viga"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sõnumit ei õnnestunud saata. Palun kontrollige oma sisestust."])}
            }
          }
        }
      },
      "fi-FI": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syötä ja vahvista vastaanotettu todennuskoodi."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vahvista"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onnistunut toiminta"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todennuskoodi lähetettiin tekstiviestillä. Sitä voidaan käyttää vain kerran ja rajoitetun ajan sisällä kirjautumiseen."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lähetä uudelleen ", _interpolate(_named("seconds")), " sekunnissa"])}
        },
        "SMSOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todennuskoodi tekstiviestillä"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetä uudestaan"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onnistunut toiminta"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todennuskoodi lähetettiin tekstiviestillä. Sitä voidaan käyttää vain kerran ja rajoitetun ajan sisällä kirjautumiseen."])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virhe"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viestiä ei voitu lähettää. Tarkista syöttösi."])}
            }
          }
        }
      },
      "he-IL": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הזן ואשר את קוד האימות שהתקבל."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["לְאַשֵׁר"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["פעולה מוצלחת"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["קוד האימות נשלח באמצעות SMS. ניתן להשתמש בו רק פעם אחת ובתוך זמן מוגבל לכניסה."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["שלח מחדש בתוך ", _interpolate(_named("seconds")), " שניות"])}
        },
        "SMSOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["קוד אימות באמצעות SMS"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שלח שוב"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["פעולה מוצלחת"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["קוד האימות נשלח באמצעות SMS. ניתן להשתמש בו רק פעם אחת ובתוך זמן מוגבל לכניסה."])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שְׁגִיאָה"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["לא ניתן היה לשלוח את ההודעה. אנא בדוק את הערך שלך."])}
            }
          }
        }
      },
      "ga-IE": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuir isteach agus deimhnigh an cód fíordheimhnithe a fuarthas."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deimhnigh"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gníomh rathúil"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seoladh an cód fíordheimhnithe trí SMS. Ní féidir é a úsáid ach uair amháin agus laistigh de thréimhse teoranta chun logáil isteach."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Athsheol i gceann ", _interpolate(_named("seconds")), " soicind"])}
        },
        "SMSOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cód fíordheimhnithe trí SMS"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seol arís"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gníomh rathúil"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seoladh an cód fíordheimhnithe trí SMS. Ní féidir é a úsáid ach uair amháin agus laistigh de thréimhse teoranta chun logáil isteach."])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earráid"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ní fhéadfaí an teachtaireacht a sheoladh. Seiceáil d’iontráil le do thoil."])}
            }
          }
        }
      },
      "ja-JP": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受信した認証コードを入力して確認します。"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功したアクション"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証コードは SMS 経由で送信されました。 ログイン時に1回限り、限られた時間内でのみ使用できます。"])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("seconds")), "秒で再送信"])}
        },
        "SMSOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS経由の認証コード"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["もう一度送信"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功したアクション"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証コードは SMS 経由で送信されました。 ログイン時に1回限り、限られた時間内でのみ使用できます。"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エラー"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メッセージを送信できませんでした。 入力内容をご確認ください。"])}
            }
          }
        }
      },
      "hr-HR": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite i potvrdite primljeni autentifikacijski kod."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrda"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješna akcija"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod za autentifikaciju poslan je putem SMS-a. Može se koristiti samo jednom i unutar ograničenog vremena za prijavu."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ponovno slanje u ", _interpolate(_named("seconds")), " sekundama"])}
        },
        "SMSOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentifikacijski kod putem SMS-a"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pošalji opet"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješna akcija"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod za autentifikaciju poslan je putem SMS-a. Može se koristiti samo jednom i unutar ograničenog vremena za prijavu."])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pogreška"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poruka se nije mogla poslati. Molimo provjerite svoj unos."])}
            }
          }
        }
      },
      "lv-LV": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ievadiet un apstipriniet saņemto autentifikācijas kodu."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstiprināt"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veiksmīga darbība"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentifikācijas kods tika nosūtīts ar SMS. To var izmantot tikai vienu reizi un ierobežotā laikā, lai pieteiktos."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Atkārtoti nosūtīt pēc ", _interpolate(_named("seconds")), " sek."])}
        },
        "SMSOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentifikācijas kods, izmantojot īsziņu"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sūtīt vēlreiz"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veiksmīga darbība"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentifikācijas kods tika nosūtīts ar SMS. To var izmantot tikai vienu reizi un ierobežotā laikā, lai pieteiktos."])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kļūda"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziņojumu nevarēja nosūtīt. Lūdzu, pārbaudiet savu ierakstu."])}
            }
          }
        }
      },
      "lt-LT": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įveskite ir patvirtinkite gautą autentifikavimo kodą."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patvirtinti"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sėkmingas veiksmas"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentifikavimo kodas buvo išsiųstas SMS žinute. Jį galima naudoti tik vieną kartą ir per ribotą prisijungimo laiką."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Iš naujo išsiųsti po ", _interpolate(_named("seconds")), " sek."])}
        },
        "SMSOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentifikavimo kodas SMS žinute"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siųsti dar kartą"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sėkmingas veiksmas"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentifikavimo kodas buvo išsiųstas SMS žinute. Jį galima naudoti tik vieną kartą ir per ribotą prisijungimo laiką."])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klaida"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepavyko išsiųsti pranešimo. Patikrinkite savo įrašą."])}
            }
          }
        }
      },
      "pt-PT": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digite e confirme o código de autenticação recebido."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ação bem-sucedida"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O código de autenticação foi enviado via SMS. Ele só pode ser usado uma vez e dentro de um tempo limitado para fazer login."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Reenviar em ", _interpolate(_named("seconds")), " segundos"])}
        },
        "SMSOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de autenticação via SMS"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envie novamente"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ação bem-sucedida"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O código de autenticação foi enviado via SMS. Ele só pode ser usado uma vez e dentro de um tempo limitado para fazer login."])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A mensagem não pôde ser enviada. Por favor, verifique sua entrada."])}
            }
          }
        }
      },
      "ro-RO": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduceți și confirmați codul de autentificare primit."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmare"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acțiune reușită"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codul de autentificare a fost trimis prin SMS. Poate fi folosit o singură dată și într-un timp limitat pentru autentificare."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Retrimitere în ", _interpolate(_named("seconds")), " secunde"])}
        },
        "SMSOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cod de autentificare prin SMS"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimite din nou"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acțiune reușită"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codul de autentificare a fost trimis prin SMS. Poate fi folosit o singură dată și într-un timp limitat pentru autentificare."])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eroare"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesajul nu a putut fi trimis. Vă rugăm să verificați intrarea."])}
            }
          }
        }
      },
      "ru-RU": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите и подтвердите полученный код аутентификации."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешное действие"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код аутентификации был отправлен в SMS. Его можно использовать только один раз и в течение ограниченного времени для входа в систему."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Отправить повторно через ", _interpolate(_named("seconds")), " секунд"])}
        },
        "SMSOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код аутентификации по SMS"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправь еще раз"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешное действие"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код аутентификации был отправлен в SMS. Его можно использовать только один раз и в течение ограниченного времени для входа в систему."])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщение не может быть отправлено. Пожалуйста, проверьте вашу запись."])}
            }
          }
        }
      },
      "sv-SE": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange och bekräfta den mottagna autentiseringskoden."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekräfta"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åtgärd utförd"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentiseringskoden skickades via SMS. Den kan endast användas en gång och inom en begränsad tid för inloggning."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Skicka igen om ", _interpolate(_named("seconds")), " sekunder"])}
        },
        "SMSOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentiseringskod via SMS"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skicka igen"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åtgärd utförd"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentiseringskoden skickades via SMS. Den kan endast användas en gång och inom en begränsad tid för inloggning."])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fel"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meddelandet kunde inte skickas. Kontrollera ditt bidrag."])}
            }
          }
        }
      },
      "sk-SK": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte a potvrďte prijatý overovací kód."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdiť"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspešná akcia"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overovací kód bol odoslaný prostredníctvom SMS. Dá sa použiť len raz a v obmedzenom čase na prihlásenie."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Opäť odoslať o ", _interpolate(_named("seconds")), " sekúnd"])}
        },
        "SMSOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentifikačný kód prostredníctvom SMS"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poslať znova"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspešná akcia"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overovací kód bol odoslaný prostredníctvom SMS. Dá sa použiť len raz a v obmedzenom čase na prihlásenie."])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Správu sa nepodarilo odoslať. Skontrolujte svoje zadanie."])}
            }
          }
        }
      },
      "cs-CZ": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte a potvrďte přijatý ověřovací kód."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdit"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspěšná akce"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověřovací kód byl zaslán prostřednictvím SMS. Lze jej použít pouze jednou a po omezenou dobu pro přihlášení."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Odeslat znovu za ", _interpolate(_named("seconds")), " s."])}
        },
        "SMSOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověřovací kód prostřednictvím SMS"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poslat znovu"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspěšná akce"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověřovací kód byl zaslán prostřednictvím SMS. Lze jej použít pouze jednou a po omezenou dobu pro přihlášení."])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zprávu se nepodařilo odeslat. Zkontrolujte prosím svůj údaj."])}
            }
          }
        }
      },
      "hu-HU": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Írja be és erősítse meg a kapott hitelesítési kódot."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megerősít"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikeres művelet"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hitelesítési kódot SMS-ben küldték el. Csak egyszer és korlátozott ideig használható a bejelentkezéshez."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Újraküldés ", _interpolate(_named("seconds")), " másodperc múlva"])}
        },
        "SMSOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hitelesítési kód SMS-ben"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küldd újra"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikeres művelet"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hitelesítési kódot SMS-ben küldték el. Csak egyszer és korlátozott ideig használható a bejelentkezéshez."])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiba"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az üzenetet nem sikerült elküldeni. Kérjük, ellenőrizze a bejegyzést."])}
            }
          }
        }
      },
      "fr-BE": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez et confirmez le code d'authentification reçu."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action réussie"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code d'authentification a été envoyé par SMS. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour se connecter."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Renvoyer dans ", _interpolate(_named("seconds")), " secondes"])}
        },
        "SMSOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code d'authentification par SMS"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer à nouveau"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action réussie"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code d'authentification a été envoyé par SMS. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour se connecter."])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le message n'a pas pu être envoyé. Veuillez vérifier votre saisie."])}
            }
          }
        }
      },
      "fr-LU": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez et confirmez le code d'authentification reçu."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action réussie"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code d'authentification a été envoyé par SMS. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour se connecter."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Renvoyer dans ", _interpolate(_named("seconds")), " secondes"])}
        },
        "SMSOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code d'authentification par SMS"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer à nouveau"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action réussie"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code d'authentification a été envoyé par SMS. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour se connecter."])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le message n'a pas pu être envoyé. Veuillez vérifier votre saisie."])}
            }
          }
        }
      },
      "mt-MT": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daħħal u kkonferma l-kodiċi ta 'awtentikazzjoni riċevut."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikkonferma"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azzjoni ta' suċċess"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il-kodiċi ta' awtentikazzjoni intbagħat permezz ta' SMS. Jista 'jintuża darba biss u fi żmien limitat għall-illoggjar."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Erġa' ibgħat fi ", _interpolate(_named("seconds")), " sekondi"])}
        },
        "SMSOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kodiċi ta 'awtentikazzjoni permezz ta' SMS"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ibgħat mill-ġdid"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azzjoni ta' suċċess"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il-kodiċi ta' awtentikazzjoni intbagħat permezz ta' SMS. Jista 'jintuża darba biss u fi żmien limitat għall-illoggjar."])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Żball"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il-messaġġ ma setax jintbagħat. Jekk jogħġbok iċċekkja d-dħul tiegħek."])}
            }
          }
        }
      },
      "sl-SI": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vnesite in potrdite prejeto kodo za preverjanje pristnosti."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potrdi"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspešno opravljeno"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koda za preverjanje pristnosti je bila poslana prek SMS-a. Uporabite ga lahko samo enkrat in v omejenem času za prijavo."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ponovno pošlji v ", _interpolate(_named("seconds")), " sekundah"])}
        },
        "SMSOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koda za preverjanje pristnosti prek SMS-a"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pošlji ponovno"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspešno opravljeno"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koda za preverjanje pristnosti je bila poslana prek SMS-a. Uporabite ga lahko samo enkrat in v omejenem času za prijavo."])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napaka"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sporočila ni bilo mogoče poslati. Prosimo, preverite svoj vnos."])}
            }
          }
        }
      },
      "tr-TR": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alınan doğrulama kodunu girin ve onaylayın."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onayla"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarılı eylem"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik doğrulama kodu SMS ile gönderildi. Giriş yapmak için yalnızca bir kez ve sınırlı bir süre içinde kullanılabilir."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("seconds")), " saniye içinde yeniden gönder"])}
        },
        "SMSOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS ile doğrulama kodu"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekrar gönderin"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarılı eylem"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik doğrulama kodu SMS ile gönderildi. Giriş yapmak için yalnızca bir kez ve sınırlı bir süre içinde kullanılabilir."])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj gönderilemedi. Lütfen girişinizi kontrol edin."])}
            }
          }
        }
      },
      "tr-CY": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alınan doğrulama kodunu girin ve onaylayın."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onayla"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarılı eylem"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik doğrulama kodu SMS ile gönderildi. Giriş yapmak için yalnızca bir kez ve sınırlı bir süre içinde kullanılabilir."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("seconds")), " saniye içinde yeniden gönder"])}
        },
        "SMSOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS ile doğrulama kodu"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekrar gönderin"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarılı eylem"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik doğrulama kodu SMS ile gönderildi. Giriş yapmak için yalnızca bir kez ve sınırlı bir süre içinde kullanılabilir."])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj gönderilemedi. Lütfen girişinizi kontrol edin."])}
            }
          }
        }
      },
      "uk-UA": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть і підтвердьте отриманий код автентифікації."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтвердити"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успішна акція"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код автентифікації надіслано через SMS. Його можна використовувати лише один раз і протягом обмеженого часу для входу."])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Надіслати повторно через ", _interpolate(_named("seconds")), " секунд"])}
        },
        "SMSOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код автентифікації через SMS"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Надіслати знову"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успішна акція"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код автентифікації надіслано через SMS. Його можна використовувати лише один раз і протягом обмеженого часу для входу."])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Nachricht konnte nicht gesendet werden. Bitte prüfe deine Eingabe."])}
            }
          }
        }
      },
      "zh-CN": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入并确认收到的验证码。"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认"])}
        },
        "smsSent": {
          "notificationPersist": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作成功"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验证码是通过短信发送的。 仅限登录一次，限时使用。"])}
            }
          }
        },
        "resendSMSInSeconds": {
          "buttonLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["在", _interpolate(_named("seconds")), "内重新发送"])}
        },
        "SMSOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过短信验证码"])}
        },
        "resendSMS": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新发送"])}
        },
        "notification": {
          "sendSMS": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作成功"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验证码是通过短信发送的。 仅限登录一次，限时使用。"])}
            },
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["错误"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法发送消息。 请检查您的输入。"])}
            }
          }
        }
      }
    }
  })
}
