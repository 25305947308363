export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ar-AE": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل وتأكد من استلام رمز المصادقة."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد"])}
        },
        "authenticatorOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز المصادقة عبر تطبيق المصادقة"])}
        }
      },
      "ar-EG": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل وتأكد من استلام رمز المصادقة."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد"])}
        },
        "authenticatorOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز المصادقة عبر تطبيق المصادقة"])}
        }
      },
      "ar-SA": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل وتأكد من استلام رمز المصادقة."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد"])}
        },
        "authenticatorOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز المصادقة عبر تطبيق المصادقة"])}
        }
      },
      "el-GR": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε και επιβεβαιώστε τον κωδικό ελέγχου ταυτότητας που λάβατε."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιβεβαίωση"])}
        },
        "authenticatorOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κωδικός ελέγχου ταυτότητας μέσω εφαρμογής ελέγχου ταυτότητας"])}
        }
      },
      "el-CY": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε και επιβεβαιώστε τον κωδικό ελέγχου ταυτότητας που λάβατε."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιβεβαίωση"])}
        },
        "authenticatorOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κωδικός ελέγχου ταυτότητας μέσω εφαρμογής ελέγχου ταυτότητας"])}
        }
      },
      "de-DE": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfangenen Authentifizierungscode eingeben und bestätigen."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])}
        },
        "authenticatorOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifizierungscode per Authentifizierungs-App"])}
        }
      },
      "de-AT": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfangenen Authentifizierungscode eingeben und bestätigen."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])}
        },
        "authenticatorOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifizierungscode per Authentifizierungs-App"])}
        }
      },
      "de-CH": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfangenen Authentifizierungscode eingeben und bestätigen."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])}
        },
        "authenticatorOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifizierungscode per Authentifizierungs-App"])}
        }
      },
      "en-US": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter and confirm the authentication code received."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])}
        },
        "authenticatorOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication code by authentication app"])}
        }
      },
      "en-GB": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter and confirm the authentication code received."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])}
        },
        "authenticatorOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication code by authentication app"])}
        }
      },
      "it-IT": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci e conferma il codice di autenticazione ricevuto."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma"])}
        },
        "authenticatorOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice di autenticazione tramite app di autenticazione"])}
        }
      },
      "fr-FR": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez et confirmez le code d'authentification reçu."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])}
        },
        "authenticatorOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code d'authentification via l'application d'authentification"])}
        }
      },
      "es-ES": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca y confirme el código de autenticación recibido."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])}
        },
        "authenticatorOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de autenticación a través de la aplicación de autenticación"])}
        }
      },
      "pl-PL": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź i potwierdź otrzymany kod uwierzytelniający."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdź"])}
        },
        "authenticatorOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod uwierzytelniający za pośrednictwem aplikacji uwierzytelniającej"])}
        }
      },
      "nl-NL": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer de ontvangen authenticatiecode in en bevestig deze."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestigen"])}
        },
        "authenticatorOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticatiecode via authenticatie-app"])}
        }
      },
      "bg-BG": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въведете и потвърдете получения код за удостоверяване."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потвърждение"])}
        },
        "authenticatorOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код за удостоверяване чрез приложение за удостоверяване"])}
        }
      },
      "bs-BA": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite i potvrdite primljeni kod za autentifikaciju."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdite"])}
        },
        "authenticatorOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kôd za provjeru autentičnosti putem aplikacije za provjeru autentičnosti"])}
        }
      },
      "da-DK": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast og bekræft den modtagne godkendelseskode."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekræft"])}
        },
        "authenticatorOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkendelseskode via godkendelsesapp"])}
        }
      },
      "et-EE": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisestage ja kinnitage saadud autentimiskood."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kinnita"])}
        },
        "authenticatorOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentimiskood autentimisrakenduse kaudu"])}
        }
      },
      "fi-FI": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syötä ja vahvista vastaanotettu todennuskoodi."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vahvista"])}
        },
        "authenticatorOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todennuskoodi todennussovelluksen kautta"])}
        }
      },
      "he-IL": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הזן ואשר את קוד האימות שהתקבל."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["לְאַשֵׁר"])}
        },
        "authenticatorOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["קוד אימות באמצעות אפליקציית אימות"])}
        }
      },
      "ga-IE": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuir isteach agus deimhnigh an cód fíordheimhnithe a fuarthas."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deimhnigh"])}
        },
        "authenticatorOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cód fíordheimhnithe trí aip fíordheimhnithe"])}
        }
      },
      "ja-JP": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受信した認証コードを入力して確認します。"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認"])}
        },
        "authenticatorOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証アプリによる認証コード"])}
        }
      },
      "hr-HR": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite i potvrdite primljeni autentifikacijski kod."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrda"])}
        },
        "authenticatorOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kôd za provjeru autentičnosti putem aplikacije za provjeru autentičnosti"])}
        }
      },
      "lv-LV": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ievadiet un apstipriniet saņemto autentifikācijas kodu."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstiprināt"])}
        },
        "authenticatorOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentifikācijas kods, izmantojot autentifikācijas lietotni"])}
        }
      },
      "lt-LT": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įveskite ir patvirtinkite gautą autentifikavimo kodą."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patvirtinti"])}
        },
        "authenticatorOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentifikavimo kodas per autentifikavimo programą"])}
        }
      },
      "pt-PT": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digite e confirme o código de autenticação recebido."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])}
        },
        "authenticatorOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de autenticação via aplicativo de autenticação"])}
        }
      },
      "ro-RO": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduceți și confirmați codul de autentificare primit."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmare"])}
        },
        "authenticatorOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cod de autentificare prin intermediul aplicației de autentificare"])}
        }
      },
      "ru-RU": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите и подтвердите полученный код аутентификации."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить"])}
        },
        "authenticatorOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код аутентификации через приложение аутентификации"])}
        }
      },
      "sv-SE": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange och bekräfta den mottagna autentiseringskoden."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekräfta"])}
        },
        "authenticatorOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentiseringskod via autentiseringsapp"])}
        }
      },
      "sk-SK": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte a potvrďte prijatý overovací kód."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdiť"])}
        },
        "authenticatorOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentifikačný kód prostredníctvom autentifikačnej aplikácie"])}
        }
      },
      "cs-CZ": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte a potvrďte přijatý ověřovací kód."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdit"])}
        },
        "authenticatorOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověřovací kód prostřednictvím ověřovací aplikace"])}
        }
      },
      "hu-HU": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Írja be és erősítse meg a kapott hitelesítési kódot."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megerősít"])}
        },
        "authenticatorOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hitelesítési kód a hitelesítési alkalmazáson keresztül"])}
        }
      },
      "fr-BE": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez et confirmez le code d'authentification reçu."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])}
        },
        "authenticatorOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code d'authentification via l'application d'authentification"])}
        }
      },
      "fr-LU": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez et confirmez le code d'authentification reçu."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])}
        },
        "authenticatorOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code d'authentification via l'application d'authentification"])}
        }
      },
      "mt-MT": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daħħal u kkonferma l-kodiċi ta 'awtentikazzjoni riċevut."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikkonferma"])}
        },
        "authenticatorOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kodiċi ta 'awtentikazzjoni permezz ta' app ta 'awtentikazzjoni"])}
        }
      },
      "sl-SI": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vnesite in potrdite prejeto kodo za preverjanje pristnosti."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potrdi"])}
        },
        "authenticatorOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koda za preverjanje pristnosti prek aplikacije za preverjanje pristnosti"])}
        }
      },
      "tr-TR": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alınan doğrulama kodunu girin ve onaylayın."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onayla"])}
        },
        "authenticatorOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik doğrulama uygulaması aracılığıyla kimlik doğrulama kodu"])}
        }
      },
      "tr-CY": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alınan doğrulama kodunu girin ve onaylayın."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onayla"])}
        },
        "authenticatorOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik doğrulama uygulaması aracılığıyla kimlik doğrulama kodu"])}
        }
      },
      "uk-UA": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть і підтвердьте отриманий код автентифікації."])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтвердити"])}
        },
        "authenticatorOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код автентифікації через додаток автентифікатора"])}
        }
      },
      "zh-CN": {
        "unlockWith": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入并确认收到的验证码。"])}
        },
        "confirmCode": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认"])}
        },
        "authenticatorOTPForm": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过身份验证应用程序的身份验证代码"])}
        }
      }
    }
  })
}
