export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ar-AE": {
        "skipSetupThisTime": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتخطى"])}
        },
        "switchMethod": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجوع إلى النظرة العامة"])}
        },
        "goBackToLogin": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عودة إلى صفحة تسجيل الدخول"])}
        },
        "notification": {
          "authenticate": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن تأكيد رمز المصادقة."])}
            }
          },
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمل ناجح"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نجح تسجيل الخروج"])}
            }
          }
        }
      },
      "ar-EG": {
        "skipSetupThisTime": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتخطى"])}
        },
        "switchMethod": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجوع إلى النظرة العامة"])}
        },
        "goBackToLogin": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عودة إلى صفحة تسجيل الدخول"])}
        },
        "notification": {
          "authenticate": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن تأكيد رمز المصادقة."])}
            }
          },
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمل ناجح"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نجح تسجيل الخروج"])}
            }
          }
        }
      },
      "ar-SA": {
        "skipSetupThisTime": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتخطى"])}
        },
        "switchMethod": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجوع إلى النظرة العامة"])}
        },
        "goBackToLogin": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عودة إلى صفحة تسجيل الدخول"])}
        },
        "notification": {
          "authenticate": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن تأكيد رمز المصادقة."])}
            }
          },
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمل ناجح"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نجح تسجيل الخروج"])}
            }
          }
        }
      },
      "el-GR": {
        "skipSetupThisTime": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παράλειψη"])}
        },
        "switchMethod": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιστροφή στην επισκόπηση"])}
        },
        "goBackToLogin": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιστροφή στη σελίδα σύνδεσης"])}
        },
        "notification": {
          "authenticate": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σφάλμα"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν ήταν δυνατή η επιβεβαίωση του κωδικού ελέγχου ταυτότητας."])}
            }
          },
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιτυχημένη ενέργεια"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η αποσύνδεση ήταν επιτυχής"])}
            }
          }
        }
      },
      "el-CY": {
        "skipSetupThisTime": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παράλειψη"])}
        },
        "switchMethod": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιστροφή στην επισκόπηση"])}
        },
        "goBackToLogin": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιστροφή στη σελίδα σύνδεσης"])}
        },
        "notification": {
          "authenticate": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σφάλμα"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν ήταν δυνατή η επιβεβαίωση του κωδικού ελέγχου ταυτότητας."])}
            }
          },
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιτυχημένη ενέργεια"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η αποσύνδεση ήταν επιτυχής"])}
            }
          }
        }
      },
      "de-DE": {
        "skipSetupThisTime": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überspringen"])}
        },
        "switchMethod": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Übersicht"])}
        },
        "goBackToLogin": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Anmeldeseite"])}
        },
        "notification": {
          "authenticate": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Authentifizierungscode konnte nicht bestätigt werden."])}
            }
          },
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreiche Aktion"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Abmeldung war erfolgreich"])}
            }
          }
        }
      },
      "de-AT": {
        "skipSetupThisTime": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überspringen"])}
        },
        "switchMethod": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Übersicht"])}
        },
        "goBackToLogin": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Anmeldeseite"])}
        },
        "notification": {
          "authenticate": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Authentifizierungscode konnte nicht bestätigt werden."])}
            }
          },
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreiche Aktion"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Abmeldung war erfolgreich"])}
            }
          }
        }
      },
      "de-CH": {
        "skipSetupThisTime": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überspringen"])}
        },
        "switchMethod": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Übersicht"])}
        },
        "goBackToLogin": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Anmeldeseite"])}
        },
        "notification": {
          "authenticate": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Authentifizierungscode konnte nicht bestätigt werden."])}
            }
          },
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreiche Aktion"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Abmeldung war erfolgreich"])}
            }
          }
        }
      },
      "en-US": {
        "skipSetupThisTime": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skip"])}
        },
        "switchMethod": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to overview"])}
        },
        "goBackToLogin": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to login page"])}
        },
        "notification": {
          "authenticate": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The authentication code could not be confirmed."])}
            }
          },
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful action"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logging out was successful"])}
            }
          }
        }
      },
      "en-GB": {
        "skipSetupThisTime": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skip"])}
        },
        "switchMethod": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to overview"])}
        },
        "goBackToLogin": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to login page"])}
        },
        "notification": {
          "authenticate": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The authentication code could not be confirmed."])}
            }
          },
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful action"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logging out was successful"])}
            }
          }
        }
      },
      "it-IT": {
        "skipSetupThisTime": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salta"])}
        },
        "switchMethod": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna alla panoramica"])}
        },
        "goBackToLogin": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna alla pagina di login"])}
        },
        "notification": {
          "authenticate": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è stato possibile confermare il codice di autenticazione."])}
            }
          },
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azione riuscita"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La disconnessione ha avuto successo"])}
            }
          }
        }
      },
      "fr-FR": {
        "skipSetupThisTime": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauter"])}
        },
        "switchMethod": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à la page d'accueil"])}
        },
        "goBackToLogin": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à la page de connexion"])}
        },
        "notification": {
          "authenticate": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code d'authentification n'a pas pu être confirmé."])}
            }
          },
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action réussie"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La déconnexion a réussi"])}
            }
          }
        }
      },
      "es-ES": {
        "skipSetupThisTime": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saltar"])}
        },
        "switchMethod": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atrás"])}
        },
        "goBackToLogin": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a la página de inicio de sesión"])}
        },
        "notification": {
          "authenticate": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo confirmar el código de autenticación."])}
            }
          },
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listo"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha cerrado la sesión correctamente"])}
            }
          }
        }
      },
      "pl-PL": {
        "skipSetupThisTime": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pomiń"])}
        },
        "switchMethod": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powrót do przeglądu"])}
        },
        "goBackToLogin": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powrót do strony logowania"])}
        },
        "notification": {
          "authenticate": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błąd"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie udało się potwierdzić kodu uwierzytelniającego."])}
            }
          },
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czynność zakończona powodzeniem"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wylogowanie powiodło się"])}
            }
          }
        }
      },
      "nl-NL": {
        "skipSetupThisTime": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overslaan"])}
        },
        "switchMethod": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar overzicht"])}
        },
        "goBackToLogin": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar inlogpagina"])}
        },
        "notification": {
          "authenticate": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De authencitatiecode kon niet worden bevestigd."])}
            }
          },
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geslaagde actie"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitloggen is gelukt"])}
            }
          }
        }
      },
      "bg-BG": {
        "skipSetupThisTime": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пропускане"])}
        },
        "switchMethod": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад към общ преглед"])}
        },
        "goBackToLogin": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обратно към страницата за вход"])}
        },
        "notification": {
          "authenticate": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["грешка"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кодът за удостоверяване не може да бъде потвърден."])}
            }
          },
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно действие"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Излизането беше успешно"])}
            }
          }
        }
      },
      "bs-BA": {
        "skipSetupThisTime": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preskoči"])}
        },
        "switchMethod": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povratak na pregled"])}
        },
        "goBackToLogin": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazad na stranicu za prijavu"])}
        },
        "notification": {
          "authenticate": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greška"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nije moguće potvrditi autentifikacijski kôd."])}
            }
          },
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno obavljeno"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odjava je bila uspješna"])}
            }
          }
        }
      },
      "da-DK": {
        "skipSetupThisTime": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["spring over"])}
        },
        "switchMethod": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbage til oversigt"])}
        },
        "goBackToLogin": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbage til loginsiden"])}
        },
        "notification": {
          "authenticate": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fejl"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkendelseskoden kunne ikke bekræftes."])}
            }
          },
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vellykket handling"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det lykkedes at logge ud"])}
            }
          }
        }
      },
      "et-EE": {
        "skipSetupThisTime": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jäta vahele"])}
        },
        "switchMethod": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagasi ülevaate juurde"])}
        },
        "goBackToLogin": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagasi sisselogimise lehele"])}
        },
        "notification": {
          "authenticate": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viga"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentimiskoodi ei õnnestunud kinnitada."])}
            }
          },
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edukas tegevus"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väljalogimine õnnestus"])}
            }
          }
        }
      },
      "fi-FI": {
        "skipSetupThisTime": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohita"])}
        },
        "switchMethod": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takaisin yleiskatsaukseen"])}
        },
        "goBackToLogin": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takaisin kirjautumissivulle"])}
        },
        "notification": {
          "authenticate": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virhe"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todennuskoodia ei voitu vahvistaa."])}
            }
          },
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onnistunut toiminta"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uloskirjautuminen onnistui"])}
            }
          }
        }
      },
      "he-IL": {
        "skipSetupThisTime": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["לדלג"])}
        },
        "switchMethod": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["חזרה לסקירה כללית"])}
        },
        "goBackToLogin": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["חזרה לדף הכניסה"])}
        },
        "notification": {
          "authenticate": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שְׁגִיאָה"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["לא ניתן היה לאשר את קוד האימות."])}
            }
          },
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["פעולה מוצלחת"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ההתנתקות הצליחה"])}
            }
          }
        }
      },
      "ga-IE": {
        "skipSetupThisTime": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scipeáil"])}
        },
        "switchMethod": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ar ais go forbhreathnú"])}
        },
        "goBackToLogin": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ar ais go dtí an leathanach logáil isteach"])}
        },
        "notification": {
          "authenticate": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earráid"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Níorbh fhéidir an cód fíordheimhnithe a dhearbhú."])}
            }
          },
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gníomh rathúil"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D'éirigh leis an logáil amach"])}
            }
          }
        }
      },
      "ja-JP": {
        "skipSetupThisTime": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スキップ"])}
        },
        "switchMethod": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["概要に戻る"])}
        },
        "goBackToLogin": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログインページに戻ります"])}
        },
        "notification": {
          "authenticate": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エラー"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証コードが確認できませんでした。"])}
            }
          },
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功したアクション"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログアウト成功"])}
            }
          }
        }
      },
      "hr-HR": {
        "skipSetupThisTime": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preskočiti"])}
        },
        "switchMethod": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povratak na pregled"])}
        },
        "goBackToLogin": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vratite se na stranicu za prijavu"])}
        },
        "notification": {
          "authenticate": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pogreška"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nije moguće potvrditi autentifikacijski kod."])}
            }
          },
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješna akcija"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odjava je bila uspješna"])}
            }
          }
        }
      },
      "lv-LV": {
        "skipSetupThisTime": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izlaist"])}
        },
        "switchMethod": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atgriezties uz kopsavilkumu"])}
        },
        "goBackToLogin": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atgriezties uz pieteikšanās lapu"])}
        },
        "notification": {
          "authenticate": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kļūda"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nevarēja apstiprināt autentifikācijas kodu."])}
            }
          },
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veiksmīga darbība"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atteikšanās sekmīga"])}
            }
          }
        }
      },
      "lt-LT": {
        "skipSetupThisTime": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praleisti"])}
        },
        "switchMethod": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grįžti į apžvalgą"])}
        },
        "goBackToLogin": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grįžti į prisijungimo puslapį"])}
        },
        "notification": {
          "authenticate": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klaida"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepavyko patvirtinti autentifikavimo kodo."])}
            }
          },
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sėkmingas veiksmas"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atsijungimas buvo sėkmingas"])}
            }
          }
        }
      },
      "pt-PT": {
        "skipSetupThisTime": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorar"])}
        },
        "switchMethod": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltar ao menu principal"])}
        },
        "goBackToLogin": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltar à página de início de sessão"])}
        },
        "notification": {
          "authenticate": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não foi possível confirmar o código de autenticação."])}
            }
          },
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ação bem-sucedida"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout bem-sucedido"])}
            }
          }
        }
      },
      "ro-RO": {
        "skipSetupThisTime": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorare"])}
        },
        "switchMethod": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Înapoi la prezentarea generală"])}
        },
        "goBackToLogin": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Înapoi la pagina de conectare"])}
        },
        "notification": {
          "authenticate": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eroare"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codul de autentificare nu a putut fi confirmat."])}
            }
          },
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acțiune reușită"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deconectarea a avut succes"])}
            }
          }
        }
      },
      "ru-RU": {
        "skipSetupThisTime": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пропустить"])}
        },
        "switchMethod": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вернуться к общим сведениям"])}
        },
        "goBackToLogin": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад на страницу входа"])}
        },
        "notification": {
          "authenticate": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код аутентификации не подтвержден."])}
            }
          },
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешное действие"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выход из системы прошел успешно"])}
            }
          }
        }
      },
      "sv-SE": {
        "skipSetupThisTime": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoppa över"])}
        },
        "switchMethod": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillbaka till översikt"])}
        },
        "goBackToLogin": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Återvänd till inloggningssidan"])}
        },
        "notification": {
          "authenticate": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fel"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentiseringskoden kunde inte bekräftas."])}
            }
          },
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åtgärd utförd"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utloggningen lyckades"])}
            }
          }
        }
      },
      "sk-SK": {
        "skipSetupThisTime": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preskočiť"])}
        },
        "switchMethod": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Späť na prehľad"])}
        },
        "goBackToLogin": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Späť na prihlasovaciu stránku"])}
        },
        "notification": {
          "authenticate": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overovací kód nebolo možné potvrdiť."])}
            }
          },
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspešná akcia"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odhlásenie bolo úspešné"])}
            }
          }
        }
      },
      "cs-CZ": {
        "skipSetupThisTime": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přeskočit"])}
        },
        "switchMethod": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpět na přehled"])}
        },
        "goBackToLogin": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpět na přihlašovací stránku"])}
        },
        "notification": {
          "authenticate": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nelze potvrdit ověřovací kód."])}
            }
          },
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspěšná akce"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odhlášení bylo úspěšné"])}
            }
          }
        }
      },
      "hu-HU": {
        "skipSetupThisTime": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kihagy"])}
        },
        "switchMethod": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vissza az áttekintéshez"])}
        },
        "goBackToLogin": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vissza a bejelentkezési oldalra"])}
        },
        "notification": {
          "authenticate": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiba"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hitelesítő kódot nem sikerült megerősíteni."])}
            }
          },
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikeres művelet"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kijelentkezés sikeres volt"])}
            }
          }
        }
      },
      "fr-BE": {
        "skipSetupThisTime": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauter"])}
        },
        "switchMethod": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à la page d'accueil"])}
        },
        "goBackToLogin": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à la page de connexion"])}
        },
        "notification": {
          "authenticate": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code d'authentification n'a pas pu être confirmé."])}
            }
          },
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action réussie"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La déconnexion a réussi"])}
            }
          }
        }
      },
      "fr-LU": {
        "skipSetupThisTime": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauter"])}
        },
        "switchMethod": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à la page d'accueil"])}
        },
        "goBackToLogin": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à la page de connexion"])}
        },
        "notification": {
          "authenticate": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code d'authentification n'a pas pu être confirmé."])}
            }
          },
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action réussie"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La déconnexion a réussi"])}
            }
          }
        }
      },
      "mt-MT": {
        "skipSetupThisTime": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aqbeż"])}
        },
        "switchMethod": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lura għall-ħarsa ġenerali"])}
        },
        "goBackToLogin": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lura lejn il-paġna tad-dħul"])}
        },
        "notification": {
          "authenticate": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Żball"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il-kodiċi ta' awtentikazzjoni ma setax jiġi kkonfermat."])}
            }
          },
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azzjoni ta' suċċess"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id-dereġistrazzjoni kienet suċċess"])}
            }
          }
        }
      },
      "sl-SI": {
        "skipSetupThisTime": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preskoči"])}
        },
        "switchMethod": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazaj na pregled"])}
        },
        "goBackToLogin": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazaj na prijavo"])}
        },
        "notification": {
          "authenticate": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napaka"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode za preverjanje pristnosti ni bilo mogoče potrditi."])}
            }
          },
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspešno opravljeno"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odjava je uspela"])}
            }
          }
        }
      },
      "tr-TR": {
        "skipSetupThisTime": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atla"])}
        },
        "switchMethod": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genel bakışa dön"])}
        },
        "goBackToLogin": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum açma sayfasına dön"])}
        },
        "notification": {
          "authenticate": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik doğrulama kodu onaylanamadı."])}
            }
          },
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarılı eylem"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çıkış başarılı oldu"])}
            }
          }
        }
      },
      "tr-CY": {
        "skipSetupThisTime": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atla"])}
        },
        "switchMethod": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genel bakışa dön"])}
        },
        "goBackToLogin": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum açma sayfasına dön"])}
        },
        "notification": {
          "authenticate": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik doğrulama kodu onaylanamadı."])}
            }
          },
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarılı eylem"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çıkış başarılı oldu"])}
            }
          }
        }
      },
      "uk-UA": {
        "skipSetupThisTime": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überspringen"])}
        },
        "switchMethod": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Übersicht"])}
        },
        "goBackToLogin": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повернутися до сторінки входу"])}
        },
        "notification": {
          "authenticate": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося підтвердити код автентифікації."])}
            }
          },
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успішна акція"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вихід успішно виконано"])}
            }
          }
        }
      },
      "zh-CN": {
        "skipSetupThisTime": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["跳过"])}
        },
        "switchMethod": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回概览"])}
        },
        "goBackToLogin": {
          "linkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回登录页面"])}
        },
        "notification": {
          "authenticate": {
            "asyncError": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["错误"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法确认验证码。"])}
            }
          },
          "logout": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作成功"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注销成功"])}
            }
          }
        }
      }
    }
  })
}
