<script setup>
import { defineEmits, defineProps, inject, onBeforeMount } from "vue";
import { useI18n } from "vue-i18n";
import { ref, reactive, computed } from 'vue';
import { to } from "await-to-js";
import { get } from "lodash";
import { interceptor } from '@dutypay/utilities';
import {useEnvStore} from "@dutypay/store-modules";

const props = defineProps({
  modelValue: { type: Object, required: true }
});
const notification = reactive({
  sendSMS: {
    success: false,
    asyncError: false
  }
});
const isLoadingAuthSMS = ref(false);
const countdown = ref(0);
const { t } = useI18n();
const composables = inject("composables");
const emit = defineEmits(["update", "trigger-authenticate"]);
const loginData = composables.useTwoWayBinding(props, emit, "modelValue");
const isResendDisabled = ref(false);
const envStore = useEnvStore();

async function verifySMSAuthentication() {
  loginData.value.mode = "sms";
  emit("trigger-authenticate");
}

const mobileNumberDisguised = computed(() => {
  // Hide parts of the mobile number
  let mobileNumber = get(loginData.value, "mobileNumber", "");
  if (mobileNumber === "")
    return "";
  return mobileNumber.substr(0, 4) + "..." + mobileNumber.substr(mobileNumber.length - 3, 3);
});
const phraseWithMobileNumber = computed(() => {
  return t("unlockWith.label", { mobileNumber: `<bdo dir="ltr">${mobileNumberDisguised}</bdo>`, });
});

function countDownTimer() {
  if (countdown.value > 0) {
    setTimeout(() => {
      countdown.value -= 1;
      countDownTimer();
    }, 1000);
  } else {
    isResendDisabled.value = false;
  }
}

async function sendAuthenticationSMS() {
  isResendDisabled.value = true;
  isLoadingAuthSMS.value = true;
  notification.sendSMS.success = false;
  notification.sendSMS.asyncError = false;
  let err;
  [err] = await to(interceptor.post(`${envStore.apiUrls.dutypay}/api/v0/send/auth-sms`, {
      ...loginData.value
    }));
  isLoadingAuthSMS.value = false;
  if (err) {
    isResendDisabled.value = false;
    notification.sendSMS.success = false;
    notification.sendSMS.asyncError = true;
    throw err;
  }
  notification.sendSMS.success = true;
  notification.sendSMS.asyncError = false;
  countdown.value = 20;
  countDownTimer();
}
onBeforeMount(async () => {
  await sendAuthenticationSMS();
});
</script>
<script>

export default {
  name: "SMSOTPForm"
};
</script>

<template>
  <div>
    <h2>{{ t("SMSOTPForm.title") }}</h2>

    <DpNotification
      :visibility="notification.sendSMS.success"
      :title="t('notification.sendSMS.success.title')"
      :message="t('notification.sendSMS.success.message')"
      type="success">
      <template #controls>
        <dp-button
          purpose="secondaryAction"
          class="full-width"
          :disabled="isResendDisabled"
          @click="sendAuthenticationSMS">
          <span v-if="isResendDisabled">{{
              t("resendSMSInSeconds.buttonLabel", { seconds: countdown })
            }}</span>
          <span v-else>{{ t("resendSMS.buttonLabel") }}</span>
        </dp-button>
      </template>
    </DpNotification>

    <DpNotification
      :visibility="notification.sendSMS.asyncError"
      :title="t('notification.sendSMS.asyncError.title')"
      :message="t('notification.sendSMS.asyncError.message')"
      type="error"
    ></DpNotification>

    <p v-html="phraseWithMobileNumber"></p>

    <DpForm
      v-model="loginData"
      v-loading="isLoadingAuthSMS"
      hide-required-asterisk
      reference="verify-sms-authentication"
      :action="verifySMSAuthentication"
      :primaryButtonLabel="t('confirmCode.buttonLabel')"
      :primaryButtonParameter="{
        class: ['full-width'],
        size: 'large',
      }"
      disable-notification>
      <DpInputMultiFactorAuthenticationCode
        v-model="loginData.SMSOTP"
        prop="SMSOTP"
        required
        :input-parameters="{ size: 'large' }"/>
    </DpForm>
  </div>
</template>

<i18n>
{
  "ar-AE": {
    "unlockWith": {
      "label": "أدخل وتأكد من استلام رمز المصادقة."
    },
    "confirmCode": {
      "buttonLabel": "تأكيد"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "title": "عمل ناجح",
          "message": "تم إرسال رمز المصادقة عبر الرسائل القصيرة. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة لتسجيل الدخول."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "إعادة إرسال في {seconds} ثوان"
    },
    "SMSOTPForm": {
      "title": "رمز المصادقة عبر الرسائل القصيرة"
    },
    "resendSMS": {
      "buttonLabel": "أعد الإرسال"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "title": "عمل ناجح",
          "message": "تم إرسال رمز المصادقة عبر الرسائل القصيرة. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة لتسجيل الدخول."
        },
        "asyncError": {
          "title": "خطأ",
          "message": "تعذر إرسال الرسالة. يرجى التحقق من دخولك."
        }
      }
    }
  },
  "ar-EG": {
    "unlockWith": {
      "label": "أدخل وتأكد من استلام رمز المصادقة."
    },
    "confirmCode": {
      "buttonLabel": "تأكيد"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "title": "عمل ناجح",
          "message": "تم إرسال رمز المصادقة عبر الرسائل القصيرة. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة لتسجيل الدخول."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "إعادة إرسال في {seconds} ثوان"
    },
    "SMSOTPForm": {
      "title": "رمز المصادقة عبر الرسائل القصيرة"
    },
    "resendSMS": {
      "buttonLabel": "أعد الإرسال"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "title": "عمل ناجح",
          "message": "تم إرسال رمز المصادقة عبر الرسائل القصيرة. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة لتسجيل الدخول."
        },
        "asyncError": {
          "title": "خطأ",
          "message": "تعذر إرسال الرسالة. يرجى التحقق من دخولك."
        }
      }
    }
  },
  "ar-SA": {
    "unlockWith": {
      "label": "أدخل وتأكد من استلام رمز المصادقة."
    },
    "confirmCode": {
      "buttonLabel": "تأكيد"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "title": "عمل ناجح",
          "message": "تم إرسال رمز المصادقة عبر الرسائل القصيرة. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة لتسجيل الدخول."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "إعادة إرسال في {seconds} ثوان"
    },
    "SMSOTPForm": {
      "title": "رمز المصادقة عبر الرسائل القصيرة"
    },
    "resendSMS": {
      "buttonLabel": "أعد الإرسال"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "title": "عمل ناجح",
          "message": "تم إرسال رمز المصادقة عبر الرسائل القصيرة. يمكن استخدامه مرة واحدة فقط وضمن فترة زمنية محدودة لتسجيل الدخول."
        },
        "asyncError": {
          "title": "خطأ",
          "message": "تعذر إرسال الرسالة. يرجى التحقق من دخولك."
        }
      }
    }
  },
  "el-GR": {
    "unlockWith": {
      "label": "Εισαγάγετε και επιβεβαιώστε τον κωδικό ελέγχου ταυτότητας που λάβατε."
    },
    "confirmCode": {
      "buttonLabel": "Επιβεβαίωση"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "title": "Επιτυχημένη ενέργεια",
          "message": "Ο κωδικός ελέγχου ταυτότητας στάλθηκε μέσω SMS. Μπορεί να χρησιμοποιηθεί μόνο μία φορά και εντός περιορισμένου χρόνου για τη σύνδεση."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Επανάληψη αποστολής σε {seconds} δευτερόλεπτα"
    },
    "SMSOTPForm": {
      "title": "Κωδικός ελέγχου ταυτότητας μέσω SMS"
    },
    "resendSMS": {
      "buttonLabel": "Στείλε ξανά"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "title": "Επιτυχημένη ενέργεια",
          "message": "Ο κωδικός ελέγχου ταυτότητας στάλθηκε μέσω SMS. Μπορεί να χρησιμοποιηθεί μόνο μία φορά και εντός περιορισμένου χρόνου για τη σύνδεση."
        },
        "asyncError": {
          "title": "Σφάλμα",
          "message": "Δεν ήταν δυνατή η αποστολή του μηνύματος. Ελέγξτε την καταχώρισή σας."
        }
      }
    }
  },
  "el-CY": {
    "unlockWith": {
      "label": "Εισαγάγετε και επιβεβαιώστε τον κωδικό ελέγχου ταυτότητας που λάβατε."
    },
    "confirmCode": {
      "buttonLabel": "Επιβεβαίωση"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "title": "Επιτυχημένη ενέργεια",
          "message": "Ο κωδικός ελέγχου ταυτότητας στάλθηκε μέσω SMS. Μπορεί να χρησιμοποιηθεί μόνο μία φορά και εντός περιορισμένου χρόνου για τη σύνδεση."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Επανάληψη αποστολής σε {seconds} δευτερόλεπτα"
    },
    "SMSOTPForm": {
      "title": "Κωδικός ελέγχου ταυτότητας μέσω SMS"
    },
    "resendSMS": {
      "buttonLabel": "Στείλε ξανά"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "title": "Επιτυχημένη ενέργεια",
          "message": "Ο κωδικός ελέγχου ταυτότητας στάλθηκε μέσω SMS. Μπορεί να χρησιμοποιηθεί μόνο μία φορά και εντός περιορισμένου χρόνου για τη σύνδεση."
        },
        "asyncError": {
          "title": "Σφάλμα",
          "message": "Δεν ήταν δυνατή η αποστολή του μηνύματος. Ελέγξτε την καταχώρισή σας."
        }
      }
    }
  },
  "de-DE": {
    "unlockWith": {
      "label": "Empfangenen Authentifizierungscode eingeben und bestätigen."
    },
    "confirmCode": {
      "buttonLabel": "Bestätigen"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "title": "Erfolgreiche Aktion",
          "message": "Der Authentifizierungscode wurde per SMS gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Erneut senden in {seconds} Sekunden"
    },
    "SMSOTPForm": {
      "title": "Authentifizierungscode per SMS"
    },
    "resendSMS": {
      "buttonLabel": "Erneut senden"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "title": "Erfolgreiche Aktion",
          "message": "Der Authentifizierungscode wurde per SMS gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden."
        },
        "asyncError": {
          "title": "Fehler",
          "message": "Die Nachricht konnte nicht gesendet werden. Bitte prüfe deine Eingabe."
        }
      }
    }
  },
  "de-AT": {
    "unlockWith": {
      "label": "Empfangenen Authentifizierungscode eingeben und bestätigen."
    },
    "confirmCode": {
      "buttonLabel": "Bestätigen"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "title": "Erfolgreiche Aktion",
          "message": "Der Authentifizierungscode wurde per SMS gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Erneut senden in {seconds} Sekunden"
    },
    "SMSOTPForm": {
      "title": "Authentifizierungscode per SMS"
    },
    "resendSMS": {
      "buttonLabel": "Erneut senden"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "title": "Erfolgreiche Aktion",
          "message": "Der Authentifizierungscode wurde per SMS gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden."
        },
        "asyncError": {
          "title": "Fehler",
          "message": "Die Nachricht konnte nicht gesendet werden. Bitte prüfe deine Eingabe."
        }
      }
    }
  },
  "de-CH": {
    "unlockWith": {
      "label": "Empfangenen Authentifizierungscode eingeben und bestätigen."
    },
    "confirmCode": {
      "buttonLabel": "Bestätigen"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "title": "Erfolgreiche Aktion",
          "message": "Der Authentifizierungscode wurde per SMS gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Erneut senden in {seconds} Sekunden"
    },
    "SMSOTPForm": {
      "title": "Authentifizierungscode per SMS"
    },
    "resendSMS": {
      "buttonLabel": "Erneut senden"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "title": "Erfolgreiche Aktion",
          "message": "Der Authentifizierungscode wurde per SMS gesendet. Er kann nur einmalig und innerhalb einer begrenzten Zeit für die Anmeldung verwendet werden."
        },
        "asyncError": {
          "title": "Fehler",
          "message": "Die Nachricht konnte nicht gesendet werden. Bitte prüfe deine Eingabe."
        }
      }
    }
  },
  "en-US": {
    "unlockWith": {
      "label": "Enter and confirm the authentication code received."
    },
    "confirmCode": {
      "buttonLabel": "Confirm"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "title": "Successful action",
          "message": "The authentication code was sent via SMS. It can only be used once and within a limited time for logging in.."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Resend in {seconds} seconds"
    },
    "SMSOTPForm": {
      "title": "Authentication code by SMS"
    },
    "resendSMS": {
      "buttonLabel": "Send again"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "title": "Successful action",
          "message": "The authentication code was sent via SMS. It can only be used once and within a limited time for logging in.."
        },
        "asyncError": {
          "title": "Error",
          "message": "The message could not be sent. Please check your entry."
        }
      }
    }
  },
  "en-GB": {
    "unlockWith": {
      "label": "Enter and confirm the authentication code received."
    },
    "confirmCode": {
      "buttonLabel": "Confirm"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "title": "Successful action",
          "message": "The authentication code was sent via SMS. It can only be used once and within a limited time for logging in.."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Resend in {seconds} seconds"
    },
    "SMSOTPForm": {
      "title": "Authentication code by SMS"
    },
    "resendSMS": {
      "buttonLabel": "Send again"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "title": "Successful action",
          "message": "The authentication code was sent via SMS. It can only be used once and within a limited time for logging in.."
        },
        "asyncError": {
          "title": "Error",
          "message": "The message could not be sent. Please check your entry."
        }
      }
    }
  },
  "it-IT": {
    "unlockWith": {
      "label": "Inserisci e conferma il codice di autenticazione ricevuto."
    },
    "confirmCode": {
      "buttonLabel": "Conferma"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "title": "Azione riuscita",
          "message": "Il codice di autenticazione è stato inviato tramite SMS. Può essere utilizzato solo una volta ed entro un tempo limitato per l'accesso."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Invia nuovamente tra {seconds} secondi"
    },
    "SMSOTPForm": {
      "title": "Codice di autenticazione via SMS"
    },
    "resendSMS": {
      "buttonLabel": "Invia di nuovo"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "title": "Azione riuscita",
          "message": "Il codice di autenticazione è stato inviato tramite SMS. Può essere utilizzato solo una volta ed entro un tempo limitato per l'accesso."
        },
        "asyncError": {
          "title": "Errore",
          "message": "Impossibile inviare il messaggio. Si prega di controllare la tua voce."
        }
      }
    }
  },
  "fr-FR": {
    "unlockWith": {
      "label": "Entrez et confirmez le code d'authentification reçu."
    },
    "confirmCode": {
      "buttonLabel": "Confirmer"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "title": "Action réussie",
          "message": "Le code d'authentification a été envoyé par SMS. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour se connecter."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Renvoyer dans {seconds} secondes"
    },
    "SMSOTPForm": {
      "title": "Code d'authentification par SMS"
    },
    "resendSMS": {
      "buttonLabel": "Envoyer à nouveau"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "title": "Action réussie",
          "message": "Le code d'authentification a été envoyé par SMS. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour se connecter."
        },
        "asyncError": {
          "title": "Erreur",
          "message": "Le message n'a pas pu être envoyé. Veuillez vérifier votre saisie."
        }
      }
    }
  },
  "es-ES": {
    "unlockWith": {
      "label": "Introduzca y confirme el código de autenticación recibido."
    },
    "confirmCode": {
      "buttonLabel": "Confirmar"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "title": "Listo",
          "message": "El código de autenticación se envió por SMS. Solo se puede usar una vez y dentro de un tiempo limitado para iniciar sesión."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Enviar de nuevo en {seconds} segundos"
    },
    "SMSOTPForm": {
      "title": "Código de autenticación por SMS"
    },
    "resendSMS": {
      "buttonLabel": "Enviar de nuevo"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "title": "Listo",
          "message": "El código de autenticación se envió por SMS. Solo se puede usar una vez y dentro de un tiempo limitado para iniciar sesión."
        },
        "asyncError": {
          "title": "Error",
          "message": "El mensaje no se pudo enviar. Por favor revise su entrada."
        }
      }
    }
  },
  "pl-PL": {
    "unlockWith": {
      "label": "Wprowadź i potwierdź otrzymany kod uwierzytelniający."
    },
    "confirmCode": {
      "buttonLabel": "Potwierdź"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "title": "Czynność zakończona powodzeniem",
          "message": "Kod uwierzytelniający został wysłany SMS-em. Można go użyć tylko raz iw ograniczonym czasie do zalogowania."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Wyślij ponownie za {seconds} s"
    },
    "SMSOTPForm": {
      "title": "Kod uwierzytelniający przez SMS"
    },
    "resendSMS": {
      "buttonLabel": "Wyślij ponownie"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "title": "Czynność zakończona powodzeniem",
          "message": "Kod uwierzytelniający został wysłany SMS-em. Można go użyć tylko raz iw ograniczonym czasie do zalogowania."
        },
        "asyncError": {
          "title": "Błąd",
          "message": "Nie udało się wysłać wiadomości. Sprawdź swój wpis."
        }
      }
    }
  },
  "nl-NL": {
    "unlockWith": {
      "label": "Voer de ontvangen authenticatiecode in en bevestig deze."
    },
    "confirmCode": {
      "buttonLabel": "Bevestigen"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "title": "Geslaagde actie",
          "message": "De authenticatiecode is per sms verzonden. Het kan slechts één keer en binnen een beperkte tijd worden gebruikt om in te loggen."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Verzenden over {seconds} seconden"
    },
    "SMSOTPForm": {
      "title": "Authenticatiecode via sms"
    },
    "resendSMS": {
      "buttonLabel": "Opnieuw verzenden"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "title": "Geslaagde actie",
          "message": "De authenticatiecode is per sms verzonden. Het kan slechts één keer en binnen een beperkte tijd worden gebruikt om in te loggen."
        },
        "asyncError": {
          "title": "Fout",
          "message": "Het bericht kon niet worden verzonden. Controleer uw invoer."
        }
      }
    }
  },
  "bg-BG": {
    "unlockWith": {
      "label": "Въведете и потвърдете получения код за удостоверяване."
    },
    "confirmCode": {
      "buttonLabel": "Потвърждение"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "title": "Успешно действие",
          "message": "Кодът за удостоверяване беше изпратен чрез SMS. Може да се използва само веднъж и в рамките на ограничено време за влизане."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Изпращане след {seconds} секунди"
    },
    "SMSOTPForm": {
      "title": "Код за удостоверяване чрез SMS"
    },
    "resendSMS": {
      "buttonLabel": "Изпрати отново"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "title": "Успешно действие",
          "message": "Кодът за удостоверяване беше изпратен чрез SMS. Може да се използва само веднъж и в рамките на ограничено време за влизане."
        },
        "asyncError": {
          "title": "грешка",
          "message": "Съобщението не можа да бъде изпратено. Моля, проверете вашето въвеждане."
        }
      }
    }
  },
  "bs-BA": {
    "unlockWith": {
      "label": "Unesite i potvrdite primljeni kod za autentifikaciju."
    },
    "confirmCode": {
      "buttonLabel": "Potvrdite"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "title": "Uspješno obavljeno",
          "message": "Kod za autentifikaciju je poslan putem SMS-a. Može se koristiti samo jednom iu ograničenom vremenu za prijavu."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Ponovo pošalji za {seconds} sekundi"
    },
    "SMSOTPForm": {
      "title": "Autentifikacijski kod putem SMS-a"
    },
    "resendSMS": {
      "buttonLabel": "Pošalji ponovo"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "title": "Uspješno obavljeno",
          "message": "Kod za autentifikaciju je poslan putem SMS-a. Može se koristiti samo jednom iu ograničenom vremenu za prijavu."
        },
        "asyncError": {
          "title": "Greška",
          "message": "Poruka se nije mogla poslati. Molimo provjerite svoj unos."
        }
      }
    }
  },
  "da-DK": {
    "unlockWith": {
      "label": "Indtast og bekræft den modtagne godkendelseskode."
    },
    "confirmCode": {
      "buttonLabel": "Bekræft"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "title": "Vellykket handling",
          "message": "Godkendelseskoden blev sendt via SMS. Det kan kun bruges én gang og inden for en begrænset tid til at logge ind."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Send igen om {seconds} sekunder"
    },
    "SMSOTPForm": {
      "title": "Godkendelseskode via SMS"
    },
    "resendSMS": {
      "buttonLabel": "Send igen"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "title": "Vellykket handling",
          "message": "Godkendelseskoden blev sendt via SMS. Det kan kun bruges én gang og inden for en begrænset tid til at logge ind."
        },
        "asyncError": {
          "title": "Fejl",
          "message": "Meddelelsen kunne ikke sendes. Tjek venligst din post."
        }
      }
    }
  },
  "et-EE": {
    "unlockWith": {
      "label": "Sisestage ja kinnitage saadud autentimiskood."
    },
    "confirmCode": {
      "buttonLabel": "Kinnita"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "title": "Edukas tegevus",
          "message": "Autentimiskood saadeti SMS-iga. Seda saab kasutada ainult üks kord ja piiratud aja jooksul sisselogimiseks."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Saada uuesti {seconds} sekundi pärast"
    },
    "SMSOTPForm": {
      "title": "Autentimiskood SMS-i teel"
    },
    "resendSMS": {
      "buttonLabel": "Saada uuesti"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "title": "Edukas tegevus",
          "message": "Autentimiskood saadeti SMS-iga. Seda saab kasutada ainult üks kord ja piiratud aja jooksul sisselogimiseks."
        },
        "asyncError": {
          "title": "Viga",
          "message": "Sõnumit ei õnnestunud saata. Palun kontrollige oma sisestust."
        }
      }
    }
  },
  "fi-FI": {
    "unlockWith": {
      "label": "Syötä ja vahvista vastaanotettu todennuskoodi."
    },
    "confirmCode": {
      "buttonLabel": "Vahvista"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "title": "Onnistunut toiminta",
          "message": "Todennuskoodi lähetettiin tekstiviestillä. Sitä voidaan käyttää vain kerran ja rajoitetun ajan sisällä kirjautumiseen."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Lähetä uudelleen {seconds} sekunnissa"
    },
    "SMSOTPForm": {
      "title": "Todennuskoodi tekstiviestillä"
    },
    "resendSMS": {
      "buttonLabel": "Lähetä uudestaan"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "title": "Onnistunut toiminta",
          "message": "Todennuskoodi lähetettiin tekstiviestillä. Sitä voidaan käyttää vain kerran ja rajoitetun ajan sisällä kirjautumiseen."
        },
        "asyncError": {
          "title": "Virhe",
          "message": "Viestiä ei voitu lähettää. Tarkista syöttösi."
        }
      }
    }
  },
  "he-IL": {
    "unlockWith": {
      "label": "הזן ואשר את קוד האימות שהתקבל."
    },
    "confirmCode": {
      "buttonLabel": "לְאַשֵׁר"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "title": "פעולה מוצלחת",
          "message": "קוד האימות נשלח באמצעות SMS. ניתן להשתמש בו רק פעם אחת ובתוך זמן מוגבל לכניסה."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "שלח מחדש בתוך {seconds} שניות"
    },
    "SMSOTPForm": {
      "title": "קוד אימות באמצעות SMS"
    },
    "resendSMS": {
      "buttonLabel": "שלח שוב"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "title": "פעולה מוצלחת",
          "message": "קוד האימות נשלח באמצעות SMS. ניתן להשתמש בו רק פעם אחת ובתוך זמן מוגבל לכניסה."
        },
        "asyncError": {
          "title": "שְׁגִיאָה",
          "message": "לא ניתן היה לשלוח את ההודעה. אנא בדוק את הערך שלך."
        }
      }
    }
  },
  "ga-IE": {
    "unlockWith": {
      "label": "Cuir isteach agus deimhnigh an cód fíordheimhnithe a fuarthas."
    },
    "confirmCode": {
      "buttonLabel": "Deimhnigh"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "title": "Gníomh rathúil",
          "message": "Seoladh an cód fíordheimhnithe trí SMS. Ní féidir é a úsáid ach uair amháin agus laistigh de thréimhse teoranta chun logáil isteach."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Athsheol i gceann {seconds} soicind"
    },
    "SMSOTPForm": {
      "title": "Cód fíordheimhnithe trí SMS"
    },
    "resendSMS": {
      "buttonLabel": "Seol arís"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "title": "Gníomh rathúil",
          "message": "Seoladh an cód fíordheimhnithe trí SMS. Ní féidir é a úsáid ach uair amháin agus laistigh de thréimhse teoranta chun logáil isteach."
        },
        "asyncError": {
          "title": "Earráid",
          "message": "Ní fhéadfaí an teachtaireacht a sheoladh. Seiceáil d’iontráil le do thoil."
        }
      }
    }
  },
  "ja-JP": {
    "unlockWith": {
      "label": "受信した認証コードを入力して確認します。"
    },
    "confirmCode": {
      "buttonLabel": "確認"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "title": "成功したアクション",
          "message": "認証コードは SMS 経由で送信されました。 ログイン時に1回限り、限られた時間内でのみ使用できます。"
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "{seconds}秒で再送信"
    },
    "SMSOTPForm": {
      "title": "SMS経由の認証コード"
    },
    "resendSMS": {
      "buttonLabel": "もう一度送信"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "title": "成功したアクション",
          "message": "認証コードは SMS 経由で送信されました。 ログイン時に1回限り、限られた時間内でのみ使用できます。"
        },
        "asyncError": {
          "title": "エラー",
          "message": "メッセージを送信できませんでした。 入力内容をご確認ください。"
        }
      }
    }
  },
  "hr-HR": {
    "unlockWith": {
      "label": "Unesite i potvrdite primljeni autentifikacijski kod."
    },
    "confirmCode": {
      "buttonLabel": "Potvrda"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "title": "Uspješna akcija",
          "message": "Kod za autentifikaciju poslan je putem SMS-a. Može se koristiti samo jednom i unutar ograničenog vremena za prijavu."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Ponovno slanje u {seconds} sekundama"
    },
    "SMSOTPForm": {
      "title": "Autentifikacijski kod putem SMS-a"
    },
    "resendSMS": {
      "buttonLabel": "Pošalji opet"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "title": "Uspješna akcija",
          "message": "Kod za autentifikaciju poslan je putem SMS-a. Može se koristiti samo jednom i unutar ograničenog vremena za prijavu."
        },
        "asyncError": {
          "title": "Pogreška",
          "message": "Poruka se nije mogla poslati. Molimo provjerite svoj unos."
        }
      }
    }
  },
  "lv-LV": {
    "unlockWith": {
      "label": "Ievadiet un apstipriniet saņemto autentifikācijas kodu."
    },
    "confirmCode": {
      "buttonLabel": "Apstiprināt"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "title": "Veiksmīga darbība",
          "message": "Autentifikācijas kods tika nosūtīts ar SMS. To var izmantot tikai vienu reizi un ierobežotā laikā, lai pieteiktos."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Atkārtoti nosūtīt pēc {seconds} sek."
    },
    "SMSOTPForm": {
      "title": "Autentifikācijas kods, izmantojot īsziņu"
    },
    "resendSMS": {
      "buttonLabel": "Sūtīt vēlreiz"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "title": "Veiksmīga darbība",
          "message": "Autentifikācijas kods tika nosūtīts ar SMS. To var izmantot tikai vienu reizi un ierobežotā laikā, lai pieteiktos."
        },
        "asyncError": {
          "title": "Kļūda",
          "message": "Ziņojumu nevarēja nosūtīt. Lūdzu, pārbaudiet savu ierakstu."
        }
      }
    }
  },
  "lt-LT": {
    "unlockWith": {
      "label": "Įveskite ir patvirtinkite gautą autentifikavimo kodą."
    },
    "confirmCode": {
      "buttonLabel": "Patvirtinti"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "title": "Sėkmingas veiksmas",
          "message": "Autentifikavimo kodas buvo išsiųstas SMS žinute. Jį galima naudoti tik vieną kartą ir per ribotą prisijungimo laiką."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Iš naujo išsiųsti po {seconds} sek."
    },
    "SMSOTPForm": {
      "title": "Autentifikavimo kodas SMS žinute"
    },
    "resendSMS": {
      "buttonLabel": "Siųsti dar kartą"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "title": "Sėkmingas veiksmas",
          "message": "Autentifikavimo kodas buvo išsiųstas SMS žinute. Jį galima naudoti tik vieną kartą ir per ribotą prisijungimo laiką."
        },
        "asyncError": {
          "title": "Klaida",
          "message": "Nepavyko išsiųsti pranešimo. Patikrinkite savo įrašą."
        }
      }
    }
  },
  "pt-PT": {
    "unlockWith": {
      "label": "Digite e confirme o código de autenticação recebido."
    },
    "confirmCode": {
      "buttonLabel": "Confirmar"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "title": "Ação bem-sucedida",
          "message": "O código de autenticação foi enviado via SMS. Ele só pode ser usado uma vez e dentro de um tempo limitado para fazer login."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Reenviar em {seconds} segundos"
    },
    "SMSOTPForm": {
      "title": "Código de autenticação via SMS"
    },
    "resendSMS": {
      "buttonLabel": "Envie novamente"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "title": "Ação bem-sucedida",
          "message": "O código de autenticação foi enviado via SMS. Ele só pode ser usado uma vez e dentro de um tempo limitado para fazer login."
        },
        "asyncError": {
          "title": "Erro",
          "message": "A mensagem não pôde ser enviada. Por favor, verifique sua entrada."
        }
      }
    }
  },
  "ro-RO": {
    "unlockWith": {
      "label": "Introduceți și confirmați codul de autentificare primit."
    },
    "confirmCode": {
      "buttonLabel": "Confirmare"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "title": "Acțiune reușită",
          "message": "Codul de autentificare a fost trimis prin SMS. Poate fi folosit o singură dată și într-un timp limitat pentru autentificare."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Retrimitere în {seconds} secunde"
    },
    "SMSOTPForm": {
      "title": "Cod de autentificare prin SMS"
    },
    "resendSMS": {
      "buttonLabel": "Trimite din nou"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "title": "Acțiune reușită",
          "message": "Codul de autentificare a fost trimis prin SMS. Poate fi folosit o singură dată și într-un timp limitat pentru autentificare."
        },
        "asyncError": {
          "title": "Eroare",
          "message": "Mesajul nu a putut fi trimis. Vă rugăm să verificați intrarea."
        }
      }
    }
  },
  "ru-RU": {
    "unlockWith": {
      "label": "Введите и подтвердите полученный код аутентификации."
    },
    "confirmCode": {
      "buttonLabel": "Подтвердить"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "title": "Успешное действие",
          "message": "Код аутентификации был отправлен в SMS. Его можно использовать только один раз и в течение ограниченного времени для входа в систему."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Отправить повторно через {seconds} секунд"
    },
    "SMSOTPForm": {
      "title": "Код аутентификации по SMS"
    },
    "resendSMS": {
      "buttonLabel": "Отправь еще раз"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "title": "Успешное действие",
          "message": "Код аутентификации был отправлен в SMS. Его можно использовать только один раз и в течение ограниченного времени для входа в систему."
        },
        "asyncError": {
          "title": "Ошибка",
          "message": "Сообщение не может быть отправлено. Пожалуйста, проверьте вашу запись."
        }
      }
    }
  },
  "sv-SE": {
    "unlockWith": {
      "label": "Ange och bekräfta den mottagna autentiseringskoden."
    },
    "confirmCode": {
      "buttonLabel": "Bekräfta"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "title": "Åtgärd utförd",
          "message": "Autentiseringskoden skickades via SMS. Den kan endast användas en gång och inom en begränsad tid för inloggning."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Skicka igen om {seconds} sekunder"
    },
    "SMSOTPForm": {
      "title": "Autentiseringskod via SMS"
    },
    "resendSMS": {
      "buttonLabel": "Skicka igen"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "title": "Åtgärd utförd",
          "message": "Autentiseringskoden skickades via SMS. Den kan endast användas en gång och inom en begränsad tid för inloggning."
        },
        "asyncError": {
          "title": "Fel",
          "message": "Meddelandet kunde inte skickas. Kontrollera ditt bidrag."
        }
      }
    }
  },
  "sk-SK": {
    "unlockWith": {
      "label": "Zadajte a potvrďte prijatý overovací kód."
    },
    "confirmCode": {
      "buttonLabel": "Potvrdiť"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "title": "Úspešná akcia",
          "message": "Overovací kód bol odoslaný prostredníctvom SMS. Dá sa použiť len raz a v obmedzenom čase na prihlásenie."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Opäť odoslať o {seconds} sekúnd"
    },
    "SMSOTPForm": {
      "title": "Autentifikačný kód prostredníctvom SMS"
    },
    "resendSMS": {
      "buttonLabel": "Poslať znova"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "title": "Úspešná akcia",
          "message": "Overovací kód bol odoslaný prostredníctvom SMS. Dá sa použiť len raz a v obmedzenom čase na prihlásenie."
        },
        "asyncError": {
          "title": "Chyba",
          "message": "Správu sa nepodarilo odoslať. Skontrolujte svoje zadanie."
        }
      }
    }
  },
  "cs-CZ": {
    "unlockWith": {
      "label": "Zadejte a potvrďte přijatý ověřovací kód."
    },
    "confirmCode": {
      "buttonLabel": "Potvrdit"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "title": "Úspěšná akce",
          "message": "Ověřovací kód byl zaslán prostřednictvím SMS. Lze jej použít pouze jednou a po omezenou dobu pro přihlášení."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Odeslat znovu za {seconds} s."
    },
    "SMSOTPForm": {
      "title": "Ověřovací kód prostřednictvím SMS"
    },
    "resendSMS": {
      "buttonLabel": "Poslat znovu"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "title": "Úspěšná akce",
          "message": "Ověřovací kód byl zaslán prostřednictvím SMS. Lze jej použít pouze jednou a po omezenou dobu pro přihlášení."
        },
        "asyncError": {
          "title": "Chyba",
          "message": "Zprávu se nepodařilo odeslat. Zkontrolujte prosím svůj údaj."
        }
      }
    }
  },
  "hu-HU": {
    "unlockWith": {
      "label": "Írja be és erősítse meg a kapott hitelesítési kódot."
    },
    "confirmCode": {
      "buttonLabel": "Megerősít"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "title": "Sikeres művelet",
          "message": "A hitelesítési kódot SMS-ben küldték el. Csak egyszer és korlátozott ideig használható a bejelentkezéshez."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Újraküldés {seconds} másodperc múlva"
    },
    "SMSOTPForm": {
      "title": "Hitelesítési kód SMS-ben"
    },
    "resendSMS": {
      "buttonLabel": "Küldd újra"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "title": "Sikeres művelet",
          "message": "A hitelesítési kódot SMS-ben küldték el. Csak egyszer és korlátozott ideig használható a bejelentkezéshez."
        },
        "asyncError": {
          "title": "Hiba",
          "message": "Az üzenetet nem sikerült elküldeni. Kérjük, ellenőrizze a bejegyzést."
        }
      }
    }
  },
  "fr-BE": {
    "unlockWith": {
      "label": "Entrez et confirmez le code d'authentification reçu."
    },
    "confirmCode": {
      "buttonLabel": "Confirmer"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "title": "Action réussie",
          "message": "Le code d'authentification a été envoyé par SMS. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour se connecter."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Renvoyer dans {seconds} secondes"
    },
    "SMSOTPForm": {
      "title": "Code d'authentification par SMS"
    },
    "resendSMS": {
      "buttonLabel": "Envoyer à nouveau"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "title": "Action réussie",
          "message": "Le code d'authentification a été envoyé par SMS. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour se connecter."
        },
        "asyncError": {
          "title": "Erreur",
          "message": "Le message n'a pas pu être envoyé. Veuillez vérifier votre saisie."
        }
      }
    }
  },
  "fr-LU": {
    "unlockWith": {
      "label": "Entrez et confirmez le code d'authentification reçu."
    },
    "confirmCode": {
      "buttonLabel": "Confirmer"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "title": "Action réussie",
          "message": "Le code d'authentification a été envoyé par SMS. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour se connecter."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Renvoyer dans {seconds} secondes"
    },
    "SMSOTPForm": {
      "title": "Code d'authentification par SMS"
    },
    "resendSMS": {
      "buttonLabel": "Envoyer à nouveau"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "title": "Action réussie",
          "message": "Le code d'authentification a été envoyé par SMS. Il ne peut être utilisé qu'une seule fois et dans un délai limité pour se connecter."
        },
        "asyncError": {
          "title": "Erreur",
          "message": "Le message n'a pas pu être envoyé. Veuillez vérifier votre saisie."
        }
      }
    }
  },
  "mt-MT": {
    "unlockWith": {
      "label": "Daħħal u kkonferma l-kodiċi ta 'awtentikazzjoni riċevut."
    },
    "confirmCode": {
      "buttonLabel": "Ikkonferma"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "title": "Azzjoni ta' suċċess",
          "message": "Il-kodiċi ta' awtentikazzjoni intbagħat permezz ta' SMS. Jista 'jintuża darba biss u fi żmien limitat għall-illoggjar."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Erġa' ibgħat fi {seconds} sekondi"
    },
    "SMSOTPForm": {
      "title": "Kodiċi ta 'awtentikazzjoni permezz ta' SMS"
    },
    "resendSMS": {
      "buttonLabel": "Ibgħat mill-ġdid"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "title": "Azzjoni ta' suċċess",
          "message": "Il-kodiċi ta' awtentikazzjoni intbagħat permezz ta' SMS. Jista 'jintuża darba biss u fi żmien limitat għall-illoggjar."
        },
        "asyncError": {
          "title": "Żball",
          "message": "Il-messaġġ ma setax jintbagħat. Jekk jogħġbok iċċekkja d-dħul tiegħek."
        }
      }
    }
  },
  "sl-SI": {
    "unlockWith": {
      "label": "Vnesite in potrdite prejeto kodo za preverjanje pristnosti."
    },
    "confirmCode": {
      "buttonLabel": "Potrdi"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "title": "Uspešno opravljeno",
          "message": "Koda za preverjanje pristnosti je bila poslana prek SMS-a. Uporabite ga lahko samo enkrat in v omejenem času za prijavo."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Ponovno pošlji v {seconds} sekundah"
    },
    "SMSOTPForm": {
      "title": "Koda za preverjanje pristnosti prek SMS-a"
    },
    "resendSMS": {
      "buttonLabel": "Pošlji ponovno"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "title": "Uspešno opravljeno",
          "message": "Koda za preverjanje pristnosti je bila poslana prek SMS-a. Uporabite ga lahko samo enkrat in v omejenem času za prijavo."
        },
        "asyncError": {
          "title": "Napaka",
          "message": "Sporočila ni bilo mogoče poslati. Prosimo, preverite svoj vnos."
        }
      }
    }
  },
  "tr-TR": {
    "unlockWith": {
      "label": "Alınan doğrulama kodunu girin ve onaylayın."
    },
    "confirmCode": {
      "buttonLabel": "Onayla"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "title": "Başarılı eylem",
          "message": "Kimlik doğrulama kodu SMS ile gönderildi. Giriş yapmak için yalnızca bir kez ve sınırlı bir süre içinde kullanılabilir."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "{seconds} saniye içinde yeniden gönder"
    },
    "SMSOTPForm": {
      "title": "SMS ile doğrulama kodu"
    },
    "resendSMS": {
      "buttonLabel": "Tekrar gönderin"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "title": "Başarılı eylem",
          "message": "Kimlik doğrulama kodu SMS ile gönderildi. Giriş yapmak için yalnızca bir kez ve sınırlı bir süre içinde kullanılabilir."
        },
        "asyncError": {
          "title": "Hata",
          "message": "Mesaj gönderilemedi. Lütfen girişinizi kontrol edin."
        }
      }
    }
  },
  "tr-CY": {
    "unlockWith": {
      "label": "Alınan doğrulama kodunu girin ve onaylayın."
    },
    "confirmCode": {
      "buttonLabel": "Onayla"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "title": "Başarılı eylem",
          "message": "Kimlik doğrulama kodu SMS ile gönderildi. Giriş yapmak için yalnızca bir kez ve sınırlı bir süre içinde kullanılabilir."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "{seconds} saniye içinde yeniden gönder"
    },
    "SMSOTPForm": {
      "title": "SMS ile doğrulama kodu"
    },
    "resendSMS": {
      "buttonLabel": "Tekrar gönderin"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "title": "Başarılı eylem",
          "message": "Kimlik doğrulama kodu SMS ile gönderildi. Giriş yapmak için yalnızca bir kez ve sınırlı bir süre içinde kullanılabilir."
        },
        "asyncError": {
          "title": "Hata",
          "message": "Mesaj gönderilemedi. Lütfen girişinizi kontrol edin."
        }
      }
    }
  },
  "uk-UA": {
    "unlockWith": {
      "label": "Введіть і підтвердьте отриманий код автентифікації."
    },
    "confirmCode": {
      "buttonLabel": "Підтвердити"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "title": "Успішна акція",
          "message": "Код автентифікації надіслано через SMS. Його можна використовувати лише один раз і протягом обмеженого часу для входу."
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "Надіслати повторно через {seconds} секунд"
    },
    "SMSOTPForm": {
      "title": "Код автентифікації через SMS"
    },
    "resendSMS": {
      "buttonLabel": "Надіслати знову"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "title": "Успішна акція",
          "message": "Код автентифікації надіслано через SMS. Його можна використовувати лише один раз і протягом обмеженого часу для входу."
        },
        "asyncError": {
          "title": "Помилка",
          "message": "Die Nachricht konnte nicht gesendet werden. Bitte prüfe deine Eingabe."
        }
      }
    }
  },
  "zh-CN": {
    "unlockWith": {
      "label": "输入并确认收到的验证码。"
    },
    "confirmCode": {
      "buttonLabel": "确认"
    },
    "smsSent": {
      "notificationPersist": {
        "success": {
          "title": "操作成功",
          "message": "验证码是通过短信发送的。 仅限登录一次，限时使用。"
        }
      }
    },
    "resendSMSInSeconds": {
      "buttonLabel": "在{seconds}内重新发送"
    },
    "SMSOTPForm": {
      "title": "通过短信验证码"
    },
    "resendSMS": {
      "buttonLabel": "重新发送"
    },
    "notification": {
      "sendSMS": {
        "success": {
          "title": "操作成功",
          "message": "验证码是通过短信发送的。 仅限登录一次，限时使用。"
        },
        "asyncError": {
          "title": "错误",
          "message": "无法发送消息。 请检查您的输入。"
        }
      }
    }
  }
}
</i18n>
